import {
  SET_ALERT_ERROR_MESSAGE, SET_ALERT_INFO_MESSAGE, SET_ALERT_INPUT_MESSAGE,
  SET_ALERT_WARNING_MESSAGE, SET_ALERT_CONFIRMATION_MESSAGE, SET_ALERT_FORCE_POPUP,
  SET_ALERT_SUCCESS_MESSAGE, SET_ALERT_PROCESSING_MESSAGE,
} from '../action';
import {
  ALERT_TYPE_INFO, ALERT_TYPE_WARNING, ALERT_TYPE_ERROR, ALERT_TYPE_CONFIRMATION, ALERT_TYPE_INPUT,
  ALERT_TYPE_SUCCESS,
  ALERT_TYPE_PROCESSING,
} from '../../constant';

const initialState = {
  error: '',
  info: '',
  warning: '',
  confirmation: '',
  success: '',
  input: '',
  loading: '',
  inputPlaceholder: '',
  inputLabel: '',
  subtitle: '',
  forcePopUp: false,
  type: ALERT_TYPE_ERROR,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT_ERROR_MESSAGE:
      return { ...state, error: action.text.message, type: ALERT_TYPE_ERROR };
    case SET_ALERT_INFO_MESSAGE:
      return { ...state, info: action.text, type: ALERT_TYPE_INFO };
    case SET_ALERT_WARNING_MESSAGE:
      return { ...state, warning: action.text, type: ALERT_TYPE_WARNING };
    case SET_ALERT_CONFIRMATION_MESSAGE:
      return { ...state, confirmation: action.text, type: ALERT_TYPE_CONFIRMATION };
    case SET_ALERT_SUCCESS_MESSAGE:
      return { ...state, success: action.text, type: ALERT_TYPE_SUCCESS };
    case SET_ALERT_PROCESSING_MESSAGE:
      return { ...state, loading: action.text, type: ALERT_TYPE_PROCESSING };
    case SET_ALERT_INPUT_MESSAGE:
      return {
        ...state,
        input: action.text,
        type: ALERT_TYPE_INPUT,
        inputPlaceholder: action.placeholder,
        inputLabel: action.label,
        subtitle: action.subtitle,
      };
    case SET_ALERT_FORCE_POPUP:
      return { ...state, forcePopUp: action.status };
    default: return state;
  }
};
