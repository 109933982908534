import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import {
  CERT_SCHEDULE_SETTING_DIALOG_MODE_SET, PICKER_MODE_DATE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE, RXFORM_SET_SCHEDULE_DIALOG,
} from '../../../constant';
import { StyledDialog } from '../../../component';
import LocalizedString from '../../../localization';
import { renderReduxFormDateTimePickerField } from '../../../../../redux-form-rendererer';
import { rxformValidateSetSchedule } from '../../../validation';
import { toMoment } from '../../../helper';

const SetScheduleDialog = ({
  selectedAssets, dialogVisibility, scheduling,
  handleSubmit, onAppear, onCancelPressed, onSavePressed,
  currDateValues, dialogMode,
}) => {
  useEffect(onAppear, []);

  return (
    <StyledDialog
      disableSubmitButton={!currDateValues}
      submitting={scheduling}
      visibility={dialogVisibility && dialogMode === CERT_SCHEDULE_SETTING_DIALOG_MODE_SET}
      onCancelPressed={onCancelPressed}
      onSubmitPressed={handleSubmit((values) => onSavePressed(values, selectedAssets))}
      title={LocalizedString.certificationScheduleSettingPage.titleSetSchedule}
    >
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.certificationScheduleSettingPage.placeholderDate}
              label={LocalizedString.certificationScheduleSettingPage.labelDate}
              pickerMode={PICKER_MODE_DATE}
              disabled={scheduling}
              required
              minDate={toMoment()}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default reduxForm({
  form: RXFORM_SET_SCHEDULE_DIALOG,
  validate: rxformValidateSetSchedule,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SetScheduleDialog);

SetScheduleDialog.propTypes = {
  selectedAssets: PropTypes.arrayOf(PropTypes.string).isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  scheduling: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  currDateValues: PropTypes.string,
  dialogMode: PropTypes.string.isRequired,
};

SetScheduleDialog.defaultProps = {
  currDateValues: '',
};
