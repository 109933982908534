import { reset } from 'redux-form';
import {
  addingEditingApprovalGroup, clearApprovalGroups, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { PAGE_MODE_TABLE, RXFORM_APPROVAL_GROUP } from '../../../constant';
import downloadApprovalGroupsAsync from './downloadApprovalGroupsAsync';
import { addApprovalGroup, editApprovalGroup } from '../../../helper';

export default (body) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingApprovalGroup(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiApprovalGroup;

    if (tappedId) {
      const newBody = {
        ...body,
        id: tappedId,
      };
      await editApprovalGroup(newBody, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addApprovalGroup(body, token);
    }

    dispatch(reset(RXFORM_APPROVAL_GROUP));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingEditingApprovalGroup(false));
  }
};
