import {
  uploadingPDFTemplate, setPDFTemplate, setUploadingPDFVisibility, setPDFData,
} from '../simple-action';
import { uploadPDFTemplate } from '../../../helper';

export default (values) => async (dispatch, getState) => {
  const {
    authentication, uiUploadPdf,
  } = getState();
  const { token } = authentication;
  const { pdfUrl } = uiUploadPdf;

  const pdfData = {
    name: values.name || '',
    description: values.description || '',
  };

  try {
    dispatch(uploadingPDFTemplate(true));

    const data = await uploadPDFTemplate(
      pdfUrl,
      token,
    );

    if (data && pdfData) {
      dispatch(setPDFTemplate(data.path));
      dispatch(setPDFData(pdfData));
    }
  } finally {
    dispatch(uploadingPDFTemplate(false));
    dispatch(setUploadingPDFVisibility(false));
  }
};
