import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, IconButton, InputAdornment, makeStyles, Typography,
} from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import {
  FunctionalPage, MapDrawer, SectionTitle,
} from '../../component';
import {
  APPLIED_ON_CLOCK_IN, APPLIED_ON_CLOCK_OUT, CLOCK_IN_OUT_LOCATION_STATUS, CLOCK_IN_OUT_STATUS,
  CLOCK_IN_OUT_STATUS_TREND_EARLY, CLOCK_IN_OUT_STATUS_TREND_LATE, CLOCK_IN_OUT_STATUS_TREND_ONTIME,
  COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_SECONDARY, DATE_TIME_FORMAT_WITHOUT_PIPE, DRAW_MODE_MARKER,
  ENUM_FIELD_PHOTO, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PICKER_MODE_DATE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_TIME,
  RXFIELD_HISTORY_ATTENDANCE_DATE, RXFIELD_HISTORY_CLOCK_IN_TIME,
  RXFIELD_HISTORY_CLOCK_IN_LOCATION_NAME, RXFIELD_HISTORY_CLOCK_IN_LOCATION_STATUS,
  RXFIELD_HISTORY_CLOCK_IN_NOTES, RXFIELD_HISTORY_CLOCK_IN_STATUS,
  RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LATITUDE, RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LONGITUDE,
  RXFIELD_HISTORY_CLOCK_OUT_TIME, RXFIELD_HISTORY_CLOCK_OUT_LOCATION_NAME,
  RXFIELD_HISTORY_CLOCK_OUT_LOCATION_STATUS, RXFIELD_HISTORY_CLOCK_OUT_NOTES,
  RXFIELD_HISTORY_CLOCK_OUT_STATUS, RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LATITUDE,
  RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LONGITUDE, RXFIELD_HISTORY_COMPANY,
  RXFIELD_HISTORY_DEPARTMENT, RXFIELD_HISTORY_DIVISION, RXFIELD_HISTORY_EMPLOYEE,
  RXFIELD_HISTORY_EMPLOYEE_ID, RXFIELD_HISTORY_FULLNAME, RXFIELD_HISTORY_WORKING_HOUR,
  RXFORM_HISTORY, RXSTATE_HISTORIES, RXSTATE_HISTORY_PAGE, RXFIELD_HISTORY_JOB_TITLE,
  RXFIELD_HISTORY_BRANCH_NAME, RXFIELD_HISTORY_MANAGER_NAME, RXFIELD_HISTORY_PROFILE_PICTURE,
  FILTER_TYPE_MULTIPLE_VALUES, RXFIELD_HISTORY_BRANCH, RXFIELD_HISTORY_CURRENT_BRANCH_NAME,
} from '../../constant';
import { renderReduxFormDateTimePickerField, renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  gridWrapper: {
    margin: '0px 0px 20px 10px',
  },
  heading: {
    color: COLOR_SECONDARY,
    fontWeight: 'bold',
    margin: '20px 0px 20px 10px',
  },
}));

const getClockInColor = (status) => {
  switch (status) {
    case CLOCK_IN_OUT_STATUS_TREND_EARLY: return COLOR_PRIMARY;
    case CLOCK_IN_OUT_STATUS_TREND_LATE: return COLOR_DANGEROUS;
    case CLOCK_IN_OUT_STATUS_TREND_ONTIME: return COLOR_SECONDARY;
    default: return undefined;
  }
};

const getClockOutColor = (status) => {
  switch (status) {
    case CLOCK_IN_OUT_STATUS_TREND_EARLY: return COLOR_DANGEROUS;
    case CLOCK_IN_OUT_STATUS_TREND_LATE: return COLOR_PRIMARY;
    case CLOCK_IN_OUT_STATUS_TREND_ONTIME: return COLOR_SECONDARY;
    default: return undefined;
  }
};

const renderFields = (splitArr, classes, isImageMoreThanOne) => splitArr.map((x) => (
  <Grid item key={x.field.name} className={classes.gridWrapper}>
    {x.field.fieldType === ENUM_FIELD_PHOTO ? (
      <Field
        name={x.field.name}
        component={renderReduxFormImageInputField}
        label={x.field.name}
        defaultValue={x.value}
        disabled
        useFullWidthImage
        fullImageWidth={isImageMoreThanOne ? '100%' : '48%'}
      />
    ) : (
      <Field
        name={x.field.name}
        component={renderReduxFormOutlinedTextField}
        placeholder={x.field.name}
        label={x.field.name}
        disabled
        defaultValue={x.value}
      />
    )}
  </Grid>
));

const renderAdditionalFields = (defaultAdditionalFieldsValue, classes) => {
  const hasAdditionalFields = defaultAdditionalFieldsValue.length > 0;
  const additionalOtherFields = hasAdditionalFields
    ? defaultAdditionalFieldsValue.filter((x) => x.field.fieldType !== ENUM_FIELD_PHOTO) : [];
  const additionalOtherFieldsLength = additionalOtherFields.length;
  const additionalPhotoFields = hasAdditionalFields
    ? defaultAdditionalFieldsValue.filter((x) => x.field.fieldType === ENUM_FIELD_PHOTO) : [];
  const additionalPhotoFieldsLength = additionalPhotoFields.length;

  return hasAdditionalFields && (
    <Grid item sm={12}>
      <Typography variant="subtitle1" className={classes.heading}>
        {LocalizedString.historyScreen.labelAdditionalFields}
      </Typography>

      {additionalOtherFieldsLength > 0 && (
      <Grid container spacing={1}>
        <Grid item sm md>
          {renderFields(additionalOtherFields
            .slice(0, Math.round(additionalOtherFieldsLength / 2)),
          classes)}
        </Grid>

        {additionalOtherFieldsLength > 1 && (
        <Grid item sm md>
          {renderFields(
            additionalOtherFields.slice(Math.round(additionalOtherFieldsLength / 2)),
            classes,
          )}
        </Grid>
        )}
      </Grid>
      )}

      {additionalPhotoFieldsLength > 0 && (
      <Grid container spacing={1}>
        <Grid item sm md>
          {renderFields(additionalPhotoFields
            .slice(0, Math.round(additionalPhotoFieldsLength / 2)),
          classes,
          additionalPhotoFieldsLength > 1)}
        </Grid>

        {additionalPhotoFieldsLength > 1 && (
        <Grid item sm md>
          {renderFields(
            additionalPhotoFields.slice(Math.round(additionalPhotoFieldsLength / 2)),
            classes,
          )}
        </Grid>
        )}
      </Grid>
      )}
    </Grid>
  );
};

const renderDialogContent = (defaultAdditionalFieldsValue, defaultClockInCenter,
  defaultClockInMarker, defaultClockInStatus, defaultClockOutCenter, defaultClockOutMarker,
  defaultClockOutStatus, classes, isProfileAccessAllowed, defaultProfilePictureValue,
  downloadingProfileDetail) => (
    <div>
      {!downloadingProfileDetail && isProfileAccessAllowed && (
      <Field
        name={RXFIELD_HISTORY_PROFILE_PICTURE}
        component={renderReduxFormImageInputField}
        label={LocalizedString.historyScreen.placeholderProfilePicture}
        defaultValue={defaultProfilePictureValue}
        disabled
      />
      )}

      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_HISTORY_EMPLOYEE_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderEmployeeId}
                label={LocalizedString.historyScreen.placeholderEmployeeId}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_HISTORY_FULLNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderName}
                label={LocalizedString.historyScreen.placeholderName}
                disabled
              />
            </Grid>
            {!downloadingProfileDetail && isProfileAccessAllowed && (
            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_JOB_TITLE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderJobTitle}
                  label={LocalizedString.historyScreen.placeholderJobTitle}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_DEPARTMENT}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderDepartment}
                  label={LocalizedString.historyScreen.placeholderDepartment}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_DIVISION}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderDivision}
                  label={LocalizedString.historyScreen.placeholderDivision}
                  disabled
                />
              </Grid>
            </Grid>
            )}
          </Grid>
          {!downloadingProfileDetail && isProfileAccessAllowed && (
            <Grid item sm md>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_COMPANY}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderCompanyName}
                  label={LocalizedString.historyScreen.placeholderCompanyName}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_CURRENT_BRANCH_NAME}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderBranch}
                  label={LocalizedString.historyScreen.placeholderBranch}
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  name={RXFIELD_HISTORY_MANAGER_NAME}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderManager}
                  label={LocalizedString.historyScreen.placeholderManager}
                  disabled
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.historyScreen.labelClockIn} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_HISTORY_ATTENDANCE_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.historyScreen.placeholderAttendanceDate}
              disabled
              pickerMode={PICKER_MODE_DATE}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_CLOCK_IN_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.historyScreen.placeholderClockInTime}
              disabled
              pickerMode={PICKER_MODE_TIME}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_CLOCK_IN_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderClockInStatus}
              label={LocalizedString.historyScreen.placeholderClockInStatus}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled edge="end">
                      <WatchLater style={{ color: getClockInColor(defaultClockInStatus) }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_CLOCK_IN_LOCATION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderLocation}
              label={LocalizedString.historyScreen.placeholderLocation}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_BRANCH_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderBranch}
              label={LocalizedString.historyScreen.placeholderBranch}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_CLOCK_IN_LOCATION_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderClockInStatus}
              label={LocalizedString.historyScreen.placeholderClockInStatus}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_HISTORY_CLOCK_IN_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderNotes}
              label={LocalizedString.historyScreen.placeholderNotes}
              disabled
              multiline
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_HISTORY_WORKING_HOUR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.historyScreen.placeholderWorkingHour}
              label={LocalizedString.historyScreen.placeholderWorkingHour}
              disabled
            />
          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LATITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderLatitutde}
                label={LocalizedString.historyScreen.placeholderLatitutde}
                disabled
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_IN_USER_REPORTED_LONGITUDE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderLongitude}
                label={LocalizedString.historyScreen.placeholderLongitude}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{LocalizedString.historyScreen.placeholderMap}</Typography>
            <MapDrawer
              drawingMode={DRAW_MODE_MARKER}
              center={defaultClockInCenter}
              marker={defaultClockInMarker}
              disabled
              containerStyle={{ height: '300px' }}
            />
          </Grid>
        </Grid>
      </Grid>

      {renderAdditionalFields(defaultAdditionalFieldsValue
        .filter((x) => x.field.appliedOn === APPLIED_ON_CLOCK_IN), classes)}

      <SectionTitle title={LocalizedString.historyScreen.labelClockOut} />

      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_OUT_TIME}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.historyScreen.placeholderClockOutTime}
                disabled
                pickerMode={PICKER_MODE_TIME}
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_OUT_STATUS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderClockInStatus}
                label={LocalizedString.historyScreen.placeholderClockOutStatus}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled edge="end">
                        <WatchLater style={{ color: getClockOutColor(defaultClockOutStatus) }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_OUT_LOCATION_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderLocation}
                label={LocalizedString.historyScreen.placeholderLocation}
                disabled
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_HISTORY_CLOCK_OUT_LOCATION_STATUS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderClockOutStatus}
                label={LocalizedString.historyScreen.placeholderClockOutStatus}
                disabled
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_HISTORY_CLOCK_OUT_NOTES}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.historyScreen.placeholderNotes}
                label={LocalizedString.historyScreen.placeholderNotes}
                disabled
                multiline
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item container>
              <Grid item sm md>
                <Field
                  name={RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LATITUDE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderLatitutde}
                  label={LocalizedString.historyScreen.placeholderLatitutde}
                  disabled
                />
              </Grid>
              <Grid item sm md>
                <Field
                  name={RXFIELD_HISTORY_CLOCK_OUT_USER_REPORTED_LONGITUDE}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.historyScreen.placeholderLongitude}
                  label={LocalizedString.historyScreen.placeholderLongitude}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography>{LocalizedString.historyScreen.placeholderMap}</Typography>
              <MapDrawer
                drawingMode={DRAW_MODE_MARKER}
                center={defaultClockOutCenter}
                marker={defaultClockOutMarker}
                disabled
                containerStyle={{ height: '300px' }}
              />
            </Grid>
          </Grid>
        </Grid>

        {renderAdditionalFields(defaultAdditionalFieldsValue
          .filter((x) => x.field.appliedOn === APPLIED_ON_CLOCK_OUT), classes)}
      </Grid>
    </div>
);

const HistoryPage = ({
  initialValues,
  branches, companies, departments, divisions,
  downloadingProfileDetail, employees, loadingBranches, isProfileAccessAllowed,
  downloading, loadingCompanies, loadingDepartments, loadingDivisions, loadingEmployees,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onChangeBranchText, onChangeCompanyText, onChangeDepartmentText,
  onChangeDivisionText, onChangeEmployeeText, onChangePage, onChangePageSize, onDownloadPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  onErrorConfirmPressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_HISTORIES}
      uiPage={RXSTATE_HISTORY_PAGE}
      filterColumns={[
        {
          title: LocalizedString.historyScreen.labelDate,
          field: RXFIELD_HISTORY_CLOCK_IN_TIME,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.historyScreen.labelEmployee,
          field: RXFIELD_HISTORY_EMPLOYEE,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: employees,
          loading: loadingEmployees,
          onChangeFilterText: onChangeEmployeeText,
        },
        {
          title: LocalizedString.historyScreen.labelDepartment,
          field: RXFIELD_HISTORY_DEPARTMENT,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: departments,
          loading: loadingDepartments,
          onChangeFilterText: onChangeDepartmentText,
        },
        {
          title: LocalizedString.historyScreen.labelDivision,
          field: RXFIELD_HISTORY_DIVISION,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: divisions,
          loading: loadingDivisions,
          onChangeFilterText: onChangeDivisionText,
        },
        {
          title: LocalizedString.historyScreen.labelCompany,
          field: RXFIELD_HISTORY_COMPANY,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: companies,
          loading: loadingCompanies,
          onChangeFilterText: onChangeCompanyText,
        },
        {
          title: LocalizedString.historyScreen.labelClockInStatus,
          field: RXFIELD_HISTORY_CLOCK_IN_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: CLOCK_IN_OUT_STATUS,
        },
        {
          title: LocalizedString.historyScreen.labelClockOutStatus,
          field: RXFIELD_HISTORY_CLOCK_OUT_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: CLOCK_IN_OUT_STATUS,
        },
        {
          title: LocalizedString.historyScreen.placeholderBranch,
          field: RXFIELD_HISTORY_BRANCH,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: branches,
          loading: loadingBranches,
          onChangeFilterText: onChangeBranchText,
        },
        {
          title: LocalizedString.historyScreen.labelClockInLocationStatus,
          field: RXFIELD_HISTORY_CLOCK_IN_LOCATION_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: CLOCK_IN_OUT_LOCATION_STATUS,
        },
        {
          title: LocalizedString.historyScreen.labelClockOutLocationStatus,
          field: RXFIELD_HISTORY_CLOCK_OUT_LOCATION_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: CLOCK_IN_OUT_LOCATION_STATUS,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.historyScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.historyScreen.labelEmployeeId, field: 'profile.employeeId', sorting: !downloading },
        { title: LocalizedString.historyScreen.labelFullName, field: 'profile.fullName', sorting: !downloading },
        {
          title: LocalizedString.historyScreen.labelClockIn,
          field: 'clockInTime',
          sorting: !downloading,
          render: ({ clockInTime, startWorkingHourTimeZone }) => (clockInTime
            ? toMoment(clockInTime, startWorkingHourTimeZone)
              .format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.historyScreen.labelClockInStatus, field: 'clockInStatus', sorting: !downloading },
        {
          title: LocalizedString.historyScreen.labelClockOut,
          field: 'clockOutTime',
          sorting: !downloading,
          render: ({ clockOutTime, endWorkingHourTimeZone }) => (clockOutTime
            ? toMoment(clockOutTime, endWorkingHourTimeZone)
              .format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        { title: LocalizedString.historyScreen.labelClockOutStatus, field: 'clockOutStatus', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={(id) => onViewPressed(id, isProfileAccessAllowed)}
      title={LocalizedString.historyScreen.title}
      disableCreate
      disableDelete
      disableEdit
      savePermissionName="ATTENDANCE_GET_HISTORY"
      useFullWidth
      onConfirmDeletePressed={onErrorConfirmPressed}
    >
      {renderDialogContent(initialValues.additionalFields, initialValues.clockInCenter,
        initialValues.clockInMarker, initialValues.clockInStatus, initialValues.clockOutCenter,
        initialValues.clockOutMarker, initialValues.clockOutStatus, classes, isProfileAccessAllowed,
        initialValues.profilePicture, downloadingProfileDetail)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_HISTORY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(HistoryPage);

HistoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  companies: PropTypes.arrayOf(PropTypes.string).isRequired,
  departments: PropTypes.arrayOf(PropTypes.string).isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
  employees: PropTypes.arrayOf(SimpleDataShape).isRequired,
  branches: PropTypes.arrayOf(PropTypes.string).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  loadingDepartments: PropTypes.bool.isRequired,
  loadingDivisions: PropTypes.bool.isRequired,
  loadingEmployees: PropTypes.bool.isRequired,
  loadingBranches: PropTypes.bool.isRequired,
  isProfileAccessAllowed: PropTypes.bool.isRequired,
  downloadingProfileDetail: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangeDivisionText: PropTypes.func.isRequired,
  onChangeEmployeeText: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onErrorConfirmPressed: PropTypes.func.isRequired,
};
