import { downloadingAssets, setAssets } from '../simple-action';
import { downloadAssets, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, assets, uiAsset,
  } = getState();
  const { token } = authentication;
  const { meta } = assets;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAsset;

  try {
    dispatch(downloadingAssets(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAssets(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAssets(list));
    }
  } finally {
    dispatch(downloadingAssets(false));
  }
};
