import { resendingEvent } from '../simple-action';
import { resendEvent } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(resendingEvent(true));

    const { token } = getState().authentication;
    const { selectedResendEventOption, tappedId } = getState().uiApproval;

    await resendEvent(selectedResendEventOption, tappedId, token);
  } finally {
    dispatch(resendingEvent(false));
  }
};
