import { connect } from 'react-redux';
import { reset } from 'redux-form';
import UploadPDFPage from './upload-pdf.presentation';
import { setAlertErrorMessage, setTemplateTotalPages, uploadPDFTemplateAsync } from '../../redux/action';
import { REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFORM_UPLOAD_PDF } from '../../constant';
import {
  setPDFUrl, setUploadingPDFVisibility,
} from '../../redux/action/simple-action';

const getTemplatePdf = (data) => {
  const url = data ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${data}` : '';

  return url;
};

const mapStateToProps = (state) => ({
  visibility: state.uiUploadPdf.visibility,
  pageMode: state.uiFunctionalPage.pageMode,
  uploadingPdfTemplate: state.uiUploadPdf.uploadingPdfTemplate,
  templatePdf: getTemplatePdf(state.uiUploadPdf.templatePdf),
  totalPages: state.uiUploadPdf.totalPages,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setPDFUrl(''));
  },
  onFileChange: async (url) => {
    dispatch(setPDFUrl(url));
  },
  onDocumentLoadSuccess: (numPages) => {
    dispatch(setTemplateTotalPages(numPages));
  },
  onDocumentLoadError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onUploadButtonPressed: () => {
    dispatch(reset(RXFORM_UPLOAD_PDF));
    dispatch(setUploadingPDFVisibility(true));
  },
  onCanceledPressed: () => {
    dispatch(setUploadingPDFVisibility(false));
  },
  onClosePressed: () => {
    dispatch(setUploadingPDFVisibility(false));
  },
  onSavePressed: (values) => {
    dispatch(uploadPDFTemplateAsync(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPDFPage);
