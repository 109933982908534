import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  transformDropdownData,
  transformInitialValues,
  toMoment,
  debounceSearch, transformUserDropdownData, getTimeSymbol,
} from '../../helper';
import LocalizedString from '../../localization';
import EventRegistrationPage from './event-registration.presentation';
import {
  INITIAL_ORDER_BY_EVENT_REGISTRATIONS, MENUID_EVENT_EVENT_REGISTRATION,
  PAGE_MODE_TABLE, RXFORM_EVENT_REGISTRATION, DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  clearEventRegistrations, clearEvents, clearProfiles, saveEventRegistrationsAsync,
  setActiveSideMenuItem, setAlertErrorMessage, setEventAdvancedFilterDialogSelectedFilterString,
  setEventRegistrationAdvancedFilterDialogSelectedFilterString,
  setEventRegistrationSearchText, setEventRegistrationSelectedOrderBy,
  setEventRegistrationSelectedPageSize, setEventRegistrationTappedId,
  setEventSearchText, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText, downloadEventRegistrationAsync,
  downloadEventRegistrationsAsync, downloadEventsAsync, setAlertInputMessage,
  approveEventRegistrationAsync, rejectEventRegistrationAsync, setProfileManagerSearchText,
  clearManagers,
} from '../../redux/action';
import { downloadManagersAsync, downloadProfilesAsync } from '../../../../redux/action';
import { clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText } from '../../../attendance/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getApproveRejectedBy = (approvedRejectedBy) => (approvedRejectedBy.email
  ? `${approvedRejectedBy?.fullName} (${approvedRejectedBy?.email})`
  : approvedRejectedBy?.fullName);

const getInitialValues = (state) => {
  const { eventRegistrations, uiEventRegistration, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventRegistrations.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        approvedRejectedOn: found.approvedRejectedOn ? toMoment(found.approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.event?.location.city?.province?.timeZone) : '',
        clockInStatus: found?.clockInStatus ? found?.clockInStatus : '',
        paymentChannel: found?.payment?.paymentChannel ? found?.payment?.paymentChannel : '',
        paymentStatus: found?.payment?.paymentStatus ? found?.payment?.paymentStatus : '',
        eventStartTime: found?.event?.startTime ? toMoment(found?.event?.startTime, found.event?.location.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        eventEndTime: found?.event?.endTime ? toMoment(found?.event?.endTime, found.event?.location.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        price: found?.price === 0 ? LocalizedString.eventRegistrationPage.labelFree : found?.price,
        approvedRejectedBy: found?.approvedRejectedBy ? getApproveRejectedBy(found?.approvedRejectedBy) : '',
        eventTimeZone: getTimeSymbol(found.event?.location?.city?.province?.timeZone),
      })
    : {
      clockInTime: '',
      eventStartTime: '',
      eventEndTime: '',
    };
  return initVal;
};

const searchManagerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchEmployeeDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  events: transformDropdownData(state.events.data),
  profiles: transformUserDropdownData(state.profiles.data),
  employees: transformUserDropdownData(state.employees.data),
  managers: transformUserDropdownData(state.managers.data),
  eventRegistrations: state.eventRegistrations.data,
  addingEditing: state.uiEventRegistration.addingEditing,
  downloadingDeleting: state.uiEventRegistration.downloadingDeleting,
  downloading: state.uiEventRegistration.downloading,
  downloadingEvents: state.uiEvent.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  downloadingEmployee: state.uiHistory.downloading,
  downloadingManagers: state.uiProfile.downloadingManagers,
  resending: state.uiEventRegistration.resending,
  approving: state.uiEventRegistration.approving,
  rejecting: state.uiEventRegistration.rejecting,
  initialValues: getInitialValues(state),
});
const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setEventRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_EVENT_REGISTRATION));
    dispatch(setEventRegistrationSearchText(''));
    dispatch(clearEventRegistrations());
    dispatch(setEventRegistrationSelectedPageSize(20));
    dispatch(setEventRegistrationSelectedOrderBy(INITIAL_ORDER_BY_EVENT_REGISTRATIONS));
    dispatch(downloadEventRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/event/, 'event.id')
      .replace(/participant/, 'user.id')
      .replaceAll(/registeredOn/g, 'payment.createdDate')
      .replaceAll(/approvedOn/g, 'approvedRejectedOn')
      .replace(/approvedBy/, 'approvedRejectedBy.id')
      .replace(/rejectedBy/, 'approvedRejectedBy.id')
      .replace(/paymentChannel/, 'payment.paymentChannel')
      .replace(/paymentStatus/, 'payment.paymentStatus');
    dispatch(setEventRegistrationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEventRegistrations());
    dispatch(downloadEventRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: () => {
    dispatch(setAlertInputMessage(
      LocalizedString.eventRegistrationPage.msgApproveEventRegistration,
      LocalizedString.eventRegistrationPage.placeholderNotes,
    ));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT_REGISTRATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventRegistrationSelectedPageSize(pageSize));
    dispatch(downloadEventRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeEventText: (text) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch);
    }
  },
  onChangeParticipantText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onChangeApprovedByText: (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEmployeeDebounce(dispatch);
    }
  },
  onChangeRejectedByText: (text) => {
    dispatch(setProfileManagerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchManagerDebounce(dispatch);
    }
  },
  onConfirmApproveRejectPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.eventRegistrationPage.msgApproveEventRegistration:
        dispatch(approveEventRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.eventRegistrationPage.msgRejectEventRegistration:
        dispatch(rejectEventRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onDownloadPressed: () => {
    dispatch(saveEventRegistrationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setEventRegistrationSelectedPageSize(pageSize));
    dispatch(clearEventRegistrations());
    dispatch(downloadEventRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.eventRegistrationPage.msgRejectEventRegistration,
      LocalizedString.eventRegistrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventRegistrations());
    dispatch(setEventRegistrationSelectedOrderBy(INITIAL_ORDER_BY_EVENT_REGISTRATIONS));
    dispatch(downloadEventRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventRegistrationSearchText(text));
      dispatch(clearEventRegistrations());
      await dispatch(downloadEventRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventRegistrationSelectedOrderBy(orderBy));
    dispatch(clearEventRegistrations());
    dispatch(downloadEventRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setEventRegistrationTappedId(id));
    dispatch(downloadEventRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventRegistrationPage);
