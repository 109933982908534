import { connect } from 'react-redux';
import { reset, change } from 'redux-form';
import moment from 'moment';
import {
  ENUM_FIELD_DATE, ENUM_FIELD_DATE_TIME, ENUM_FIELD_TIME, DATE_TIME_FORMAT_WITHOUT_PIPE,
  INITIAL_ORDER_BY_ADDITIONAL_FIELDS, MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD,
  PAGE_MODE_TABLE, RXFIELD_ADDITIONAL_FIELD_APPLIED_ON, RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE,
  RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR,
  RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA, RXFIELD_ADDITIONAL_FIELD_OPTION_MODE,
  RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR, RXFIELD_ADDITIONAL_FIELD_PARENT,
  RXFIELD_ADDITIONAL_FIELD_PICKER_MODE, RXFIELD_ADDITIONAL_FIELD_READ_ONLY,
  RXFIELD_ADDITIONAL_FIELD_REQUIRED, RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE,
  RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE, RXFIELD_ADDITIONAL_FIELD_VISIBLE,
  RXFORM_ADDITIONAL_FIELD, SIMPLE_DATE_FORMAT, TIME_FORMAT,
} from '../../constant';
import {
  sortAsc, transformDropdownData, transformInitialValues, toMoment,
} from '../../../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearAdditionalFieldParents, clearAdditionalFields, setActiveSideMenuItem,
  setAdvancedFilterDialogSelectedFilterString, setAlertConfirmationMessage, setAlertErrorMessage,
  setAdditionalFieldSearchText, setAdditionalFieldParentSearchText,
  setAdditionalFieldSelectedPageSize, setAdditionalFieldSelectedAppliedOn,
  setAdditionalFieldSelectedFieldType, setAdditionalFieldSelectedLogicalOperator,
  setAdditionalFieldSelectedMustUseCamera, setAdditionalFieldSelectedOptionMode,
  setAdditionalFieldSelectedOptionValueSeparator, setAdditionalFieldSelectedOrderBy,
  setAdditionalFieldSelectedParent, setAdditionalFieldSelectedPickerMode,
  setAdditionalFieldSelectedTextKeyboardType, setAdditionalFieldSelectedTextMultiline,
  setAdditionalFieldTappedId,
  addEditAdditionalFieldAsync, downloadAdditionalFieldParentsAsync, downloadAdditionalFieldsAsync,
  downloadDeleteAdditionalFieldAsync, enableDisableAdditionalFieldAsync,
} from '../../redux/action';
import AdditionalFieldPage from './additional-field.presentation';

const getDateTimeFormat = (fieldType, dateTime) => {
  switch (fieldType) {
    case ENUM_FIELD_DATE: return moment.utc(dateTime).format(SIMPLE_DATE_FORMAT);
    case ENUM_FIELD_DATE_TIME: return toMoment(dateTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE);
    case ENUM_FIELD_TIME: return moment.utc(dateTime).format(TIME_FORMAT);
    default: return null;
  }
};

const getInitialValues = (state) => {
  const { additionalFields, uiFunctionalPage, uiAdditionalField } = state;
  const { data } = additionalFields;
  const { downloadingDeleting, tappedId } = uiAdditionalField;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    parent: found.parent ? { label: found.parent.name, value: found.parent.id } : { label: '', value: '' },
    dateTimeMaxValue: found.dateTimeMaxValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMaxValue) : null,
    dateTimeMinValue: found.dateTimeMinValue
      ? getDateTimeFormat(found.fieldType, found.dateTimeMinValue) : null,
    showIfParentValue: found.parent ? found.showIfParentValue : '',
    textMultiline: found.textMultiline
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
    mustUseCamera: found.mustUseCamera
      ? { label: GlobalLocalizedString.common.labelYes, value: true }
      : { label: GlobalLocalizedString.common.labelNo, value: false },
  }) : {
    name: '',
    label: '',
    placeHolder: '',
    validationMessage: '',
    defaultValue: '',
    order: '',
    required: false,
    visible: true,
    readOnly: false,
    textMultiline: false,
    textKeyboardType: null,
    fieldType: '',
    pickerMode: '',
    optionMode: null,
    optionValues: '',
    optionValueSeparator: null,
    sectionId: '',
    sectionLabel: '',
    appliedOn: '',
    textMinLength: '',
    textMaxLength: '',
    textRegexValidation: null,
    intMinValue: '',
    intMaxValue: '',
    floatMinValue: '',
    floatMaxValue: '',
    dateTimeMinValue: null,
    dateTimeMaxValue: null,
    parent: null,
    showIfParentValue: null,
    showIfParentValueLogicalOperator: null,
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiAdditionalField.addingEditing,
  downloading: state.uiAdditionalField.downloading,
  downloadingDeleting: state.uiAdditionalField.downloadingDeleting,
  enablingDisabling: state.uiAdditionalField.enablingDisabling,
  initialValues: getInitialValues(state),
  parents: Object.keys(state.additionalFieldParents.data).length > 0
  && !state.uiAdditionalFielddownloadingParents
    ? transformDropdownData(state.additionalFieldParents.data)
      .filter((x) => x.value !== state.uiAdditionalField.tappedId)
      .sort((a, b) => sortAsc(a.label.toLowerCase(), b.label.toLowerCase())) : [],
  selectedParent: state.uiAdditionalField.selectedParent,
  selectedFieldType: state.uiAdditionalField.selectedFieldType,
  loadingParents: state.uiAdditionalField.downloadingParents,
  currentAdditionalFieldStatus: state.additionalFields.data[state.uiFunctionalPage.tappedId]
    ? state.additionalFields.data[state.uiFunctionalPage.tappedId].status : '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_MANAGEMENT_ADDITIONAL_FIELD));
    dispatch(setAdditionalFieldSearchText(''));
    dispatch(clearAdditionalFields());
    dispatch(setAdditionalFieldSelectedPageSize(20));
    dispatch(setAdditionalFieldSelectedOrderBy(INITIAL_ORDER_BY_ADDITIONAL_FIELDS));
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppliedOnOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedAppliedOn(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_APPLIED_ON, option));
    } else {
      dispatch(setAdditionalFieldSelectedAppliedOn(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_APPLIED_ON, ''));
    }
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearAdditionalFields());
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(reset(RXFORM_ADDITIONAL_FIELD));
  },
  onCancelPressed: () => {
    dispatch(setAdditionalFieldParentSearchText(''));
    dispatch(setAdditionalFieldSelectedAppliedOn(''));
    dispatch(setAdditionalFieldSelectedFieldType(''));
    dispatch(setAdditionalFieldSelectedLogicalOperator(''));
    dispatch(setAdditionalFieldSelectedMustUseCamera(''));
    dispatch(setAdditionalFieldSelectedOptionMode(''));
    dispatch(setAdditionalFieldSelectedOptionValueSeparator(''));
    dispatch(setAdditionalFieldSelectedParent(''));
    dispatch(setAdditionalFieldSelectedPickerMode(''));
    dispatch(setAdditionalFieldSelectedTextKeyboardType(''));
    dispatch(setAdditionalFieldSelectedTextMultiline(''));
    dispatch(reset(RXFORM_ADDITIONAL_FIELD));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAdditionalFieldsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAdditionalFieldSelectedPageSize(pageSize));
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeParentText: async (text) => {
    try {
      dispatch(setAdditionalFieldParentSearchText(text));
      dispatch(clearAdditionalFieldParents());
      await dispatch(downloadAdditionalFieldParentsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteAdditionalFieldAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableAdditionalFieldAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCreatePressed: () => {
    dispatch(setAdditionalFieldTappedId(''));
    dispatch(reset(RXFORM_ADDITIONAL_FIELD));
    dispatch(setAdditionalFieldParentSearchText(''));
    dispatch(setAdditionalFieldSelectedAppliedOn(''));
    dispatch(setAdditionalFieldSelectedFieldType(''));
    dispatch(setAdditionalFieldSelectedLogicalOperator(''));
    dispatch(setAdditionalFieldSelectedMustUseCamera(''));
    dispatch(setAdditionalFieldSelectedOptionMode(''));
    dispatch(setAdditionalFieldSelectedOptionValueSeparator(''));
    dispatch(setAdditionalFieldSelectedParent(''));
    dispatch(setAdditionalFieldSelectedPickerMode(''));
    dispatch(setAdditionalFieldSelectedTextKeyboardType(''));
    dispatch(setAdditionalFieldSelectedTextMultiline(''));
    dispatch(setAdditionalFieldParentSearchText(''));
    dispatch(clearAdditionalFieldParents());
    dispatch(downloadAdditionalFieldParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setAdditionalFieldTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setAdditionalFieldTappedId(id));
    dispatch(downloadDeleteAdditionalFieldAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(clearAdditionalFieldParents());
    dispatch(downloadAdditionalFieldParentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setAdditionalFieldTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onFieldTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedFieldType(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE, option));
    } else {
      dispatch(setAdditionalFieldSelectedFieldType(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_FIELD_TYPE, ''));
    }
  },
  onLogicalOperatorOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedLogicalOperator(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD,
        RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, option));
    } else {
      dispatch(setAdditionalFieldSelectedLogicalOperator(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD,
        RXFIELD_ADDITIONAL_FIELD_SHOW_IF_PARENT_VALUE_LOGICAL_OPERATOR, ''));
    }
  },
  onMustUseCameraOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedMustUseCamera(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA, option));
    } else {
      dispatch(setAdditionalFieldSelectedMustUseCamera(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_MUST_USE_CAMERA, ''));
    }
  },

  onOptionModeOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedOptionMode(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_OPTION_MODE, option));
    } else {
      dispatch(setAdditionalFieldSelectedOptionMode(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_OPTION_MODE, ''));
    }
  },
  onOptionValueSeparatorOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedOptionValueSeparator(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR,
        option));
    } else {
      dispatch(setAdditionalFieldSelectedOptionValueSeparator(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_OPTION_VALUE_SEPARATOR, ''));
    }
  },
  onParentOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedParent(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_PARENT, option));
    } else {
      dispatch(setAdditionalFieldSelectedParent(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_PARENT, ''));
    }
  },
  onPickerModeOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedPickerMode(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_PICKER_MODE, option));
    } else {
      dispatch(setAdditionalFieldSelectedPickerMode(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_PICKER_MODE, ''));
    }
  },
  onReadOnlyOptionSelected: (option) => {
    dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_READ_ONLY, option));
  },
  onRefresh: (pageSize) => {
    dispatch(setAdditionalFieldSelectedPageSize(pageSize));
    dispatch(clearAdditionalFields());
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRequiredOptionSelected: (option) => {
    dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_REQUIRED, option));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearAdditionalFields());
    dispatch(setAdditionalFieldSelectedOrderBy(INITIAL_ORDER_BY_ADDITIONAL_FIELDS));
    dispatch(downloadAdditionalFieldsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditAdditionalFieldAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAdditionalFieldSearchText(text));
      dispatch(clearAdditionalFields());
      await dispatch(downloadAdditionalFieldsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAdditionalFieldSelectedOrderBy(orderBy));
    dispatch(clearAdditionalFields());
    dispatch(downloadAdditionalFieldsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditAdditionalFieldAsync(values));
  },
  onTextKeyboardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedTextKeyboardType(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE,
        option));
    } else {
      dispatch(setAdditionalFieldSelectedTextKeyboardType(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_TEXT_KEYBOARD_TYPE, ''));
    }
  },
  onTextMultilineOptionSelected: (option) => {
    if (option) {
      dispatch(setAdditionalFieldSelectedTextMultiline(option));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE, option));
    } else {
      dispatch(setAdditionalFieldSelectedTextMultiline(''));
      dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_TEXT_MULTILINE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setAdditionalFieldTappedId(id));
    dispatch(downloadDeleteAdditionalFieldAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibleOptionSelected: (option) => {
    dispatch(change(RXFORM_ADDITIONAL_FIELD, RXFIELD_ADDITIONAL_FIELD_VISIBLE, option));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFieldPage);
