import {
  ADDING_EDITING_ASSET_CERTIFICATE, DOWNLOADING_ASSET_CERTIFICATES,
  DOWNLOADING_DELETING_ASSET_CERTIFICATE,
  SET_ASSET_CERTIFICATE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_ASSET_CERTIFICATE_SEARCH_TEXT, SET_ASSET_CERTIFICATE_SELECTED_ORDER_BY,
  SET_ASSET_CERTIFICATE_SELECTED_PAGE_SIZE, SET_ASSET_CERTIFICATE_TAPPED_ID,
} from '../action';
import { INITIAL_ORDER_BY_ASSET_CERTIFICATES } from '../../constant';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  selectedPageSize: 20,
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_ASSET_CERTIFICATES,
  tappedId: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ASSET_CERTIFICATES:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_ASSET_CERTIFICATE:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_ASSET_CERTIFICATE:
      return { ...state, downloadingDeleting: action.status };
    case SET_ASSET_CERTIFICATE_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ASSET_CERTIFICATE_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ASSET_CERTIFICATE_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ASSET_CERTIFICATE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_ASSET_CERTIFICATE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    default: return state;
  }
};
