import { downloadingParentlessAssets, setParentlessAssets } from '../simple-action';
import { downloadParentlessAssets, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, parentlessAssets, uiAssetChild,
  } = getState();
  const { token } = authentication;
  const { meta } = parentlessAssets;
  const {
    parentlessAssetSelectedPageSize, parentlessAssetSearchBarText, parentlessAssetOrderBy,
    parentlessAssetFilterString,
  } = uiAssetChild;

  try {
    dispatch(downloadingParentlessAssets(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadParentlessAssets(
      pageToBeDownloaded,
      parentlessAssetSelectedPageSize,
      parentlessAssetOrderBy,
      transformSearchText(parentlessAssetSearchBarText),
      parentlessAssetFilterString,
      token,
    );

    if (list) {
      dispatch(setParentlessAssets(list));
    }
  } finally {
    dispatch(downloadingParentlessAssets(false));
  }
};
