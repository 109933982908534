import { ORDER_SORT_ASCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_FONT_LIST = '/docgen/api/v1/Font';
export const REST_URL_SUBMIT_ANNOTATION = '/docgen/api/v1/PdfAnnotationDesign';
export const REST_URL_PREVIEW_PDF = '/docgen/api/v1/PdfAnnotation';
export const REST_URL_UPLOAD_TEMPLATE = '/docgen/api/v1/PdfAnnotationTemplate/Upload';

export const ROUTE_NAME_PDF_MANAGEMENT = '/pdf-management';
export const ROUTE_NAME_ANNOTATION_DESIGNER = '/annotation-designer';

export const RXFORM_ANNOTATION_DESIGNER = 'annotationDesignerPage';
export const RXFORM_UPLOAD_PDF = 'uploadPDFPage';

export const RXFIELD_ANNOTATION_DESIGNER_VALUE = 'value';
export const RXFIELD_ANNOTATION_DESIGNER_PAGE = 'page';
export const RXFIELD_ANNOTATION_DESIGNER_FONT_NAME = 'fontName';
export const RXFIELD_ANNOTATION_DESIGNER_FONT_SIZE = 'fontSize';
export const RXFIELD_ANNOTATION_DESIGNER_COLOR = 'color';
export const RXFIELD_ANNOTATION_DESIGNER_FONT_COLOR = 'fontColor';
export const RXFIELD_ANNOTATION_DESIGNER_FONT_STYLE = 'fontStyle';
export const RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X = 'x';
export const RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y = 'y';
export const RXFIELD_ANNOTATION_DESIGNER_ROTATION = 'rotation';
export const RXFIELD_ANNOTATION_DESIGNER_WIDTH = 'width';
export const RXFIELD_ANNOTATION_DESIGNER_HEIGHT = 'height';
export const RXFIELD_ANNOTATION_DESIGNER_IMAGE = 'imageValue';
export const RXFIELD_UPLOAD_PDF_NAME = 'name';
export const RXFIELD_UPLOAD_PDF_DESCRIPTION = 'description';
export const RXFIELD_UPLOAD_PDF_FILE = 'file';

export const RXSTATE_ANNOTATION_DESIGNER = 'annotationDesigners';
export const RXSTATE_ANNOTATION_DESIGNER_PAGE = 'uiAnnotationDesigner';

export const INITIAL_ORDER_BY_ANNOTATION_DESIGNERS = `createdDate ${ORDER_SORT_ASCENDING}`;

export const MENUID_PDF_MANAGEMENT = 'MENUID_PDF_MANAGEMENT';
export const MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER = 'MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER';

export const PDF_TYPE_TEXT = 'Text';
export const PDF_TYPE_QR_CODE = 'QrCode';
export const PDF_TYPE_IMAGE = 'Image';

export const COLOR_BORDER_DELETE = '#DD2121';
export const COLOR_BORDER_SHADOW_PDF = '#DADADA';
export const COLOR_TYPE_TEXT = '#007DC5';
export const COLOR_TYPE_QR_CODE = '#21919C';
export const COLOR_TYPE_IMAGE = '#6EBE45';
export const COLOR_FIELD_BORDER_COLOR = '#979797';

export const DUMMY_DATA = {
  data: [
    {
      title: 'Alan Budi Kusuma',
      type: 'text',
      page: '2003',
      fontName: 'Times New Roman',
      fontStyle: 'Bold',
      fontSize: '14px',
      rotation: '0',
      coordinateX: '0',
      coordinateY: '0',
      width: '0',
      height: '0',
      color: '#0095FF',
    },
    {
      title: 'www.google.com',
      type: 'qrCode',
      page: '2003',
      fontName: '',
      fontStyle: '',
      fontSize: '',
      rotation: '',
      coordinateX: '102,3093',
      coordinateY: '-111,1111',
      width: '30',
      height: '24',
      color: '#0095FF',
    },
    {
      title: 'Imaging.png',
      type: 'image',
      page: '2003',
      fontName: '',
      fontStyle: '',
      fontSize: '',
      rotation: '',
      coordinateX: '102,3093',
      coordinateY: '-111,1111',
      width: '30',
      height: '24',
      color: '',
    },
  ],
};

export const DUMMY_FONT_DATA = {
  data: [
    {
      name: 'Courier New',
    },
    {
      name: 'Times New Roman',
    },
  ],
};

export const FONT_STYLE = [
  {
    value: 'Bold',
    label: 'Bold',
  },
  {
    value: 'Italic',
    label: 'Italic',
  },
];
