import {
  ADDING_EDITING_ANNOTATION_DESIGN,
  DELETING_ANNOTATION_DESIGN,
  DOWNLOADING_FONT, SET_ANNOTATION_DESIGN_JSON_VISIBILITY,
  SET_ANNOTATION_DESIGN_SELECTED_IMAGE,
  SET_ANNOTATION_DESIGN_SELECTED_ORDER_BY,
  SET_ANNOTATION_DESIGN_SELECTED_PAGE_SIZE,
  SET_ANNOTATION_DESIGN_TAPPED_ID,
  SET_SELECTED_FONT,
} from '../action';
import { INITIAL_ORDER_BY_ANNOTATION_DESIGNERS } from '../../constant';

const initialState = {
  addingEditing: false,
  deleting: false,
  downloadingFont: false,
  jsonVisibility: false,
  selectedFont: '',
  selectedImage: '',
  selectedImageName: '',
  selectedPageSize: 20,
  tappedId: '',
  orderBy: INITIAL_ORDER_BY_ANNOTATION_DESIGNERS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_EDITING_ANNOTATION_DESIGN:
      return { ...state, addingEditing: action.status };
    case DELETING_ANNOTATION_DESIGN:
      return { ...state, deleting: action.status };
    case DOWNLOADING_FONT:
      return { ...state, downloadingFont: action.status };
    case SET_ANNOTATION_DESIGN_JSON_VISIBILITY:
      return { ...state, jsonVisibility: action.status };
    case SET_SELECTED_FONT:
      return { ...state, selectedFont: action.font };
    case SET_ANNOTATION_DESIGN_SELECTED_IMAGE:
      return { ...state, selectedImage: action.url, selectedImageName: action.fileName };
    case SET_ANNOTATION_DESIGN_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ANNOTATION_DESIGN_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_ANNOTATION_DESIGN_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    default: return state;
  }
};
