import { connect } from 'react-redux';
import {
  OAUTH_MICROSOFT_REDIRECT_URI, OAUTH_GOOGLE_URL, OAUTH_KEY_CLOAK_URL, OAUTH_MICROSOFT_URL,
  OAUTH_PROVIDER_GOOGLE, OAUTH_PROVIDER_KEY_CLOAK, OAUTH_PROVIDER_MICROSOFT,
} from '../../constant';
import { transformConfigValueBoolean } from '../../helper';
import { downloadMyConfigItemsAsync, setRedirectionParam } from '../../module/configuration/redux/action';
import {
  clearCaptcha, setAlertErrorMessage, setAlertForcePopUp,
  createCaptchaAsync, loginAsync, loginWithGoogleAsync, loginWithKeyCloakAsync,
  loginWithMicrosoftAsync,
} from '../../redux/action';
import LoginScreen from './login.presentation';

const getOAuthGoogleUrl = (state) => {
  const { myConfigItems, uiLogin } = state;
  const { redirectionParam } = uiLogin;
  const clientId = myConfigItems?.auth?.oAuthGoogleClientIdWeb || '';
  const scope = myConfigItems?.auth?.oAuthGoogleScopes || '';
  let url = OAUTH_GOOGLE_URL
    .replace(/\{clientId\}/, clientId)
    .replace(/\{scopes\}/, encodeURIComponent(scope));

  if (redirectionParam) {
    url = url.concat(`&state=${redirectionParam}`);
  }
  return url;
};

const getOAuthKeyCloakUrl = (state) => {
  const { myConfigItems, uiLogin } = state;
  const { redirectionParam } = uiLogin;
  const serverName = myConfigItems?.auth?.oAuthKeyCloakServerAddress || '';
  const realmName = myConfigItems?.auth?.oAuthKeyCloakRealm || '';
  const clientId = myConfigItems?.auth?.oAuthKeyCloakClientIdWeb || '';
  const scope = myConfigItems?.auth?.oAuthKeyCloakScopes || '';
  let url = OAUTH_KEY_CLOAK_URL
    .replace(/\{serverName\}/, serverName)
    .replace(/\{realmName\}/, realmName)
    .replace(/\{clientId\}/, clientId)
    .replace(/\{scope\}/, encodeURIComponent(scope));

  if (redirectionParam) {
    url = url.concat(`&state=${redirectionParam}`);
  }
  return url;
};

const getOAuthMicrosoftUrl = (state) => {
  const { myConfigItems, uiLogin } = state;
  const { redirectionParam } = uiLogin;
  const tenantId = myConfigItems?.auth?.oAuthMicrosoftTenantId || '';
  const clientId = myConfigItems?.auth?.oAuthMicrosoftClientIdWeb || '';
  const scope = myConfigItems?.auth?.oAuthMicrosoftScopes || '';
  const redirectUri = encodeURIComponent(OAUTH_MICROSOFT_REDIRECT_URI);
  let url = OAUTH_MICROSOFT_URL
    .replace(/\{tenantId\}/, tenantId)
    .replace(/\{clientId\}/, clientId)
    .replace(/\{redirectUri\}/, redirectUri)
    .replace(/\{scope\}/, encodeURIComponent(scope));

  if (redirectionParam) {
    url = url.concat(`&state=${redirectionParam}`);
  }
  return url;
};

const mapStateToProps = (state) => ({
  downloadingCaptcha: state.captcha.creating,
  downloadingMyConfigItems: state.uiLogin.downloadingMyConfigItems,
  loggingIn: state.uiLogin.loggingIn,
  loggingInWithGoogle: state.uiLogin.loggingInWithGoogle,
  loggingInWithKeyCloak: state.uiLogin.loggingInWithKeyCloak,
  loggingInWithMicrosoft: state.uiLogin.loggingInWithMicrosoft,
  oAuthGoogleEnabled: transformConfigValueBoolean(state.myConfigItems?.auth?.oAuthGoogleEnabled || ''),
  oAuthKeyCloakEnabled: transformConfigValueBoolean(state.myConfigItems?.auth?.oAuthKeyCloakEnabled || ''),
  oAuthMicrosoftEnabled: transformConfigValueBoolean(state.myConfigItems?.auth?.oAuthMicrosoftEnabled || ''),
  useCaptcha: transformConfigValueBoolean(state.myConfigItems?.auth?.useCaptchaForLoginApi || ''),
  myConfigItems: state.myConfigItems,
  captchaImage: state.captcha.data?.captchaImage,
  oAuthGoogleUrl: getOAuthGoogleUrl(state),
  oAuthKeyCloakUrl: getOAuthKeyCloakUrl(state),
  oAuthMicrosoftUrl: getOAuthMicrosoftUrl(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createCaptcha: (useCaptcha) => {
    if (useCaptcha) {
      dispatch(clearCaptcha());
      dispatch(createCaptchaAsync())
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    }
  },
  onAppear: () => {
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppearWithAuthCode: (googleOAuthCode, oAuthProvider, microsoftOAuthCode, keyCloakOAuthCode) => {
    const searchParams = new URLSearchParams(ownProps.location.search);
    const redirectUrl = searchParams.get('state')?.split('?')[0];
    const backTo = redirectUrl?.split('/')[1];

    if (googleOAuthCode && oAuthProvider === OAUTH_PROVIDER_GOOGLE) {
      dispatch(loginWithGoogleAsync(
        googleOAuthCode, ownProps.history.push, redirectUrl, backTo,
      ))
        .catch((err) => {
          dispatch(setAlertForcePopUp(true));
          dispatch(setAlertErrorMessage(err));
        });
    }
    if (microsoftOAuthCode && oAuthProvider === OAUTH_PROVIDER_MICROSOFT) {
      dispatch(loginWithMicrosoftAsync(
        microsoftOAuthCode, ownProps.history.push, redirectUrl, backTo,
      ))
        .catch((err) => {
          dispatch(setAlertForcePopUp(true));
          dispatch(setAlertErrorMessage(err));
        });
    }
    if (keyCloakOAuthCode && oAuthProvider === OAUTH_PROVIDER_KEY_CLOAK) {
      dispatch(loginWithKeyCloakAsync(
        microsoftOAuthCode, ownProps.history.push, redirectUrl, backTo,
      ))
        .catch((err) => {
          dispatch(setAlertForcePopUp(true));
          dispatch(setAlertErrorMessage(err));
        });
    }
  },
  onLoginPressed: async ({ username, password, captchaText }) => {
    try {
      await dispatch(loginAsync(username, password, captchaText, ownProps.history.push));
    } catch (error) {
      dispatch(setAlertForcePopUp(true));
      dispatch(setAlertErrorMessage(error));
    }
  },
  onRefreshCaptcha: () => {
    dispatch(createCaptchaAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  setRedirectionParam: (redirectionParam = '') => {
    dispatch(setRedirectionParam(redirectionParam));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
