import { rxFormValidateRequiredFields } from '../../validation';
import { RXFIELD_APPROVAL_GROUP_NAME, RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS } from './constant';

// eslint-disable-next-line import/prefer-default-export
export const rxformValidateApprovalGroup = (values) => {
  const requiredFields = [
    RXFIELD_APPROVAL_GROUP_NAME,
    RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
