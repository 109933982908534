import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, makeStyles, Typography,
} from '@material-ui/core';
import { Lightbulb } from 'mdi-material-ui';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import {
  COLOR_BODY_TEXT, COLOR_ICON, COLOR_POLYGON, COLOR_SECONDARY, COLOR_TERTIARY, COLOR_TEXT_03,
} from '../constant';
import BootstrapInput from './bootstrap-input';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: '4px 8px',
    '& label.MuiInputLabel-root': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '70%',
    },
    '& label.MuiInputLabel-shrink': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_SECONDARY,
      },
    },
    width: '100%',
    minWidth: 100,
  },
  label: {
    flexDirection: 'row',
    display: 'flex',
    color: COLOR_ICON,
    fontWeight: 400,
  },
  requiredSymbol: {
    color: COLOR_POLYGON,
    fontSize: 14,
    fontWeight: 400,
    marginTop: -2,
    marginLeft: 2,
  },
  helperContainer: {
    margin: '10px 0px 10px 0px',
  },
  rowHelperContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  goldText: {
    fontWeight: 'bold',
    color: COLOR_TERTIARY,
  },
  blackText: {
    color: COLOR_BODY_TEXT,
    marginLeft: 38,
  },
  greenText: {
    fontStyle: 'italic',
    color: COLOR_TEXT_03,
  },
  textField: {
    fontSize: '14px',
    '&::placeholder': {
      textOverflow: 'ellipsis',
    },
  },
}));

const OutlinedTextField = ({
  currencyTextFormat, editable, error, hidden, required, secureTextEntry, useHelperIcon,
  onChangeText,
  label, helperText, placeholder, secondaryHelperText, subHelperText, value, inputProps,
  ...props
}) => {
  const classes = useStyles();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const isRequired = required ? '*' : '';

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const renderTextFieldWithPassIcon = (
    <BootstrapInput
      disabled={editable}
      placeholder={placeholder}
      variant="outlined"
      value={value}
      onChange={(text) => onChangeText(text)}
      fullWidth
      type={passwordVisibility ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passwordVisibility ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        classes: {
          input: classes.textField,
        },
      }}
      {...props}
    />
  );

  const renderTextField = () => {
    if (currencyTextFormat) {
      return (
        <CurrencyTextField
          label={label}
          disabled={editable}
          placeholder={placeholder}
          variant="outlined"
          currencySymbol="Rp"
          value={value || 0}
          decimalPlaces={0}
          digitGroupSeparator="."
          decimalCharacter=","
          onChange={(e, text) => onChangeText(text)}
          InputProps={{
            classes: {
              input: classes.textField,
            },
          }}
          InputLabelProps={{
            style: {
              fontSize: '14px',
              '&.MuiOutlinedInput-notchedOutline': { fontSize: '14px' },
            },
          }}
          size="small"
          {...props}
        />
      );
    }

    return secureTextEntry ? renderTextFieldWithPassIcon : (
      <BootstrapInput
        disabled={editable}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        onChange={(text) => onChangeText(text)}
        fullWidth
        InputProps={{
          ...inputProps,
          classes: {
            input: classes.textField,
          },
        }}
        {...props}
      />
    );
  };

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} error={error}>
          <InputLabel shrink htmlFor="bootstrap-input" className={classes.label}>
            {label}
            <Typography className={classes.requiredSymbol}>
              {isRequired}
            </Typography>
          </InputLabel>
          {renderTextField()}

          {useHelperIcon ? (
            <div className={classes.helperContainer}>
              <FormHelperText className={classes.greenText}>{helperText}</FormHelperText>
              <div className={classes.rowHelperContainer}>
                <Lightbulb style={{ color: COLOR_TERTIARY }} />
                <FormHelperText className={classes.goldText}>{secondaryHelperText}</FormHelperText>
              </div>
              <FormHelperText className={classes.blackText}>{subHelperText}</FormHelperText>
            </div>
          ) : (<FormHelperText className={classes.greenText}>{helperText}</FormHelperText>)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default OutlinedTextField;

OutlinedTextField.propTypes = {
  currencyTextFormat: PropTypes.bool,
  editable: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  required: PropTypes.bool,
  secureTextEntry: PropTypes.bool,
  useHelperIcon: PropTypes.bool,
  onChangeText: PropTypes.func,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryHelperText: PropTypes.string,
  subHelperText: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

OutlinedTextField.defaultProps = {
  currencyTextFormat: false,
  editable: false,
  error: false,
  hidden: false,
  required: false,
  secureTextEntry: false,
  useHelperIcon: false,
  onChangeText: () => {},
  helperText: null,
  placeholder: null,
  secondaryHelperText: null,
  subHelperText: null,
  value: null,
  inputProps: {},
};
