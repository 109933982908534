import { setMyAssetLocations, downloadingMyAssetLocations } from '../simple-action';
import { downloadMyAssetLocations } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingMyAssetLocations(true));

    const { token } = getState().authentication;

    const result = await downloadMyAssetLocations(token);
    dispatch(setMyAssetLocations(result));
  } finally {
    dispatch(downloadingMyAssetLocations(false));
  }
};
