import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as Constant from '../constant';

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const redirection = props?.location?.pathname || '';

      return (
        authenticated === true
          ? <Component {...props} />
          : <Redirect to={redirection ? `${Constant.ROUTE_NAME_LOGIN}${Constant.URL_REDIRECT_TO_TAG}${redirection}` : Constant.ROUTE_NAME_LOGIN} />
      );
    }}
  />
);

PrivateRoute.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: !!state.authentication.token,
});

export default connect(mapStateToProps)(PrivateRoute);
