import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, InputAdornment } from '@material-ui/core';
import {
  Person, VpnKey, Public, Language, Android, Apple, DesktopWindows, PhoneIphone, Web,
} from '@material-ui/icons';
import { FunctionalPage, SendNotificationDialog } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_MULTIPLE_VALUES, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_TOKEN_APP_VERSION, RXFIELD_TOKEN_DEVICE,
  RXFIELD_TOKEN_DEVICE_MANUFACTURER, RXFIELD_TOKEN_DEVICE_MODEL, RXFIELD_TOKEN_FULLNAME,
  RXFIELD_TOKEN_LANGUAGE, RXFIELD_TOKEN_LAST_ACTIVITY, RXFIELD_TOKEN_OS, RXFIELD_TOKEN_OS_NAME,
  RXFIELD_TOKEN_OS_VERSION, RXFIELD_STATUS, RXFIELD_TOKEN_TIMEZONE, RXFIELD_TOKEN_TOKEN,
  RXFIELD_TOKEN_USERNAME, RXFIELD_TOKEN_VALIDITY, RXFORM_TOKEN, RXSTATE_TOKENS,
  RXSTATE_TOKEN_PAGE, status, RXFIELD_TOKEN_FCM_TOKEN, RXFIELD_TOKEN_DEVICE_ID, FILTER_TYPE_TEXT,
  RXFIELD_TOKEN_OAUTH_PROVIDER,
  OAUTH_PROVIDER,
} from '../../constant';
import { toMoment } from '../../helper';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';

const getOsIcon = (os) => {
  if (os.includes('Android')) { return (<Android />); }
  if (os.includes('iOS') || os.includes('Mac OS')) { return (<Apple />); }
  return (<DesktopWindows />);
};

const renderDialogContent = (addingEditing, pageMode, os) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_TOKEN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderToken}
          label={LocalizedString.tokenScreen.placeholderToken}
          disabled
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <VpnKey />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_USERNAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderUsername}
          label={LocalizedString.tokenScreen.placeholderUsername}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_FULLNAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderFullName}
          label={LocalizedString.tokenScreen.placeholderFullName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Person />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_VALIDITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderValidUntil}
          label={LocalizedString.tokenScreen.placeholderValidUntil}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_LAST_ACTIVITY}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderLastActivity}
          label={LocalizedString.tokenScreen.placeholderLastActivity}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_FCM_TOKEN}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderFCMToken}
          label={LocalizedString.tokenScreen.placeholderFCMToken}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_OAUTH_PROVIDER}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderOAuthProvider}
          label={LocalizedString.tokenScreen.placeholderOAuthProvider}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_DEVICE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderDevice}
          label={LocalizedString.tokenScreen.placeholderDevice}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {os.includes('Android') || os.includes('iOS') ? <PhoneIphone /> : <Web />}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_DEVICE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderDeviceId}
          label={LocalizedString.tokenScreen.placeholderDeviceId}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_OS}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderOS}
          label={LocalizedString.tokenScreen.placeholderOS}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getOsIcon(os)}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_APP_VERSION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderAppVersion}
          label={LocalizedString.tokenScreen.placeholderAppVersion}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_TIMEZONE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderTimezone}
          label={LocalizedString.tokenScreen.placeholderTimezone}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Public />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_TOKEN_LANGUAGE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.tokenScreen.placeholderLanguage}
          label={LocalizedString.tokenScreen.placeholderLanguage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Language />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  </Grid>
);

const TokenPage = ({
  appVersions, deviceManufacturers, deviceModels, osNames, osVersions, timeZones,
  addingEditing, downloading, downloadingAppVersions, downloadingDeviceManufacturers,
  downloadingDeviceModels, downloadingOsNames, downloadingOsVersions, downloadingTimeZones,
  sendingNotification,
  handleSubmit, onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onAppear, onCancelPressed,
  onChangeAppVersionText, onChangeDeviceManufacturerText, onChangeDeviceModelText,
  onChangeOsNameText, onChangeOsVersionText, onChangeTimeZoneText, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCountTotalPersonPressed, onDeletePressed, onOpenSendNotificationDialog,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSendNotificationPressed,
  onSortPressed, onViewPressed,
  pageMode, os, tappedId,
  users, loadingUser, onChangeUserText,
}) => (
  <div>
    <SendNotificationDialog
      filterColumns={[
        {
          title: LocalizedString.tokenScreen.labelLastActivity,
          field: RXFIELD_TOKEN_LAST_ACTIVITY,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.tokenScreen.labelAppVersion,
          field: RXFIELD_TOKEN_APP_VERSION,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: appVersions,
          loading: downloadingAppVersions,
          onChangeFilterText: onChangeAppVersionText,
        },
        {
          title: LocalizedString.tokenScreen.labelDeviceManufacturer,
          field: RXFIELD_TOKEN_DEVICE_MANUFACTURER,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: deviceManufacturers,
          loading: downloadingDeviceManufacturers,
          onChangeFilterText: onChangeDeviceManufacturerText,
        },
        {
          title: LocalizedString.tokenScreen.labelDeviceModel,
          field: RXFIELD_TOKEN_DEVICE_MODEL,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: deviceModels,
          loading: downloadingDeviceModels,
          onChangeFilterText: onChangeDeviceModelText,
        },
        {
          title: LocalizedString.tokenScreen.labelOSName,
          field: RXFIELD_TOKEN_OS_NAME,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: osNames,
          loading: downloadingOsNames,
          onChangeFilterText: onChangeOsNameText,
        },
        {
          title: LocalizedString.tokenScreen.labelOSVersion,
          field: RXFIELD_TOKEN_OS_VERSION,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: osVersions,
          loading: downloadingOsVersions,
          onChangeFilterText: onChangeOsVersionText,
        },
        {
          title: LocalizedString.tokenScreen.labelTimeZone,
          field: RXFIELD_TOKEN_TIMEZONE,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: timeZones,
          loading: downloadingTimeZones,
          onChangeFilterText: onChangeTimeZoneText,
        },
      ]}
      useFilterSection
      loading={sendingNotification}
      onCountTotalPersonPressed={onCountTotalPersonPressed}
      onSendPressed={onSendNotificationPressed}
      additionalUrlPlaceholder={`${LocalizedString.common.labelAdditionalUrlPlaceholders}${LocalizedString.tokenScreen.labelAdditionalUrlPlaceholders}`}
      sendingToLabel={LocalizedString.tokenScreen.labelSendNotificationTo}
    />

    <FunctionalPage
      data={RXSTATE_TOKENS}
      uiPage={RXSTATE_TOKEN_PAGE}
      tableColumns={[
        {
          title: LocalizedString.tokenScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.tokenScreen.labelToken, field: 'tokenKey', sorting: !downloading },
        { title: LocalizedString.tokenScreen.labelUsername, field: 'user.username', sorting: !downloading },
        {
          title: LocalizedString.tokenScreen.labelLastActivity,
          field: 'lastActivity',
          render: ({ lastActivity }) => (
            toMoment(lastActivity).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
          searchable: false,
          sorting: !downloading,
        },
        {
          title: LocalizedString.tokenScreen.labelValidUntil,
          field: 'validUntil',
          render: ({ validUntil }) => (toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
          searchable: false,
          sorting: !downloading,
        },
        {
          title: LocalizedString.tokenScreen.labelDevice,
          field: 'device',
          render: ({ deviceManufacturer, deviceModel }) => (`${deviceManufacturer} ${deviceModel}`),
          customFilterAndSearch: (term, rowData) => (`${rowData.deviceManufacturer} ${rowData.deviceModel}`).indexOf(term) !== -1,
          sorting: false,
        },
        {
          title: LocalizedString.tokenScreen.labelOS,
          field: 'os',
          render: ({ osName, osVersion }) => (`${osName} ${osVersion}`),
          customFilterAndSearch: (term, rowData) => (`${rowData.osName} ${rowData.osVersion}`).indexOf(term) !== -1,
          sorting: false,
        },
        { title: LocalizedString.tokenScreen.labelAppVersion, field: 'appVersion', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.tokenScreen.labelLastActivity,
          field: RXFIELD_TOKEN_LAST_ACTIVITY,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.tokenScreen.labelValidUntil,
          field: RXFIELD_TOKEN_VALIDITY,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.common.placeholderStatus,
          field: RXFIELD_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: status,
        },
        {
          title: LocalizedString.tokenScreen.labelAppVersion,
          field: RXFIELD_TOKEN_APP_VERSION,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: appVersions,
          loading: downloadingAppVersions,
          onChangeFilterText: onChangeAppVersionText,
        },
        {
          title: LocalizedString.tokenScreen.labelDeviceManufacturer,
          field: RXFIELD_TOKEN_DEVICE_MANUFACTURER,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: deviceManufacturers,
          loading: downloadingDeviceManufacturers,
          onChangeFilterText: onChangeDeviceManufacturerText,
        },
        {
          title: LocalizedString.tokenScreen.labelDeviceModel,
          field: RXFIELD_TOKEN_DEVICE_MODEL,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: deviceModels,
          loading: downloadingDeviceModels,
          onChangeFilterText: onChangeDeviceModelText,
        },
        {
          title: LocalizedString.tokenScreen.labelOSName,
          field: RXFIELD_TOKEN_OS_NAME,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: osNames,
          loading: downloadingOsNames,
          onChangeFilterText: onChangeOsNameText,
        },
        {
          title: LocalizedString.tokenScreen.labelOSVersion,
          field: RXFIELD_TOKEN_OS_VERSION,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: osVersions,
          loading: downloadingOsVersions,
          onChangeFilterText: onChangeOsVersionText,
        },
        {
          title: LocalizedString.tokenScreen.labelTimeZone,
          field: RXFIELD_TOKEN_TIMEZONE,
          type: FILTER_TYPE_MULTIPLE_VALUES,
          data: timeZones,
          loading: downloadingTimeZones,
          onChangeFilterText: onChangeTimeZoneText,
        },
        {
          title: LocalizedString.tokenScreen.placeholderUsername,
          field: 'user',
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: onChangeUserText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.tokenScreen.labelDeviceId,
          field: RXFIELD_TOKEN_DEVICE_ID,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.tokenScreen.placeholderOAuthProvider,
          field: RXFIELD_TOKEN_OAUTH_PROVIDER,
          type: FILTER_TYPE_DROPDOWN,
          data: OAUTH_PROVIDER,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={() => onConfirmDeletePressed(tappedId)}
      onDeletePressed={onDeletePressed}
      onOpenSendNotificationDialog={onOpenSendNotificationDialog}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      deleteButtonCaption={LocalizedString.tokenScreen.buttonCaptionDisable}
      customDeleteButtonMenuCaption
      disableCreate
      disableEdit
      useSendAllNotification
      title={LocalizedString.tokenScreen.title}
      useFullWidth
      deletePermissionName="AUTH_DELETE_TOKEN"
      sendNotificationPermissionName="CORE_SEND_SIMPLE_NOTIFICATION"
    >
      {renderDialogContent(addingEditing, pageMode, os)}
    </FunctionalPage>
  </div>
);

export default reduxForm({
  form: RXFORM_TOKEN,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TokenPage);

TokenPage.propTypes = {
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  appVersions: PropTypes.arrayOf(PropTypes.string).isRequired,
  deviceManufacturers: PropTypes.arrayOf(PropTypes.string).isRequired,
  deviceModels: PropTypes.arrayOf(PropTypes.object).isRequired,
  osNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  osVersions: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.string).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingAppVersions: PropTypes.bool.isRequired,
  downloadingDeviceManufacturers: PropTypes.bool.isRequired,
  downloadingDeviceModels: PropTypes.bool.isRequired,
  downloadingOsNames: PropTypes.bool.isRequired,
  downloadingOsVersions: PropTypes.bool.isRequired,
  downloadingTimeZones: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAppVersionText: PropTypes.func.isRequired,
  onChangeDeviceManufacturerText: PropTypes.func.isRequired,
  onChangeDeviceModelText: PropTypes.func.isRequired,
  onChangeOsNameText: PropTypes.func.isRequired,
  onChangeOsVersionText: PropTypes.func.isRequired,
  onChangeTimeZoneText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCountTotalPersonPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onOpenSendNotificationDialog: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  os: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
