/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, IconButton, InputAdornment, makeStyles, Snackbar,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { isEmpty } from 'lodash';
import { FunctionalPage } from '../../component';
import {
  RXFORM_VOUCHER_TOKEN_ASSIGNMENT, RXSTATE_VOUCHER_TOKEN_ASSIGNMENTS,
  RXSTATE_VOUCHER_TOKEN_ASSIGNMENT_PAGE, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_ID, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_NAME,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_TOKEN_ID, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTES,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE, PAGE_MODE_VIEW,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_ID, FILTER_TYPE_DROPDOWN,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_NAME, ASSIGNMENT_MODE_OPTION,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_ID,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_NAME, ASSIGNMENT_MODE_GENERATE_NEW_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_IMAGE, ASSIGNMENT_MODE_CUSTOM_CODE,
  FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATED_DATE, FILTER_TYPE_SWITCH,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTES,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL,
  status, switches, snackbarDuration,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_BODY,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_TITLE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_BODY,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_TITLE,
  COLOR_BODY_TEXT_LIGHTER, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD,
  ASSIGNMENT_METHOD_IMMEDIATELY, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL,
  ASSIGNMENT_METHOD_OPTION, ASSIGNMENT_METHOD_URL_ACCESS,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL,
  COLOR_PRIMARY, RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_REDEMPTION_CODE,
  RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_STATUS, STATUS_DISABLED, STATUS_ENABLED,
  PAGE_MODE_EDIT,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
  renderReduxFormSimpleDropdownField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormDateTimePickerField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateVoucherTokenAssignment } from '../../validation';

const REGEX_MATCH_STRING_INSIDE_CURLY_BRACES = '{(.*?)}';
const additionalPlaceholderNotifMessageBody = `<p><i>${LocalizedString.voucherTokenAssignment.additionalPlaceholderNotifMessageBody.match(REGEX_MATCH_STRING_INSIDE_CURLY_BRACES) ? LocalizedString.voucherTokenAssignment.additionalPlaceholderNotifMessageBody.replace(/{(.*?)}/g, '<b>{$1}</b>') : LocalizedString.voucherTokenAssignment.additionalPlaceholderNotifMessageBody}</i></p>`;

const useStyles = makeStyles(() => ({
  captionText: {
    marginLeft: 10,
    marginTop: -14,
  },
  border: {
    borderWidth: 1,
    borderColor: COLOR_BODY_TEXT_LIGHTER,
    borderRadius: 8,
    paddingTop: 10,
    paddingBottom: 10,
    borderStyle: 'solid',
  },
}));

const renderDialogContent = (
  initialValues, pageMode, addingEditing, downloadingDeleting,
  vouchers, loadingVoucher, onChangeVouchersText, selectedVoucher, onVoucherOptionSelected,
  selectedAssignmentMode, onAssignmentModeOptionSelected,
  selectedBypassPayment, onBypassPaymentOptionSelected,
  selectedBypassMaxPurchase, onBypassMaxPurchaseOptionSelected,
  selectedBypassAchievement, onBypassAchievementOptionSelected,
  selectedUser, onUserOptionSelected, users, loadingUser, onChangeUserText,
  selectedAssignmentMethod, onAssignmentMethodOptionSelected,
  copying, onCopyPressed, classes,
) => {
  if (pageMode === PAGE_MODE_VIEW || pageMode === PAGE_MODE_EDIT) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentMethod}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignmentMethod}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_REDEMPTION_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderRedemptionCode}
              label={LocalizedString.voucherTokenAssignment.placeholderRedemptionCode}
              hidden={initialValues.assignmentMethod !== ASSIGNMENT_METHOD_URL_ACCESS}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues?.redemptionCode}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_FROM}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidFrom}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidFrom}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={initialValues.assignmentMethod === ASSIGNMENT_METHOD_IMMEDIATELY}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidUntil}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidUntil}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={initialValues.assignmentMethod === ASSIGNMENT_METHOD_IMMEDIATELY}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherId}
              label={LocalizedString.voucherTokenAssignment.placeholderVoucherId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherName}
              label={LocalizedString.voucherTokenAssignment.placeholderVoucherName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_TOKEN_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherTokenId}
              label={LocalizedString.voucherTokenAssignment.placeholderVoucherTokenId}
              hidden={isEmpty(initialValues.voucherToken)}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE}
              component={renderReduxFormSimpleDropdownField}
              data={ASSIGNMENT_MODE_OPTION}
              value={initialValues.codeAssignmentMode}
              onOptionSelected={(option) => onAssignmentModeOptionSelected(option, pageMode)}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentMode}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignmentMode}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderCode}
              label={LocalizedString.voucherTokenAssignment.placeholderCode}
              secureTextEntry
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={initialValues.codeAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_FROM}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidFrom}
              label={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidFrom}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={initialValues.codeAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE
                && initialValues.codeAssignmentMode !== ASSIGNMENT_MODE_GENERATE_NEW_CODE}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidUntil}
              label={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidUntil}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              hidden={initialValues.codeAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE
                && initialValues.codeAssignmentMode !== ASSIGNMENT_MODE_GENERATE_NEW_CODE}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderNotes}
              label={LocalizedString.voucherTokenAssignment.placeholderNotes}
              multiline
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT}
              component={renderReduxFormSimpleDropdownField}
              data={switches}
              value={initialValues.bypassPayment}
              onOptionSelected={(option) => onBypassPaymentOptionSelected(option, pageMode)}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderBypassPayment}
              label={LocalizedString.voucherTokenAssignment.placeholderBypassPayment}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE}
              component={renderReduxFormSimpleDropdownField}
              data={switches}
              value={initialValues.bypassMaxPurchaseValidation}
              onOptionSelected={(option) => onBypassMaxPurchaseOptionSelected(option, pageMode)}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderBypassMaxPurchase}
              label={LocalizedString.voucherTokenAssignment.placeholderBypassMaxPurchase}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT}
              component={renderReduxFormSimpleDropdownField}
              data={switches}
              value={initialValues.bypassAchievementValidation}
              onOptionSelected={(option) => onBypassAchievementOptionSelected(option, pageMode)}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderByPassAchievement}
              label={LocalizedString.voucherTokenAssignment.placeholderByPassAchievement}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignedById}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignedById}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignedByName}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignedByName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignedToId}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignedToId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignedToName}
              label={LocalizedString.voucherTokenAssignment.placeholderAssignedToName}
              disabled
            />
          </Grid>
          <div className={classes.border}>
            <Grid item>
              <Field
                name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_TITLE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.voucherTokenAssignment.placeholderNotifMessageTitle}
                label={LocalizedString.voucherTokenAssignment.placeholderNotifMessageTitle}
                multiline
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_NOTIF_MESSAGE_BODY}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.voucherTokenAssignment.placeholderNotifMessageBody}
                label={LocalizedString.voucherTokenAssignment.placeholderNotifMessageBody}
                multiline
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <div
              className={classes.captionText}
              dangerouslySetInnerHTML={{ __html: additionalPlaceholderNotifMessageBody }}
            />
          </div>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_IMAGE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.voucherTokenAssignment.placeholderImage}
              defaultValue={initialValues.image}
              useFullWidthImage
              useAvatarWithoutCropper
              fullImageWidth="100%"
              useCropper={false}
              loading={downloadingDeleting}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_METHOD}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentMethod}
            label={LocalizedString.voucherTokenAssignment.placeholderAssignmentMethod}
            data={ASSIGNMENT_METHOD_OPTION}
            value={selectedAssignmentMethod}
            onOptionSelected={onAssignmentMethodOptionSelected}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidFrom}
            label={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidFrom}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing}
            hidden={selectedAssignmentMethod === ASSIGNMENT_METHOD_IMMEDIATELY}
            required={selectedAssignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_ASSIGNMENT_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidUntil}
            label={LocalizedString.voucherTokenAssignment.placeholderAssignmentValidUntil}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing}
            hidden={selectedAssignmentMethod === ASSIGNMENT_METHOD_IMMEDIATELY}
            required={selectedAssignmentMethod === ASSIGNMENT_METHOD_URL_ACCESS}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_VOUCHER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.voucherTokenAssignment.labelVoucher}
            label={LocalizedString.voucherTokenAssignment.labelVoucher}
            data={vouchers}
            value={selectedVoucher}
            loading={loadingVoucher}
            onChangeText={onChangeVouchersText}
            onOptionSelected={onVoucherOptionSelected}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_ASSIGNMENT_MODE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenAssignment.labelAssignedMode}
            label={LocalizedString.voucherTokenAssignment.labelAssignedMode}
            data={ASSIGNMENT_MODE_OPTION}
            value={selectedAssignmentMode}
            onOptionSelected={onAssignmentModeOptionSelected}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderCode}
            label={LocalizedString.voucherTokenAssignment.placeholderCode}
            disabled={addingEditing}
            hidden={selectedAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidFrom}
            label={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidFrom}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing}
            hidden={selectedAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE
                && selectedAssignmentMode !== ASSIGNMENT_MODE_GENERATE_NEW_CODE}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_CODE_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidUntil}
            label={LocalizedString.voucherTokenAssignment.placeholderVoucherCodeValidUntil}
            pickerMode={PICKER_MODE_DATE_TIME}
            disabled={addingEditing}
            hidden={selectedAssignmentMode !== ASSIGNMENT_MODE_CUSTOM_CODE
                && selectedAssignmentMode !== ASSIGNMENT_MODE_GENERATE_NEW_CODE}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderNotes}
            label={LocalizedString.voucherTokenAssignment.placeholderNotes}
            disabled={addingEditing}
            multiline
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_PAYMENT}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenAssignment.labelBypassPayment}
            label={LocalizedString.voucherTokenAssignment.labelBypassPayment}
            data={switches}
            value={selectedBypassPayment}
            onOptionSelected={(option) => onBypassPaymentOptionSelected(option, pageMode)}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_MAX_PURCHASE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenAssignment.labelBypassMaxPurchase}
            label={LocalizedString.voucherTokenAssignment.labelBypassMaxPurchase}
            data={switches}
            value={selectedBypassMaxPurchase}
            onOptionSelected={(option) => onBypassMaxPurchaseOptionSelected(option, pageMode)}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_BYPASS_ACHIEVEMENT}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenAssignment.labelBypassAchievement}
            label={LocalizedString.voucherTokenAssignment.labelBypassAchievement}
            data={switches}
            value={selectedBypassAchievement}
            onOptionSelected={(option) => onBypassAchievementOptionSelected(option, pageMode)}
            disabled={addingEditing}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_USERS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.voucherTokenAssignment.placeholderUser}
            label={LocalizedString.voucherTokenAssignment.placeholderUser}
            data={users}
            value={selectedUser}
            loading={loadingUser}
            onChangeText={onChangeUserText}
            onOptionSelected={onUserOptionSelected}
            disabled={addingEditing}
            required={selectedAssignmentMethod === ASSIGNMENT_METHOD_IMMEDIATELY}
          />
        </Grid>
        <div className={classes.border}>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderNotifMessageTitle}
              label={LocalizedString.voucherTokenAssignment.placeholderNotifMessageTitle}
              disabled={addingEditing}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATE_NOTIF_MESSAGE_BODY}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherTokenAssignment.placeholderNotifMessageBody}
              label={LocalizedString.voucherTokenAssignment.placeholderNotifMessageBody}
              disabled={addingEditing}
              multiline
            />
            <div
              className={classes.captionText}
              dangerouslySetInnerHTML={{ __html: additionalPlaceholderNotifMessageBody }}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const VoucherTokenAssignmentPage = ({
  downloading, handleSubmit, onAppear, onChangePage, onChangePageSize,
  onRefresh, onSearchBarTextChanged, onSortPressed, onViewPressed,
  initialValues, onApplyAdvancedFilterPressed, onAdvancedFilterPressed,
  onResetAdvancedFilterPressed, pageMode, onCreateNewPressed,
  vouchers, loadingVoucher, onChangeVouchersText, addingEditing, selectedVoucher,
  users, loadingUser, onChangeAssignedByText, onChangeAssignedToText,
  onVoucherOptionSelected, onSubmitPressed,
  selectedAssignmentMode, onAssignmentModeOptionSelected,
  selectedBypassPayment, onBypassPaymentOptionSelected,
  selectedBypassMaxPurchase, onBypassMaxPurchaseOptionSelected,
  selectedBypassAchievement, onBypassAchievementOptionSelected,
  selectedUser, onUserOptionSelected, onChangeUserText,
  loadingAssignedTo, assignedTo, selectedAssignmentMethod, onAssignmentMethodOptionSelected,
  copying, onCopyPressed, onEnabledDisabledPressed, enablingDisabling,
  currentStatus, onConfirmEnabledDisabledPressed, onEditPressed, onSavePressed,
  currentAssignmentMethod, downloadingDeleting,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_VOUCHER_TOKEN_ASSIGNMENTS}
      uiPage={RXSTATE_VOUCHER_TOKEN_ASSIGNMENT_PAGE}
      title={LocalizedString.voucherTokenAssignment.title}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (tappedId) => onEnabledDisabledPressed(tappedId,
            LocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (tappedId) => onEnabledDisabledPressed(tappedId,
            LocalizedString.common.msgDisableConfirmation),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.voucherTokenAssignment.labelCreatedDate,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelVoucher,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_VOUCHER_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: vouchers,
          loading: loadingVoucher,
          onChangeFilterText: (text) => onChangeVouchersText(text, true),
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedBy,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_BY_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: users,
          loading: loadingUser,
          onChangeFilterText: (text) => onChangeAssignedByText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedTo,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNED_TO_NAME,
          type: FILTER_TYPE_DROPDOWN,
          data: assignedTo,
          loading: loadingAssignedTo,
          onChangeFilterText: (text) => onChangeAssignedToText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedMode,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_MODE,
          type: FILTER_TYPE_DROPDOWN,
          data: ASSIGNMENT_MODE_OPTION,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassPayment,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_PAYMENT,
          type: FILTER_TYPE_SWITCH,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassMaxPurchase,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_MAX_PURCHASE,
          type: FILTER_TYPE_SWITCH,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassAchievement,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_BYPASS_ACHIEVEMENT,
          type: FILTER_TYPE_SWITCH,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignmentMethod,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_ASSIGNMENT_METHOD,
          type: FILTER_TYPE_DROPDOWN,
          data: ASSIGNMENT_METHOD_OPTION,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelStatus,
          field: RXFIELD_VOUCHER_TOKEN_ASSIGNMENT_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.voucherTokenAssignment.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelVoucher,
          field: 'voucher.title',
          sorting: !downloading,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedTo,
          field: 'assignedTo.fullName',
          sorting: !downloading,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedBy,
          field: 'assignedBy.fullName',
          sorting: !downloading,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelAssignedMode,
          field: 'codeAssignmentMode',
          sorting: !downloading,
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassPayment,
          field: 'bypassPayment',
          sorting: !downloading,
          render: ({ bypassPayment }) => (bypassPayment
            ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo),
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassMaxPurchase,
          field: 'bypassMaxPurchaseValidation',
          sorting: !downloading,
          render: ({ bypassMaxPurchaseValidation }) => (bypassMaxPurchaseValidation
            ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo),
        },
        {
          title: LocalizedString.voucherTokenAssignment.labelBypassAchievement,
          field: 'bypassAchievementValidation',
          sorting: !downloading,
          render: ({ bypassAchievementValidation }) => (bypassAchievementValidation
            ? GlobalLocalizedString.common.labelYes : GlobalLocalizedString.common.labelNo),
        },
      ]}
      onConfirmDeletePressed={onConfirmEnabledDisabledPressed}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onCreatePressed={onCreateNewPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={onViewPressed}
      onSavePressed={onSavePressed}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      createNewButtonCaption={LocalizedString.voucherTokenAssignment.buttonCaptionCreate}
      editButtonCaption={LocalizedString.voucherTokenAssignment.buttonCaptionEdit}
      onSubmitPressed={onSubmitPressed}
      onEditPressed={onEditPressed}
      useFullWidth
      disableDelete
      disableEdit={currentAssignmentMethod !== ASSIGNMENT_METHOD_URL_ACCESS}
      createPermissionName="VOUCHER_ASSIGN_TOKEN"
      editPermissionName="VOUCHER_EDIT_TOKEN_ASSIGNMENT"
    >
      {renderDialogContent(initialValues, pageMode, addingEditing, downloadingDeleting,
        vouchers, loadingVoucher, onChangeVouchersText, selectedVoucher, onVoucherOptionSelected,
        selectedAssignmentMode, onAssignmentModeOptionSelected,
        selectedBypassPayment, onBypassPaymentOptionSelected,
        selectedBypassMaxPurchase, onBypassMaxPurchaseOptionSelected,
        selectedBypassAchievement, onBypassAchievementOptionSelected,
        selectedUser, onUserOptionSelected, users, loadingUser, onChangeUserText,
        selectedAssignmentMethod, onAssignmentMethodOptionSelected,
        copying, onCopyPressed, classes)}
      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message="Copied"
        onClose={() => onCopyPressed(copying)}
      />
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_VOUCHER_TOKEN_ASSIGNMENT,
  validate: rxformValidateVoucherTokenAssignment,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherTokenAssignmentPage);

VoucherTokenAssignmentPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape),
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  assignedTo: PropTypes.arrayOf(SimpleDataShape).isRequired,
  pageMode: PropTypes.string.isRequired,
  currentStatus: PropTypes.string.isRequired,
  currentAssignmentMethod: PropTypes.string.isRequired,
  selectedVoucher: SimpleDataShape.isRequired,
  selectedAssignmentMethod: PropTypes.string.isRequired,
  selectedAssignmentMode: PropTypes.string.isRequired,
  selectedBypassPayment: PropTypes.oneOfType([PropTypes.string, SimpleDataShape]).isRequired,
  selectedBypassMaxPurchase: PropTypes.oneOfType([PropTypes.string, SimpleDataShape]).isRequired,
  selectedBypassAchievement: PropTypes.oneOfType([PropTypes.string, SimpleDataShape]).isRequired,
  selectedUser: PropTypes.oneOfType([PropTypes.string, SimpleDataShape]).isRequired,

  downloading: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  loadingAssignedTo: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeVouchersText: PropTypes.func.isRequired,
  onChangeAssignedByText: PropTypes.func.isRequired,
  onChangeAssignedToText: PropTypes.func.isRequired,
  onCreateNewPressed: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onAssignmentModeOptionSelected: PropTypes.func.isRequired,
  onBypassPaymentOptionSelected: PropTypes.func.isRequired,
  onBypassMaxPurchaseOptionSelected: PropTypes.func.isRequired,
  onBypassAchievementOptionSelected: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onAssignmentMethodOptionSelected: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onEnabledDisabledPressed: PropTypes.func.isRequired,
  onConfirmEnabledDisabledPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
};
VoucherTokenAssignmentPage.defaultProps = {
  vouchers: [],
};
