/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, withStyles,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { reduxForm, Field } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  COLOR_ICON, FILTER_TYPE_DATE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_MULTIPLE_VALUES, FILTER_TYPE_NUMBER, FILTER_TYPE_NUMBER_RANGE,
  FILTER_TYPE_RADIO_BUTTON, FILTER_TYPE_SWITCH, FILTER_TYPE_TEXT,
  REGEX_CONTAIN_DATE_RANGE_TIMEZONE_KEY, REGEX_DATE_TIME_FORMAT, RXFIELD_DATE_RANGE_TIME_ZONE,
  RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG, switches, timezoneOffset,
} from '../../../constant';
import {
  localDateToUtc, toMoment, toNumber, toUtcMoment,
} from '../../../helper';
import LocalizedString from '../../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormRadioButtonField, renderReduxFormSimpleDropdownField,
} from '../../../redux-form-rendererer';
import { FormInitialValueShape, TableColumnShape } from '../../../type';
import AccentButton from '../../accent-button';
import SectionTitle from '../../section-title';

const styles = (() => ({
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    margin: '12px 0px 12px 0px',
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '14px',
    },
    padding: '16px 24px 8px 24px',
  },
}));

const getGroupedColumns = (filterColumns, filterSections) => {
  const mapped = filterColumns.reduce((acc, item) => (acc.has(item.sectionId)
    ? acc.set(item.sectionId, acc.get(item.sectionId).concat({ ...item }))
    : acc.set(item.sectionId, [{ ...item }])),
  new Map());

  return [...mapped].map((e) => {
    const { sectionId } = e[1][0];
    const title = sectionId ? filterSections.find((x) => x.id === sectionId).title : null;
    return ({ title, data: e[1] });
  });
};

const onClosePress = (onCancelAdvancedFilterPressed, onClosePressed, onResetPressed,
  setErrorDateRange, setErrorNumRange, filterString) => {
  setErrorDateRange({ message: null, fields: [] });
  setErrorNumRange({ message: null, fields: [] });
  onClosePressed();
  onCancelAdvancedFilterPressed();
  if (typeof onResetPressed === 'function' && !filterString) { onResetPressed(); }
};

const onHandleSubmit = async (e, onApplyFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onClosePressed, onError, setErrorDateRange, setErrorNumRange,
  columns) => {
  setErrorDateRange({ message: null, fields: [] });
  setErrorNumRange({ message: null, fields: [] });

  const getFilteredColumns = (type) => columns.filter((item) => item.type === type)
    .map((x) => x.field);
  const foundDateRangePicker = getFilteredColumns(FILTER_TYPE_DATE_RANGE);
  const foundNumberRangePicker = getFilteredColumns(FILTER_TYPE_NUMBER_RANGE);

  let result = '';
  let minNum = {};
  let maxNum = {};
  let minDate = {};
  let maxDate = {};

  Object.keys(e).forEach((key) => {
    const originalKey = key.replace('>', '').replace('<', '');

    if (foundDateRangePicker.find((x) => x === originalKey)) {
      const timeZoneKey = `${originalKey}${RXFIELD_DATE_RANGE_TIME_ZONE}`;
      if (e[key] && key.includes('>')) {
        minDate = { ...minDate, [originalKey]: toUtcMoment(e[key], e[timeZoneKey]) };
      } else if (e[key] && key.includes('<')) {
        maxDate = { ...maxDate, [originalKey]: toUtcMoment(e[key], e[timeZoneKey]) };
      }
    }
    if (foundNumberRangePicker.find((x) => x === originalKey)) {
      if (e[key] && key.includes('>')) {
        minNum = { ...minNum, [originalKey]: toNumber(e[key]) };
      } else if (e[key] && key.includes('<')) {
        maxNum = { ...maxNum, [originalKey]: toNumber(e[key]) };
      }
    }
  });

  const dateRangeCheck = [];
  const numberRangeCheck = [];

  if (!isEmpty(minDate) && !isEmpty(maxDate)) {
    Object.keys(minDate).forEach((minKey) => Object.keys(maxDate)
      .forEach((maxKey) => {
        if (minKey === maxKey) {
          dateRangeCheck.push({
            label: maxKey,
            value: toMoment(maxDate[maxKey])
              .isSameOrBefore(toMoment(minDate[minKey])),
          });
        }
        return maxKey;
      }));
  }
  if (!isEmpty(minNum) && !isEmpty(maxNum)) {
    Object.keys(minNum).forEach((minKey) => Object.keys(maxNum)
      .forEach((maxKey) => {
        if (minKey === maxKey) {
          numberRangeCheck.push({
            label: maxKey,
            value: minNum[minKey] > maxNum[maxKey] || maxNum[maxKey] === 0,
          });
        }
        return maxKey;
      }));
  }

  const dateRangeError = dateRangeCheck.some((x) => x.value);
  const numberRangeError = numberRangeCheck.some((x) => x.value);

  if (dateRangeError) {
    setErrorDateRange({
      message: LocalizedString.common.errMsgStartEndDate,
      fields: dateRangeCheck.filter((x) => x.value).map((x) => x.label),
    });
  }
  if (numberRangeError) {
    setErrorNumRange({
      message: LocalizedString.common.errMsgMinMaxNum,
      fields: numberRangeCheck.filter((x) => x.value).map((x) => x.label),
    });
  }
  if (!dateRangeError && !numberRangeError) {
    Object.keys(e).forEach((key) => {
      if ((!e[key] && typeof e[key] !== 'boolean' && typeof e[key] !== 'object')) {
        return delete e[key];
      }
      if (typeof e[key] === 'string' && e[key].match(REGEX_DATE_TIME_FORMAT)) {
        const timeZoneKey = `${key.replace('>', '').replace('<', '')}${RXFIELD_DATE_RANGE_TIME_ZONE}`;
        result += `|${key}=${localDateToUtc(e[key], e[timeZoneKey] ?? null)}`;
      }
      if ((typeof e[key] === 'object' && e[key].value)
        || (typeof e[key] === 'string' && !e[key].match(REGEX_DATE_TIME_FORMAT) && !key.match(REGEX_CONTAIN_DATE_RANGE_TIMEZONE_KEY)) || (typeof e[key] === 'boolean')) {
        result += `|${key}=${e[key] && typeof e[key] === 'object' && e[key].value ? encodeURIComponent(e[key].value) : encodeURIComponent(e[key])}`;
      }
      if (e[key] instanceof Array) {
        if (e[key].some((item) => typeof item === 'string')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x));
          result += `|${key}><${transformArr.join(';')}`;
        }
        if (e[key].some((item) => typeof item === 'object')) {
          const transformArr = e[key].map((x) => encodeURIComponent(x.value));
          result += `|${key}><${transformArr.join(';')}`;
        }
      }
      return result;
    });

    if (result) {
      try {
        onApplyFilterPressed(result);
        await onApplyAdvancedFilterPressed(result);
        onClosePress(onCancelAdvancedFilterPressed, onClosePressed, null, setErrorDateRange,
          setErrorNumRange, null);
      } catch (error) {
        onError(error);
      }
    } else {
      try {
        onApplyFilterPressed('');
        await onApplyAdvancedFilterPressed('');
        onClosePress(onCancelAdvancedFilterPressed, onClosePressed, null, setErrorDateRange,
          setErrorNumRange, null);
      } catch (error) {
        onError(error);
      }
    }
  }
};

const renderItem = (item, downloading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
  initialValues) => {
  let component = null;

  const mediumWidth = item.mediumWidth ? item.mediumWidth : true;

  switch (item.type) {
    case FILTER_TYPE_DATE: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormDateTimePickerField}
            label={item.title}
            disabled={downloading}
            pickerMode={item.pickerMode}
            onChangeDate={(e) => onChangeDate(e, item.field)}
            dateFormat={item.format}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_DATE_RANGE: {
      const found = errorDateRange.fields.find((x) => x === item.field);
      const errorMsg = found ? errorDateRange.message : null;

      component = (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={downloading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange({ message: null, fields: [] });
                onChangeDate(e, `${item.field}>`);
              }}
              helperText={errorMsg}
              dateFormat={item.format}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormDateTimePickerField}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={downloading}
              pickerMode={item.pickerMode}
              onChangeDate={(e) => {
                setErrorDateRange({ message: null, fields: [] });
                onChangeDate(e, `${item.field}<`);
              }}
              helperText={errorMsg}
              dateFormat={item.format}
            />
          </Grid>
          {item.useTimeZone && (
          <Grid item sm md>
            <Field
              name={`${item.field}${RXFIELD_DATE_RANGE_TIME_ZONE}`}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.common.placeholderTimeZone}
              label={`${LocalizedString.common.placeholderTimeZone} ${item.title}`}
              disabled={downloading}
              data={timezoneOffset}
              defaultValue={initialValues[`${item.field}${RXFIELD_DATE_RANGE_TIME_ZONE}`] ?? timezoneOffset[0]}
              onOptionSelected={(e) => onChangeSwitch(e, `${item.field}${RXFIELD_DATE_RANGE_TIME_ZONE}`)}
            />
          </Grid>
          )}
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_DROPDOWN: {
      component = (
        <Grid item sm md={mediumWidth} key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading || !!item?.disabled}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected(e);
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={item.useDropdownValue ? (e) => e.preventDefault() : undefined}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_MULTIPLE_VALUES: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormAutocompleteCheckboxField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            data={item.data}
            loading={item.loading}
            onChangeText={item.onChangeFilterText}
            onOptionSelected={item.onFilterOptionSelected ? (e) => {
              onChangeDropdown(e, item.field);
              item.onFilterOptionSelected(e);
            } : (e) => onChangeDropdown(e, item.field)}
            onBlur={(e) => e.preventDefault()}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_NUMBER: {
      component = (
        <Grid item xs md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            type="number"
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_NUMBER_RANGE: {
      const found = errorNumRange.fields.find((x) => x === item.field);
      const errorMsg = found ? errorNumRange.message : null;

      component = (
        <Grid item container key={item.field}>
          <Grid item sm md>
            <Field
              name={`${item.field}>`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMin} ${item.title}`}
              label={`${LocalizedString.common.labelMin} ${item.title}`}
              disabled={downloading}
              onChangeText={(e) => {
                setErrorNumRange({ message: null, fields: [] });
                onChangeText(e, `${item.field}>`);
              }}
              type="number"
              helperText={errorMsg}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={`${item.field}<`}
              component={renderReduxFormOutlinedTextField}
              placeholder={`${LocalizedString.common.labelMax} ${item.title}`}
              label={`${LocalizedString.common.labelMax} ${item.title}`}
              disabled={downloading}
              onChangeText={(e) => {
                setErrorNumRange({ message: null, fields: [] });
                onChangeText(e, `${item.field}<`);
              }}
              type="number"
              helperText={errorMsg}
            />
          </Grid>
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_RADIO_BUTTON: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormRadioButtonField}
            label={item.title}
            data={item.data}
            onOptionSelected={item.onFilterOptionSelected}
            disabled={downloading}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_SWITCH: {
      component = (
        <Grid item sm md key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormSimpleDropdownField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            data={item.data && item.data.length > 0 ? item.data : switches}
            onOptionSelected={(e) => onChangeSwitch(e, item.field)}
          />
        </Grid>
      );
      break;
    }
    case FILTER_TYPE_TEXT: {
      component = (
        <Grid item xs sm md={mediumWidth} key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
            onChangeText={(e) => onChangeText(e, item.field)}
          />
        </Grid>
      );
      break;
    }

    default: {
      component = (
        <Grid item xs sm md={mediumWidth} key={item.field}>
          <Field
            name={item.field}
            component={renderReduxFormOutlinedTextField}
            placeholder={item.title}
            label={item.title}
            disabled={downloading}
          />
        </Grid>
      );
      break;
    }
  }

  return component;
};

const renderTwoColumnItem = (
  data, downloading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
  initialValues,
) => {
  const newData = data.reduce((p, c) => {
    if (!p.length) {
      return [[c]];
    }
    const lastItemArrayIndex = p.length - 1;
    const lastItemArray = p[lastItemArrayIndex];
    const lastItemArrayLength = lastItemArray.length;
    if (lastItemArrayLength === 2) {
      return [...p, [c]];
    }
    if (lastItemArrayLength === 1) {
      const item = lastItemArray[0];
      const isDateRange = item.type === FILTER_TYPE_DATE_RANGE;
      if (isDateRange) {
        return [...p, [c]];
      }

      const lastItemArrayWithNewItem = [...lastItemArray, c];
      const newArray = p;
      newArray[lastItemArrayIndex] = lastItemArrayWithNewItem;
      return newArray;
    }

    return [...p, [c]];
  }, []);

  return newData.map((columns, i) => {
    const length = columns?.length || 0;
    if (length > 1) {
      return (
        <Grid item container key={`row${i + 1}`}>
          {columns.map((item) => renderItem(
            item, downloading, errorDateRange, errorNumRange, onChangeDate,
            onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
            initialValues,
          ))}
        </Grid>
      );
    }

    if (columns.length === 1) {
      const item = columns[0];
      return renderItem(
        item, downloading, errorDateRange, errorNumRange, onChangeDate,
        onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
        initialValues,
      );
    }

    return null;
  });
};

const renderItemContainer = (data, downloading, errorDateRange, errorNumRange, onChangeDate,
  onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
  initialValues, useTwoColumnFilterDialog) => (
    <Grid container>
      {useTwoColumnFilterDialog
        ? renderTwoColumnItem(
          data, downloading, errorDateRange, errorNumRange, onChangeDate,
          onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
          initialValues,
        )
        : data.map((x) => renderItem(x, downloading, errorDateRange, errorNumRange, onChangeDate,
          onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange, setErrorNumRange,
          initialValues))}
    </Grid>
);

const AdvancedFilterDialog = ({
  initialValues, filterColumns, filterSections,
  advancedFilterVisibility, downloading,
  handleSubmit, onAppear, onApplyFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed,
  onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, onClosePressed, onError,
  onResetAdvancedFilterPressed, onResetPressed,
  classes,
  filterString,
  useTwoColumnFilterDialog,
}) => {
  const [errorDateRange, setErrorDateRange] = useState({ message: null, fields: [] });
  const [errorNumRange, setErrorNumRange] = useState({ message: null, fields: [] });
  const columnsWithSection = filterSections.length > 0
    ? getGroupedColumns(filterColumns, filterSections) : [];

  // eslint-disable-next-line
  useEffect(() => onAppear(columnsWithSection, filterColumns), []);

  return (
    <Dialog
      open={advancedFilterVisibility}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.paper }}
    >
      <div className={classes.headerContainer}>
        <DialogTitle className={classes.title}>
          {LocalizedString.common.labelAdvancedFilter}
        </DialogTitle>

        <IconButton
          onClick={() => onClosePress(onCancelAdvancedFilterPressed, onClosePressed, onResetPressed,
            setErrorDateRange, setErrorNumRange, filterString)}
          disabled={downloading}
        >
          <CloseRounded style={{ color: COLOR_ICON, fontSize: '20px' }} />
        </IconButton>
      </div>

      <DialogContent className={classes.dialogContent}>
        <form
          onSubmit={handleSubmit((e) => {
            onHandleSubmit(e, onApplyFilterPressed,
              onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onClosePressed, onError,
              setErrorDateRange, setErrorNumRange,
              columnsWithSection.length ? columnsWithSection : filterColumns);
          })}
          className={classes.form}
        >

          {columnsWithSection.length > 0 ? columnsWithSection.map((x) => (
            <div>
              {x.title && (<SectionTitle title={x.title} />)}
              {renderItemContainer(x.data, downloading, errorDateRange, errorNumRange,
                onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange,
                setErrorNumRange, initialValues, useTwoColumnFilterDialog)}
            </div>
          )) : renderItemContainer(filterColumns, downloading, errorDateRange, errorNumRange,
            onChangeDate, onChangeDropdown, onChangeText, onChangeSwitch, setErrorDateRange,
            setErrorNumRange, initialValues, useTwoColumnFilterDialog)}

          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={() => onClosePress(onCancelAdvancedFilterPressed, onClosePressed,
                onResetPressed, setErrorDateRange, setErrorNumRange, filterString)}
              variant="text"
              caption={LocalizedString.common.buttonCaptionCancel}
              disabled={downloading}
              size="small"
            />

            <AccentButton
              onClick={() => {
                setErrorDateRange({ message: null, fields: [] });
                setErrorNumRange({ message: null, fields: [] });
                onClosePressed();
                onResetPressed();
                onResetAdvancedFilterPressed();
              }}
              variant="outlined"
              caption={LocalizedString.common.buttonCaptionReset}
              disabled={downloading}
              size="small"
            />

            <AccentButton
              type="submit"
              disableElevation
              caption={LocalizedString.common.buttonCaptionApply}
              disabled={downloading}
              size="small"
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(reduxForm({
  form: RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AdvancedFilterDialog));

AdvancedFilterDialog.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  filterColumns: PropTypes.arrayOf(TableColumnShape).isRequired,
  filterSections: PropTypes.arrayOf(TableColumnShape).isRequired,
  advancedFilterVisibility: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  useTwoColumnFilterDialog: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
  onChangeText: PropTypes.func.isRequired,
  onChangeSwitch: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetPressed: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filterString: PropTypes.string.isRequired,
};

AdvancedFilterDialog.defaultProps = {
  useTwoColumnFilterDialog: false,
};
