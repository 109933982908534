import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  setAlertConfirmationMessage, setAlertErrorMessage, setActiveSideMenuItem,
  setEventCategoryAdvancedFilterDialogSelectedFilterString, setEventCategorySearchText,
  clearEventCategories, setEventCategorySelectedPageSize, setEventCategorySelectedOrderBy,
  downloadEventCategoriesAsync, downloadDeleteEventCategoryAsync,
  enableDisableEventCategoryAsync, setEventCategoryTappedId, addEditEventCategoryAsync,
  setEventCategorySelectedClearImage, deleteMultipleEventCategoryAsync,
  enableDisableMultipleEventCategoryAsync,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE,
  MENUID_EVENT_CATEGORY,
  REST_BASE_URL,
  REST_URL_IMAGE_STORAGE,
  RXFORM_EVENT_CATEGORY,
  RXFIELD_EVENT_CATEGORY_IMAGE,
  INITIAL_ORDER_BY_EVENT_CATEGORIES,
  RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE,
  approval,
  STATUS_DISABLED,
  STATUS_ENABLED,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import EventCategoryPage from './event-category.presentation';

const getInitialValues = (state) => {
  const { eventCategories, uiEventCategory, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventCategories.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        name: found.name,
        description: found.description,
        image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
        clearImage: found.clearImage ? approval[0] : approval[1],
      })
    : {
      name: '',
      description: '',
      image: null,
      clearImage: false,
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiEventCategory.addingEditing,
  currentCategoryStatus: state.eventCategories.data[state.uiFunctionalPage.tappedId]
    ? state.eventCategories.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiEventCategory.downloading,
  enablingDisabling: state.uiEventCategory.enablingDisabling,
  eventCategories: state.eventCategories.data,
  pageMode: state.uiFunctionalPage.pageMode,
  tappedData: state.eventCategories.data[state.uiEventCategory.tappedId],
  multiple: state.uiFunctionalPage.selectedRow.length > 0,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_CATEGORY));
    dispatch(setEventCategorySearchText(''));
    dispatch(clearEventCategories());
    dispatch(setEventCategorySelectedPageSize(20));
    dispatch(setEventCategorySelectedOrderBy(INITIAL_ORDER_BY_EVENT_CATEGORIES));
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT_CATEGORY));
  },
  onChangeClearImage: (option) => {
    if (option) {
      dispatch(setEventCategorySelectedClearImage(option));
      dispatch(change(RXFORM_EVENT_CATEGORY, RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE, option));
    } else {
      dispatch(setEventCategorySelectedClearImage(''));
      dispatch(change(RXFORM_EVENT_CATEGORY, RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE, ''));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventCategorySelectedPageSize(pageSize));
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message, multiple) => {
    if (multiple) {
      switch (message) {
        case GlobalLocalizedString.common.msgDeleteConfirmation:
          dispatch(deleteMultipleEventCategoryAsync())
            .catch((error) => {
              dispatch(setAlertErrorMessage(error));
            }); break;
        case GlobalLocalizedString.common.msgDisableConfirmation:
          dispatch(enableDisableMultipleEventCategoryAsync(STATUS_DISABLED))
            .catch((error) => {
              dispatch(setAlertErrorMessage(error));
            }); break;
        default:
          dispatch(enableDisableMultipleEventCategoryAsync(STATUS_ENABLED))
            .catch((error) => {
              dispatch(setAlertErrorMessage(error));
            });
      }
    } else {
      switch (message) {
        case GlobalLocalizedString.common.msgDeleteConfirmation:
          dispatch(downloadDeleteEventCategoryAsync())
            .catch((error) => {
              dispatch(setAlertErrorMessage(error));
            }); break;
        default:
          dispatch(enableDisableEventCategoryAsync())
            .catch((error) => {
              dispatch(setAlertErrorMessage(error));
            });
      }
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setEventCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setEventCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setEventCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setEventCategoryTappedId(id));
    dispatch(downloadDeleteEventCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_EVENT_CATEGORY, RXFIELD_EVENT_CATEGORY_IMAGE, image));
  },
  onRefresh: (pageSize) => {
    dispatch(setEventCategorySelectedPageSize(pageSize));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventCategories());
    dispatch(setEventCategorySelectedOrderBy(INITIAL_ORDER_BY_EVENT_CATEGORIES));
    dispatch(downloadEventCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventCategorySearchText(text));
      dispatch(clearEventCategories());
      await dispatch(downloadEventCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSavePressed: async ({
    name, description, image, clearImage,
  }) => {
    try {
      await dispatch(addEditEventCategoryAsync(name, description, image, clearImage));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventCategorySelectedOrderBy(orderBy));
    dispatch(clearEventCategories());
    dispatch(downloadEventCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, image,
  }) => {
    await dispatch(addEditEventCategoryAsync(name, description, image));
  },
  onViewPressed: (id) => {
    dispatch(setEventCategoryTappedId(id));
    dispatch(downloadDeleteEventCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCategoryPage);
