import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  ADVANCED_FILTER_MODE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS,
  MENUID_SESSION_OPERATOR_MAPPING, PAGE_MODE_TABLE, RXFIELD_SESSION_OPERATOR_MAPPING_SESSION,
  RXFIELD_SESSION_OPERATOR_MAPPING_USER, RXFORM_SESSION_OPERATOR_MAPPING,
} from '../../constant';
import {
  clearSessions, downloadSessionsAsync, setSessionAdvancedFilterDialogSelectedFilterString,
  setSessionSearchText,
} from '../../../event/redux/action';
import {
  debounceSearch, toMoment, transformDropdownData, transformInitialValues,
  transformUserDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearSessionOperatorMappings, clearProfiles, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setSessionOperatorMappingAdvancedFilterDialogSelectedFilterString,
  setSessionOperatorMappingSelectedSession, setSessionOperatorMappingSelectedOrderBy,
  setSessionOperatorMappingSelectedPageSize, setSessionOperatorMappingSearchText,
  setSessionOperatorMappingSelectedUser, setSessionOperatorMappingTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addEditSessionOperatorMappingAsync, downloadDeleteSessionOperatorMappingAsync,
  downloadSessionOperatorMappingsAsync, enableDisableSessionOperatorMappingAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import SessionOperatorMappingPage from './session-operator-mapping.presentation';

const getInitialValues = (state) => {
  const { sessionOperatorMappings, uiSessionOperatorMapping, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionOperatorMapping;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionOperatorMappings.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      userId: { label: found.user.fullName, value: found.user.id },
      sessionId: { label: found.session.name, value: found.session.id },
      validUntil: found?.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    }) : {
      userId: { label: '', value: '' },
      sessionId: { label: '', value: '' },
      validUntil: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  sessions: transformDropdownData(state.sessions.data),
  users: transformUserDropdownData(state.profiles.data),
  addingEditing: state.uiSessionOperatorMapping.addingEditing,
  downloading: state.uiSessionOperatorMapping.downloading,
  downloadingDeleting: state.uiSessionOperatorMapping.downloadingDeleting,
  enablingDisabling: state.uiSessionOperatorMapping.enablingDisabling,
  loadingUser: state.uiProfile.downloading,
  loadingSession: state.uiSession.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
});

const searchSessionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(setSessionSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearSessions());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setSessionOperatorMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_SESSION_OPERATOR_MAPPING));
    dispatch(setSessionOperatorMappingSearchText(''));
    dispatch(clearSessionOperatorMappings());
    dispatch(setSessionOperatorMappingSelectedPageSize(20));
    dispatch(setSessionOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS));
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/session/, 'session.id');
    dispatch(setSessionOperatorMappingAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessionOperatorMappings());
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION_OPERATOR_MAPPING));
    dispatch(setSessionOperatorMappingSelectedSession(''));
    dispatch(setSessionOperatorMappingSelectedUser(''));
  },
  onChangeSessionText: (text, mode) => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled|visibilityStatus=visible',
    ));
    dispatch(setSessionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionDebounce(dispatch, true);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionOperatorMappingsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionOperatorMappingSelectedPageSize(pageSize));
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch, true);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSessionOperatorMappingAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSessionOperatorMappingAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSessionOperatorMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setSessionOperatorMappingTappedId(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileSearchText(''));
    dispatch(setSessionSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearSessions());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setSessionOperatorMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSessionOperatorMappingTappedId(id));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileSearchText(''));
    dispatch(setSessionSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearSessions());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDeleteSessionOperatorMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSessionOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionOperatorMappingSelectedSession(option));
      dispatch(change(RXFORM_SESSION_OPERATOR_MAPPING, RXFIELD_SESSION_OPERATOR_MAPPING_SESSION,
        option));
    } else {
      dispatch(setSessionOperatorMappingSelectedSession(''));
      dispatch(change(RXFORM_SESSION_OPERATOR_MAPPING, RXFIELD_SESSION_OPERATOR_MAPPING_SESSION, ''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionOperatorMappingSelectedUser(option));
      dispatch(change(RXFORM_SESSION_OPERATOR_MAPPING, RXFIELD_SESSION_OPERATOR_MAPPING_USER,
        option));
    } else {
      dispatch(setSessionOperatorMappingSelectedUser(''));
      dispatch(change(RXFORM_SESSION_OPERATOR_MAPPING, RXFIELD_SESSION_OPERATOR_MAPPING_USER, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionOperatorMappingSelectedPageSize(pageSize));
    dispatch(clearSessionOperatorMappings());
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionOperatorMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionOperatorMappings());
    dispatch(setSessionOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_SESSION_OPERATOR_MAPPINGS));
    dispatch(downloadSessionOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ validUntil }) => {
    try {
      await dispatch(addEditSessionOperatorMappingAsync(validUntil));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionOperatorMappingSearchText(text));
      dispatch(clearSessionOperatorMappings());
      await dispatch(downloadSessionOperatorMappingsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionOperatorMappingSelectedOrderBy(orderBy));
    dispatch(clearSessionOperatorMappings());
    dispatch(downloadSessionOperatorMappingsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ validUntil }) => {
    await dispatch(addEditSessionOperatorMappingAsync(validUntil));
  },
  onViewPressed: (id) => {
    dispatch(setSessionOperatorMappingTappedId(id));
    dispatch(downloadDeleteSessionOperatorMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionOperatorMappingPage);
