import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_ASSETS = 'DOWNLOADING_ASSETS';
export const downloadingAssets = makeActionCreator(DOWNLOADING_ASSETS, 'status');

export const SET_ASSETS = 'SET_ASSETS';
export const setAssets = makeActionCreator(SET_ASSETS, 'data');

export const ADD_ASSET = 'ADD_ASSET';
export const addAsset = makeActionCreator(ADD_ASSET, 'data');

export const CLEAR_ASSETS = 'CLEAR_ASSETS';
export const clearAssets = makeActionCreator(CLEAR_ASSETS);

export const DOWNLOADING_DELETING_ASSET = 'DOWNLOADING_DELETING_ASSET';
export const downloadingDeletingAsset = makeActionCreator(DOWNLOADING_DELETING_ASSET, 'status');

export const CHANGING_ASSET_REMINDER_FLAG = 'CHANGING_ASSET_REMINDER_FLAG';
export const changingAssetReminderFlag = makeActionCreator(CHANGING_ASSET_REMINDER_FLAG, 'status');


export const SET_ASSET_SELECTED_PAGE_SIZE = 'SET_ASSET_SELECTED_PAGE_SIZE';
export const setAssetSelectedPageSize = makeActionCreator(SET_ASSET_SELECTED_PAGE_SIZE, 'size');

export const SET_ASSET_SELECTED_ORDER_BY = 'SET_ASSET_SELECTED_ORDER_BY';
export const setAssetSelectedOrderBy = makeActionCreator(SET_ASSET_SELECTED_ORDER_BY, 'order');

export const SET_ASSET_TAPPED_ID = 'SET_ASSET_TAPPED_ID';
export const setAssetTappedId = makeActionCreator(SET_ASSET_TAPPED_ID, 'id');

export const SET_ASSET_SEARCH_TEXT = 'SET_ASSET_SEARCH_TEXT';
export const setAssetSearchText = makeActionCreator(SET_ASSET_SEARCH_TEXT, 'text');

export const SET_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAssetAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_ASSET_ACTIVE_TAB = 'SET_ASSET_ACTIVE_TAB';
export const setAssetActiveTab = makeActionCreator(SET_ASSET_ACTIVE_TAB, 'text');


export const DOWNLOADING_ASSET_TYPES = 'DOWNLOADING_ASSET_TYPES';
export const downloadingAssetTypes = makeActionCreator(DOWNLOADING_ASSET_TYPES, 'status');

export const SET_ASSET_TYPES = 'SET_ASSET_TYPES';
export const setAssetTypes = makeActionCreator(SET_ASSET_TYPES, 'data');

export const ADD_ASSET_TYPE = 'ADD_ASSET_TYPE';
export const addAssetType = makeActionCreator(ADD_ASSET_TYPE, 'data');

export const CLEAR_ASSET_TYPES = 'CLEAR_ASSET_TYPES';
export const clearAssetTypes = makeActionCreator(CLEAR_ASSET_TYPES);

export const SET_ASSET_TYPE_SEARCH_TEXT = 'SET_ASSET_TYPE_SEARCH_TEXT';
export const setAssetTypeSearchText = makeActionCreator(SET_ASSET_TYPE_SEARCH_TEXT, 'text');


export const DOWNLOADING_ASSET_LOCATIONS = 'DOWNLOADING_ASSET_LOCATIONS';
export const downloadingAssetLocations = makeActionCreator(DOWNLOADING_ASSET_LOCATIONS, 'status');

export const SET_ASSET_LOCATIONS = 'SET_ASSET_LOCATIONS';
export const setAssetLocations = makeActionCreator(SET_ASSET_LOCATIONS, 'data');

export const ADD_ASSET_LOCATION = 'ADD_ASSET_LOCATION';
export const addAssetLocation = makeActionCreator(ADD_ASSET_LOCATION, 'data');

export const CLEAR_ASSET_LOCATIONS = 'CLEAR_ASSET_LOCATIONS';
export const clearAssetLocations = makeActionCreator(CLEAR_ASSET_LOCATIONS);

export const SET_ASSET_LOCATION_SEARCH_TEXT = 'SET_ASSET_LOCATION_SEARCH_TEXT';
export const setAssetLocationSearchText = makeActionCreator(SET_ASSET_LOCATION_SEARCH_TEXT, 'text');


export const DOWNLOADING_ASSET_CERTIFICATES = 'DOWNLOADING_ASSET_CERTIFICATES';
export const downloadingAssetCertificates = makeActionCreator(DOWNLOADING_ASSET_CERTIFICATES, 'status');

export const SET_ASSET_CERTIFICATES = 'SET_ASSET_CERTIFICATES';
export const setAssetCertificates = makeActionCreator(SET_ASSET_CERTIFICATES, 'data');

export const ADD_ASSET_CERTIFICATE = 'ADD_ASSET_CERTIFICATE';
export const addAssetCertificate = makeActionCreator(ADD_ASSET_CERTIFICATE, 'data');

export const CLEAR_ASSET_CERTIFICATES = 'CLEAR_ASSET_CERTIFICATES';
export const clearAssetCertificates = makeActionCreator(CLEAR_ASSET_CERTIFICATES);

export const ADDING_EDITING_ASSET_CERTIFICATE = 'ADDING_EDITING_ASSET_CERTIFICATE';
export const addingEditingAssetCertificate = makeActionCreator(ADDING_EDITING_ASSET_CERTIFICATE, 'status');

export const DOWNLOADING_DELETING_ASSET_CERTIFICATE = 'DOWNLOADING_DELETING_ASSET_CERTIFICATE';
export const downloadingDeletingAssetCertificate = makeActionCreator(DOWNLOADING_DELETING_ASSET_CERTIFICATE, 'status');


export const SET_ASSET_CERTIFICATE_SELECTED_PAGE_SIZE = 'SET_ASSET_CERTIFICATE_SELECTED_PAGE_SIZE';
export const setAssetCertificateSelectedPageSize = makeActionCreator(SET_ASSET_CERTIFICATE_SELECTED_PAGE_SIZE, 'size');

export const SET_ASSET_CERTIFICATE_SELECTED_ORDER_BY = 'SET_ASSET_CERTIFICATE_SELECTED_ORDER_BY';
export const setAssetCertificateSelectedOrderBy = makeActionCreator(SET_ASSET_CERTIFICATE_SELECTED_ORDER_BY, 'order');

export const SET_ASSET_CERTIFICATE_SEARCH_TEXT = 'SET_ASSET_CERTIFICATE_SEARCH_TEXT';
export const setAssetCertificateSearchText = makeActionCreator(SET_ASSET_CERTIFICATE_SEARCH_TEXT, 'text');

export const SET_ASSET_CERTIFICATE_TAPPED_ID = 'SET_ASSET_CERTIFICATE_TAPPED_ID';
export const setAssetCertificateTappedId = makeActionCreator(SET_ASSET_CERTIFICATE_TAPPED_ID, 'id');

export const SET_ASSET_CERTIFICATE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ASSET_CERTIFICATE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAssetCertificateAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ASSET_CERTIFICATE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_ASSET_DOCUMENTS = 'DOWNLOADING_ASSET_DOCUMENTS';
export const downloadingAssetDocuments = makeActionCreator(DOWNLOADING_ASSET_DOCUMENTS, 'status');

export const SET_ASSET_DOCUMENTS = 'SET_ASSET_DOCUMENTS';
export const setAssetDocuments = makeActionCreator(SET_ASSET_DOCUMENTS, 'data');

export const ADD_ASSET_DOCUMENT = 'ADD_ASSET_DOCUMENT';
export const addAssetDocument = makeActionCreator(ADD_ASSET_DOCUMENT, 'data');

export const CLEAR_ASSET_DOCUMENTS = 'CLEAR_ASSET_DOCUMENTS';
export const clearAssetDocuments = makeActionCreator(CLEAR_ASSET_DOCUMENTS);

export const ADDING_EDITING_ASSET_DOCUMENT = 'ADDING_EDITING_ASSET_DOCUMENT';
export const addingEditingAssetDocument = makeActionCreator(ADDING_EDITING_ASSET_DOCUMENT, 'status');

export const DOWNLOADING_DELETING_ASSET_DOCUMENT = 'DOWNLOADING_DELETING_ASSET_DOCUMENT';
export const downloadingDeletingAssetDocument = makeActionCreator(DOWNLOADING_DELETING_ASSET_DOCUMENT, 'status');


export const SET_ASSET_DOCUMENT_SELECTED_PAGE_SIZE = 'SET_ASSET_DOCUMENT_SELECTED_PAGE_SIZE';
export const setAssetDocumentSelectedPageSize = makeActionCreator(SET_ASSET_DOCUMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_ASSET_DOCUMENT_SELECTED_ORDER_BY = 'SET_ASSET_DOCUMENT_SELECTED_ORDER_BY';
export const setAssetDocumentSelectedOrderBy = makeActionCreator(SET_ASSET_DOCUMENT_SELECTED_ORDER_BY, 'order');

export const SET_ASSET_DOCUMENT_TAPPED_ID = 'SET_ASSET_DOCUMENT_TAPPED_ID';
export const setAssetDocumentTappedId = makeActionCreator(SET_ASSET_DOCUMENT_TAPPED_ID, 'id');

export const SET_ASSET_DOCUMENT_SEARCH_TEXT = 'SET_ASSET_DOCUMENT_SEARCH_TEXT';
export const setAssetDocumentSearchText = makeActionCreator(SET_ASSET_DOCUMENT_SEARCH_TEXT, 'text');

export const SET_ASSET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ASSET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAssetDocumentAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ASSET_DOCUMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_ASSET_DOCUMENT_SELECTED_FILE = 'SET_ASSET_DOCUMENT_SELECTED_FILE';
export const setAssetDocumentSelectedFile = makeActionCreator(SET_ASSET_DOCUMENT_SELECTED_FILE, 'option');


export const DOWNLOADING_CERTIFICATION_TYPES = 'DOWNLOADING_CERTIFICATION_TYPES';
export const downloadingCertificationTypes = makeActionCreator(DOWNLOADING_CERTIFICATION_TYPES, 'status');

export const SET_CERTIFICATION_TYPES = 'SET_CERTIFICATION_TYPES';
export const setCertificationTypes = makeActionCreator(SET_CERTIFICATION_TYPES, 'data');

export const ADD_CERTIFICATION_TYPE = 'ADD_CERTIFICATION_TYPE';
export const addCertificationType = makeActionCreator(ADD_CERTIFICATION_TYPE, 'data');

export const CLEAR_CERTIFICATION_TYPES = 'CLEAR_CERTIFICATION_TYPES';
export const clearCertificationTypes = makeActionCreator(CLEAR_CERTIFICATION_TYPES);

export const ADDING_EDITING_CERTIFICATION_TYPE = 'ADDING_EDITING_CERTIFICATION_TYPE';
export const addingEditingCertificationType = makeActionCreator(ADDING_EDITING_CERTIFICATION_TYPE, 'status');

export const DOWNLOADING_DELETING_CERTIFICATION_TYPE = 'DOWNLOADING_DELETING_CERTIFICATION_TYPE';
export const downloadingDeletingCertificationType = makeActionCreator(DOWNLOADING_DELETING_CERTIFICATION_TYPE, 'status');


export const SET_CERTIFICATION_TYPE_SELECTED_PAGE_SIZE = 'SET_CERTIFICATION_TYPE_SELECTED_PAGE_SIZE';
export const setCertificationTypeSelectedPageSize = makeActionCreator(SET_CERTIFICATION_TYPE_SELECTED_PAGE_SIZE, 'size');

export const SET_CERTIFICATION_TYPE_SELECTED_ORDER_BY = 'SET_CERTIFICATION_TYPE_SELECTED_ORDER_BY';
export const setCertificationTypeSelectedOrderBy = makeActionCreator(SET_CERTIFICATION_TYPE_SELECTED_ORDER_BY, 'order');

export const SET_CERTIFICATION_TYPE_TAPPED_ID = 'SET_CERTIFICATION_TYPE_TAPPED_ID';
export const setCertificationTypeTappedId = makeActionCreator(SET_CERTIFICATION_TYPE_TAPPED_ID, 'id');

export const SET_CERTIFICATION_TYPE_SEARCH_TEXT = 'SET_CERTIFICATION_TYPE_SEARCH_TEXT';
export const setCertificationTypeSearchText = makeActionCreator(SET_CERTIFICATION_TYPE_SEARCH_TEXT, 'text');

export const SET_CERTIFICATION_TYPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CERTIFICATION_TYPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCertificationTypeAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CERTIFICATION_TYPE_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_ASSET_CHILDREN = 'DOWNLOADING_ASSET_CHILDREN';
export const downloadingAssetChildren = makeActionCreator(DOWNLOADING_ASSET_CHILDREN, 'status');

export const SET_ASSET_CHILDREN = 'SET_ASSET_CHILDREN';
export const setAssetChildren = makeActionCreator(SET_ASSET_CHILDREN, 'data');

export const ADD_ASSET_CHILD = 'ADD_ASSET_CHILD';
export const addAssetChild = makeActionCreator(ADD_ASSET_CHILD, 'data');

export const CLEAR_ASSET_CHILDREN = 'CLEAR_ASSET_CHILDREN';
export const clearAssetChildren = makeActionCreator(CLEAR_ASSET_CHILDREN);

export const ADDING_ASSET_CHILD = 'ADDING_ASSET_CHILD';
export const addingAssetChild = makeActionCreator(ADDING_ASSET_CHILD, 'status');

export const DELETING_ASSET_CHILD = 'DELETING_ASSET_CHILD';
export const deletingAssetChild = makeActionCreator(DELETING_ASSET_CHILD, 'status');


export const SET_ASSET_CHILD_SELECTED_PAGE_SIZE = 'SET_ASSET_CHILD_SELECTED_PAGE_SIZE';
export const setAssetChildSelectedPageSize = makeActionCreator(SET_ASSET_CHILD_SELECTED_PAGE_SIZE, 'size');

export const SET_ASSET_CHILD_SELECTED_ORDER_BY = 'SET_ASSET_CHILD_SELECTED_ORDER_BY';
export const setAssetChildSelectedOrderBy = makeActionCreator(SET_ASSET_CHILD_SELECTED_ORDER_BY, 'order');

export const SET_ASSET_CHILD_SEARCH_TEXT = 'SET_ASSET_CHILD_SEARCH_TEXT';
export const setAssetChildSearchText = makeActionCreator(SET_ASSET_CHILD_SEARCH_TEXT, 'text');

export const SET_ASSET_CHILD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ASSET_CHILD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAssetChildAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ASSET_CHILD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_ASSET_CHILD_DIALOG_VISIBILITY = 'SET_ASSET_CHILD_DIALOG_VISIBILITY';
export const setAssetChildDialogVisibility = makeActionCreator(SET_ASSET_CHILD_DIALOG_VISIBILITY, 'status');


export const DOWNLOADING_PARENTLESS_ASSETS = 'DOWNLOADING_PARENTLESS_ASSETS';
export const downloadingParentlessAssets = makeActionCreator(DOWNLOADING_PARENTLESS_ASSETS, 'status');

export const SET_PARENTLESS_ASSETS = 'SET_PARENTLESS_ASSETS';
export const setParentlessAssets = makeActionCreator(SET_PARENTLESS_ASSETS, 'data');

export const ADD_PARENTLESS_ASSET = 'ADD_PARENTLESS_ASSET';
export const addParentlessAsset = makeActionCreator(ADD_PARENTLESS_ASSET, 'data');

export const CLEAR_PARENTLESS_ASSETS = 'CLEAR_PARENTLESS_ASSETS';
export const clearParentlessAssets = makeActionCreator(CLEAR_PARENTLESS_ASSETS);


export const SET_PARENTLESS_ASSET_SELECTED_PAGE_SIZE = 'SET_PARENTLESS_ASSET_SELECTED_PAGE_SIZE';
export const setParentlessAssetSelectedPageSize = makeActionCreator(SET_PARENTLESS_ASSET_SELECTED_PAGE_SIZE, 'size');

export const SET_PARENTLESS_ASSET_SELECTED_ORDER_BY = 'SET_PARENTLESS_ASSET_SELECTED_ORDER_BY';
export const setParentlessAssetSelectedOrderBy = makeActionCreator(SET_PARENTLESS_ASSET_SELECTED_ORDER_BY, 'order');

export const SET_PARENTLESS_ASSET_SEARCH_TEXT = 'SET_PARENTLESS_ASSET_SEARCH_TEXT';
export const setParentlessAssetSearchText = makeActionCreator(SET_PARENTLESS_ASSET_SEARCH_TEXT, 'text');

export const SET_PARENTLESS_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PARENTLESS_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setParentlessAssetAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PARENTLESS_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_CERTIFICATION_REQUESTS = 'DOWNLOADING_CERTIFICATION_REQUESTS';
export const downloadingCertificationRequests = makeActionCreator(DOWNLOADING_CERTIFICATION_REQUESTS, 'status');

export const SET_CERTIFICATION_REQUESTS = 'SET_CERTIFICATION_REQUESTS';
export const setCertificationRequests = makeActionCreator(SET_CERTIFICATION_REQUESTS, 'data');

export const ADD_CERTIFICATION_REQUEST = 'ADD_CERTIFICATION_REQUEST';
export const addCertificationRequest = makeActionCreator(ADD_CERTIFICATION_REQUEST, 'data');

export const CLEAR_CERTIFICATION_REQUESTS = 'CLEAR_CERTIFICATION_REQUESTS';
export const clearCertificationRequests = makeActionCreator(CLEAR_CERTIFICATION_REQUESTS);

export const ADDING_EDITING_CERTIFICATION_REQUEST = 'ADDING_EDITING_CERTIFICATION_REQUEST';
export const addingEditingCertificationRequest = makeActionCreator(ADDING_EDITING_CERTIFICATION_REQUEST, 'status');

export const DOWNLOADING_DELETING_CERTIFICATION_REQUEST = 'DOWNLOADING_DELETING_CERTIFICATION_REQUEST';
export const downloadingDeletingCertificationRequest = makeActionCreator(DOWNLOADING_DELETING_CERTIFICATION_REQUEST, 'status');

export const APPROVING_CERTIFICATION_REQUEST = 'APPROVING_CERTIFICATION_REQUEST';
export const approvingCertificationRequest = makeActionCreator(APPROVING_CERTIFICATION_REQUEST, 'status');

export const REJECTING_CERTIFICATION_REQUEST = 'REJECTING_CERTIFICATION_REQUEST';
export const rejectingCertificationRequest = makeActionCreator(REJECTING_CERTIFICATION_REQUEST, 'status');

export const CHECKING_CERTIFICATION_REQUEST_APPROVAL_PERMISSION = 'CHECKING_CERTIFICATION_REQUEST_APPROVAL_PERMISSION';
export const checkingCertificationRequestApprovalPermission = makeActionCreator(CHECKING_CERTIFICATION_REQUEST_APPROVAL_PERMISSION, 'status');


export const SET_CERTIFICATION_REQUEST_SELECTED_PAGE_SIZE = 'SET_CERTIFICATION_REQUEST_SELECTED_PAGE_SIZE';
export const setCertificationRequestSelectedPageSize = makeActionCreator(SET_CERTIFICATION_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_CERTIFICATION_REQUEST_SELECTED_ORDER_BY = 'SET_CERTIFICATION_REQUEST_SELECTED_ORDER_BY';
export const setCertificationRequestSelectedOrderBy = makeActionCreator(SET_CERTIFICATION_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_CERTIFICATION_REQUEST_TAPPED_ID = 'SET_CERTIFICATION_REQUEST_TAPPED_ID';
export const setCertificationRequestTappedId = makeActionCreator(SET_CERTIFICATION_REQUEST_TAPPED_ID, 'id');

export const SET_CERTIFICATION_REQUEST_SEARCH_TEXT = 'SET_CERTIFICATION_REQUEST_SEARCH_TEXT';
export const setCertificationRequestSearchText = makeActionCreator(SET_CERTIFICATION_REQUEST_SEARCH_TEXT, 'text');

export const SET_CERTIFICATION_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_CERTIFICATION_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setCertificationRequestAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_CERTIFICATION_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_CERTIFICATION_REQUEST_DIALOG_VISIBILITY = 'SET_CERTIFICATION_REQUEST_DIALOG_VISIBILITY';
export const setCertificationRequestDialogVisibility = makeActionCreator(SET_CERTIFICATION_REQUEST_DIALOG_VISIBILITY, 'status', 'mode');

export const SET_CERTIFICATION_REQUEST_ACTIVE_TAB = 'SET_CERTIFICATION_REQUEST_ACTIVE_TAB';
export const setCertificationRequestActiveTab = makeActionCreator(SET_CERTIFICATION_REQUEST_ACTIVE_TAB, 'text');

export const SET_CERTIFICATION_REQUEST_SELECTED_CATEGORY = 'SET_CERTIFICATION_REQUEST_SELECTED_CATEGORY';
export const setCertificationRequestSelectedCategory = makeActionCreator(SET_CERTIFICATION_REQUEST_SELECTED_CATEGORY, 'option');

export const SET_CERTIFICATION_REQUEST_SELECTED_TYPE = 'SET_CERTIFICATION_REQUEST_SELECTED_TYPE';
export const setCertificationRequestSelectedType = makeActionCreator(SET_CERTIFICATION_REQUEST_SELECTED_TYPE, 'option');

export const SET_CERTIFICATION_REQUEST_SELECTED_OWNER = 'SET_CERTIFICATION_REQUEST_SELECTED_OWNER';
export const setCertificationRequestSelectedOwner = makeActionCreator(SET_CERTIFICATION_REQUEST_SELECTED_OWNER, 'option');

export const SET_HAS_CERTIFICATION_REQUEST_APPROVAL_PERMISSION = 'SET_HAS_CERTIFICATION_REQUEST_APPROVAL_PERMISSION';
export const setHasCertificationRequestApprovalPermission = makeActionCreator(SET_HAS_CERTIFICATION_REQUEST_APPROVAL_PERMISSION, 'status');


export const DOWNLOADING_MY_ASSET_LOCATIONS = 'DOWNLOADING_MY_ASSET_LOCATIONS';
export const downloadingMyAssetLocations = makeActionCreator(DOWNLOADING_MY_ASSET_LOCATIONS, 'status');

export const SET_MY_ASSET_LOCATIONS = 'SET_MY_ASSET_LOCATIONS';
export const setMyAssetLocations = makeActionCreator(SET_MY_ASSET_LOCATIONS, 'data');

export const CLEAR_MY_ASSET_LOCATIONS = 'CLEAR_MY_ASSET_LOCATIONS';
export const clearMyAssetLocations = makeActionCreator(CLEAR_MY_ASSET_LOCATIONS);

export const SET_MY_ASSET_LOCATION_SEARCH_TEXT = 'SET_MY_ASSET_LOCATION_SEARCH_TEXT';
export const setMyAssetLocationSearchText = makeActionCreator(SET_MY_ASSET_LOCATION_SEARCH_TEXT, 'text');


export const DOWNLOADING_CERTIFICATION_REQUEST_APPROVALS = 'DOWNLOADING_CERTIFICATION_REQUEST_APPROVALS';
export const downloadingCertificationRequestApprovals = makeActionCreator(DOWNLOADING_CERTIFICATION_REQUEST_APPROVALS, 'status');

export const SET_CERTIFICATION_REQUEST_APPROVALS = 'SET_CERTIFICATION_REQUEST_APPROVALS';
export const setCertificationRequestApprovals = makeActionCreator(SET_CERTIFICATION_REQUEST_APPROVALS, 'data');

export const CLEAR_CERTIFICATION_REQUEST_APPROVALS = 'CLEAR_CERTIFICATION_REQUEST_APPROVALS';
export const clearCertificationRequestApprovals = makeActionCreator(
  CLEAR_CERTIFICATION_REQUEST_APPROVALS,
);


export const SCHEDULING_ASSET = 'SCHEDULING_ASSET';
export const addingAssetScreening = makeActionCreator(SCHEDULING_ASSET, 'status');

export const SET_CERTIFICATION_SCHEDULE_SETTING_DIALOG_VISIBILITY = 'SET_CERTIFICATION_SCHEDULE_SETTING_DIALOG_VISIBILITY';
export const setCertificationScheduleSettingDialogVisibility = makeActionCreator(SET_CERTIFICATION_SCHEDULE_SETTING_DIALOG_VISIBILITY, 'status', 'mode');

export const SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_RESULT_TYPE = 'SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_RESULT_TYPE';
export const setCertificationScheduleSettingSelectedResultType = makeActionCreator(SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_RESULT_TYPE, 'option');

export const SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_FILE = 'SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_FILE';
export const setCertificationScheduleSettingSelectedFile = makeActionCreator(SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_FILE, 'option');


export const ADDING_ASSET_SCREENING = 'ADDING_ASSET_SCREENING';
export const schedulingAsset = makeActionCreator(ADDING_ASSET_SCREENING, 'status');

export const SET_ASSET_SCREENING_DIALOG_VISIBILITY = 'SET_ASSET_SCREENING_DIALOG_VISIBILITY';
export const setAssetScreeningDialogVisibility = makeActionCreator(SET_ASSET_SCREENING_DIALOG_VISIBILITY, 'status', 'mode');

export const SET_ASSET_SCREENING_SELECTED_TYPE = 'SET_ASSET_SCREENING_SELECTED_TYPE';
export const setAssetScreeningSelectedType = makeActionCreator(SET_ASSET_SCREENING_SELECTED_TYPE, 'option');
