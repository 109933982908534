import { connect } from 'react-redux';
import { change, getFormValues, reset } from 'redux-form';
import {
  setAlertConfirmationMessage, setAlertErrorMessage, setActiveSideMenuItem,
  clearCompaniesMData, setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData,
  setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText, clearCities,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText, clearProvinces,
  setLocationAdvancedFilterDialogSelectedFilterString, setLocationSearchText,
  setLocationSelectedPageSize, setLocationSelectedOrderBy, setLocationMapCenter,
  setLocationMapMarker, setLocationMapPolygon, setLocationSelectedCityId, setLocationTappedId,
  clearLocations,
  downloadLocationsAsync, enableDisableLocationAsync, saveLocationsAsync,
  addEditLocationAsync,
  downloadDeleteLocationAsync,
} from '../../redux/action';
import { downloadCitiesAsync, downloadMDataCompaniesAsync, downloadProvincesAsync } from '../../../../redux/action';
import {
  DEFAULT_GOOGLE_MAP_URL, DEFAULT_MAP_DRAWER_CENTER_MARKER, DRAW_MODE_MARKER, GOOGLE_MAP_URL_PREFIX,
  PAGE_MODE_TABLE,
  INITIAL_ORDER_BY_LOCATIONS,
  RXFIELD_LOCATION_AREA,
  RXFIELD_LOCATION_CITY,
  RXFIELD_LOCATION_GOOGLE_MAP_URL,
  RXFIELD_LOCATION_LATITUDE,
  RXFIELD_LOCATION_LONGITUDE,
  RXFORM_LOCATION,
  MENUID_EVENT_LOCATION,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import {
  transformDropdownData, transformInitialValues, toDecimal, toNumber, addLocation, debounceSearch,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocationPage from './location.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { locations, uiLocation, uiFunctionalPage } = state;
  const { downloadingDeleting, polygon, tappedId } = uiLocation;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? locations.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        city: { label: found.city?.name || '', value: found.city?.id || '' },
        area: polygon.length > 0 ? polygon.map((x, i) => ({ ...x, no: i + 1 })) : [],
      })
    : {
      name: '',
      city: { label: '', value: '' },
      description: '',
      phone: '',
      address: '',
      postalCode: '',
      googleMapUrl: '',
      longitude: '',
      latitude: '',
      email: '',
      area: polygon.length > 0 ? polygon.map((x, i) => ({ ...x, no: i + 1 })) : [],
    };
  return initVal;
};

const serachCityDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProvinceDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  addingEditing: state.uiLocation.addingEditing,
  addingPolygon: state.mapDrawer.addingPolygon,
  center: state.uiLocation.center,
  currentLocationStatus: state.locations.data[state.uiFunctionalPage.tappedId]
    ? state.locations.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiLocation.downloading,
  enablingDisabling: state.uiLocation.enablingDisabling,
  loadingCity: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
  locations: state.locations.data,
  marker: state.uiLocation.marker,
  pageMode: state.uiFunctionalPage.pageMode,
  polygon: state.uiLocation.polygon,
  tappedData: state.locations.data[state.uiLocation.tappedId],
  cities: transformDropdownData(state.cities.data),
  currentFormValues: getFormValues(RXFORM_LOCATION)(state) || {},
  initialValues: getInitialValues(state),
  provinces: transformDropdownData(state.provinces.data),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_LOCATION));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(setLocationSelectedPageSize(20));
    dispatch(setLocationSelectedOrderBy(INITIAL_ORDER_BY_LOCATIONS));
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/city/, 'city.id')
      .replace(/province/, 'city.province.id')
      .replace(/timeZone/, 'city.province.timeZone')
      .replace(/company/, 'company.id');
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_LOCATION));
  },
  onChangeCityText: async (text, mode) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(
      mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
    ));
    dispatch(setCitySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      serachCityDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadLocationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setLocationSelectedPageSize(pageSize));
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeLatitudeText: (e, currLng) => {
    const latitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setLocationMapCenter({ latitude }));
    dispatch(setLocationMapMarker({ latitude }));
    dispatch(setLocationMapPolygon([]));
    dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LATITUDE, latitude));
    if (latitude && currLng) {
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, e.target.value).replace(/\{longitude\}/, currLng)));
    } else {
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, ''));
    }
  },
  onChangeLongitudeText: (e, currLat) => {
    const longitude = e.target.value ? toDecimal(e.target.value) : '';
    dispatch(setLocationMapCenter({ longitude }));
    dispatch(setLocationMapMarker({ longitude }));
    dispatch(setLocationMapPolygon([]));
    dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LONGITUDE, longitude));
    if (currLat && longitude) {
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, GOOGLE_MAP_URL_PREFIX
        .replace(/\{latitude\}/, currLat).replace(/\{longitude\}/, e.target.value)));
    } else {
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, ''));
    }
  },
  onChangeProvinceText: async (text) => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProvinceSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProvinceDebounce(dispatch);
    }
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setLocationSelectedCityId(option.value));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_CITY, option));
    } else {
      dispatch(setLocationSelectedCityId(''));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_CITY, ''));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteLocationAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableLocationAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setLocationTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setLocationTappedId(''));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setLocationMapCenter(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(setLocationMapMarker(DEFAULT_MAP_DRAWER_CENTER_MARKER));
    dispatch(setLocationMapPolygon([]));
    dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LATITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.latitude));
    dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LONGITUDE,
      DEFAULT_MAP_DRAWER_CENTER_MARKER.longitude));
    dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, DEFAULT_GOOGLE_MAP_URL));
  },
  onDeletePressed: (id) => {
    dispatch(setLocationTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveLocationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onDrawingCompleted: (data, drawingMode, tappedData) => {
    if (drawingMode === DRAW_MODE_MARKER) {
      dispatch(setLocationMapMarker(data));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LATITUDE, data.latitude));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_LONGITUDE, data.longitude));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_GOOGLE_MAP_URL, data.url));
      if (tappedData) {
        dispatch(addLocation({ ...tappedData, area: [] }));
      }
    } else {
      const transformedDate = data.map((x, idx) => ({ ...x, order: idx + 1 }));
      dispatch(setLocationMapPolygon(transformedDate));
      dispatch(change(RXFORM_LOCATION, RXFIELD_LOCATION_AREA, transformedDate));
    }
  },
  onEditPressed: (id, data) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCities());
    dispatch(clearCompaniesMData());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setLocationTappedId(id));
    const { latitude, longitude, area } = data[id];
    if (latitude && longitude) {
      dispatch(setLocationMapCenter({ latitude, longitude }));
      dispatch(setLocationMapMarker({ latitude, longitude }));
      dispatch(setLocationMapPolygon(area));
    } else {
      dispatch(setLocationMapCenter(null));
      dispatch(setLocationMapMarker(null));
      dispatch(setLocationMapPolygon(null));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setLocationSelectedPageSize(pageSize));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearLocations());
    dispatch(setLocationSelectedOrderBy(INITIAL_ORDER_BY_LOCATIONS));
    dispatch(downloadLocationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, email,
  }) => {
    try {
      await dispatch(
        addEditLocationAsync(name, description, toNumber(latitude), toNumber(longitude),
          googleMapUrl, address, postalCode, phone, email),
      );
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setLocationSearchText(text));
      dispatch(clearLocations());
      await dispatch(downloadLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setLocationSelectedOrderBy(orderBy));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, latitude, longitude, googleMapUrl, address, postalCode, phone, email,
  }) => {
    await dispatch(addEditLocationAsync(name, description, toNumber(latitude), toNumber(longitude),
      googleMapUrl, address, postalCode, phone, email));
  },
  onViewPressed: (id, data) => {
    dispatch(setLocationTappedId(id));
    const { latitude, longitude, area } = data[id];
    if (latitude && longitude) {
      dispatch(setLocationMapCenter({ latitude, longitude }));
      dispatch(setLocationMapMarker({ latitude, longitude }));
      dispatch(setLocationMapPolygon(area));
    } else {
      dispatch(setLocationMapCenter(null));
      dispatch(setLocationMapMarker(null));
      dispatch(setLocationMapPolygon(null));
    }
    dispatch(downloadDeleteLocationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationPage);
