import { connect } from 'react-redux';
import { INITIAL_ORDER_BY_ASSET_CERTIFICATES } from '../../../constant';
import { getData } from '../../../helper';
import {
  clearAssetCertificates, downloadAssetCertificatesAsync, setAlertErrorMessage,
  setAssetCertificateAdvancedFilterDialogSelectedFilterString,
  setAssetCertificateSearchText, setAssetCertificateSelectedOrderBy,
  setAssetCertificateSelectedPageSize,
} from '../../../redux/action';
import CertificatesTab from './certficates-tab.presentation';

const mapStateToProps = (state) => ({
  assetCertificates: getData(state.assetCertificates, state.uiAssetCertificate),
  downloading: state.uiAssetCertificate.downloading,
  currentPage: state.assetCertificates?.meta?.currentPage || 0,
  totalCount: state.assetCertificates?.meta?.totalCount || 0,
  selectedPageSize: state.uiAssetCertificate?.selectedPageSize || 1,
  orderBy: state.uiAssetCertificate?.orderBy || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (tappedAssetId) => {
    dispatch(setAssetCertificateAdvancedFilterDialogSelectedFilterString(`asset.id=${tappedAssetId}`));
    dispatch(setAssetCertificateSearchText(''));
    dispatch(clearAssetCertificates());
    dispatch(setAssetCertificateSelectedPageSize(20));
    dispatch(setAssetCertificateSelectedOrderBy(INITIAL_ORDER_BY_ASSET_CERTIFICATES));
    dispatch(downloadAssetCertificatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetCertificatesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetCertificateSelectedPageSize(pageSize));
    dispatch(downloadAssetCertificatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPdfPressed: async (assetNumber, fileUrl, issuedDate) => {
    try {
      const extension = fileUrl?.match(/(\.[^.]*)$/g)[0];
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `asset_certificate_${assetNumber}_${issuedDate}${extension}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetCertificateSelectedOrderBy(orderBy));
    dispatch(clearAssetCertificates());
    dispatch(downloadAssetCertificatesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificatesTab);
