import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { BasePage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_SECONDARY,
  COLOR_TEXT_BLACK_SECONDARY,
} from '../../constant';
import LocalizedString from '../../localization';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: COLOR_PRIMARY,
  },
  subTitle: {
    color: COLOR_TEXT_BLACK_SECONDARY,
    maxWidth: '80%',
  },
  mb: {
    marginBottom: 6,
  },
  w800: {
    fontWeight: '800',
  },
  w700: {
    fontWeight: '700',
  },
  w400: {
    fontWeight: '400',
  },
  header: {
    borderBottom: `1.5px solid ${COLOR_SECONDARY}`,
  },
  body: {
    marginTop: 20,
  },
  card: {
    backgroundColor: COLOR_BACKGROUND,
    borderRadius: 10,
    padding: '32px 24px',
    marginBottom: 20,
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
}));

const DashboardPage = ({ currentUser, onAppear }) => {
  const classes = useStyles();
  useEffect(onAppear, []);
  return (
    <BasePage>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h4" className={[classes.title, classes.mb, classes.w800]}>
            {LocalizedString.dashboardScreen.labelHi}
            {currentUser?.fullName ? `, ${currentUser?.fullName}!` : ''}
            {' 👋'}
          </Typography>
          <Typography variant="h4" className={[classes.title, classes.mb, classes.w800]}>
            {LocalizedString.dashboardScreen.labelWelcomeTitle}
          </Typography>
          <Typography variant="h5" className={classes.subTitle} style={{ fontWeight: '400', marginBottom: 20 }}>
            {LocalizedString.dashboardScreen.labelWelcomeSubtitle}
          </Typography>
        </div>
        <div className={classes.body}>
          <Typography variant="h5" className={[classes.title, classes.mb, classes.w700]}>
            {LocalizedString.dashboardScreen.labelGetStarted}
          </Typography>
          <div className={classes.card}>
            <Typography variant="h6" className={[classes.title, classes.mb, classes.w700]}>
              {LocalizedString.dashboardScreen.labelAssetScreening}
            </Typography>
            <Typography variant="subtitle1" className={[classes.subTitle, classes.w400]}>
              {LocalizedString.dashboardScreen.labelAssetScreeningDescription}
            </Typography>
          </div>
          <div className={classes.card}>
            <Typography variant="h6" className={[classes.title, classes.mb, classes.w700]}>
              {LocalizedString.dashboardScreen.labelCertificationApplication}
            </Typography>
            <Typography variant="subtitle1" className={[classes.subTitle, classes.w400]}>
              {LocalizedString.dashboardScreen.labelCertificationApplicationDescription}
            </Typography>
          </div>
          <div className={classes.card}>
            <Typography variant="h6" className={[classes.title, classes.mb, classes.w700]}>
              {LocalizedString.dashboardScreen.labelCertificationScheduling}
            </Typography>
            <Typography variant="subtitle1" className={[classes.subTitle, classes.w400]}>
              {LocalizedString.dashboardScreen.labelCertificationSchedulingDescription}
            </Typography>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default DashboardPage;

DashboardPage.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onAppear: PropTypes.func.isRequired,
};
