import {
  SET_ANNOTATION_DESIGNS, ADD_ANNOTATION_DESIGN, CLEAR_ANNOTATION_DESIGNS,
  ADD_ANNOTATION,
} from '../action';

const initialState = {
  data: {
    id: '',
    name: '',
    description: '',
    pdfPath: '',
    annotations: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANNOTATION_DESIGNS: {
      return {
        data: {
          id: action.data.id,
          name: action.data.name,
          description: action.data.description,
          pdfPath: action.data.pdfPath,
          annotations: action.data.annotations,
        },
      };
    }
    case ADD_ANNOTATION_DESIGN: {
      return {
        ...state,
        data: { ...state.data, [action.data.id]: action.data },
      };
    }
    case ADD_ANNOTATION: {
      return {
        data: {
          ...state.data,
          annotations: [...state.data.annotations, action.data],
        },
      };
    }
    case CLEAR_ANNOTATION_DESIGNS:
      return initialState;
    default:
      return state;
  }
};
