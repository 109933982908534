import { InputBase, withStyles } from '@material-ui/core';
import {
  COLOR_BACKGROUND, COLOR_BODY_TEXT_LIGHTER, COLOR_DISABLED_CONTROL, COLOR_ICON, COLOR_SECONDARY,
} from '../constant';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    marginTop: -8,
    borderRadius: 6,
    position: 'relative',
    backgroundColor: COLOR_BACKGROUND,
    border: `1px solid ${COLOR_SECONDARY}`,
    fontSize: 12,
    padding: '6px 8px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_SECONDARY}`,
      borderColor: COLOR_SECONDARY,
    },
    color: COLOR_ICON,
  },
  multiline: {
    padding: 0,
  },
  inputMultiline: {
    padding: '2px 8px 1.5px',
  },
  disabled: {
    borderRadius: 6,
    backgroundColor: COLOR_DISABLED_CONTROL,
    color: COLOR_BODY_TEXT_LIGHTER,
  },
}))(InputBase);

export default BootstrapInput;
