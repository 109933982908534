import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  FILTER_TYPE_DATE_RANGE, RXFIELD_DATE_RANGE_TIME_ZONE,
  RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG, timezoneOffset,
} from '../../../constant';
import {
  addEditableTableFieldAdvancedFilterForm, clearEditableTableFieldAdvancedFilterForm,
  setAlertErrorMessage, setEditableTableFieldAdvancedFilterDialogSelectedFilterString,
} from '../../../redux/action';
// eslint-disable-next-line import/no-cycle
import AdvancedFilterDialog from './advanced-filter-dialog.presentation';

const mapStateToProps = (state) => ({
  filterString: state.uiEditableTableField.filterString || '',
  initialValues: state.uiEditableTableField.advancedFilterForm,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (columnsWithSection, filterColumns) => {
    if (columnsWithSection.length) {
      const foundDateRangePicker = columnsWithSection
        .filter((item) => item.type === FILTER_TYPE_DATE_RANGE)
        .map((x) => x.field);

      foundDateRangePicker.data.forEach((item) => {
        dispatch(change(RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG, `${item}${RXFIELD_DATE_RANGE_TIME_ZONE}`, timezoneOffset[0]));
      });
    } else {
      const foundDateRangePicker = filterColumns
        .filter((item) => item.type === FILTER_TYPE_DATE_RANGE)
        .map((x) => x.field);

      foundDateRangePicker.forEach((item) => {
        dispatch(change(RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG, `${item}${RXFIELD_DATE_RANGE_TIME_ZONE}`, timezoneOffset[0]));
      });
    }
  },
  onApplyFilterPressed: (filterString) => {
    dispatch(setEditableTableFieldAdvancedFilterDialogSelectedFilterString(filterString));
  },
  onChangeDate: (e, field) => {
    dispatch(addEditableTableFieldAdvancedFilterForm(field, e));
  },
  onChangeDropdown: (e, field) => {
    if (e) {
      dispatch(addEditableTableFieldAdvancedFilterForm(field, e));
    } else {
      dispatch(addEditableTableFieldAdvancedFilterForm(field, ''));
    }
  },
  onChangeText: (e, field) => {
    dispatch(addEditableTableFieldAdvancedFilterForm(field, e.target.value));
  },
  onChangeSwitch: (e, field) => {
    dispatch(addEditableTableFieldAdvancedFilterForm(field, e?.value || e));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
  onResetPressed: () => {
    dispatch(setEditableTableFieldAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEditableTableFieldAdvancedFilterForm());
    dispatch(reset(RXFORM_EDITABLE_TABLE_ADVANCED_FILTER_DIALOG));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilterDialog);
