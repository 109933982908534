import LocalizedStrings from 'react-localization';
import * as Constant from './constant';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'ERROR',
      alertTitleWarning: 'WARNING',
      alertTitleConfirmation: 'CONFIRMATION',
      alertTitleSuccess: 'SUCCESS',
      alertTitleProcessing: 'Processing',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'Cancel',
      buttonCaptionDetail: 'Detail',
      buttonCaptionYes: 'Yes',
      buttonCaptionNo: 'No',
      buttonCaptionSave: 'Save',
      buttonCaptionEdit: 'Edit',
      buttonCaptionSubmit: 'Submit',
      buttonCaptionNext: 'Next',
      buttonCaptionPrev: 'Prev',
      buttonCaptionBack: 'Back',
      buttonCaptionAdd: 'Add',
      buttonCaptionRemove: 'Remove',
      buttonCaptionApply: 'Apply',
      buttonCaptionView: 'View',
      buttonCaptionSend: 'Send',
      buttonCaptionSendNotification: 'Send Notification',
      buttonCaptionCount: 'Count',
      buttonCaptionAdvancedFilter: 'Filter',
      buttonCaptionReset: 'Reset',
      buttonCaptionTakePicture: 'Take Picture',
      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',
      buttonCaptionResync: 'Resync',
      buttonCaptionDuplicate: 'Duplicate',
      buttonCaptionDownloadFile: 'Download File',
      buttonCaptionAction: 'Action',
      buttonCaptionTakePhoto: 'Take Photo',
      buttonCaptionChooseFromLibrary: 'Choose from Library',
      buttonCaptionCopy: 'Copy',
      buttonCaptionLock: 'Lock',
      buttonCaptionUnlock: 'Unlock',
      buttonCaptionUpload: 'Upload',
      buttonCaptionDelete: 'Delete',
      buttonCaptionDownload: 'Download',

      errMsgEmptyRequiredFields: 'Required fields cannot be empty',
      errMsgInvalidEmailFormat: 'The email address is invalid',
      errMsgInvalidPhoneNumberFormat: 'The phone number format is invalid',
      errMsgMinPasswordLength: `Minimum password length is ${Constant.PASSWORD_MIN_LENGTH} characters`,
      errMsgPasswordDoesNotMatch: 'The password does not match',
      errMsgCannotOpenUrl: 'Cannot open the URL',
      errMsgRequired: 'Required',
      errMsgDuplicatedData: 'Duplicated data found. Please select another option',
      errMsgStartEndDate: 'End Date must be greater than or equal to Start Date',
      errMsgRegStartEndDate: 'Registration End Date must be greater than or equal to Registration Start Date',
      errMsgEndRegEndDate: 'Event End Date must be greater than or equal to Registration End Date',
      errMsgInvalidYoutubeUrl: 'Link can only be filled with Youtube URL format',
      errMsgStartEndWorkingHour: 'End Working Hour must be different from Start Working Hour',
      errMsgMinMaxNum: 'Maximal number must be greater than or equal to Minimal number',
      errMsgNoDataYet: 'No Data Yet',
      errMsgNoDataFound: 'No data found',
      errMsgStartEndTime: 'End Time must be greater than or equal to Start Time',
      errMsgMinMaxValue: 'Max Value must be greater than or equal to Min Value',
      errMsgMustaNumber: 'Field must be a number',
      errMsgEarliestClockInTime: 'Earliest Clock In must be lesser than or equal to Start Working Hour',
      errMsgAutoClockOut: 'Auto Clock Out must be greater than or equal to End Working Hour',
      errMsgFileUploadedTooLarge: 'The file uploaded was too large',
      errMsgResourceNotFound: 'The resource was not found',
      errMsgResourceNotAvailable: 'The resource was not available',
      errMsgTimeoutContactingService: 'Timeout while contacting service',
      errMsgFailedActions: '{number} of {total} records were failed',
      errMsgDifferentRetypePassword: 'Password Doesn\'t Match',
      errMsgMinLength: 'The minimum length is {minLength}',
      errMsgMaxLength: 'The maximum length is {maxLength}',
      errMsgInvalidFormat: 'Invalid format',
      errMsgMinValue: 'The minimum value is {minValue}',
      errMsgMaxValue: 'The maximum value is {maxValue}',

      labelWelcome: 'Welcome',
      labelAuthentication: 'Authentication',
      labelPersonalization: 'Personalization',
      labelMasterData: 'Master Data',
      labelReport: 'Report',
      labelHi: 'Hi, {name}',
      labelRefreshData: 'Refresh Data',
      labelEdit: 'Edit',
      labelDelete: 'Delete',
      labelBasicInformation: 'Basic Information',
      labelChoices: 'Choices',
      labelChosen: 'Chosen',
      labelSelected: 'Selected',
      labelYes: 'Yes',
      labelNo: 'No',
      labelTrue: 'True',
      labelFalse: 'False',
      labelLegend: 'Legend',
      labelCancel: 'Cancel',
      labelFilter: 'Filter',
      labelSearch: 'Search',
      labelPerson: 'Person',
      labelCity: 'City',
      labelDepartment: 'Department',
      labelDivision: 'Division',
      labelCompanyName: 'Company Name',
      labelSendNotification: 'Send Notification',
      labelSelectedOrAllUser: 'Selected or All User',
      labelBranch: 'Branch',
      labelNewsManagement: 'News',
      labelAdvancedFilter: 'Advanced Filter',
      labelMin: 'Min',
      labelMax: 'Max',
      labelCharacter: 'Character(s)',
      labelCameraSource: 'Camera Source',
      labelYouCanUsePlaceholdersFieldsAbove: '* You can use these placeholders in the fields above',
      labelAdditionalUrlPlaceholders: '{fullName}: Full name of the recipient<br/>{profileId}: The ID of the recipient',
      labelComments: 'Comments',
      labelViewCommentReply: 'View {replyCount} replies',
      labelHideCommentReply: 'Hide {replyCount} replies',
      labelMoreComments: 'More comments',
      labelMoreReplies: 'More replies',
      labelNoComments: 'No Comments',
      labelReason: 'Reason',
      labelMyProfile: 'My Profile',
      labelShellMembership: 'Shell Membership',
      labelUserTier: 'User Tier',
      labelRemoveAllItems: 'Remove all selected items',
      labelSettings: 'Settings',
      labelLogout: 'Logout',
      labelChangePassword: 'Change Password',
      labelLogoutDevices: 'Logout from other devices',
      labelNewPasswordSaved: 'New password successfully saved',
      labelSucces: 'Success',
      labelUserName: 'Username',
      labelUserCode: 'User Code',
      labelGender: 'Gender',
      labelPhone: 'Phone',
      labelRoles: 'Roles',
      labelTags: 'Tags',
      labelNoPhoto: 'No Photo',
      labelUpload: 'Upload',
      labelDownloadRequestTimeout: 'Your request takes too long. You can view the progress and download the result on "My Job" menu. Do you want to open "My Job" menu?',
      labelImageRatio1To1: 'The image aspect ratio should be 1:1 (square)',
      labelFileUpload: 'File Upload',
      labelBrowseFile: 'Browse Files',
      labelDropzonePicker: ' to upload your file, or drag and drop it in the box',
      labelAction: 'Action',
      labelCopyright: 'EPSON E-Certification © {year}',
      labelCopied: 'Copied!',

      placeholderId: 'ID',
      placeholderCreatedBy: 'Created By',
      placeholderCreatedDate: 'Created Date',
      placeholderLastModifiedBy: 'Last Modified By',
      placeholderLastModifiedDate: 'Last Modified Date',
      placeholderStatus: 'Status',
      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderUrl: 'URL',
      placeholderSendTo: 'Send to',
      placeholderReason: 'Reason',
      placeholderSearchBranch: 'Search Branch',
      placeholderSearchCompany: 'Search Company',
      placeholderSearchDivision: 'Search Division',
      placeholderSearchDepartment: 'Search Department',
      placeholderType: 'Type',
      placeholderOldPassword: 'Old Password',
      placeholderNewPassword: 'New Password',
      placeholderRetypeNewPassword: 'Retype New Password',
      placeholderTimeZone: 'Time Zone',
      placeholderImage: 'Image',

      msgLogoutConfirmation: 'Are you sure want to log out?',
      msgDeleteConfirmation: 'Are you sure want to delete this item?',
      msgSomethingWentWrong: 'Something went wrong',
      msgCancelConfirmation: 'Are you sure want to cancel this item?',
      msgDisableConfirmation: 'Are you sure want to disable this item?',
      msgEnableConfirmation: 'Are you sure want to enable this item?',
      msgResyncConfirmation: 'Are you sure want to resync this item?',
      msgLockConfirmation: 'Are you sure want to lock this item?',
      msgUnlockConfirmation: 'Are you sure want to unlock this item?',
      msgDownloadingData: 'Refreshing data...',
      msgEnablingData: 'Enabling {count} data',
      msgDisablingData: 'Disabling {count} data',
      msgDeletingData: 'Deleting {count} data',
      msgSuccessfullyEnablingData: 'Sucessfully enabled {count} data',
      msgSuccessfullyDisablingData: 'Sucessfully disabled {count} data',
      msgSuccessfullyDeletingData: 'Sucessfully deleted {count} data',
      msgFailedEnablingData: 'Failed to enable data',
      msgFailedDisablingData: 'Failed to disable data',
      msgFailedDeletingData: 'Failed to delete data',

      alertMessageUnsavedChanges: 'You have unsaved changes!',
      alertMessageSaveChanges: 'Please save/reset your changes first',
    },
    loginScreen: {
      title: 'Sign In',

      buttonCaptionLogin: 'Login',
      buttonCaptionLoginWithGoogle: 'Sign in with Google',
      buttonCaptionLoginWithMicrosoft: 'Sign in with Microsoft',
      buttonCaptionLoginWith: 'Login with ',
      buttonCaptionLoginWithEmail: 'Login with Email',
      buttonCaptionForgotPassword: 'Forgot Password?',

      labelWelcomeBack: 'Welcome Back!',
      labelAccessYourAccount: 'Access Your Account',
      labelEmail: 'Email',
      labelPassword: 'Password',
      labelCaptcha: 'Captcha',

      placeholderEmail: 'ex: yourname@company.com',
      placeholderCaptcha: 'Please re-type the code above',

      errEmptyUsernamePassword: 'Email and password cannot be empty',
      errMsgInvalidSignIn: 'Email can\'t be found',
      errMsgNoPermissionToLogin: 'You don\'t have permission to login',
    },
    dashboardScreen: {
      title: 'Welcome',

      labelHi: 'Hi',
      labelWelcomeTitle: 'Welcome to e-Certification EPSON',
      labelWelcomeSubtitle: 'Efficiently Monitor and Manage All Your Asset Certification Processes',
      labelGetStarted: 'Get Started',
      labelAssetScreening: 'Asset Screening',
      labelAssetScreeningDescription: 'Seamlessly evaluate and verify that all your assets comply with certification requirements. Our intelligent screening tools ensure nothing is overlooked.',
      labelCertificationApplication: 'Certification Application',
      labelCertificationApplicationDescription: 'Enjoy a streamlined application process that simplifies submitting your certification requests. With user-friendly forms and real-time updates, securing your certifications has never been easier.',
      labelCertificationScheduling: 'Certification Scheduling',
      labelCertificationSchedulingDescription: 'Take control of your certification timelines with our flexible scheduling options. Plan, adjust, and manage your certification appointments with ease to fit your needs.',
    },
    profileScreen: {
      title: 'Profile',

      labelNo: 'No',
      labelEmployeeID: 'Employee ID',
      labelFullName: 'Full Name',
      labelEmail: 'Email',
      labelJobTitle: 'Job Title',
      labelCreatedDate: 'Created Date',
      labelCompanyInformation: 'Company Information',
      labelAdditionalInformation: 'Additional Information',
      labelSendNotificationTo: 'You are going to send notification to user',
      labelWorkingHour: 'Custom Working Hour',
      labelDepartment: 'Department',
      labelDivision: 'Division',
      labelCompany: 'Company',
      labelBranch: 'Branch',
      labelManager: 'Manager',
      labelStatus: 'Status',
      labelShiftingUser: 'Shifting User',
      labelAddress: 'Address',
      labelHomeAddress: 'Home Address',
      labelWorkAddress: 'Work Address',

      buttonCaptionEditProfile: 'Edit Profile',
      buttonCaptionSendNotification: 'Send Notification',
      buttonCaptionReset: 'Reset',

      placeholderUsername: 'Username',
      placeholderFullName: 'Full Name',
      placeholderEmail: 'Email',
      placeholderDateOfBirth: 'Date of Birth',
      placeholderGender: 'Gender',
      placeholderPhone: 'Phone',
      placeholderEmployeeId: 'Employee ID',
      placeholderJobTitle: 'Job Title',
      placeholderDepartment: 'Department',
      placeholderDivision: 'Division',
      placeholderCompanyName: 'Company Name',
      placeholderBranch: 'Branch',
      placeholderManager: 'Manager',
      placeholderProfilePicture: 'Profile Picture',
      placeholderRoles: 'Roles',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderWorkingHourTimeZone: 'Working Hour TimeZone',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',
      placeholderUserCode: 'User Code',
      placeholderTags: 'Tags',
      placeholderAddressId: 'ID',
      placeholderAddressContactName: 'Contact Name',
      placeholderAddressContactPhone: 'Contact Phone',
      placeholderAddressAddressLine1: 'Address Line 1',
      placeholderAddressAddressLine2: 'Address Line 2',
      placeholderAddressRegion: 'Region',
      placeholderAddressPostalCode: 'Postal Code',
      placeholderWorkingShift: 'Working Shift',
    },
    provinceScreen: {
      title: 'Province',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',

      buttonCaptionCreateNewProvince: 'Create New Province',
      buttonCaptionEditProvince: 'Edit Province',
      buttonCaptionDeleteProvince: 'Delete Province',

      placeholderProvinceName: 'Province Name',
      placeholderProvinceTimeZone: 'Province Time Zone',
      placeholderProvinceCode: 'Province Code',
    },
    cityScreen: {
      title: 'City',

      labelNo: 'No',
      labelName: 'Name',
      labelProvince: 'Province',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',

      buttonCaptionCreateNewCity: 'Create New City',
      buttonCaptionEditCity: 'Edit City',
      buttonCaptionDeleteCity: 'Delete City',

      placeholderCityName: 'City Name',
      placeholderProvinceName: 'Province Name',
      placeholderCityCode: 'City Code',
    },
    companyScreen: {
      title: 'Company',

      labelNo: 'No',
      labelName: 'Name',
      labelAddress: 'Address',
      labelCity: 'City',
      labelProvince: 'Province',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelTimeZone: 'Time Zone',
      labelStatus: 'Status',
      labelGoogleMap: 'Google Map',

      buttonCaptionCreateNewCompany: 'Create New Company',
      buttonCaptionEditCompany: 'Edit Company',
      buttonCaptionDeleteCompany: 'Delete Company',

      placeholderCompanyName: 'Name',
      placeholderCityName: 'City',
      placeholderAddress: 'Address',
      placeholderDescription: 'Description',
      placeholderTag: 'Tag',
      placeholderEmail: 'Email',
      placeholderPhone: 'Phone',
      placeholderLogo: 'Logo',
      placeholderBanner: 'Banner',
      placeholderWebsite: 'Website',
      placeholderPostalCode: 'Postal Code',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderAppStoreUrl: 'AppStore URL',
      placeholderPlayStoreUrl: 'PlayStore URL',
    },
    branchScreen: {
      title: 'Branch',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelAddress: 'Address',
      labelGoogleMap: 'Google Map',
      labelWorkingHour: 'Working Hour',
      labelBorderArea: 'Border Area',
      labelLongitude: 'Longitude',
      labelLatitude: 'Latitude',
      labelTimeZone: 'Time Zone',
      labelProvince: 'Province',
      labelStatus: 'Status',
      labelCompany: 'Company',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      buttonCaptionDuplicateBranch: 'Duplicate Branch',

      buttonCaptionCreateNewBranch: 'Create New Branch',
      buttonCaptionEditBranch: 'Edit Branch',
      buttonCaptionDeleteBranch: 'Delete Branch',

      placeholderBranchName: 'Name',
      placeholderCityName: 'City',
      placeholderAddress: 'Address',
      placeholderDescription: 'Description',
      placeholderPhone: 'Phone',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Postal Code',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',
      placeholderCompanyName: 'Company Name',
    },
    permissionScreen: {
      title: 'Permission',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelDescription: 'Description',

      buttonCaptionCreateNewPermission: 'Create New Permission',
      buttonCaptionDeletePermission: 'Delete Permission',
      buttonCaptionEditPermission: 'Edit Permission',

      placeholderPermissionName: 'Permission Name',
      placeholderDescription: 'Description',
    },
    roleScreen: {
      title: 'Role',

      labelNo: 'No',
      labelName: 'Name',
      labelPermission: 'Permission',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelDescription: 'Description',

      buttonCaptionCreateNewRole: 'Create New Role',
      buttonCaptionEditRole: 'Edit Role',
      buttonCaptionDeleteRole: 'Delete Role',

      placeholderRoleName: 'Role Name',
      placeholderPermissionName: 'Permission Name',
      placeholderDescription: 'Description',
    },
    userScreen: {
      title: 'User',

      labelNo: 'No',
      labelUsername: 'Username',
      labelFullName: 'Full Name',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelRoles: 'Roles',
      labelUserType: 'Type',
      labelLastActivity: 'Last Activity',
      labelValidUntil: 'Valid Until',
      labelAppVersion: 'App Version',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelTimezone: 'Timezone',
      labelReferrer: 'Referrer',
      labelDeviceId: 'Device ID',
      labelCreatedDate: 'Created Date',

      buttonCaptionEditUser: 'Edit User',
      buttonCaptionDisable: 'Disable',

      placeholderUserUsername: 'Username',
      placeholderUserFullName: 'Full Name',
      placeholderUserEmail: 'Email',
      placeholderUserPhone: 'Phone',
      placeholderUserRoles: 'Roles',
      placeholderActiveSessions: 'Active Sessions',
      placeholderTopActiveSessions: 'Top {pageSize} Active Sessions',
      placeholderUserUserCode: 'User Code',
      placeholderUserInfo1: 'Info 1',
      placeholderUserInfo2: 'Info 2',
      placeholderUserInfo3: 'Info 3',
      placeholderReferrerId: 'Referrer ID',
      placeholderReferrerName: 'Referrer Name',
      placeholderOrigin: 'Origin',

      msgDisableRowConfirmation: 'Are you sure want to disable this row?',
    },
    apiKeyScreen: {
      title: 'API Key',

      labelNo: 'No',
      labelToken: 'Token',
      labelScope: 'Scope',
      labelConsumer: 'Consumer',
      labelOwner: 'Owner',
      labelCreatedDate: 'Created Date',
      labelCopied: 'Copied!',

      buttonCaptionCreateNewApiKey: 'Create New API Key',
      buttonCaptionDeleteApiKey: 'Delete API Key',
      buttonCaptionEditApiKey: 'Edit API Key',

      placeholderToken: 'Token',
      placeholderScope: 'Scope',
      placeholderConsumer: 'Consumer App Name',
      placeholderOwner: 'Owner App Name',
    },
    tokenScreen: {
      title: 'Token',

      labelNo: 'No',
      labelToken: 'Token',
      labelUsername: 'Username',
      labelValidUntil: 'Valid Until',
      labelLastActivity: 'Last Activity',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelDeviceManufacturer: 'Device Manufacturer',
      labelDeviceModel: 'Device Model',
      labelOSName: 'OS Name',
      labelOSVersion: 'OS Version',
      labelTimeZone: 'Time Zone',
      labelAdditionalUrlPlaceholders: '<br/>{appVersion}: The application version of the recipient<br/>{latestAppVersion}: The latest application version<br/>{deviceId}: The device ID of the recipient<br/>{deviceManufacturer}: The device manufacturer of the recipient<br/>{deviceModel}: The device model of the recipient<br/>{osName}: The operating system used on the recipient\'s device<br/>{osVersion}: The operating system version used on the recipient\'s device<br/>{timeZone}: The timezone on the recipient\'s device',
      labelSendNotificationTo: 'You are going to send notification to',
      labelDeviceId: 'Device ID',

      buttonCaptionDisable: 'Disable',

      placeholdreToken: 'Token',
      placeholderUsername: 'Username',
      placeholderValidUntil: 'Valid Until',
      placeholderLastActivity: 'Last Activity',
      placeholderDevice: 'Device',
      placeholderOS: 'OS',
      placeholderAppVersion: 'App Version',
      placeholderCreatedDate: 'Created Date',
      placeholderFullName: 'Full Name',
      placeholderTimezone: 'TimeZone',
      placeholderLanguage: 'Language',
      placeholderToken: 'Token',
      placeholderFCMToken: 'FCM Token',
      placeholderOAuthProvider: 'OAuth Provider',
      placeholderDeviceId: 'Device ID',
    },
    applicationScreen: {
      title: 'Application',

      labelNo: 'No',
      labelName: 'Name',
      labelOrder: 'Order',
      labelUrl: 'URL',
      labelIcon: 'Icon',

      buttonCaptionCreateNewApplication: 'Create New Application',
      buttonCaptionEditApplication: 'Edit Application',
      buttonCaptionDeleteApplication: 'Delete Application',

      placeholderName: 'Application Name',
      placeholderDescription: 'Description',
      placeholderUrl: 'URL',
      placeholderAndroidAppId: 'Android App ID',
      placeholderIosAppId: 'iOS App ID',
      placeholderDownloadUrl: 'Download URL',
      placeholderOrder: 'Order',
      placeholderPermission: 'Permission',
      placeholderIcon: 'Icon',
      placeholderMinAppVersion: 'Minimum App Version',
      placeholderMaxAppVersion: 'Maximum App Version',
    },
    summaryScreen: {
      title: 'Summary',

      labelTotalUser: 'Total Users',
      labelActiveUser: 'Active Users',
      labelActiveDevice: 'Active Devices',
      labelOnlineUsers: 'Online Users',
      labelOsDistribution: 'OS Distribution',
      labelAccessUsing: 'Access Using',
      labelAndroidVersionDistribution: 'Android Version Distribution',
      labelAndroidDeviceModelDistribution: 'Android Device Model Distribution',
      labelIosVersionDistribution: 'iOS Version Distribution',
      labelIosDeviceModelDistribution: 'iOS Device Model Distribution',
      labelBrowserDistribution: 'Browser Distribution',
      labelTimezoneDistribution: 'Timezone Distribution',
      labelAppVersionDistribution: 'App Version Distribution',
      labelDeviceManufacturerDistribution: 'Device Manufacturer Distribution',
      labelPieChartOther: 'Other',
    },
    serviceScreen: {
      title: 'Service',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',

      placeholderServiceName: 'Name',
      placeholderServiceDescription: 'Description',

      buttonCaptionCreateNewService: 'Create New Service',
      buttonCaptionDeleteService: 'Delete Service',
      buttonCaptionEditService: 'Edit Service',
    },
    scopeScreen: {
      title: 'Scope',

      labelNo: 'No',
      labelName: 'Name',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',
      labelService: 'Service',

      placeholderScopeName: 'Name',
      placeholderScopeDescription: 'Description',
      placeholderServiceName: 'Service',

      buttonCaptionCreateNewScope: 'Create New Scope',
      buttonCaptionDeleteScope: 'Delete Scope',
      buttonCaptionEditScope: 'Edit Scope',
    },
    bannerScreen: {
      title: 'Banner',

      labelNo: 'No',
      labelTitle: 'Title',
      labelOrder: 'Order',
      labelAction: 'Action',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',
      labelViewCount: 'View Count',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Image',
      labelCharacter: 'Character(s)',
      labelCompany: 'Company',

      buttonCaptionCreateNewBanner: 'Create New Banner',
      buttonCaptionEditBanner: 'Edit Banner',
      buttonCaptionDeleteBanner: 'Delete Banner',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Image',

      placeholderTitle: 'Title',
      placeholderBody: 'Body',
      placeholderUrl: 'URL',
      placeholderAction: 'Action',
      placeholderViewCount: 'View Count',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderShortURL: 'Short URL',
      placeholderReactionId: 'Reaction ID',
      placeholderDiscussionId: 'Discussion ID',
      placeholderAndroidAppId: 'Android App ID',
      placeholderIosAppId: 'iOS App ID',
      placeholderDownloadUrl: 'Download URL',
      placeholderOrder: 'Order',
      placeholderMedia: 'Media',
      placeholderShortDescription: 'Short Description',
      placeholderTags: 'Tags',
      placeholderCompany: 'Company',
    },
    popUpBannerScreen: {
      title: 'Pop Up Banner',

      labelNo: 'No',
      labelTitle: 'Title',
      labelOrder: 'Order',
      labelValidFrom: 'Valid From',
      labelValidUntil: 'Valid Until',

      buttonCaptionCreateNewPopUpBanner: 'Create New Pop Up Banner',
      buttonCaptionEditPopUpBanner: 'Edit Pop Up Banner',
      buttonCaptionDeletePopUpBanner: 'Delete Pop Up Banner',

      placeholderTitle: 'Title',
      placeholderDescription: 'Description',
      placeholderUrl: 'URL',
      placeholderOrder: 'Order',
      placeholderValidFrom: 'Valid From',
      placeholderValidUntil: 'Valid Until',
      placeholderImage: 'Image',
    },
    districtScreen: {
      title: 'District',

      labelNo: 'No',
      labelName: 'Name',
      labelCity: 'City',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelStatus: 'Status',

      buttonCaptionCreateNewDistrict: 'Create New District',
      buttonCaptionEditDistrict: 'Edit District',
      buttonCaptionDeleteDistrict: 'Delete District',

      placeholderDistrictName: 'District Name',
      placeholderCityName: 'City Name',
      placeholderDistrictCode: 'District Code',
    },
    subdistrictScreen: {
      title: 'Subdistrict',

      labelNo: 'No',
      labelName: 'Name',
      labelDistrict: 'District',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelStatus: 'Status',

      buttonCaptionCreateNewSubdistrict: 'Create New Subdistrict',
      buttonCaptionEditSubdistrict: 'Edit Subdistrict',
      buttonCaptionDeleteSubdistrict: 'Delete Subdistrict',

      placeholderSubdistrictName: 'Subdistrict Name',
      placeholderDistrictName: 'District Name',
      placeholderSubdistrictCode: 'Subdistrict Code',
    },
    registrationAttemptScreen: {
      title: 'Registration Attempt',

      labelNo: 'No',

      labelUserName: 'User Name',
      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelRegisterUsing: 'Register Using',
      labelVerified: 'Verified',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelValidUntil: 'Valid Until',
      labelReferralCode: 'Referral Code',
      labelDeviceID: 'Device ID',

      placeholderUserName: 'Username',
      placeholderRegisterUsing: 'Register Using',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Phone',
      placeholderResendPINCount: 'Resend PIN Count',
      placeholderReferralCode: 'Referral Code',
      placeholderInvalidPINCount: 'Invalid PIN Count',
      placeholderDeviceID: 'Device ID',
      placeholderValidUntil: 'Valid Until',
      placeholderDevice: 'Device',
      placeholderVerified: 'Verified',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Verified On',
      placeholderAppVersion: 'App Version',
      placeholderLanguage: 'Language',
    },
    passwordResetAttemptScreen: {
      title: 'Password Reset Attempt',

      labelEmail: 'Email',
      labelPhone: 'Phone',
      labelPIN: 'PIN',
      labelResetUsing: 'Reset Using',
      labelDone: 'Done',
      labelDevice: 'Device',
      labelOS: 'OS',
      labelAppVersion: 'App Version',
      labelCreatedDate: 'Created Date',
      labelValidUntil: 'Valid Until',
      labelDeviceID: 'Device ID',

      placeholderResetUsing: 'Register Using',
      placeholderResendPINCount: 'Resend PIN Count',
      placeholderInvalidPINCount: 'Invalid PIN Count',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Phone',
      placeholderDeviceID: 'Device ID',
      placeholderValidUntil: 'Valid Until',
      placeholderDevice: 'Device',
      placeholderVerified: 'Verified',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Verified On',
      placeholderAppVersion: 'App Version',
      placeholderDone: 'Done',
      placeholderLanguage: 'Language',
    },
    workingShiftPage: {
      title: 'Working Shift',

      labelNo: 'No.',
      labelName: 'Name',
      labelStartWorkingHour: 'Start Working Hour',
      labelEndWorkingHour: 'End Working Hour',
      labelBranchName: 'Branch Name',
      labelCreatedDate: 'Created Date',
      labelLastModifiedDate: 'Last Modified Date',
      labelBranch: 'Branch',
      labelStatus: 'Status',

      placeholderWorkingShiftName: 'Working Shift Name',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderStartBeforeMin: 'Start Before Min',
      placeholderStartAfterMin: 'Start After Min',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderBranchName: 'Branch Name',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderEndBeforeMin: 'End Before Min',
      placeholderEndAfterMin: 'End After Min',
      placeholderAutoClockOut: 'Auto Clock Out',

      buttonCaptionCreateNewWorkingShift: 'Create New Working Shift',
      buttonCaptionEditWorkingShift: 'Edit Working Shift',
      buttonCaptionDuplicateWorkingShift: 'Duplicate Working Shift',
    },
    notification: {
      labelNotificationPromptTitle: 'Turn on the notifications',
      labelNotificationPromptMessage: 'Allow this website to access notifications',

      buttonCaptionAllow: 'Allow',

      errorMessageRetrievingToken: 'An error occurred while retrieving token.',
      errorMessagePermissionDenied: 'You have to allow notification in order to get notification',
    },
  },
  id: {
    common: {
      alertTitleInfo: 'INFO',
      alertTitleError: 'GALAT',
      alertTitleWarning: 'PERINGATAN',
      alertTitleConfirmation: 'KONFIRMASI',
      alertTitleSuccess: 'SUKSES',
      alertTitleProcessing: 'Mengolah',

      buttonCaptionOK: 'OK',
      buttonCaptionCancel: 'Batal',
      buttonCaptionDetail: 'Detail',
      buttonCaptionYes: 'Ya',
      buttonCaptionNo: 'Tidak',
      buttonCaptionSave: 'Simpan',
      buttonCaptionEdit: 'Ubah',
      buttonCaptionSubmit: 'Kirim',
      buttonCaptionNext: 'Lanjut',
      buttonCaptionPrev: 'Sebelumnya',
      buttonCaptionBack: 'Kembali',
      buttonCaptionAdd: 'Tambah',
      buttonCaptionRemove: 'Hapus',
      buttonCaptionApply: 'Terapkan',
      buttonCaptionView: 'Lihat',
      buttonCaptionSend: 'Kirim',
      buttonCaptionSendNotification: 'Kirim Pemberitahuan',
      buttonCaptionCount: 'Hitung',
      buttonCaptionAdvancedFilter: 'Penyaring',
      buttonCaptionReset: 'Atur Ulang',
      buttonCaptionTakePicture: 'Ambil Foto',
      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',
      buttonCaptionResync: 'Sinkronisasi Ulang',
      buttonCaptionDuplicate: 'Duplikasi',
      buttonCaptionDownloadFile: 'Unduh Berkas',
      buttonCaptionAction: 'Aksi',
      buttonCaptionTakePhoto: 'Ambil Foto',
      buttonCaptionChooseFromLibrary: 'Pilih dari Galeri',
      buttonCaptionCopy: 'Cetak',
      buttonCaptionLock: 'Kunci',
      buttonCaptionUnlock: 'Buka Kunci',
      buttonCaptionUpload: 'Unggah',
      buttonCaptionDelete: 'Hapus',
      buttonCaptionDownload: 'Unduh',

      errMsgEmptyRequiredFields: 'Tidak boleh ada field yang kosong',
      errMsgInvalidEmailFormat: 'Format email yang Anda masukkan salah',
      errMsgInvalidPhoneNumberFormat: 'Format nomor telepon yang Anda masukkan salah',
      errMsgMinPasswordLength: `Minimal panjang password adalah ${Constant.PASSWORD_MIN_LENGTH} karakter`,
      errMsgPasswordDoesNotMatch: 'Kedua kata sandi tidak cocok',
      errMsgCannotOpenUrl: 'Tidak bisa membuka URL',
      errMsgRequired: 'Harus diisi',
      errMsgDuplicatedData: 'Duplikasi data ditemukan. Silakan pilih opsi lain',
      errMsgStartEndDate: 'Tanggal Berakhir harus lebih besar dari atau sama dengan Tanggal Dimulai',
      errMsgRegStartEndDate: 'Tanggal Registrasi Berakhir harus lebih besar dari atau sama dengan Tanggal Registrasi Dimulai',
      errMsgEndRegEndDate: 'Tanggal Berakhir harus lebih besar dari atau sama dengan Tanggal Registrasi Berakhir',
      errMsgInvalidYoutubeUrl: 'Tautan hanya dapat diisi dengan format URL Youtube',
      errMsgStartEndWorkingHour: 'Jam Selesai Kerja tidak boleh sama dengan Jam Mulai Kerja',
      errMsgMinMaxNum: 'Angka Maksimal harus lebih besar dari atau sama dengan angka Minimal',
      errMsgNoDataYet: 'Belum Ada Data',
      errMsgNoDataFound: 'Tidak ada data yang ditemukan',
      errMsgStartEndTime: 'Waktu Berakhir harus lebih besar dari atau sama dengan Waktu Dimulai',
      errMsgMinMaxValue: 'Nilai  Maks. harus lebih besar atau sama dengan Nilai Min.',
      errMsgMustaNumber: 'Harus berupa angka',
      errMsgEarliestClockInTime: 'Jam Mulai Kerja Paling Awal harus lebih kecil atau sama dengan Jam Mulai Kerja',
      errMsgAutoClockOut: 'Jam Selesai Kerja Otomatis harus lebih besar atau sama dengan Jam Selesai Kerja',
      errMsgFileUploadedTooLarge: 'Ukuran file yang diunggah terlalu besar',
      errMsgResourceNotFound: 'Sumber tidak ditemukan',
      errMsgResourceNotAvailable: 'Sumber tidak tersedia',
      errMsgTimeoutContactingService: 'Waktu habis ketika menghubungi layanan',
      errMsgFailedActions: '{number} dari {total} data gagal dihapus',
      errMsgDifferentRetypePassword: 'Kata Sandi Tidak Sesuai',
      errMsgMinLength: 'Panjang karakter dibawah {minLength}',
      errMsgMaxLength: 'Panjang karakter diatas {maxLength}',
      errMsgInvalidFormat: 'Format yang tidak valid',
      errMsgMinValue: 'Minimum nilai {minValue}',
      errMsgMaxValue: 'Nilai maksimum {maxValue}',

      labelWelcome: 'Selamat Datang',
      labelAuthentication: 'Autentikasi',
      labelPersonalization: 'Personalisi',
      labelMasterData: 'Data Master',
      labelReport: 'Laporan',
      labelHi: 'Halo, {name}',
      labelRefreshData: 'Perbarui Data',
      labelEdit: 'Ubah',
      labelDelete: 'Hapus',
      labelBasicInformation: 'Informasi Dasar',
      labelChoices: 'Pilihan',
      labelChosen: 'Terpilih',
      labelSelected: 'Dipilih',
      labelYes: 'Iya',
      labelNo: 'Tidak',
      labelTrue: 'Benar',
      labelFalse: 'Salah',
      labelLegend: 'Legenda',
      labelCancel: 'Batalkan',
      labelFilter: 'Saring',
      labelSearch: 'Cari',
      labelPerson: 'Orang',
      labelCity: 'Kota',
      labelDepartment: 'Departemen',
      labelDivision: 'Divisi',
      labelCompanyName: 'Nama Perusahaan',
      labelSendNotification: 'Kirim Pemberitahuan',
      labelSelectedOrAllUser: 'Terpilih atau Semua Pengguna',
      labelBranch: 'Cabang',
      labelNewsManagement: 'Manajemen Berita',
      labelAdvancedFilter: 'Penyaring Lanjutan',
      labelMin: 'Min',
      labelMax: 'Maks',
      labelCharacter: 'Karakter',
      labelCameraSource: 'Sumber Kamera',
      labelYouCanUsePlaceholdersFieldsAbove: '* Anda dapat menggunakan placeholder berikut pada baris di atas',
      labelAdditionalUrlPlaceholders: '{fullName}: Nama lengkap penerima<br/>{profileId}: ID penerima',
      labelComments: 'Komentar',
      labelViewCommentReply: 'Lihat {replyCount} balasan',
      labelHideCommentReply: 'Sembunyikan {replyCount} balasan',
      labelMoreComments: 'Lebih banyak komentar',
      labelMoreReplies: 'Lebih banyak balasan',
      labelNoComments: 'Tidak ada komentar',
      labelReason: 'Alasan',
      labelMyProfile: 'Profil Saya',
      labelShellMembership: 'Keanggotaan Shell',
      labelUserTier: 'Tingkatan Pengguna',
      labelRemoveAllItems: 'Hapus semua data yang dipilih',
      labelSettings: 'Pengaturan',
      labelLogout: 'Keluar',
      labelChangePassword: 'Ubah Kata Sandi',
      labelLogoutDevices: 'Keluar dari perangkat lain',
      labelNewPasswordSaved: 'Kata Sandi baru berhasil disimpan',
      labelSucces: 'Sukses',
      labelUserName: 'Nama Pengguna',
      labelUserCode: 'Kode Pengguna',
      labelGender: 'Jenis Kelamin',
      labelPhone: 'No. Telepon',
      labelRoles: 'Peran',
      labelTags: 'Tags',
      labelNoPhoto: 'Tidak Ada Foto',
      labelUpload: 'Unggah',
      labelDownloadRequestTimeout: 'Permintaan Anda memakan waktu terlalu lama. Anda dapat melihat perkembangannya dan mengunduh hasilnya pada menu "Pekerjaan Saya". Apakah Anda ingin membuka menu "Pekerjaan Saya"?',
      labelImageRatio1To1: 'Rasio gambar yang dipilih harus 1:1 (persegi)',
      labelFileUpload: 'Unggah Berkas',
      labelBrowseFile: 'Cari Berkas',
      labelDropzonePicker: ' untuk menggunggah berkas Anda, atau tarik berkas dan lepaskan di kotak',
      labelAction: 'Aksi',
      labelCopyright: 'E-Sertifikasi EPSON © {year}',
      labelCopied: 'Disalin!',

      placeholderId: 'ID',
      placeholderCreatedBy: 'Dibuat Oleh',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderLastModifiedBy: 'Terakhir Diubah Oleh',
      placeholderLastModifiedDate: 'Tanggal Perubahan Terakhir',
      placeholderStatus: 'Status',
      placeholderTitle: 'Judul',
      placeholderBody: 'Isi',
      placeholderUrl: 'URL',
      placeholderSendTo: 'Kirim kepada',
      placeholderReason: 'Alasan',
      placeholderSearchBranch: 'Cari Cabang',
      placeholderSearchCompany: 'Cari Perusahaan',
      placeholderSearchDivision: 'Cari Divisi',
      placeholderSearchDepartment: 'Cari Departemen',
      placeholderType: 'Tipe',
      placeholderOldPassword: 'Kata Sandi Lama',
      placeholderNewPassword: 'Kata Sandi Baru',
      placeholderRetypeNewPassword: 'Ketik Ulang Kata Sandi Baru',
      placeholderTimeZone: 'Zona Waktu',
      placeholderImage: 'Gambar',

      msgLogoutConfirmation: 'Apa Anda yakin ingin keluar?',
      msgDeleteConfirmation: 'Apa Anda yakin ingin menghapus pilihan ini?',
      msgSomethingWentWrong: 'Terjadi kesalahan',
      msgCancelConfirmation: 'Apa Anda yakin ingin membatalkan pilihan ini?',
      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
      msgResyncConfirmation: 'Apa Anda yakin ingin mensinkronisasi ulang pilihan ini?',
      msgLockConfirmation: 'Apa Anda yakin ingin mengunci pilihan ini?',
      msgUnlockConfirmation: 'Apa Anda yakin ingin membuka kunci pilihan ini?',
      msgDownloadingData: 'Memperbarui data...',
      msgEnablingData: 'Mengaktifkan data {count}',
      msgDisablingData: 'Menonaktifkan data {count}',
      msgDeletingData: 'Menghapus {count} data',
      msgSuccessfullyEnablingData: 'Berhasil mengaktifkan {count} data',
      msgSuccessfullyDisablingData: 'Berhasil menonaktifkan {count} data',
      msgSuccessfullyDeletingData: 'Berhasil menghapus {count} data',
      msgFailedEnablingData: 'Gagal mengaktifkan data',
      msgFailedDisablingData: 'Gagal menonaktifkan data',
      msgFailedDeletingData: 'Gagal menghapus data',

      alertMessageUnsavedChanges: 'Anda memiliki perubahan yang belum disimpan!',
      alertMessageSaveChanges: 'Mohon simpan/reset perubahan yang anda buat terlebih dahulu',
    },
    loginScreen: {
      title: 'Masuk',

      buttonCaptionLogin: 'Masuk',
      buttonCaptionLoginWithGoogle: 'Masuk dengan Google',
      buttonCaptionLoginWithMicrosoft: 'Masuk dengan Microsoft',
      buttonCaptionLoginWith: 'Masuk dengan ',
      buttonCaptionLoginWithEmail: 'Masuk dengan Email',
      buttonCaptionForgotPassword: 'Lupa Kata Sandi?',

      labelWelcomeBack: 'Selamat Datang Kembali!',
      labelAccessYourAccount: 'Akses Akun Anda',
      labelEmail: 'Email',
      labelPassword: 'Kata sandi',
      labelCaptcha: 'Captcha',

      placeholderEmail: 'contoh: yourname@company.com',
      placeholderCaptcha: 'Harap ketik ulang kode di atas',

      errEmptyUsernamePassword: 'Email dan kata sandi tidak dapat kosong',
      errMsgInvalidSignIn: 'Email tidak dapat ditemukan',
      errMsgNoPermissionToLogin: 'Anda tidak memiliki izin untuk masuk',
    },
    dashboardScreen: {
      title: 'Selamat Datang',

      labelHi: 'Hi',
      labelWelcomeTitle: 'Selamat datang di e-Certification EPSON',
      labelWelcomeSubtitle: 'Pantau dan Kelola Semua Proses Sertifikasi Aset Anda secara Efisien',
      labelGetStarted: 'Memulai',
      labelAssetScreening: 'Pemeriksaan Aset',
      labelAssetScreeningDescription: 'Evaluasi dan verifikasi dengan lancar bahwa semua aset Anda mematuhi persyaratan sertifikasi. Alat penyaringan cerdas kami memastikan tidak ada yang terlewatkan.',
      labelCertificationApplication: 'Permohonan Sertifikasi',
      labelCertificationApplicationDescription: 'Nikmati proses aplikasi yang disederhanakan yang menyederhanakan pengajuan permintaan sertifikasi Anda. Dengan formulir yang mudah digunakan dan pembaruan waktu nyata, mengamankan sertifikasi Anda tidak pernah semudah ini.',
      labelCertificationScheduling: 'Penjadwalan Sertifikasi',
      labelCertificationSchedulingDescription: 'Kendalikan jadwal sertifikasi Anda dengan opsi penjadwalan fleksibel kami. Rencanakan, sesuaikan, dan kelola janji temu sertifikasi Anda dengan mudah agar sesuai dengan kebutuhan Anda.',
    },
    profileScreen: {
      title: 'Profil',

      labelNo: 'No',
      labelEmployeeID: 'ID Karyawan',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelJobTitle: 'Jabatan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCompanyInformation: 'Informasi Perusahaan',
      labelAdditionalInformation: 'Informasi Tambahan',
      labelSendNotificationTo: 'Anda akan mengirim pemberitahuan kepada pengguna',
      labelWorkingHour: 'Penyesuaian Jam Kerja',
      labelDepartment: 'Departemen',
      labelDivision: 'Divisi',
      labelCompany: 'Perusahaan',
      labelBranch: 'Cabang',
      labelManager: 'Manajer',
      labelStatus: 'Status',
      labelShiftingUser: 'Shifting User',
      labelAddress: 'Alamat',
      labelHomeAddress: 'Alamat Rumah',
      labelWorkAddress: 'Alamat Kantor',

      buttonCaptionEditProfile: 'Ubah Profil',
      buttonCaptionSendNotification: 'Kirim Pemberitahuan',
      buttonCaptionReset: 'Reset',

      placeholderUsername: 'Nama Pengguna',
      placeholderFullName: 'Nama Lengkap',
      placeholderEmail: 'Email',
      placeholderDateOfBirth: 'Tanggal Lahir',
      placeholderGender: 'Jenis Kelamin',
      placeholderPhone: 'Telepon',
      placeholderEmployeeId: 'ID Karyawan',
      placeholderJobTitle: 'Jabatan',
      placeholderDepartment: 'Departemen',
      placeholderDivision: 'Divisi',
      placeholderCompanyName: 'Nama Perusahaan',
      placeholderBranch: 'Cabang',
      placeholderManager: 'Manajer',
      placeholderProfilePicture: 'Foto Profil',
      placeholderRoles: 'Peran',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',
      placeholderUserCode: 'Kode Pengguna',
      placeholderTags: 'Penanda',
      placeholderAddressId: 'ID',
      placeholderAddressContactName: 'Nama Kontak',
      placeholderAddressContactPhone: 'Nomor Kontak',
      placeholderAddressAddressLine1: 'Alamat Baris 1',
      placeholderAddressAddressLine2: 'Alamat Baris 2',
      placeholderAddressRegion: 'Wilayah',
      placeholderAddressPostalCode: 'Kode Pos',
      placeholderWorkingShift: 'Shift Kerja',
    },
    provinceScreen: {
      title: 'Provinsi',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',

      buttonCaptionCreateNewProvince: 'Buat Provinsi Baru',
      buttonCaptionEditProvince: 'Ubah Provinsi',
      buttonCaptionDeleteProvince: 'Hapus Provinsi',

      placeholderProvinceName: 'Nama Provinsi',
      placeholderProvinceTimeZone: 'Zona Waktu Provinsi',
      placeholderProvinceCode: 'Kode Provinsi',
    },
    cityScreen: {
      title: 'Kota',

      labelNo: 'No',
      labelName: 'Nama',
      labelProvince: 'Provinsi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',

      buttonCaptionCreateNewCity: 'Buat Kota Baru',
      buttonCaptionEditCity: 'Ubah Kota',
      buttonCaptionDeleteCity: 'Hapus Kota',

      placeholderCityName: 'Nama Kota',
      placeholderProvinceName: 'Nama Provinsi',
      placeholderCityCode: 'Kode Kota',
    },
    companyScreen: {
      title: 'Perusahaan',

      labelNo: 'No',
      labelName: 'Nama',
      labelAddress: 'Alamat',
      labelCity: 'Kota',
      labelProvince: 'Provinsi',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelTimeZone: 'Zona Waktu',
      labelStatus: 'Status',
      labelGoogleMap: 'Peta Google',

      buttonCaptionCreateNewCompany: 'Buat Perusahaan Baru',
      buttonCaptionEditCompany: 'Ubah Perusahaan',
      buttonCaptionDeleteCompany: 'Hapus Perusahaan',

      placeholderCompanyName: 'Nama',
      placeholderCityName: 'Kota',
      placeholderAddress: 'Alamat',
      placeholderDescription: 'Deskripsi',
      placeholderTag: 'Tanda',
      placeholderEmail: 'Email',
      placeholderPhone: 'Telepon',
      placeholderLogo: 'Logo',
      placeholderBanner: 'Spanduk',
      placeholderWebsite: 'Situs',
      placeholderPostalCode: 'Kode Pos',
      placeholderGoogleMapUrl: 'Google Map URL',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderAppStoreUrl: 'URL AppStore',
      placeholderPlayStoreUrl: 'URL PlayStore',
    },
    branchScreen: {
      title: 'Cabang',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelAddress: 'Alamat',
      labelGoogleMap: 'Peta Google',
      labelWorkingHour: 'Jam Kerja',
      labelBorderArea: 'Batas Wilayah',
      labelLongitude: 'Garis Bujur',
      labelLatitude: 'Garis Lintang',
      labelTimeZone: 'Zona Waktu',
      labelProvince: 'Provinsi',
      labelStatus: 'Status',
      labelCompany: 'Perusahaan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      buttonCaptionDuplicateBranch: 'Duplikasi Branch',

      buttonCaptionCreateNewBranch: 'Buat Cabang Baru',
      buttonCaptionEditBranch: 'Ubah Cabang',
      buttonCaptionDeleteBranch: 'Hapus Cabang',

      placeholderBranchName: 'Cabang',
      placeholderCityName: 'Kota',
      placeholderAddress: 'Alamat',
      placeholderDescription: 'Deskripsi',
      placeholderPhone: 'Telepon',
      placeholderEmail: 'Email',
      placeholderPostalCode: 'Kode Pos',
      placeholderGoogleMapUrl: 'URL Peta Google',
      placeholderLatitude: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',
      placeholderCompanyName: 'Nama Perusahaan',
    },
    permissionScreen: {
      title: 'Izin',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewPermission: 'Buat Izin Baru',
      buttonCaptionDeletePermission: 'Hapus Izin',
      buttonCaptionEditPermission: 'Edit izin',

      placeholderPermissionName: 'Nama Izin',
      placeholderDescription: 'Deskripsi',
    },
    roleScreen: {
      title: 'Peran',

      labelNo: 'No',
      labelName: 'Nama',
      labelPermission: 'Izin',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelDescription: 'Deskripsi',

      buttonCaptionCreateNewRole: 'Buat Peran Baru',
      buttonCaptionEditRole: 'Ubah Peran',
      buttonCaptionDeleteRole: 'Hapus Peran',

      placeholderRoleName: 'Nama Peran',
      placeholderPermissionName: 'Nama Izin',
      placeholderDescription: 'Deskripsi',
    },
    userScreen: {
      title: 'Pengguna',

      labelNo: 'No',
      labelUsername: 'Nama Pengguna',
      labelFullName: 'Nama Lengkap',
      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelRoles: 'Peran',
      labelUserType: 'Tipe',
      labelLastActivity: 'Aktivitas Terakhir',
      labelValidUntil: 'Berlaku Sampai',
      labelAppVersion: 'Versi App',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelTimezone: 'Zona Waktu',
      labelReferrer: 'Perujuk',
      labelDeviceId: 'ID Perangkat',
      labelCreatedDate: 'Tanggal Pembuatan',

      buttonCaptionEditUser: 'Ubah Pengguna',
      buttonCaptionDisable: 'Nonaktifkan',

      placeholderUserUsername: 'Nama Pengguna',
      placeholderUserFullName: 'Nama Lengkap',
      placeholderUserEmail: 'Email',
      placeholderUserPhone: 'Telepon',
      placeholderUserRoles: 'Peran',
      placeholderActiveSessions: 'Sesi Aktif',
      placeholderTopActiveSessions: '{pageSize} Sesi Aktif Terbaru',
      placeholderUserUserCode: 'Kode Pengguna',
      placeholderUserInfo1: 'Informasi 1',
      placeholderUserInfo2: 'Informasi 2',
      placeholderUserInfo3: 'Informasi 3',
      placeholderReferrerId: 'ID Perujuk',
      placeholderReferrerName: 'Nama Perujuk',
      placeholderOrigin: 'Asal',

      msgDisableRowConfirmation: 'Apa Anda yakin ingin menonaktifkan baris ini?',
    },
    apiKeyScreen: {
      title: 'Kunci API',

      labelNo: 'No',
      labelToken: 'Token',
      labelScope: 'Cakupan',
      labelConsumer: 'Pengguna',
      labelOwner: 'Pemilik',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelCopied: 'Disalin!',

      buttonCaptionCreateNewApiKey: 'Buat Kunci API Baru',
      buttonCaptionDeleteApiKey: 'Hapus Kunci API',
      buttonCaptionEditApiKey: 'Ubah API Key',

      placeholderToken: 'Token',
      placeholderScope: 'Cakupan',
      placeholderConsumer: 'Nama Pengguna App',
      placeholderOwner: 'Nama Pemilik App',
    },
    tokenScreen: {
      title: 'Token',

      labelNo: 'No',
      labelToken: 'Token',
      labelUsername: 'Nama Pengguna',
      labelValidUntil: 'Berlaku Sampai',
      labelLastActivity: 'Aktivitas Terakhir',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDeviceManufacturer: 'Produsen Perangkat',
      labelDeviceModel: 'Model Perangkat',
      labelOSName: 'Nama OS',
      labelOSVersion: 'Versi OS',
      labelTimeZone: 'Zona Waktu',
      labelAdditionalUrlPlaceholders: '<br/>{appVersion}: Versi aplikasi penerima<br/>{latestAppVersion}: Versi aplikasi terbaru<br/>{deviceId}: ID perangkat penerima<br/>{deviceManufacturer}: Produsen perangkat penerima<br/>{deviceModel}: Model perangkat penerima<br/>{osName}: Sistem operasi yang digunakan di perangkat penerima<br/>{osVersion}: Versi sistem operasi yang digunakan di perangkat penerima<br/>{timeZone}: Zona waktu perangkat penerima',
      labelSendNotificationTo: 'Anda akan mengirim notifikasi ke',
      labelDeviceId: 'ID Perangkat',

      buttonCaptionDisable: 'Nonaktifkan',

      placeholdreToken: 'Token',
      placeholderUsername: 'Nama Pengguna',
      placeholderValidUntil: 'berlaku Sampai',
      placeholderLastActivity: 'Aktivitas Terakhir',
      placeholderDevice: 'Perangkat',
      placeholderOS: 'OS',
      placeholderAppVersion: 'Versi App',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderFullName: 'Nama Lengkap',
      placeholderTimezone: 'Zona Waktu',
      placeholderLanguage: 'Bahasa',
      placeholderToken: 'Token',
      placeholderFCMToken: 'Token FCM',
      placeholderOAuthProvider: 'Pemberi OAuth',
      placeholderDeviceId: 'ID Perangkat',
    },
    applicationScreen: {
      title: 'Aplikasi',

      labelNo: 'No',
      labelName: 'Nama',
      labelOrder: 'Urutan',
      labelUrl: 'URL',
      labelIcon: 'Ikon',

      buttonCaptionCreateNewApplication: 'Buat Aplikasi Baru',
      buttonCaptionEditApplication: 'Ubah Aplikasi',
      buttonCaptionDeleteApplication: 'Hapus Aplikasi',

      placeholderName: 'Nama Aplikasi',
      placeholderDescription: 'Deskripsi',
      placeholderUrl: 'URL',
      placeholderAndroidAppId: 'ID App Android',
      placeholderIosAppId: 'ID iOS App',
      placeholderDownloadUrl: 'URL Pengunduhan',
      placeholderOrder: 'Urutan',
      placeholderPermission: 'Izin',
      placeholderIcon: 'Ikon',
      placeholderMinAppVersion: 'Versi Minimal Aplikasi',
      placeholderMaxAppVersion: 'Versi Maksimal Aplikasi',
    },
    summaryScreen: {
      title: 'Rangkuman',

      labelTotalUser: 'Total Pengguna',
      labelActiveUser: 'Pengguna Aktif',
      labelActiveDevice: 'Perangkat Aktif',
      labelOnlineUsers: 'Pengguna Online',
      labelOsDistribution: 'Distribusi Sistem Operasi',
      labelAccessUsing: 'Akses Melalui',
      labelAndroidVersionDistribution: 'Distribusi Versi Android',
      labelAndroidDeviceModelDistribution: 'Model Distribusi Perangkat Android',
      labelIosVersionDistribution: 'Distribusi Versi iOS',
      labelIosDeviceModelDistribution: 'Model Distribusi Perangkat iOS',
      labelBrowserDistribution: 'Distribusi Browser',
      labelTimezoneDistribution: 'Distribusi Zona Waktu',
      labelAppVersionDistribution: 'Distribusi Versi App',
      labelDeviceManufacturerDistribution: 'Distribusi Perangkat Manufaktur',
      labelPieChartOther: 'Lainnya',
    },
    serviceScreen: {
      title: 'Layanan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',

      placeholderServiceName: 'Nama',
      placeholderServiceDescription: 'Deskripsi',

      buttonCaptionCreateNewService: 'Buat Layanan Baru',
      buttonCaptionDeleteService: 'Hapus Layanan',
      buttonCaptionEditService: 'Ubah Layanan',
    },
    scopeScreen: {
      title: 'Jangkauan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',
      labelService: 'Layanan',

      placeholderScopeName: 'Nama',
      placeholderScopeDescription: 'Deskripsi',
      placeholderServiceName: 'Layanan',

      buttonCaptionCreateNewScope: 'Buat Jangkauan Baru',
      buttonCaptionDeleteScope: 'Hapus Jangkauan',
      buttonCaptionEditScope: 'Edit Scope',
    },
    bannerScreen: {
      title: 'Spanduk',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelOrder: 'Urutan',
      labelAction: 'Aksi',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',
      labelViewCount: 'Jumlah Tampilan',
      labelMedia: 'Media',
      labelYoutube: 'Youtube',
      labelImage: 'Gambar',
      labelCharacter: 'Karakter',
      labelCompany: 'Perusahaan',

      buttonCaptionCreateNewBanner: 'Buat Spanduk Baru',
      buttonCaptionEditBanner: 'Ubah Spanduk',
      buttonCaptionDeleteBanner: 'Hapus Spanduk',
      buttonCaptionYoutube: 'Youtube',
      buttonCaptionImage: 'Gambar',

      placeholderTitle: 'Judul',
      placeholderBody: 'Badan',
      placeholderUrl: 'URL',
      placeholderAction: 'Aksi',
      placeholderViewCount: 'Jumlah Tampilan',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderShortURL: 'Tautan Pendek',
      placeholderReactionId: 'ID Reaksi',
      placeholderDiscussionId: 'ID Diskusi',
      placeholderAndroidAppId: 'ID App Android',
      placeholderIosAppId: 'ID App iOS',
      placeholderDownloadUrl: 'URL Pengunduh',
      placeholderOrder: 'Urutan',
      placeholderMedia: 'Media',
      placeholderShortDescription: 'Deskripsi Singkat',
      placeholderTags: 'Tag',
      placeholderCompany: 'Perusahaan',
    },
    popUpBannerScreen: {
      title: 'Spanduk Pop Up',

      labelNo: 'No',
      labelTitle: 'Judul',
      labelOrder: 'Urutan',
      labelValidFrom: 'Berlaku Dari',
      labelValidUntil: 'Berlaku Sampai',

      buttonCaptionCreateNewPopUpBanner: 'Buat Spanduk Pop Up Baru',
      buttonCaptionEditPopUpBanner: 'Ubah Spanduk Pop Up',
      buttonCaptionDeletePopUpBanner: 'Hapus Spanduk Pop Up',

      placeholderTitle: 'Judul',
      placeholderDescription: 'Deskripsi',
      placeholderUrl: 'URL',
      placeholderOrder: 'Urutan',
      placeholderValidFrom: 'Berlaku Dari',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderImage: 'Gambar',
    },
    districtScreen: {
      title: 'Kecamatan',

      labelNo: 'No',
      labelName: 'Nama',
      labelCity: 'Kota',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelStatus: 'Status',

      buttonCaptionCreateNewDistrict: 'Buat Kecamatan Baru',
      buttonCaptionEditDistrict: 'Ubah Kecamatan',
      buttonCaptionDeleteDistrict: 'Hapus Kecamatan',

      placeholderDistrictName: 'Nama Kecamatan',
      placeholderCityName: 'Nama Kota',
      placeholderDistrictCode: 'Kode Kecamatan',
    },
    subdistrictScreen: {
      title: 'Kelurahan',

      labelNo: 'No',
      labelName: 'Nama',
      labelDistrict: 'Kecamatan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelLastModifiedDate: 'Tanggal Perubahan Terakhir',
      labelStatus: 'Status',

      buttonCaptionCreateNewSubdistrict: 'Buat Kelurahan Baru',
      buttonCaptionEditSubdistrict: 'Ubah Kelurahan',
      buttonCaptionDeleteSubdistrict: 'Hapus Kelurahan',

      placeholderSubdistrictName: 'Nama Kelurahan',
      placeholderDistrictName: 'Nama Kecamatan',
      placeholderSubdistrictCode: 'Kode Kelurahan',
    },
    registrationAttemptScreen: {
      title: 'Percobaan Pendaftaran',

      labelNo: 'No',
      labelUserName: 'Nama User',
      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelRegisterUsing: 'Mendaftar Menggunakan',
      labelVerified: 'Diverifikasi',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Dibuat',
      labelValidUntil: 'Berlaku Sampai',
      labelReferralCode: 'Kode Referral',
      labelDeviceID: 'ID Perangkat',

      placeholderUserName: 'Nama User',
      placeholderRegisterUsing: 'Mendaftar Menggunakan',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Telepon',
      placeholderResendPINCount: 'Jumlah Kirim Ulang PIN',
      placeholderReferralCode: 'Kode Referral',
      placeholderInvalidPINCount: 'Jumlah PIN tidak valid',
      placeholderDeviceID: 'ID Perangkat',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderDevice: 'Perangkat',
      placeholderVerified: 'Diverifikasi',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Diverifikasi Pada',
      placeholderAppVersion: 'Versi App',
      placeholderLanguage: 'Bahasa',
    },
    passwordResetAttemptScreen: {
      title: 'Percobaan Atur Ulang Kata Sandi',

      labelEmail: 'Email',
      labelPhone: 'Telepon',
      labelPIN: 'PIN',
      labelResetUsing: 'Atur Ulang Menggunakan',
      labelDone: 'Selesai',
      labelDevice: 'Perangkat',
      labelOS: 'OS',
      labelAppVersion: 'Versi App',
      labelCreatedDate: 'Tanggal Dibuat',
      labelValidUntil: 'Berlaku Sampai',
      labelDeviceID: 'ID Perangkat',

      placeholderResetUsing: 'Atur Ulang Menggunakan',
      placeholderResendPINCount: 'Jumlah Kirim Ulang PIN',
      placeholderInvalidPINCount: 'Jumlah PIN tidak valid',
      placeholderEmail: 'Email',
      placeholderPIN: 'PIN',
      placeholderPhone: 'Telepon',
      placeholderDeviceID: 'ID Perangkat',
      placeholderValidUntil: 'Berlaku Sampai',
      placeholderDevice: 'Perangkat',
      placeholderVerified: 'Diverifikasi',
      placeholderOS: 'OS',
      placeholderVerifiedOn: 'Diverifikasi Pada',
      placeholderAppVersion: 'Versi App',
      placeholderDone: 'Selesai',
      placeholderLanguage: 'Bahasa',
    },
    workingShiftPage: {
      title: 'Shift Kerja',

      labelNo: 'No.',
      labelName: 'Nama',
      labelStartWorkingHour: 'Jam Mulai Kerja',
      labelEndWorkingHour: 'Jam Selesai Kerja',
      labelBranchName: 'Nama Cabang',
      labelCreatedDate: 'Tanggal Dibuat',
      labelLastModifiedDate: 'Tanggal Terakhir Diubah',
      labelBranch: 'Cabang',
      labelStatus: 'Status',

      placeholderWorkingShiftName: 'Nama',
      placeholderBranchName: 'Nama Cabang',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',

      buttonCaptionCreateNewWorkingShift: 'Create New Working Shift',
      buttonCaptionEditWorkingShift: 'Edit Working Shift',
      buttonCaptionDuplicateWorkingShift: 'Duplikasi Shift Kerja',
    },
    notification: {
      labelNotificationPromptTitle: 'Nyalakan notifikasi',
      labelNotificationPromptMessage: 'Izinkan situs ini untuk mengakses notifikasi',

      buttonCaptionAllow: 'Izinkan',

      errorMessageRetrievingToken: 'Terjadi kesalahan ketika mendapatkan token.',
      errorMessagePermissionDenied: 'Anda harus mengizinkan notifikasi untuk mendapatkan notifikasi',
    },
  },
});

export default LocalizedString;
