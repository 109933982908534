import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, makeStyles, Grid, Paper,
} from '@material-ui/core';
import {
  AccountMultiple, ClockIn, ClockOut,
} from 'mdi-material-ui';
import {
  BasePage, TotalCountSection, PieChart, AttendanceTrendChart,
} from '../../component';
import LocalizedString from '../../localization';
import {
  COLOR_PRIMARY, CLOCK_IN_OUT_STATUS_TREND_BARS, CLOCK_IN_OUT_LOCATION_TREND_BARS,
} from '../../constant';
import {
  SummaryCountShape, ClockInOutStatusTrendShape, ClockInOutLocationTrendShape,
} from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_PRIMARY,
  },
  chartContainer: {
    padding: 24,
    margin: '30px 0px 30px 0px',
    justifyContent: 'space-between',
  },
}));

const renderPieChartField = (data) => data.map((row) => (
  <Grid container alignItems="stretch" key={row.id}>
    {row.children.map((item) => (
      item.title ? (
        <Grid item xs={12} md key={item.id}>
          <PieChart
            data={item.data}
            loading={item.downloading}
            argumentField="name"
            title={item.title}
            valueField="count"
          />
        </Grid>
      ) : (
        <Grid item xs={12} md key={item.id} />
      )
    ))}
  </Grid>
));

const renderTrendChart = (
  data, downloading, onTooltipTargetItemChange, targetItem, title,
  chartBars,
) => (
  <AttendanceTrendChart
    data={data}
    downloading={downloading}
    title={title}
    targetItem={targetItem}
    onTooltipTargetItemChange={onTooltipTargetItemChange}
    chartBars={chartBars}
  />
);

const AttendanceSummaryPage = ({
  downloadingTotalUsers, totalUsers, onAppear,
  downloadingTotalClockInToday, totalClockInToday,
  downloadingTotalClockOutToday, totalClockOutToday,
  downloadingTodayClockInStatus, todayClockInStatus,
  downloadingTodayClockOutStatus, todayClockOutStatus,
  downloadingTodayClockInLocationStatus, todayClockInLocationStatus,
  downloadingTodayClockOutLocationStatus, todayClockOutLocationStatus,
  downloadingClockInStatusTrend, clockInStatusTrend,
  downloadingClockOutStatusTrend, clockOutStatusTrend,
  downloadingClockInLocationTrend, clockInLocationTrend,
  downloadingClockOutLocationTrend, clockOutLocationTrend,
  onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
  onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
  onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
  onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);

  const dataChart = [
    {
      id: '1a',
      children: [
        {
          id: '1aa',
          downloading: downloadingTodayClockInStatus,
          data: todayClockInStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInStatus,
        },
        {
          id: '1ab',
          downloading: downloadingTodayClockInLocationStatus,
          data: todayClockInLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockInLocationStatus,
        },
      ],
    },
    {
      id: '2a',
      children: [
        {
          id: '2aa',
          downloading: downloadingTodayClockOutStatus,
          data: todayClockOutStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutStatus,
        },
        {
          id: '2ab',
          downloading: downloadingTodayClockOutLocationStatus,
          data: todayClockOutLocationStatus,
          title: LocalizedString.attendanceSummaryScreen.labelTodayClockOutLocationStatus,
        },
      ],
    },
  ];

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {LocalizedString.attendanceSummaryScreen.title}
        </Typography>
      </div>

      <div className={classes.bodyContainer}>
        <TotalCountSection
          data={[
            {
              downloading: downloadingTotalUsers,
              count: totalUsers,
              icon: <AccountMultiple />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalUser,
            },
            {
              downloading: downloadingTotalClockInToday,
              count: totalClockInToday,
              icon: <ClockIn />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockInToday,
            },
            {
              downloading: downloadingTotalClockOutToday,
              count: totalClockOutToday,
              icon: <ClockOut />,
              label: LocalizedString.attendanceSummaryScreen.labelTotalClockOutToday,
            },
          ]}
        />

        <Paper elevation={3} className={classes.chartContainer}>
          {renderPieChartField(dataChart)}
          {renderTrendChart(
            clockInStatusTrend, downloadingClockInStatusTrend,
            onClockInStatusTrendTargetItemChange, clockInStatusTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockInStatusTrend,
            CLOCK_IN_OUT_STATUS_TREND_BARS,
          )}
          {renderTrendChart(
            clockOutStatusTrend, downloadingClockOutStatusTrend,
            onClockOutStatusTrendTargetItemChange, clockOutStatusTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockOutStatusTrend,
            CLOCK_IN_OUT_STATUS_TREND_BARS,
          )}
          {renderTrendChart(
            clockInLocationTrend, downloadingClockInLocationTrend,
            onClockInLocationTrendTargetItemChange, clockInLocationTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockInLocationTrend,
            CLOCK_IN_OUT_LOCATION_TREND_BARS,
          )}
          {renderTrendChart(
            clockOutLocationTrend, downloadingClockOutLocationTrend,
            onClockOutLocationTrendTargetItemChange, clockOutLocationTrendTargetItem,
            LocalizedString.attendanceSummaryScreen.labelClockOutLocationTrend,
            CLOCK_IN_OUT_LOCATION_TREND_BARS,
          )}
        </Paper>
      </div>
    </BasePage>
  );
};

export default AttendanceSummaryPage;

AttendanceSummaryPage.propTypes = {
  downloadingTotalUsers: PropTypes.bool,
  downloadingTotalClockInToday: PropTypes.bool,
  downloadingTotalClockOutToday: PropTypes.bool,
  downloadingTodayClockInStatus: PropTypes.bool,
  downloadingTodayClockOutStatus: PropTypes.bool,
  downloadingTodayClockInLocationStatus: PropTypes.bool,
  downloadingTodayClockOutLocationStatus: PropTypes.bool,
  downloadingClockInStatusTrend: PropTypes.bool,
  downloadingClockOutStatusTrend: PropTypes.bool,
  downloadingClockInLocationTrend: PropTypes.bool,
  downloadingClockOutLocationTrend: PropTypes.bool,

  clockInStatusTrendTargetItem: PropTypes.object,
  clockOutStatusTrendTargetItem: PropTypes.object,
  clockInLocationTrendTargetItem: PropTypes.object,
  clockOutLocationTrendTargetItem: PropTypes.object,

  todayClockInLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutLocationStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockOutStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  todayClockInStatus: PropTypes.arrayOf(SummaryCountShape).isRequired,
  totalClockOutToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalClockInToday: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  totalUsers: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  clockInStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockOutStatusTrend: PropTypes.arrayOf(ClockInOutStatusTrendShape).isRequired,
  clockInLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,
  clockOutLocationTrend: PropTypes.arrayOf(ClockInOutLocationTrendShape).isRequired,

  onAppear: PropTypes.func.isRequired,
  onClockInStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutStatusTrendTargetItemChange: PropTypes.func.isRequired,
  onClockInLocationTrendTargetItemChange: PropTypes.func.isRequired,
  onClockOutLocationTrendTargetItemChange: PropTypes.func.isRequired,
};

AttendanceSummaryPage.defaultProps = {
  downloadingTotalUsers: false,
  downloadingTotalClockInToday: false,
  downloadingTotalClockOutToday: false,
  downloadingTodayClockInStatus: false,
  downloadingTodayClockOutStatus: false,
  downloadingTodayClockInLocationStatus: false,
  downloadingTodayClockOutLocationStatus: false,
  downloadingClockInStatusTrend: false,
  downloadingClockOutStatusTrend: false,
  downloadingClockInLocationTrend: false,
  downloadingClockOutLocationTrend: false,
  clockInStatusTrendTargetItem: null,
  clockOutStatusTrendTargetItem: null,
  clockInLocationTrendTargetItem: null,
  clockOutLocationTrendTargetItem: null,
};
