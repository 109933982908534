import { addAsset, downloadingDeletingAsset } from '../simple-action';
import { downloadAsset } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingAsset(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAsset;

    const result = await downloadAsset(tappedId, token);
    dispatch(addAsset(result));
  } finally {
    dispatch(downloadingDeletingAsset(false));
  }
};
