import { addApproval, downloadingApprovalDetail } from '../simple-action';
import { downloadApproval } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingApprovalDetail(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiApproval;

    const result = await downloadApproval(tappedId, token);
    dispatch(addApproval(result));
  } finally {
    dispatch(downloadingApprovalDetail(false));
  }
};
