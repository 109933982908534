import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_ASSET_DOCUMENT_FILE, RXFIELD_CERTIFICATION_REQUEST_OWNER,
  RXFIELD_CERTIFICATION_REQUEST_CATEGORY, RXFIELD_CERTIFICATION_REQUEST_PIC,
  RXFIELD_CERTIFICATION_REQUEST_TYPE, RXFIELD_CERTIFICATION_TYPE_NAME,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_ISSUE_DATE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_EXPIRED_DATE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_REMARK,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE,
  RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE,
} from './constant';

export const rxformValidateAssetDocumentTab = (values) => {
  const requiredFields = [
    RXFIELD_ASSET_DOCUMENT_FILE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCertificationType = (values) => {
  const requiredFields = [
    RXFIELD_CERTIFICATION_TYPE_NAME,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateCertificationRequest = (values) => {
  const requiredFields = [
    RXFIELD_CERTIFICATION_REQUEST_OWNER,
    RXFIELD_CERTIFICATION_REQUEST_PIC,
    RXFIELD_CERTIFICATION_REQUEST_CATEGORY,
    RXFIELD_CERTIFICATION_REQUEST_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateSetSchedule = (values) => {
  const requiredFields = [
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateAssetCertificate = (values) => {
  const requiredFields = [
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_ISSUE_DATE,
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_EXPIRED_DATE,
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE,
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_REMARK,
    RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateAssetScreening = (values) => {
  const requiredFields = [
    RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};
