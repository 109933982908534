import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearCities, clearProvinces, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setCitySelectedPageSize, setCitySelectedProvinceId, setCitySelectedOrderBy, setCityTappedId,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  addEditCityAsync, downloadCitiesAsync, downloadProvincesAsync, enableDisableCityAsync,
  resyncCityAsync, saveCitiesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_CITIES, PAGE_MODE_TABLE, RXFORM_CITY, RXFIELD_CITY_PROVINCE,
  MENUID_MASTERDATA_CITY,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { getPermission, transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import CityPage from './city.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const getInitialValues = (state) => {
  const { cities, uiCity, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiCity;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? cities.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      { province: { label: found.province.name, value: found.province.id } })
    : {
      name: '',
      province: { label: '', value: '' },
      code: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  provinces: transformDropdownData(state.provinces.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiCity.addingEditing,
  downloading: state.uiCity.downloading,
  loadingProvince: state.uiProvince.downloading,
  enablingDisabling: state.uiCity.enablingDisabling,
  resyncing: state.uiCity.resyncing,
  currentCityStatus: state.cities.data[state.uiFunctionalPage.tappedId]
    ? state.cities.data[state.uiFunctionalPage.tappedId].status : '',
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_CITY));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(setCitySelectedPageSize(20));
    dispatch(setCitySelectedOrderBy(INITIAL_ORDER_BY_CITIES));
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/province/, 'province.id').replace(/timeZone/, 'province.timeZone');
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CITY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCitiesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCitySelectedPageSize(pageSize));
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeProvinceText: async (text, mode) => {
    try {
      dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(
        mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled',
      ));
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncCityAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableCityAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setCityTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setCityTappedId(''));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveCitiesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCityTappedId(id));
  },
  onProvinceOptionSelected: (option) => {
    if (option) {
      dispatch(setCitySelectedProvinceId(option.value));
      dispatch(change(RXFORM_CITY, RXFIELD_CITY_PROVINCE, option));
    } else {
      dispatch(setCitySelectedProvinceId(''));
      dispatch(change(RXFORM_CITY, RXFIELD_CITY_PROVINCE, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setCitySelectedPageSize(pageSize));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearCities());
    dispatch(setCitySelectedOrderBy(INITIAL_ORDER_BY_CITIES));
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, code }) => {
    try {
      await dispatch(addEditCityAsync(name, code));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCitySelectedOrderBy(orderBy));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, code }) => {
    await dispatch(addEditCityAsync(name, code));
  },
  onViewPressed: (id) => {
    dispatch(setCityTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CityPage);
