import {
  previewingPDFTemplate,
  setAnnotationDesigns,
} from '../simple-action';
import { editAnnotation, submitAnnotation } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(previewingPDFTemplate(true));

    const { token } = getState().authentication;
    const { data } = getState().annotationDesigners;
    const { templatePdf, pdfData } = getState().uiUploadPdf;

    const bodyAdd = ({
      name: pdfData.name,
      description: pdfData.description,
      pdfPath: templatePdf,
      annotations: data.annotations,
    });

    const bodyEdit = ({
      id: data.id,
      name: pdfData.name,
      description: pdfData.description,
      pdfPath: templatePdf,
      annotations: data.annotations,
    });

    const processAnnotation = async () => {
      if (data.id === '') {
        const responseAdd = await submitAnnotation(
          bodyAdd,
          token,
        );
        return responseAdd;
      }

      const responseEdit = await editAnnotation(
        bodyEdit,
        token,
      );
      return responseEdit;
    };
    const response = await processAnnotation();

    dispatch(setAnnotationDesigns(response));
  } finally {
    dispatch(previewingPDFTemplate(false));
  }
};
