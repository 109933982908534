import { connect } from 'react-redux';
import {
  setAnnotationDesignJsonVisibility,
} from '../../redux/action';
import CodeEditorDialog from './code-editor-dialog.presentation';
import { PDF_TYPE_IMAGE } from '../../constant';

const generateCode = (state) => {
  const arrData = state.annotationDesigners.data.annotations;

  const normalData = arrData.filter((x) => x.type !== PDF_TYPE_IMAGE);
  const imageData = arrData.filter((x) => x.type === PDF_TYPE_IMAGE);

  const transformImageData = imageData.map((x) => ({
    ...x, value: '[base64]',
  }));

  const newData = [...normalData, ...transformImageData];

  const transformedNewData = newData.map(({ id, image, ...item }) => ({ ...item }));

  return ({
    name: state.uiUploadPdf.pdfData.name,
    description: state.uiUploadPdf.pdfData.description,
    pdfFile: '[base64]',
    annotations: transformedNewData,
  });
};

const mapStateToProps = (state) => ({
  code: JSON.stringify(generateCode(state), null, '\t'),
  visibility: state.uiAnnotationDesigner.jsonVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onClosePressed: () => {
    dispatch(setAnnotationDesignJsonVisibility(false));
  },
  onCopyPressed: (code) => {
    navigator.clipboard.writeText(code);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeEditorDialog);
