import {
  addCertificationType,
  clearCertificationTypes,
  downloadingDeletingCertificationType,
  setCertificationTypeTappedId,
  setFunctionalPageMode,
  setAlertErrorMessage,
} from '../simple-action';
import { deleteCertificationType, downloadCertificationType } from '../../../helper';
import { PAGE_MODE_TABLE } from '../../../constant';
import downloadCertificationTypesAsync from './downloadCertificationTypesAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCertificationType(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCertificationType;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteCertificationType(tappedId, token);
      dispatch(setCertificationTypeTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearCertificationTypes());
      dispatch(downloadCertificationTypesAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const CertificationType = await downloadCertificationType(tappedId, token);
      dispatch(addCertificationType(CertificationType));
    }
  } finally {
    dispatch(downloadingDeletingCertificationType(false));
  }
};
