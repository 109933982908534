import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_ROLE_DESCRIPTION, RXFIELD_ROLE_NAME, RXFIELD_ROLE_PERMISSION,
  RXFORM_ROLE, RXSTATE_ROLES, RXSTATE_ROLE_PAGE,
} from '../../constant';
import { renderReduxFormOutlinedTextField, renderReduxFormTransferListField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateRole } from '../../validation';

const renderDialogContent = (permissions, addingEditing, loadingPermission, onPermissionSelected,
  pageMode, errorMessageRequired) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_ROLE_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.roleScreen.placeholderRoleName}
            label={LocalizedString.roleScreen.placeholderRoleName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ROLE_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.roleScreen.placeholderDescription}
            label={LocalizedString.roleScreen.placeholderDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            multiline
          />
        </Grid>
      </Grid>

      <Field
        name={RXFIELD_ROLE_PERMISSION}
        component={renderReduxFormTransferListField}
        label={LocalizedString.roleScreen.placeholderPermissionName}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        data={permissions}
        loading={loadingPermission}
        onOptionSelected={onPermissionSelected}
        required
        helperText={errorMessageRequired}
      />
    </div>
);

const RolePage = ({
  permissions,
  addingEditing, downloading, loadingPermission,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize, onConfirmDeletePressed,
  onCreatePressed, onDeletePressed, onEditPressed, onPermissionSelected, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, errorMessageRequired,
}) => (
  <FunctionalPage
    data={RXSTATE_ROLES}
    uiPage={RXSTATE_ROLE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.roleScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.roleScreen.labelName, field: 'name', sorting: !downloading },
      {
        title: LocalizedString.roleScreen.labelPermission,
        field: 'permissions',
        render: ({ permissions: permission }) => (permission.length),
        searchable: false,
        sorting: false,
      },
      { title: LocalizedString.roleScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.roleScreen.buttonCaptionCreateNewRole}
    deleteButtonCaption={LocalizedString.roleScreen.buttonCaptionDeleteRole}
    editButtonCaption={LocalizedString.roleScreen.buttonCaptionEditRole}
    title={LocalizedString.roleScreen.title}
    useFullWidth
    usefullWidthDialog
    createPermissionName="AUTH_CREATE_ROLE"
    deletePermissionName="AUTH_DELETE_ROLE"
    editPermissionName="AUTH_EDIT_ROLE"
  >
    {renderDialogContent(permissions, addingEditing, loadingPermission, onPermissionSelected,
      pageMode, errorMessageRequired)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_ROLE,
  validate: rxformValidateRole,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RolePage);

RolePage.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingPermission: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onPermissionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  errorMessageRequired: PropTypes.string.isRequired,
};
