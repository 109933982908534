import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { debounceSearch, transformDropdownData, transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import RoomPage from './room.presentation';
import {
  ADVANCED_FILTER_MODE,
  INITIAL_ORDER_BY_ROOMS, MENUID_EVENT_ROOM, PAGE_MODE_TABLE, RXFIELD_ROOM_LOCATION,
  RXFORM_ROOM,
} from '../../constant';
import {
  addEditRoomAsync,
  clearLocations, clearRooms, downloadDeleteRoomAsync, downloadLocationsAsync,
  downloadRoomsAsync, enableDisableRoomAsync, saveRoomsAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setLocationAdvancedFilterDialogSelectedFilterString, setLocationSearchText,
  setRoomAdvancedFilterDialogSelectedFilterString, setRoomSearchText, setRoomSelectedLocationId,
  setRoomSelectedOrderBy, setRoomSelectedPageSize, setRoomTappedId,
} from '../../redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const { rooms, uiRoom, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiRoom;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? rooms.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        locationId: found.location.id,
        location: { label: found.location?.name || '', value: found.location?.id || '' },
        locationName: found.location.name,
      })
    : {
      name: '',
      description: '',
      locationId: '',
      location: { label: '', value: '' },
      locationName: '',
    };
  return initVal;
};

const searchLocationDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  addingEditing: state.uiRoom.addingEditing,
  currentRoomStatus: state.rooms.data[state.uiFunctionalPage.tappedId]
    ? state.rooms.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiRoom.downloading,
  enablingDisabling: state.uiRoom.enablingDisabling,
  locations: transformDropdownData(state.locations.data),
  loadingLocation: state.uiLocation.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setRoomAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_ROOM));
    dispatch(setRoomSearchText(''));
    dispatch(clearRooms());
    dispatch(setRoomSelectedPageSize(20));
    dispatch(setRoomSelectedOrderBy(INITIAL_ORDER_BY_ROOMS));
    dispatch(downloadRoomsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/location/, 'location.id');
    dispatch(setRoomAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setRoomSelectedLocationId(''));
    dispatch(reset(RXFORM_ROOM));
  },
  onChangeLocationText: async (text, mode) => {
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
    dispatch(setLocationSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchLocationDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadRoomsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setRoomSelectedPageSize(pageSize));
    dispatch(downloadRoomsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteRoomAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableRoomAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setRoomTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setRoomTappedId(''));
    dispatch(setRoomSelectedLocationId(''));
    dispatch(setLocationAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setRoomTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDownloadPressed: () => {
    dispatch(saveRoomsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setRoomTappedId(id));
    dispatch(downloadDeleteRoomAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setLocationAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setLocationSearchText(''));
    dispatch(clearLocations());
    dispatch(downloadLocationsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onLocationOptionSelected: (option) => {
    if (option) {
      dispatch(setRoomSelectedLocationId(option.value));
      dispatch(change(RXFORM_ROOM, RXFIELD_ROOM_LOCATION, option));
    } else {
      dispatch(setRoomSelectedLocationId(''));
      dispatch(change(RXFORM_ROOM, RXFIELD_ROOM_LOCATION, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setRoomSelectedPageSize(pageSize));
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setRoomAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearRooms());
    dispatch(setRoomSelectedOrderBy(INITIAL_ORDER_BY_ROOMS));
    dispatch(downloadRoomsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditRoomAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setRoomSearchText(text));
      dispatch(clearRooms());
      await dispatch(downloadRoomsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setRoomSelectedOrderBy(orderBy));
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditRoomAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setRoomTappedId(id));
    dispatch(downloadDeleteRoomAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomPage);
