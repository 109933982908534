import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearCities, clearDistricts, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setCityAdvancedFilterDialogSelectedFilterString, setCitySearchText,
  setDistrictAdvancedFilterDialogSelectedFilterString, setDistrictSearchText,
  setDistrictSelectedCityId, setDistrictSelectedOrderBy, setDistrictSelectedPageSize,
  setDistrictTappedId,
  addEditDistrictAsync, downloadCitiesAsync, downloadDistrictsAsync, enableDisableDistrictAsync,
  resyncDistrictAsync, saveDistrictsAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_DISTRICTS, PAGE_MODE_TABLE, RXFORM_DISTRICT, RXFIELD_DISTRICT_CITY,
  MENUID_MASTERDATA_DISTRICT, ADVANCED_FILTER_MODE,
} from '../../constant';
import { getPermission, transformDropdownData, transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import DistrictPage from './district.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const getInitialValues = (state) => {
  const { districts, uiDistrict, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiDistrict;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? districts.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      { city: { label: found.city.name, value: found.city.id } })
    : {
      name: '',
      city: { label: '', value: '' },
      code: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  cities: transformDropdownData(state.cities.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiDistrict.addingEditing,
  downloading: state.uiDistrict.downloading,
  loadingCity: state.uiCity.downloading,
  enablingDisabling: state.uiDistrict.enablingDisabling,
  resyncing: state.uiDistrict.resyncing,
  currentDistrictStatus: state.districts.data[state.uiFunctionalPage.tappedId]
    ? state.districts.data[state.uiFunctionalPage.tappedId].status : '',
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_DISTRICT));
    dispatch(setDistrictSearchText(''));
    dispatch(clearDistricts());
    dispatch(setDistrictSelectedPageSize(20));
    dispatch(setDistrictSelectedOrderBy(INITIAL_ORDER_BY_DISTRICTS));
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/city/, 'city.id').replace(/timeZone/, 'city.timeZone');
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_DISTRICT));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadDistrictsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setDistrictSelectedPageSize(pageSize));
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeCityText: async (text, mode) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncDistrictAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableDistrictAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setDistrictTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setDistrictTappedId(''));
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveDistrictsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setDistrictTappedId(id));
  },
  onCityOptionSelected: (option) => {
    if (option) {
      dispatch(setDistrictSelectedCityId(option.value));
      dispatch(change(RXFORM_DISTRICT, RXFIELD_DISTRICT_CITY, option));
    } else {
      dispatch(setDistrictSelectedCityId(''));
      dispatch(change(RXFORM_DISTRICT, RXFIELD_DISTRICT_CITY, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setDistrictSelectedPageSize(pageSize));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setDistrictAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearDistricts());
    dispatch(setDistrictSelectedOrderBy(INITIAL_ORDER_BY_DISTRICTS));
    dispatch(downloadDistrictsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, code }) => {
    try {
      await dispatch(addEditDistrictAsync(name, code));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setDistrictSearchText(text));
      dispatch(clearDistricts());
      await dispatch(downloadDistrictsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setDistrictSelectedOrderBy(orderBy));
    dispatch(clearDistricts());
    dispatch(downloadDistrictsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, code }) => {
    await dispatch(addEditDistrictAsync(name, code));
  },
  onViewPressed: (id) => {
    dispatch(setDistrictTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DistrictPage);
