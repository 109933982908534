/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import {
  CERT_CATEGORIES, CERT_REQUEST_DIALOG_MODE_ADD_ASSET, COLOR_SECONDARY, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME, REVERSED_ISO_DATE_FORMAT, ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL,
  RXFIELD_ASSET_ASSET_DESCRIPTION, RXFIELD_ASSET_ASSET_NUMBER, RXFIELD_ASSET_ASSET_TYPE,
  RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE, RXFIELD_ASSET_LOCATION, RXFIELD_ASSET_PART_CODE,
  RXFIELD_ASSET_SERIAL_NUMBER, RXFIELD_ASSET_SOURCE_ID, RXFIELD_CERTIFICATION_REQUEST_ASSET,
  RXFIELD_CERTIFICATION_REQUEST_CATEGORY, RXFIELD_CERTIFICATION_REQUEST_OWNER,
  RXFIELD_CERTIFICATION_REQUEST_PIC, RXFIELD_CERTIFICATION_REQUEST_TYPE,
  RXFORM_CERTIFICATION_REQUEST,
} from '../../../constant';
import {
  AccentButton, EditableTableField, StyledDialog, VerticalSpacer,
} from '../../../component';
import { toMoment } from '../../../helper';
import LocalizedString from '../../../localization';
import GlobalLocalizedString from '../../../../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
} from '../../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../type';
import { rxformValidateCertificationRequest } from '../../../validation';
import SelectFileDialog from '../select-file-dialog';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
    borderLeft: `1px solid ${COLOR_SECONDARY}`,
    borderRight: `1px solid ${COLOR_SECONDARY}`,
    padding: 8,
  },
}));

const CreateCertificationRequestPage = ({
  initialValues, assets, assetTypes, certificationTypes, locations, owners,
  addingEditing, dialogVisibility, downloadingAssets, loadingAssetTypes, loadingCertificationTypes,
  loadingLocations, loadingOwners,
  handleSubmit, onAddAssetPressed, onAddPressed, onAppear, onApplyAssetAdvancedFilterPressed,
  onAssetAdvancedFilterPressed, onAssetSearchBarTextChanged, onAssetSortPressed, onCancelPressed,
  onCategoryOptionSelected, onCertTypeOptionSelected, onChangeAssetPage, onChangeAssetPageSize,
  onChangeAssetTypeText, onChangeCertTypeText, onChangeLocationText, onChangeOwnerText,
  onDownloadDocumentPressed, onOwnerOptionSelected, onResetAssetAdvancedFilterPressed,
  onSubmitPressed,
  assetCurrentPage, assetSelectedPageSize, assetTotalCount,
  assetFilterString, assetOrderBy, assetSearchBarText, dialogMode,
}) => {
  const [filteredAssets, setFilteredAssets] = useState(assets);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [tappedAssetId, setTappedAssetId] = useState();

  const classes = useStyles();

  const isDetailRoute = useRouteMatch(ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL);
  const detailIdParam = isDetailRoute?.params?.id;

  useEffect(() => {
    if (assets.length) {
      const currAssetIds = selectedAssets.map((x) => x.id);
      const newAssetData = assets.filter((x) => !currAssetIds.includes(x.id));
      setFilteredAssets(newAssetData);
    } else {
      setFilteredAssets([]);
    }
    // eslint-disable-next-line
  }, [assets]);

  useEffect(() => onAppear(detailIdParam), [onAppear, detailIdParam]);

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_OWNER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelAssetOwner}
              disabled={addingEditing}
              data={owners}
              value={initialValues[RXFIELD_CERTIFICATION_REQUEST_OWNER]}
              loading={loadingOwners}
              onChangeText={onChangeOwnerText}
              onOptionSelected={onOwnerOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_PIC}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelPic}
              disabled
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_CATEGORY}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelCategory}
              disabled={addingEditing}
              data={CERT_CATEGORIES}
              value={initialValues[RXFIELD_CERTIFICATION_REQUEST_CATEGORY]}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_TYPE}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelCertificationType}
              disabled={addingEditing}
              data={certificationTypes}
              value={initialValues[RXFIELD_CERTIFICATION_REQUEST_TYPE]}
              loading={loadingCertificationTypes}
              onChangeText={onChangeCertTypeText}
              onOptionSelected={onCertTypeOptionSelected}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Paper className={classes.contentContainer}>
        <div className={classes.rowContainer}>
          <AccentButton
            onClick={onAddAssetPressed}
            variant="outlined"
            caption={LocalizedString.certificationRequestPage.buttonCaptionAddAsset}
            disabled={addingEditing}
            endIcon={<Add />}
          />
          <div style={{ flex: 1 }} />
        </div>

        <VerticalSpacer />

        <EditableTableField
          data={selectedAssets}
          moreMenuList={[
            {
              caption: GlobalLocalizedString.common.buttonCaptionDelete,
              disabled: false,
              onPress: () => {
                const newData = selectedAssets.filter((x) => x.id !== tappedAssetId);
                setSelectedAssets(newData);
              },
            },
            {
              caption: LocalizedString.certificationRequestPage.buttonCaptionDownloadDocument,
              disabled: false,
              onPress: () => onDownloadDocumentPressed(tappedAssetId),
            },
          ]}
          tableColumns={[
            {
              title: LocalizedString.certificationRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
            },
            { title: LocalizedString.certificationRequestPage.labelId, field: 'sourceId' },
            { title: LocalizedString.certificationRequestPage.labelAssetDescription, field: 'assetDescription' },
            { title: LocalizedString.certificationRequestPage.labelPartCode, field: 'partCode' },
            { title: LocalizedString.certificationRequestPage.labelSerialNumber, field: 'serialNumber' },
            { title: LocalizedString.certificationRequestPage.labelAssetNumber, field: 'assetNumber' },
            { title: LocalizedString.certificationRequestPage.labelAssetClass, field: 'category.name' },
            { title: LocalizedString.certificationRequestPage.labelLocation, field: 'location.name' },
            {
              title: LocalizedString.certificationRequestPage.labelExpired,
              field: 'certificateExpirationDate',
              sorting: !downloadingAssets,
              render: ({ certificateExpirationDate }) => (certificateExpirationDate
                ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
            },
          ]}
          disableActions={false}
          disabled
          disableToolbar
          onMorePressed={(id) => setTappedAssetId(id)}
        />

        <VerticalSpacer />

        <div className={classes.rowContainer}>
          <div style={{ flex: 1 }} />
          <AccentButton
            onClick={handleSubmit(() => onSubmitPressed(selectedAssets))}
            variant="contained"
            caption={GlobalLocalizedString.common.buttonCaptionSubmit}
            loading={addingEditing}
            disabled={!selectedAssets.length}
          />
        </div>
      </Paper>

      <StyledDialog
        disableSubmitButton={!selectedRows.length || downloadingAssets}
        visibility={dialogVisibility && dialogMode === CERT_REQUEST_DIALOG_MODE_ADD_ASSET}
        onCancelPressed={onCancelPressed}
        onSubmitPressed={() => {
          onAddPressed(selectedRows);
          const currDataIds = selectedAssets.map((x) => x.id);
          const newData = selectedRows.filter((x) => !currDataIds.includes(x.id));
          setSelectedAssets([...selectedAssets, ...newData]);
        }}
        title={LocalizedString.certificationRequestPage.titleSelectAsset}
        submitButtonCaption={GlobalLocalizedString.common.buttonCaptionAdd}
      >
        <Field
          name={RXFIELD_CERTIFICATION_REQUEST_ASSET}
          component={renderReduxFormEditableTableField}
          data={filteredAssets}
          loading={downloadingAssets}
          selection
          tableColumns={[
            { title: LocalizedString.certificationRequestPage.labelId, field: 'sourceId', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelAssetDescription, field: 'assetDescription', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelPartCode, field: 'partCode', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelSerialNumber, field: 'serialNumber', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelAssetNumber, field: 'assetNumber', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelAssetClass, field: 'category.name', sorting: !downloadingAssets },
            { title: LocalizedString.certificationRequestPage.labelLocation, field: 'location.name', sorting: !downloadingAssets },
            {
              title: LocalizedString.certificationRequestPage.labelExpired,
              field: 'certificateExpirationDate',
              sorting: !downloadingAssets,
              render: ({ certificateExpirationDate }) => (certificateExpirationDate
                ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
            },
          ]}
          filterColumns={[
            {
              title: LocalizedString.certificationRequestPage.labelId,
              field: RXFIELD_ASSET_SOURCE_ID,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.certificationRequestPage.labelAssetDescription,
              field: RXFIELD_ASSET_ASSET_DESCRIPTION,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.certificationRequestPage.labelPartCode,
              field: RXFIELD_ASSET_PART_CODE,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.certificationRequestPage.labelSerialNumber,
              field: RXFIELD_ASSET_SERIAL_NUMBER,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.certificationRequestPage.labelAssetNumber,
              field: RXFIELD_ASSET_ASSET_NUMBER,
              type: FILTER_TYPE_TEXT,
            },
            {
              title: LocalizedString.certificationRequestPage.labelAssetClass,
              field: RXFIELD_ASSET_ASSET_TYPE,
              type: FILTER_TYPE_DROPDOWN,
              data: assetTypes,
              loading: loadingAssetTypes,
              onChangeFilterText: onChangeAssetTypeText,
              useDropdownValue: true,
            },
            {
              title: LocalizedString.certificationRequestPage.labelLocation,
              field: RXFIELD_ASSET_LOCATION,
              type: FILTER_TYPE_DROPDOWN,
              data: locations,
              loading: loadingLocations,
              onChangeFilterText: onChangeLocationText,
              useDropdownValue: true,
            },
            {
              title: LocalizedString.certificationRequestPage.labelExpired,
              field: RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
              type: FILTER_TYPE_DATE_RANGE,
              pickerMode: PICKER_MODE_DATE_TIME,
              format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
            },
          ]}
          disabled
          paging
          search
          AdvancedFilterPressed={onAssetAdvancedFilterPressed}
          onApplyAdvancedFilterPressed={onApplyAssetAdvancedFilterPressed}
          onChangePage={onChangeAssetPage}
          onChangePageSize={onChangeAssetPageSize}
          onResetAdvancedFilterPressed={onResetAssetAdvancedFilterPressed}
          onSearchBarTextChanged={onAssetSearchBarTextChanged}
          onSelectionChange={setSelectedRows}
          onSortPressed={onAssetSortPressed}
          filterString={assetFilterString}
          searchBarText={assetSearchBarText}
          currentPage={assetCurrentPage}
          totalCount={assetTotalCount}
          selectedPageSize={assetSelectedPageSize}
          orderBy={assetOrderBy}
        />
      </StyledDialog>

      <SelectFileDialog />
    </div>
  );
};

export default reduxForm({
  form: RXFORM_CERTIFICATION_REQUEST,
  validate: rxformValidateCertificationRequest,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CreateCertificationRequestPage);

CreateCertificationRequestPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  assetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  certificationTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  owners: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  downloadingAssets: PropTypes.bool.isRequired,
  loadingAssetTypes: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loadingOwners: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddAssetPressed: PropTypes.func.isRequired,
  onAddPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onAssetSearchBarTextChanged: PropTypes.func.isRequired,
  onAssetSortPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onCertTypeOptionSelected: PropTypes.func.isRequired,
  onChangeAssetPage: PropTypes.func.isRequired,
  onChangeAssetPageSize: PropTypes.func.isRequired,
  onChangeAssetTypeText: PropTypes.func.isRequired,
  onChangeCertTypeText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangeOwnerText: PropTypes.func.isRequired,
  onDownloadDocumentPressed: PropTypes.func.isRequired,
  onOwnerOptionSelected: PropTypes.func.isRequired,
  onResetAssetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  assetCurrentPage: PropTypes.number.isRequired,
  assetSelectedPageSize: PropTypes.number.isRequired,
  assetTotalCount: PropTypes.number.isRequired,
  assetFilterString: PropTypes.string.isRequired,
  assetOrderBy: PropTypes.string.isRequired,
  assetSearchBarText: PropTypes.string.isRequired,
  dialogMode: PropTypes.string.isRequired,
};
