import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  AppBar, Avatar, CircularProgress, CssBaseline, Divider, Drawer, ExpansionPanel,
  ExpansionPanelSummary, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles,
  ThemeProvider, Toolbar, Tooltip, Typography,
} from '@material-ui/core';
import {
  ExpandLess, ExpandMore, Menu, Settings,
} from '@material-ui/icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TERTIARY, DRAWER_WIDTH,
} from '../../constant';
import { MenuItemShape } from '../../type';
import LocalizedString from '../../localization';
import AlertBox from '../alert-box';
import ErrorBoundary from '../error-boundary';
import SettingsDialog from '../settings-dialog';
import AccentButton from '../accent-button';
import DownloadRequestTimeoutDialog from '../download-request-timeout-dialog';
import theme from '../../style';

const APP_BAR_HEIGHT = 60;

const logo = require('../../asset/logo.png');
const logoutIcon = require('../../asset/logout.png');

const useStyles = makeStyles((defaultTheme) => ({
  container: {
    display: 'flex',
  },
  appBar: {
    transition: defaultTheme.transitions.create(['margin', 'width'], {
      easing: defaultTheme.transitions.easing.sharp,
      duration: defaultTheme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: DRAWER_WIDTH,
    transition: defaultTheme.transitions.create(['margin', 'width'], {
      easing: defaultTheme.transitions.easing.easeOut,
      duration: defaultTheme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    background: COLOR_BACKGROUND,
    display: 'flex',
    justifyContent: 'space-between',
    height: APP_BAR_HEIGHT,
  },
  openedToolbar: {
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginRight: defaultTheme.spacing(2),
    background: COLOR_BACKGROUND,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  hide: {
    display: 'none',
  },
  welcomeField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  activityIndicator: {
    color: COLOR_PRIMARY,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  welcomeText: {
    color: COLOR_PRIMARY,
  },
  logoutIcon: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...defaultTheme.mixins.toolbar,
    padding: 0,
    height: APP_BAR_HEIGHT,
  },
  divider: {
    backgroundColor: COLOR_SECONDARY,
  },
  list: {
    padding: 0,
  },
  expansionPanel: {
    padding: 0,
    '& .Mui-expanded': {
      margin: '0px auto',
      minHeight: '0px',
    },
  },
  expansionPanelSummary: {
    padding: '0px 8px',
    '& .MuiExpansionPanelSummary-content': {
      margin: '0px auto',
    },
  },
  activeMenu: {
    color: COLOR_PRIMARY,
  },
  drawerText: {
    fontSize: '14px',
  },
  avatarContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  square: {
    width: '60%',
    height: '25%',
  },
  content: {
    flexGrow: 1,
    backgroundColor: COLOR_TERTIARY,
    transition: defaultTheme.transitions.create('margin', {
      easing: defaultTheme.transitions.easing.sharp,
      duration: defaultTheme.transitions.duration.leavingScreen,
    }),
    marginLeft: -DRAWER_WIDTH,
  },
  contentShift: {
    transition: defaultTheme.transitions.create('margin', {
      easing: defaultTheme.transitions.easing.easeOut,
      duration: defaultTheme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
  bodyContainer: {
    padding: defaultTheme.spacing(3),
  },
  profilePictureContainer: {
    marginRight: 8,
  },
  rounded: {
    width: 31,
    height: 31,
  },
  settingsIcon: {
    color: COLOR_PRIMARY,
    fontSize: '20px',
  },
  buttonColor: {
    background: COLOR_PRIMARY,
    color: COLOR_BACKGROUND,
  },
  processDialog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: COLOR_BACKGROUND,
    height: 20,
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
    padding: defaultTheme.spacing(3),
  },
  copyrightText: {
    color: COLOR_PRIMARY,
  },
}));

const styles = {
  menuContainer: {
    padding: '4px 8px',
  },
  activeMenuContainer: {
    borderRadius: 8,
  },
  leftIcon: {
    minWidth: '10%',
    marginRight: '16px',
  },
  rightIcon: {
    minWidth: '10%',
    marginLeft: '16px',
  },
};

const onDrawerMenuPressed = (item, history, onMenuPressed, onSideMenuPressed) => {
  if (!onSideMenuPressed) {
    onMenuPressed(item, history);
  } else {
    onSideMenuPressed(item, history);
  }
};

const renderExpansionPanelSummary = (
  item, classes, onMenuPressed, history, onSideMenuPressed, isExpanded,
) => {
  const MenuIcon = item.icon;
  const MenuCustomIcon = item.customIcon;
  const hasChildren = item.children && item.children.length > 0;
  const isChild = item.level > 1;
  const isChildSelected = isChild && item.selected;
  const isParent = item.level === 1;
  const isParentSelected = (isParent && item.selected)
  || (isParent && isExpanded && hasChildren && item.children.some((x) => x.selected));

  return (
    <ExpansionPanelSummary className={classes.expansionPanelSummary} key={`${item.id}${item.label}`}>
      <ListItem
        button
        disableGutters
        key={`${item.id}${item.label}`}
        onClick={hasChildren
          ? () => {}
          : () => onDrawerMenuPressed(item, history, onMenuPressed, onSideMenuPressed)}
        style={item.selected || isParentSelected
          ? {
            ...styles.menuContainer,
            ...styles.activeMenuContainer,
            backgroundColor: isParentSelected ? COLOR_TERTIARY : COLOR_PRIMARY,
            marginLeft: isChildSelected ? 16 : 0,
            paddingLeft: isChildSelected ? 16 : 0,
            width: isChild ? DRAWER_WIDTH - 32 : DRAWER_WIDTH - 16,
          } : {
            ...styles.menuContainer,
            marginLeft: isChild ? 16 : 0,
            paddingLeft: isChild ? 16 : 0,
            width: isChild ? DRAWER_WIDTH - 32 : DRAWER_WIDTH - 16,
          }}
      >
        {(MenuIcon || MenuCustomIcon) && (
        <ListItemIcon className={item.selected ? classes.activeMenu : ''} style={styles.leftIcon}>
          {MenuIcon ? (<MenuIcon fontSize="small" className={classes.activeMenu} />) : (<img alt="icon" src={MenuCustomIcon} width={18} height={18} />)}
        </ListItemIcon>
        )}
        <ListItemText
          primary={item.label}
          style={{ color: isChildSelected ? COLOR_BACKGROUND : COLOR_PRIMARY, flex: 1, display: 'flex' }}
          classes={{ primary: classes.drawerText }}
        />
        {hasChildren && (
          <ListItemIcon style={styles.rightIcon}>
            {isParent && isExpanded ? (<ExpandLess />) : (<ExpandMore />)}
          </ListItemIcon>
        )}
      </ListItem>
    </ExpansionPanelSummary>
  );
};

const renderExpansionMenuPanel = (item, classes, onMenuPressed,
  history, expanded, onExpansionPanelChanged, expandedMenuId,
  onSideMenuPressed) => {
  const isExpanded = item.id === expandedMenuId && expanded;
  return (
    <ExpansionPanel
      expanded={item.children && item.children.length > 0
        && item.id === expandedMenuId ? expanded : false}
      onChange={item.children && item.children.length > 0
        ? () => onExpansionPanelChanged(expanded, item.id, expandedMenuId) : () => {}}
      key={`${item.id}${item.label}`}
      sx={{ fontSize: '14px' }}
      className={classes.expansionPanel}
    >
      {renderExpansionPanelSummary(item, classes, onMenuPressed, history, onSideMenuPressed,
        isExpanded)}
      {item.children && item.children.length > 0
        ? item.children.map((child) => (renderExpansionPanelSummary(child,
          classes, onMenuPressed, history, onSideMenuPressed, isExpanded)))
        : null }
    </ExpansionPanel>
  );
};

const BasePage = ({
  menuItems, downloadingMyProfile, expanded, visibility,
  onDrawerPressed, onConfirmLogoutPressed, onConfirmPressed,
  onExpansionPanelChanged, onLogoutPressed, onMenuPressed,
  expandedMenuId, children, currentUser, history, myProfile,
  onSideMenuPressed,
  onSettingsPressed, processingMultipleData,
  onCloseChangePasswordSuccess, changePasswordSuccess,
  onCloseMultipleProcessing, processingMessage, myConfigItems,
}) => {
  const classes = useStyles();
  const currentYear = moment().year();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <CssBaseline />

        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: visibility,
          })}
        >
          <Toolbar className={clsx(classes.toolbar, visibility && classes.openedToolbar)}>
            <IconButton
              aria-label="open drawer"
              onClick={() => onDrawerPressed(visibility)}
              edge="start"
              className={clsx(classes.menuButton, visibility && classes.hide)}
            >
              <Menu />
            </IconButton>

            <span className={classes.welcomeField}>
              {downloadingMyProfile
                ? (<CircularProgress className={classes.activityIndicator} size={16} />)
                : (
                  <div className={classes.profilePictureContainer}>
                    <Avatar variant="rounded" className={classes.rounded} src={myProfile?.profilePicture} />
                  </div>
                )}
              <div className={classes.rowContainer}>
                <Typography variant="body2" className={classes.welcomeText}>
                  {currentUser.fullName}
                </Typography>
                <Typography variant="caption" className={classes.welcomeText}>
                  {currentUser.email}
                </Typography>
              </div>
              <Tooltip title={LocalizedString.common.labelSettings}>
                <IconButton
                  aria-label="settings"
                  onClick={() => onSettingsPressed(currentUser)}
                  edge="end"
                >
                  <Settings className={classes.settingsIcon} />
                </IconButton>
              </Tooltip>
              <SettingsDialog />
              <Tooltip title={LocalizedString.common.labelLogout}>
                <IconButton
                  aria-label="logout"
                  onClick={() => onLogoutPressed()}
                  edge="end"
                >
                  <div className={classes.avatarContainer}>
                    <img alt="logout" className={classes.logoutIcon} src={logoutIcon} />
                  </div>
                </IconButton>
              </Tooltip>
              <AlertBox
                onConfirmPressed={(message, reason) => onConfirmLogoutPressed(message, reason,
                  history, myConfigItems, onConfirmPressed)}
              />
              <DownloadRequestTimeoutDialog />
              <SweetAlert
                show={changePasswordSuccess}
                success
                title={LocalizedString.common.labelSucces}
                onConfirm={onCloseChangePasswordSuccess}
                closeOnClickOutside={false}
                customButtons={(
                  <AccentButton
                    onClick={onCloseChangePasswordSuccess}
                    variant="contained"
                    className={classes.buttonColor}
                    caption={LocalizedString.common.buttonCaptionOK}
                  />
          )}
              >
                {LocalizedString.common.labelNewPasswordSaved}
              </SweetAlert>
              <SweetAlert
                show={!!processingMessage}
                default
                title={LocalizedString.common.alertTitleProcessing}
                onConfirm={onCloseMultipleProcessing}
                closeOnClickOutside={false}
                showConfirm={!processingMultipleData}
                customButtons={!processingMultipleData && (
                <AccentButton
                  onClick={onCloseMultipleProcessing}
                  variant="contained"
                  className={classes.buttonColor}
                  caption={LocalizedString.common.buttonCaptionOK}
                />
                )}
              >
                <div className={classes.processDialog}>
                  {processingMessage}
                  {processingMultipleData
                && (<CircularProgress color={COLOR_PRIMARY} style={{ marginTop: 10 }} />)}
                </div>
              </SweetAlert>
            </span>
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={visibility}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <div className={classes.avatarContainer}>
              <img alt="logo" className={classes.square} src={logo} />
            </div>
          </div>
          <Divider className={classes.divider} />
          <List className={classes.list}>
            {menuItems.map((item) => {
              if (item.level === 1) {
                return renderExpansionMenuPanel(item, classes, onMenuPressed, history,
                  expanded, onExpansionPanelChanged, expandedMenuId, onSideMenuPressed);
              }
              return null;
            })}
          </List>
          <Divider className={classes.divider} />
          <div className={classes.footer} />
        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: visibility,
          })}
        >
          <div className={classes.drawerHeader} />
          <div className={classes.bodyContainer}>
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.footer}>
            <Typography variant="body2" className={classes.copyrightText}>
              {LocalizedString.common.labelCopyright.replace(/\{year\}/, currentYear)}
            </Typography>
          </div>
        </main>
      </div>
    </ThemeProvider>
  );
};

export default withRouter(BasePage);

BasePage.propTypes = {
  menuItems: PropTypes.arrayOf(MenuItemShape).isRequired,
  downloadingMyProfile: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  visibility: PropTypes.bool.isRequired,
  changePasswordSuccess: PropTypes.bool.isRequired,
  processingMultipleData: PropTypes.bool.isRequired,
  onConfirmLogoutPressed: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func,
  onDrawerPressed: PropTypes.func.isRequired,
  onExpansionPanelChanged: PropTypes.func.isRequired,
  onMenuPressed: PropTypes.func.isRequired,
  onLogoutPressed: PropTypes.func.isRequired,
  onSettingsPressed: PropTypes.func.isRequired,
  onCloseChangePasswordSuccess: PropTypes.func.isRequired,
  onCloseMultipleProcessing: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  myProfile: PropTypes.object.isRequired,
  myConfigItems: PropTypes.object.isRequired,
  processingMessage: PropTypes.string.isRequired,
  expandedMenuId: PropTypes.string.isRequired,
  onSideMenuPressed: PropTypes.func,
};

BasePage.defaultProps = {
  onConfirmPressed: () => {},
  onSideMenuPressed: null,
};
