import {
  INITIAL_ORDER_BY_APPROVAL_GROUPS,
  STATUS_DISABLED, STATUS_ENABLED,
} from '../../../constant';
import { enableDisableApprovalGroup } from '../../../helper';
import {
  setAlertErrorMessage,
  enablingDisablingApprovalGroup,
  setApprovalGroupSelectedOrderBy,
  setApprovalGroupSelectedPageSize,
  clearApprovalGroups,
} from '../simple-action';
import downloadApprovalGroupsAsync from './downloadApprovalGroupsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(enablingDisablingApprovalGroup(true));

    const { token } = getState().authentication;
    const { data } = getState().approvalGroups;
    const { tappedId } = getState().uiApprovalGroup;

    const status = data[tappedId].status === STATUS_ENABLED ? STATUS_DISABLED : STATUS_ENABLED;

    await enableDisableApprovalGroup(tappedId, status, token);

    dispatch(setApprovalGroupSelectedPageSize(20));
    dispatch(setApprovalGroupSelectedOrderBy(INITIAL_ORDER_BY_APPROVAL_GROUPS));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(enablingDisablingApprovalGroup(false));
  }
};
