import React from 'react';
import {
  Button, CircularProgress, makeStyles, ThemeProvider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY } from '../constant';
import theme from '../style';

const useStyles = makeStyles(() => ({
  outlined: {
    color: COLOR_PRIMARY,
    borderWidth: 1,
    borderRadius: 6,
    borderColor: COLOR_PRIMARY,
  },
  text: {
    color: COLOR_PRIMARY,
  },
  contained: {
    background: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    borderRadius: 6,
    fontSize: 12,
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const AccentButton = ({
  loading, variant, size, caption, style, ...props
}) => {
  const classes = useStyles();

  let component;
  switch (variant) {
    case 'outlined':
      component = (
        <Button
          variant="outlined"
          className={`${classes.outlined} ${style}`}
          size={size}
          {...props}
        >
          {caption}
        </Button>
      ); break;
    case 'text':
      component = (
        <Button
          variant="text"
          className={`${classes.text} ${style}`}
          size={size}
          {...props}
        >
          {caption}
        </Button>
      ); break;
    case 'textless':
      component = (
        <Button
          variant="outlined"
          className={`${classes.outlined} ${style}`}
          size={size}
          {...props}
        />
      ); break;
    default:
      component = (
        <Button
          variant="contained"
          className={`${classes.contained} ${style}`}
          size={size}
          {...props}
        >
          {caption}
        </Button>
      ); break;
  }

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <div className={classes.activityIndicator}>
          <CircularProgress color="inherit" />
        </div>
      ) : component}
    </ThemeProvider>
  );
};

export default AccentButton;

AccentButton.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'text', 'contained']),
  caption: PropTypes.string.isRequired,
  size: PropTypes.string,
  style: PropTypes.string,
};

AccentButton.defaultProps = {
  loading: false,
  variant: 'contained',
  size: 'medium',
  style: '',
};
