import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_SPEAKERS, MENUID_EVENT_SPEAKER, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_SPEAKER_IMAGE, RXFORM_SPEAKER,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import SpeakerPage from './speaker.presentation';
import {
  addEditSpeakerAsync, clearSpeakers, downloadDeleteSpeakerAsync,
  downloadSpeakersAsync, enableDisableSpeakerAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setSpeakerAdvancedFilterDialogSelectedFilterString, setSpeakerSearchText,
  setSpeakerSelectedOrderBy,
  setSpeakerSelectedPageSize, setSpeakerTappedId,
} from '../../redux/action';

const getInitialValues = (state) => {
  const { speakers, uiSpeaker, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSpeaker;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? speakers.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        image: found.image ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.image}` : null,
      })
    : {
      name: '',
      description: '',
      jobTitle: '',
      image: null,
      companyName: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiSpeaker.addingEditing,
  currentCategoryStatus: state.speakers.data[state.uiFunctionalPage.tappedId]
    ? state.speakers.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiSpeaker.downloading,
  enablingDisabling: state.uiSpeaker.enablingDisabling,
  speakers: state.speakers.data,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SPEAKER));
    dispatch(setSpeakerSearchText(''));
    dispatch(clearSpeakers());
    dispatch(setSpeakerSelectedPageSize(20));
    dispatch(setSpeakerSelectedOrderBy(INITIAL_ORDER_BY_SPEAKERS));
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SPEAKER));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSpeakersAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSpeakerSelectedPageSize(pageSize));
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSpeakerAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSpeakerAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSpeakerTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setSpeakerTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setSpeakerTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSpeakerTappedId(id));
    dispatch(downloadDeleteSpeakerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onImageSelected: (image) => {
    dispatch(change(RXFORM_SPEAKER, RXFIELD_SPEAKER_IMAGE, image));
  },
  onRefresh: (pageSize) => {
    dispatch(setSpeakerSelectedPageSize(pageSize));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSpeakers());
    dispatch(setSpeakerSelectedOrderBy(INITIAL_ORDER_BY_SPEAKERS));
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description, jobTitle, companyName, image,
  }) => {
    try {
      await dispatch(addEditSpeakerAsync(name, description, jobTitle, companyName, image));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSpeakerSearchText(text));
      dispatch(clearSpeakers());
      await dispatch(downloadSpeakersAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSpeakerSelectedOrderBy(orderBy));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, jobTitle, companyName, image,
  }) => {
    await dispatch(addEditSpeakerAsync(name, description, jobTitle, companyName, image));
  },
  onViewPressed: (id) => {
    dispatch(setSpeakerTappedId(id));
    dispatch(downloadDeleteSpeakerAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerPage);
