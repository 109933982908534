import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_POINT_SUBMISSIONS, MENUID_POINT_POINT_SUBMISSION,
  PAGE_MODE_TABLE, RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION, RXFIELD_POINT_SUBMISSION_USER,
  RXFORM_POINT_SUBMISSION, approval,
} from '../../constant';
import {
  getPermission, transformInitialValues, transformUserDropdownData, toMoment,
  transformConfigValueBoolean,
} from '../../helper';
import {
  clearPointSubmissions, clearProfiles, setAdvancedFilterDialogSelectedFilterString,
  setActiveSideMenuItem, setAlertErrorMessage, setPointSubmissionSearchText,
  setPointSubmissionSelectedPageSize, setPointSubmissionSelectedOrderBy,
  setPointSubmissionTappedId, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  downloadPointSubmissionAsync, downloadPointSubmissionsAsync, reprocessPointSubmissionAsync,
  setAlertConfirmationMessage, setPointSubmissionSelectedUser,
  setPointSubmissionSendNotificationOption, addPointSubmissionAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import PointSubmissionPage from './point-submission.presentation';
import localizedString from '../../localization';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';

const getInitialValues = (state) => {
  const {
    pointSubmissions, uiFunctionalPage, uiPointSubmission,
  } = state;
  const { data } = pointSubmissions;
  const { downloadingDeleting, tappedId } = uiPointSubmission;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    fullName: found.user.fullName,
    validUntil: toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE),
    userId: { label: '', value: '' },
    sendNotification: found.sendNotification ? approval[0] : approval[1],
  }) : {
    tags: '',
    amount: '',
    validUntil: '',
    label: '',
    submissionStatus: '',
    submissionStatusNotes: null,
    merchantReferenceId: '',
    merchantId: '',
    merchantName: '',
    callbackUrl: '',
    fullName: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pointValidForAllowOverride:
    transformConfigValueBoolean(state.myConfigItems?.point?.pointValidForAllowOverride),
  downloading: state.uiPointSubmission.downloading,
  isRetryAllowed: getPermission(state, 'POINT_REPROCESS_POINT_SUBMISSION'),
  addingEditing: state.uiPointSubmission.addingEditing,
  initialValues: getInitialValues(state),
  users: transformUserDropdownData(state.profiles.data),
  loadingUser: state.uiProfile.downloading,
  reprocessing: state.uiPointSubmission.reprocessing,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_POINT_POINT_SUBMISSION));
    dispatch(setPointSubmissionSearchText(''));
    dispatch(clearPointSubmissions());
    dispatch(setPointSubmissionSelectedPageSize(20));
    dispatch(setPointSubmissionSelectedOrderBy(INITIAL_ORDER_BY_POINT_SUBMISSIONS));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/userId/, 'user.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_POINT_SUBMISSION));
  },
  onCreatePressed: () => {
    dispatch(reset(RXFORM_POINT_SUBMISSION));
    dispatch(setPointSubmissionTappedId(''));
    dispatch(setPointSubmissionSelectedUser(''));
    dispatch(setPointSubmissionSearchText(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPointSubmissionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPointSubmissionSelectedPageSize(pageSize));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: async (text) => {
    try {
      dispatch(setProfileSearchText(text));
      dispatch(clearProfiles());
      await dispatch(downloadProfilesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setPointSubmissionSelectedUser(option));
      dispatch(change(RXFORM_POINT_SUBMISSION, RXFIELD_POINT_SUBMISSION_USER, option));
    } else {
      dispatch(setPointSubmissionSelectedUser(''));
      dispatch(change(RXFORM_POINT_SUBMISSION, RXFIELD_POINT_SUBMISSION_USER, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setPointSubmissionSelectedPageSize(pageSize));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPointSubmissions());
    dispatch(setPointSubmissionSelectedOrderBy(INITIAL_ORDER_BY_POINT_SUBMISSIONS));
    dispatch(downloadPointSubmissionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setPointSubmissionSearchText(text));
      dispatch(clearPointSubmissions());
      await dispatch(downloadPointSubmissionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendNotificationOptionSelected: (option) => {
    if (option) {
      dispatch(setPointSubmissionSendNotificationOption(option));
      dispatch(change(RXFORM_POINT_SUBMISSION, RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION, option));
    } else {
      dispatch(setPointSubmissionSendNotificationOption(''));
      dispatch(change(RXFORM_POINT_SUBMISSION, RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION, ''));
    }
  },
  onSubmitPressed: async ({
    merchantReferenceId, tags, amount, validUntil,
    label, callbackUrl, sendNotification,
  }) => {
    await dispatch(addPointSubmissionAsync(merchantReferenceId, tags, amount, validUntil,
      label, callbackUrl, sendNotification));
  },
  onSortPressed: (orderBy) => {
    dispatch(setPointSubmissionSelectedOrderBy(orderBy));
    dispatch(clearPointSubmissions());
    dispatch(downloadPointSubmissionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setPointSubmissionTappedId(id));
    dispatch(downloadPointSubmissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRetryPressed: (id) => {
    dispatch(setPointSubmissionTappedId(id));
    dispatch(setAlertConfirmationMessage(localizedString.pointSubmissionPage.msgRetryConfirmation));
  },
  onConfirmRetryPressed: () => {
    dispatch(reprocessPointSubmissionAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PointSubmissionPage);
