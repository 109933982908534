import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import {
  Button, Card, CardMedia, CircularProgress, Container, makeStyles,
  ThemeProvider, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import {
  KeyboardArrowUp, KeyboardArrowDown, Refresh,
} from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { AlertBox, AccentButton } from '../../component';
import {
  COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY, RXFIELD_USERNAME, RXFIELD_PASSWORD,
  RXFORM_LOGIN_SCREEN, OAUTH_PROVIDER_PARAM,
  OAUTH_KEY_CLOAK_REDIRECT_URI, OAUTH_MICROSOFT_REDIRECT_URI,
  RXFIELD_CAPTCHA_TEXT, COLOR_TERTIARY,
} from '../../constant';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import { rxformValidateLogin } from '../../validation';
import theme from '../../style';
import { ROUTE_NAME_CERTIFICATION_REQUEST } from '../../module/e-certification';

const logo = require('../../asset/e-certification-logo.png');
const epsonIcon = require('../../asset/epson-icon.png');
const googleIcon = require('../../asset/google-icon.png');
const microsoftIcon = require('../../asset/microsoft-icon.png');

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: COLOR_PRIMARY,
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    width: '100vw',
    height: '100vh',
  },
  innerContainer: {
    maxWidth: 500,
    minWidth: 275,
  },
  card: {
    justifyContent: 'space-between',
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 350,
    maxHeight: 1000,
    borderRadius: 10,
    boxShadow: '0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 1px rgba(63, 63, 68, 0.05)',
    padding: '40px 0px 0px 0px',
  },
  topField: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 20,
    display: 'flex',
  },
  logo: {
    width: 155,
    marginBottom: 20,
  },
  oAuthImage: {
    width: 58,
    height: 14,
    resizeMode: 'center',
    marginRight: 2,
    marginBottom: 2,
    marginLeft: 3,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px',
  },
  loginFormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    padding: '10px 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    color: COLOR_PRIMARY,
    minWidth: 250,
    margin: '5px 10px 10px 10px',
    borderColor: COLOR_PRIMARY,
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  oAuthButtonContainer: {
    minWidth: 250,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    marginTop: 16,
    marginBottom: 5,
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 8,
    height: 50,
  },
  fieldContainer: {
    minWidth: 250,
    display: 'flex',
    flexDirection: 'column',
  },
  captchaContainer: {
    margin: '0px 8px',
    display: 'flex',
    flexDirection: 'column',
  },
  captchaImageContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
  weight400: {
    fontWeight: 400,
    alignSelf: 'center',
  },
  weight800: {
    fontWeight: 800,
  },
  whiteText: {
    color: COLOR_TEXT_ON_PRIMARY,
  },
  blueText: {
    color: COLOR_PRIMARY,
  },
  dropdownButtonContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
  },
  loginWithEpsonButton: {
    display: 'flex',
    flex: 1,
    minWidth: 250,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    backgroundColor: COLOR_PRIMARY,
    borderRadius: 8,
    height: 50,
    marginRight: 4,
  },
  centered: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowIcon: {
    color: COLOR_TERTIARY,
  },
  arrowButton: {
    padding: 0,
    marginRight: '20px',
    marginBottom: '20px',
  },
  flex1: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  body: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const onOAuthLoginPressed = (url) => {
  window.open(url, '_self');
};

const renderExpandButton = (
  oAuthKeyCloakEnabled, formVisibility, classes, setFormVisibility,
  loggingIn, loggingInWithKeyCloak,
) => {
  if (oAuthKeyCloakEnabled) {
    if (formVisibility) {
      return (
        <div className={classes.dropdownButtonContainer}>
          <IconButton
            aria-label="arrow up"
            onClick={() => setFormVisibility(!formVisibility)}
            disabled={loggingIn || loggingInWithKeyCloak}
            className={classes.arrowButton}
          >
            <KeyboardArrowUp fontSize="small" className={classes.arrowIcon} />
          </IconButton>
        </div>
      );
    }
    return (
      <div className={classes.dropdownButtonContainer}>
        <IconButton
          aria-label="arrow up"
          onClick={() => setFormVisibility(!formVisibility)}
          disabled={loggingIn || loggingInWithKeyCloak}
          className={classes.arrowButton}
        >
          <KeyboardArrowDown fontSize="small" className={classes.arrowIcon} />
        </IconButton>
      </div>
    );
  }
  return null;
};

const renderOAuthLoginButton = (
  item, loggingIn, formVisibility, classes,
) => {
  const {
    loading, caption, disabled, hidden, onPress, icon,
  } = item;
  if (loading) {
    return (
      <div className={classes.activityIndicator} key={item.caption}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  if (!hidden) {
    return (
      <Button
        variant="outlined"
        className={classes.oAuthButtonContainer}
        onClick={onPress}
        disabled={loggingIn || disabled || formVisibility}
        key={item.caption}
      >
        <Typography className={classes.whiteText}>{caption}</Typography>
        <CardMedia
          component="img"
          className={classes.oAuthImage}
          image={icon}
        />
      </Button>
    );
  }
  return null;
};

const renderLoginForm = (
  classes, handleSubmit, onLoginPressed,
  loggingIn, loggingInWithGoogle, loggingInWithMicrosoft,
  loggingInWithKeyCloak, downloadingCaptcha, setFormVisibility,
  formVisibility, oAuthKeyCloakEnabled, captchaImage, useCaptcha,
  onRefreshCaptcha, downloadingMyConfigItems,
) => {
  if (downloadingMyConfigItems) {
    return (
      <div className={classes.centered}>
        <CircularProgress sx={{ color: COLOR_PRIMARY }} size={36} />
      </div>
    );
  }

  if (oAuthKeyCloakEnabled) {
    if (formVisibility) {
      return (
        <div className={classes.loginFormContainer}>
          <form
            onSubmit={handleSubmit(onLoginPressed)}
            className={classes.form}
          >
            <div className={classes.fieldContainer}>
              <Field
                name={RXFIELD_USERNAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.loginScreen.placeholderEmail}
                label={LocalizedString.loginScreen.labelEmail}
                disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
                required
              />

              <Field
                name={RXFIELD_PASSWORD}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.loginScreen.labelPassword}
                label={LocalizedString.loginScreen.labelPassword}
                disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
                secureTextEntry
                required
              />
            </div>

            {useCaptcha && (downloadingCaptcha ? (
              <div>
                <div className={classes.captchaContainer}>
                  <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
                  <div className={classes.form}>
                    <CircularProgress color="inherit" size={20} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.captchaContainer}>
                  <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
                  <div className={classes.captchaImageContainer}>
                    <img alt="avatar" src={captchaImage} width={200} height={60} />
                    <Tooltip title={LocalizedString.loginScreen.buttonCaptionRefreshCaptcha}>
                      <IconButton onClick={onRefreshCaptcha} aria-label="text">
                        <Refresh />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <Field
                  name={RXFIELD_CAPTCHA_TEXT}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.loginScreen.placeholderCaptcha}
                  label={LocalizedString.loginScreen.placeholderCaptcha}
                  disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                    || loggingInWithKeyCloak}
                  required
                />
              </div>
            )
            )}

            <AccentButton
              type="submit"
              variant="outlined"
              className={classes.button}
              loading={loggingIn}
              disableElevation
              disabled={downloadingCaptcha || loggingInWithGoogle || loggingInWithMicrosoft
                || loggingInWithKeyCloak}
              caption={LocalizedString.loginScreen.buttonCaptionLogin}
            />
            <AlertBox />
          </form>
        </div>
      );
    }
    return null;
  }

  return (
    <form
      onSubmit={handleSubmit(onLoginPressed)}
      className={classes.form}
    >
      <div className={classes.fieldContainer}>
        <Field
          name={RXFIELD_USERNAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.loginScreen.placeholderEmail}
          label={LocalizedString.loginScreen.labelEmail}
          disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
          required
        />

        <Field
          name={RXFIELD_PASSWORD}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.loginScreen.labelPassword}
          label={LocalizedString.loginScreen.labelPassword}
          disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                  || loggingInWithKeyCloak}
          secureTextEntry
          required
        />
      </div>

      {useCaptcha && (downloadingCaptcha ? (
        <div>
          <div className={classes.captchaContainer}>
            <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
            <div className={classes.form}>
              <CircularProgress color="inherit" size={20} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={classes.captchaContainer}>
            <Typography>{LocalizedString.loginScreen.labelCaptcha}</Typography>
            <div className={classes.captchaImageContainer}>
              <img alt="avatar" src={captchaImage} width={200} height={60} />
              <Tooltip title={LocalizedString.loginScreen.buttonCaptionRefreshCaptcha}>
                <IconButton onClick={onRefreshCaptcha} aria-label="text">
                  <Refresh />
                </IconButton>
              </Tooltip>
            </div>
          </div>

          <Field
            name={RXFIELD_CAPTCHA_TEXT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.loginScreen.placeholderCaptcha}
            label={LocalizedString.loginScreen.placeholderCaptcha}
            disabled={loggingIn || loggingInWithGoogle || loggingInWithMicrosoft
                    || loggingInWithKeyCloak}
            required
          />
        </div>
      )
      )}

      <AccentButton
        type="submit"
        variant="outlined"
        className={classes.button}
        loading={loggingIn}
        disableElevation
        disabled={downloadingCaptcha || loggingInWithGoogle || loggingInWithMicrosoft
                || loggingInWithKeyCloak}
        caption={LocalizedString.loginScreen.buttonCaptionLogin}
      />
    </form>
  );
};

const LoginScreen = ({
  downloadingCaptcha, downloadingMyConfigItems, loggingIn, loggingInWithGoogle,
  loggingInWithKeyCloak, loggingInWithMicrosoft, oAuthGoogleEnabled, oAuthKeyCloakEnabled,
  oAuthMicrosoftEnabled, useCaptcha,
  createCaptcha, handleSubmit, onAppear, onAppearWithAuthCode, onLoginPressed,
  onRefreshCaptcha, setRedirectionParam,
  myConfigItems,
  captchaImage, oAuthGoogleUrl, oAuthKeyCloakUrl, oAuthMicrosoftUrl,
}) => {
  const [mount, setMount] = useState(false);
  const [formVisibility, setFormVisibility] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const redirectionParam = searchParams.get('redirectTo');

  const googleOAuthCode = searchParams.get('code');
  const oAuthProvider = searchParams.get(OAUTH_PROVIDER_PARAM);

  const microsoftSearchParams = new URLSearchParams(window.location.href.replace(OAUTH_MICROSOFT_REDIRECT_URI, ''));
  const microsoftOAuthCode = microsoftSearchParams.get('code');

  const keyCloakSearchParams = new URLSearchParams(window.location.href.replace(OAUTH_KEY_CLOAK_REDIRECT_URI, ''));
  const keyCloakOAuthCode = keyCloakSearchParams.get('code');

  const classes = useStyles();

  useEffect(() => {
    if (redirectionParam) {
      setRedirectionParam(redirectionParam);
    } else {
      setRedirectionParam('');
    }
  }, [setRedirectionParam, redirectionParam]);

  useEffect(() => {
    if (!mount) {
      setMount(true);
      onAppear();
      onAppearWithAuthCode(googleOAuthCode, oAuthProvider, microsoftOAuthCode, keyCloakOAuthCode);
    }
  }, [onAppear, onAppearWithAuthCode, mount, googleOAuthCode, oAuthProvider, microsoftOAuthCode,
    keyCloakOAuthCode]);

  useEffect(() => {
    createCaptcha(useCaptcha);
  }, [createCaptcha, useCaptcha]);

  useEffect(() => {
    if (isEmpty(myConfigItems) && !downloadingMyConfigItems) {
      onAppear();
    }
  }, [myConfigItems, downloadingMyConfigItems, onAppear]);

  const oAuthButtons = [
    {
      loading: loggingInWithGoogle,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWithGoogle,
      disabled: loggingInWithMicrosoft || loggingInWithKeyCloak,
      hidden: !oAuthGoogleEnabled,
      onPress: () => onOAuthLoginPressed(oAuthGoogleUrl),
      icon: googleIcon,
    },
    {
      loading: loggingInWithMicrosoft,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWithMicrosoft,
      disabled: loggingInWithGoogle || loggingInWithKeyCloak,
      hidden: !oAuthMicrosoftEnabled,
      onPress: () => onOAuthLoginPressed(oAuthMicrosoftUrl),
      icon: microsoftIcon,
    },
    {
      loading: loggingInWithKeyCloak,
      caption: LocalizedString.loginScreen.buttonCaptionLoginWith,
      disabled: loggingInWithGoogle || loggingInWithMicrosoft,
      hidden: !oAuthKeyCloakEnabled,
      onPress: () => onOAuthLoginPressed(`${oAuthKeyCloakUrl}${location?.search && location?.search?.includes(ROUTE_NAME_CERTIFICATION_REQUEST) ? location?.search : ''}`),
      icon: epsonIcon,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <Container className={classes.innerContainer} fixed>
          <Card className={classes.card} raised>
            <div className={classes.body}>
              <span className={classes.topField}>
                <CardMedia
                  component="img"
                  className={classes.logo}
                  image={logo}
                />
                <Typography variant="h5" className={classes.weight800}>
                  {LocalizedString.loginScreen.labelWelcomeBack}
                </Typography>
                <Typography variant="body1" className={classes.weight400}>
                  {LocalizedString.loginScreen.labelAccessYourAccount}
                </Typography>
              </span>
              <div className={classes.formContainer}>
                {oAuthButtons.map((button) => renderOAuthLoginButton(
                  button, loggingIn, formVisibility, classes,
                ))}
                {renderLoginForm(
                  classes, handleSubmit, onLoginPressed,
                  loggingIn, loggingInWithGoogle, loggingInWithMicrosoft,
                  loggingInWithKeyCloak, downloadingCaptcha, setFormVisibility,
                  formVisibility, oAuthKeyCloakEnabled, captchaImage, useCaptcha,
                  onRefreshCaptcha, downloadingMyConfigItems,
                )}
              </div>
            </div>
            <div className={classes.flex1}>
              {renderExpandButton(
                oAuthKeyCloakEnabled, formVisibility, classes, setFormVisibility,
                loggingIn, loggingInWithKeyCloak,
              )}
            </div>
          </Card>
        </Container>
        <AlertBox />
      </div>
    </ThemeProvider>
  );
};

export default reduxForm({
  form: RXFORM_LOGIN_SCREEN,
  validate: rxformValidateLogin,
})(LoginScreen);

LoginScreen.propTypes = {
  downloadingCaptcha: PropTypes.bool.isRequired,
  downloadingMyConfigItems: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loggingInWithGoogle: PropTypes.bool.isRequired,
  loggingInWithKeyCloak: PropTypes.bool.isRequired,
  loggingInWithMicrosoft: PropTypes.bool.isRequired,
  oAuthGoogleEnabled: PropTypes.bool.isRequired,
  oAuthKeyCloakEnabled: PropTypes.bool.isRequired,
  oAuthMicrosoftEnabled: PropTypes.bool.isRequired,
  useCaptcha: PropTypes.bool.isRequired,
  createCaptcha: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onAppearWithAuthCode: PropTypes.func.isRequired,
  onLoginPressed: PropTypes.func.isRequired,
  onRefreshCaptcha: PropTypes.func.isRequired,
  setRedirectionParam: PropTypes.func.isRequired,
  myConfigItems: PropTypes.object.isRequired,
  captchaImage: PropTypes.string,
  oAuthGoogleUrl: PropTypes.string.isRequired,
  oAuthKeyCloakUrl: PropTypes.string.isRequired,
  oAuthMicrosoftUrl: PropTypes.string.isRequired,
};

LoginScreen.defaultProps = {
  captchaImage: null,
};
