import {
  buildQueryParams, sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest,
  transformQueryResult,
} from '../../helper';
import {
  REST_URL_ASSETS, REST_URL_VIEW_ASSET, REST_URL_ASSET_TYPES,
  REST_URL_ASSET_LOCATIONS, REST_URL_CERTIFICATION_TYPES, REST_URL_ASSET_CERTIFICATES,
  REST_URL_ASSET_DOCUMENTS, REST_URL_ADD_ASSET_DOCUMENT, REST_URL_VIEW_DELETE_ASSET_DOCUMENT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, REST_URL_ADD_EDIT_CERTIFICATION_TYPE,
  REST_URL_VIEW_DELETE_CERTIFICATION_TYPE, REST_URL_ADD_ASSET_CHILD, REST_URL_ASSET_CHILDREN,
  REST_URL_PARENTLESS_ASSETS, REST_URL_DELETE_ASSET_CHILD, REST_URL_CERTIFICATION_REQUESTS,
  REST_URL_ADD_CERTIFICATION_REQUEST, REST_URL_VIEW_CERTIFICATION_REQUEST,
  REST_URL_MY_ASSET_LOCATIONS, REST_URL_CHANGE_ASSET_REMINDER_FLAG,
  REST_URL_CERTIFICATION_REQUEST_APPROVALS, REST_URL_APPROVE_REJECT_CERTIFICATION_REQUEST,
  REST_URL_SCHEDULE_ASSET, REST_URL_ADD_EDIT_ASSET_CERTIFICATE,
  REST_URL_VIEW_DELETE_ASSET_CERTIFICATE,
  REST_URL_CHECK_CERTIFICATION_REQUEST_APPROVAL_PERMISSION,
  REST_URL_ADD_ASSET_SCREENING,
} from './constant';

export * from '../../helper';

export const downloadAssets = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_ASSETS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAsset = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_ASSET.replace(/\{id\}/, id), token);
  return response;
};

export const downloadAssetTypes = async (searchText, token) => {
  const url = REST_URL_ASSET_TYPES.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAssetLocations = async (searchText, token) => {
  const url = REST_URL_ASSET_LOCATIONS.replace(/\{searchText\}/, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadAssetCertificates = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_ASSET_CERTIFICATES.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = response.data.map((x) => ({
    ...x,
    file: `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${x.file}`,
  }));
  return transformQueryResult(response, transformData);
};

export const addAssetCertificate = async (assetId, fileName, remark, issuedDate, expiredDate,
  resultType, fileContent, token) => {
  const body = {
    assetId, fileName, remark, issuedDate, expiredDate, resultType, fileContent,
  };
  await sendPostRequest(REST_URL_ADD_EDIT_ASSET_CERTIFICATE, body, token);
};

export const editAssetCertificate = async (id, assetId, fileName, remark, issuedDate, expiredDate,
  resultType, fileContent, token) => {
  const body = {
    id, assetId, fileName, remark, issuedDate, expiredDate, resultType, fileContent,
  };
  await sendPutRequest(REST_URL_ADD_EDIT_ASSET_CERTIFICATE, body, token);
};

export const downloadAssetCertificate = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ASSET_CERTIFICATE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteAssetCertificate = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ASSET_CERTIFICATE.replace(/\{id\}/, id), token);
};

export const downloadAssetDocuments = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_ASSET_DOCUMENTS.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  const transformData = response.data.map((x) => ({
    ...x,
    file: `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${x.file}`,
  }));
  return transformQueryResult(response, transformData);
};

export const addAssetDocument = async (assetId, fileName, label, fileContent, token) => {
  const body = {
    assetId, fileName, label, fileContent,
  };
  await sendPostRequest(REST_URL_ADD_ASSET_DOCUMENT, body, token);
};

export const downloadAssetDocument = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_ASSET_DOCUMENT.replace(/\{id\}/, id), token);
  return response;
};

export const deleteAssetDocument = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_ASSET_DOCUMENT.replace(/\{id\}/, id), token);
};

export const changeAssetReminderFlag = async (assetId, reminderFlag, token) => {
  const body = { assetId, reminderFlag };
  await sendPutRequest(REST_URL_CHANGE_ASSET_REMINDER_FLAG, body, token);
};

export const downloadAssetChildren = async (pageNumber, pageSize, orderBy, searchText, filterString,
  token) => {
  const url = buildQueryParams(REST_URL_ASSET_CHILDREN.concat(filterString), pageNumber, pageSize,
    orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addAssetChild = async (parentAssetId, assetIds, token) => {
  const body = { parentAssetId, assetIds };
  await sendPostRequest(REST_URL_ADD_ASSET_CHILD, body, token);
};

export const deleteAssetChild = async (id, token) => {
  await sendDeleteRequest(REST_URL_DELETE_ASSET_CHILD.replace(/\{id\}/, id), token);
};

export const downloadParentlessAssets = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_PARENTLESS_ASSETS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCertificationTypes = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_CERTIFICATION_TYPES.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const addCertificationType = async (name, description, token) => {
  const body = { name, description };
  await sendPostRequest(REST_URL_ADD_EDIT_CERTIFICATION_TYPE, body, token);
};

export const editCertificationType = async (id, name, description, token) => {
  const body = { id, name, description };
  await sendPutRequest(REST_URL_ADD_EDIT_CERTIFICATION_TYPE, body, token);
};

export const downloadCertificationType = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_DELETE_CERTIFICATION_TYPE.replace(/\{id\}/, id), token);
  return response;
};

export const deleteCertificationType = async (id, token) => {
  await sendDeleteRequest(REST_URL_VIEW_DELETE_CERTIFICATION_TYPE.replace(/\{id\}/, id), token);
};

export const downloadCertificationRequests = async (pageNumber, pageSize, orderBy, searchText,
  filterString, token) => {
  const url = buildQueryParams(REST_URL_CERTIFICATION_REQUESTS.concat(filterString), pageNumber,
    pageSize, orderBy, searchText);
  const response = await sendGetRequest(url, token);
  return transformQueryResult(response);
};

export const downloadCertificationRequest = async (id, token) => {
  const response = await sendGetRequest(REST_URL_VIEW_CERTIFICATION_REQUEST.replace(/\{id\}/, id), token);
  const transformResponse = {
    ...response,
    assets: response.assets.length ? response.assets.map((x, i) => ({ ...x, no: i + 1 })) : [],
  };
  return transformResponse;
};

export const addCertificationRequest = async (locationId, certificationCategory,
  certificationTypeId, assetIds, token) => {
  const body = {
    locationId, certificationCategory, certificationTypeId, assetIds,
  };
  await sendPostRequest(REST_URL_ADD_CERTIFICATION_REQUEST, body, token);
};

export const downloadCertificationRequestApprovals = async (id, token) => {
  const response = await sendGetRequest(REST_URL_CERTIFICATION_REQUEST_APPROVALS.replace(/\{id\}/, id), token);
  return response;
};

export const checkCertificationRequestApprovalPermission = async (referenceId, token) => {
  const response = await sendGetRequest(REST_URL_CHECK_CERTIFICATION_REQUEST_APPROVAL_PERMISSION.replace(/\{referenceId\}/, referenceId), token);
  return response.data.length > 0;
};

export const approveRejectCertificationRequest = async (approvalId, status, notes, token) => {
  const body = { approvalId, status, notes };
  await sendPostRequest(REST_URL_APPROVE_REJECT_CERTIFICATION_REQUEST, body, token);
};

export const downloadMyAssetLocations = async (token) => {
  const response = await sendGetRequest(REST_URL_MY_ASSET_LOCATIONS, token);
  return response;
};

export const scheduleAsset = async (scheduledDate, assetIds, token) => {
  const body = { scheduledDate, assetIds };
  await sendPostRequest(REST_URL_SCHEDULE_ASSET, body, token);
};

export const addAssetScreening = async (status, certificationTypeId, assetIds, token) => {
  const body = { status, certificationTypeId, assetIds };
  await sendPostRequest(REST_URL_ADD_ASSET_SCREENING, body, token);
};
