import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { onMessage } from 'firebase/messaging';
import { PrivateRoute, NavigationPrompt, Notification } from './component';
import {
  ROUTE_NAME_LOGIN, ROUTE_NAME_DASHBOARD,
  ROUTE_NAME_SUMMARY, ROUTE_NAME_SERVICE, ROUTE_NAME_SCOPE, ROUTE_NAME_API_KEY, ROUTE_NAME_TOKEN,
  ROUTE_NAME_PERMISSION, ROUTE_NAME_ROLE, ROUTE_NAME_USER,
  ROUTE_NAME_APPLICATION, ROUTE_NAME_BANNER, ROUTE_NAME_PROFILE,
  ROUTE_NAME_PROVINCE, ROUTE_NAME_CITY, ROUTE_NAME_COMPANY, ROUTE_NAME_BRANCH, ROUTE_NAME_DISTRICT,
  ROUTE_NAME_SUBDISTRICT, ROUTE_NAME_REPORT, ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT,
  ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT, ROUTE_NAME_POPUP_BANNER,
  ROUTE_NAME_WORKING_SHIFT,
} from './constant';
import {
  SplashPage,
  LoginPage,
  DashboardPage,
  SummaryPage, ServicePage, ScopePage, ApiKeyPage, TokenPage, PermissionPage, RolePage, UserPage,
  ApplicationPage, BannerPage, ProfilePage, PopupBannerPage,
  ProvincePage, CityPage, CompanyPage, BranchPage, DistrictPage, SubdistrictPage,
  ReportPage, AuthRegistrationAttemptPage, AuthPasswordResetAttemptPage,
  WorkingShiftPage,
} from './page';
import {
  ConfigActionPage, ConfigGroupPage, ConfigItemPage, ConfigValuePage, ProfileSyncPage,
  ROUTE_NAME_CONFIG_ACTION, ROUTE_NAME_CONFIG_GROUP, ROUTE_NAME_CONFIG_ITEM,
  ROUTE_NAME_CONFIG_VALUE,
  ROUTE_NAME_PROFILE_SYNC,
} from './module/configuration';
import {
  AdditionalFieldPage, AttendanceSummaryPage, HistoryPage,
  ROUTE_NAME_ADDITIONAL_FIELD, ROUTE_NAME_ATTENDANCE_SUMMARY, ROUTE_NAME_HISTORY,
} from './module/attendance';
import {
  FaqPage, FaqCategoryPage, ROUTE_NAME_FAQ, ROUTE_NAME_FAQ_CATEGORY,
} from './module/faq';
import {
  AnnotationDesignerPage, ROUTE_NAME_ANNOTATION_DESIGNER,
} from './module/pdf';
import {
  EmailPage, SmsPage, WhatsappPage, PushNotificationPage, NotificationTaskPage,
  InboxPage,
  ROUTE_NAME_EMAIL, ROUTE_NAME_SMS, ROUTE_NAME_WHATSAPP,
  ROUTE_NAME_PUSH_NOTIFICATION, ROUTE_NAME_NOTIFICATION_TASK,
  ROUTE_NAME_INBOX,
} from './module/notification';
import {
  PacketPage, PacketTypePage, PickupLocationPage, PacketSummaryPage,
  ROUTE_NAME_PACKET, ROUTE_NAME_PACKET_TYPE, ROUTE_NAME_PICKUP_LOCATION, ROUTE_NAME_PACKET_SUMMARY,
} from './module/packet';
import {
  ActivityPage, ActivityTypePage, CustomerPage, IndustryPage,
  ROUTE_NAME_ACTIVITY, ROUTE_NAME_ACTIVITY_TYPE, ROUTE_NAME_CUSTOMER, ROUTE_NAME_INDUSTRY,
} from './module/activity';
import {
  CompanyMappingPage, InfoMappingPage, ROUTE_NAME_COMPANY_MAPPING, ROUTE_NAME_INFO_MAPPING,
} from './module/bcard';
import {
  ShortlinkPage, ROUTE_NAME_SHORTLINK_SCREEN,
} from './module/shortlink';
import {
  AuditTrailPage, ROUTE_NAME_AUDIT_TRAIL,
} from './module/audit';
import {
  AchievementPage, PointPage, PointSubmissionPage, PointTransactionPage,
  PurchaseRequestPage, VoidPointRequestPage, LeaderBoardPage,
  ROUTE_NAME_POINT_POINT, ROUTE_NAME_POINT_ACHIEVEMENT,
  ROUTE_NAME_POINT_POINT_SUBMISSION, ROUTE_NAME_POINT_POINT_TRANSACTION,
  ROUTE_NAME_PURCHASE_REQUEST, ROUTE_NAME_VOID_POINT_REQUEST, ROUTE_NAME_POINT_LEADER_BOARD,
} from './module/point';
import {
  GiftVoucherRedemptionPage, TokenRequestPage, VoucherCategoryPage, VoucherPage, VoucherTokenPage,
  VoucherTokenImportPage, VoucherPurchaseRequestPage, PromotedMerchantPage,
  VoucherTokenAssignmentPage, VoucherUsagePage,
  ROUTE_NAME_VOUCHER_CATEGORY, ROUTE_NAME_VOUCHER_TOKEN, ROUTE_NAME_VOUCHER_TOKEN_REQUEST,
  ROUTE_NAME_VOUCHER_VOUCHER, ROUTE_NAME_VOUCHER_TOKEN_IMPORT, ROUTE_NAME_VOUCHER_PURCHASE_REQUEST,
  ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT, ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT,
  ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION, ROUTE_NAME_VOUCHER_USAGE,
} from './module/voucher';
import {
  DiscussionPage, DiscussionCommentPage, ReportedCommentPage,
  ROUTE_NAME_DISCUSSION, ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT,
  ROUTE_NAME_DISCUSSION_REPORTED_COMMENT,
} from './module/discussion';
import {
  ForumCategoryPage, ForumPostPage, ROUTE_NAME_FORUM_CATEGORY, ROUTE_NAME_FORUM_POST,
} from './module/forum';
import {
  ChallengePage, GameQRCategoryPage, ROUTE_NAME_GAMEQR_CATEGORY, ROUTE_NAME_GAMEQR_CHALLENGE,
  ROUTE_NAME_GAMEQR_SCANNED_CODE, ScannedCodePage, ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE,
  CompletedChallangePage,
} from './module/game-qr';
import {
  NewsPage, NewsCategoryPage, ROUTE_NAME_NEWS, ROUTE_NAME_NEWS_CATEGORY,
} from './module/news';
import {
  PartnerMerchantPage, ROUTE_NAME_PARTNER_MERCHANT,
} from './module/partner';
import {
  SessionCategoryPage, ROUTE_NAME_SESSION_CATEGORY,
  LocationPage, ROUTE_NAME_LOCATION, ROUTE_NAME_EVENT_CATEGORY, EventCategoryPage,
  ROUTE_NAME_SPONSOR_CATEGORY, SponsorCategoryPage,
  RoomPage, ROUTE_NAME_ROOM,
  ROUTE_NAME_SPEAKER, SpeakerPage, ROUTE_NAME_SESSION, SessionPage,
  ROUTE_NAME_EVENT, EventPage,
  EventInvitationPage, ROUTE_NAME_EVENT_INVITATION,
  SponsorPage, ROUTE_NAME_SPONSOR,
  ROUTE_NAME_EVENT_REGISTRATION, EventRegistrationPage,
  SessionInvitationPage, ROUTE_NAME_SESSION_INVITATION,
  ROUTE_NAME_SESSION_REGISTRATION, SessionRegistrationPage,
} from './module/event';
import {
  DailyCheckinPage, ROUTE_NAME_DAILY_CHECKIN,
  RegistrationReferralPage, ROUTE_NAME_REGISTRATION_REFERRAL,
} from './module/activity-reward';
import {
  CaptchaRequestPage, ROUTE_NAME_CAPTCHA_REQUEST,
} from './module/captcha';
import {
  FormPage, ROUTE_NAME_DFORM_FORM,
  FieldPage, ROUTE_NAME_DFORM_FIELD,
  ResponsePage, ROUTE_NAME_DFORM_RESPONSE, ROUTE_NAME_DFORM_PREVIEW, PreviewPage,
} from './module/dform';
import {
  PaymentPage, ROUTE_NAME_PAYMENT,
} from './module/payment';
import {
  EventOperatorMappingPage, EventOperatorInvitationPage,
  EventOperatorRegistrationPage, EventOperatorEventPage,
  ROUTE_NAME_EVENT_OPERATOR_MAPPING, ROUTE_NAME_EVENT_OPERATOR_INVITATION,
  ROUTE_NAME_EVENT_OPERATOR_REGISTRATION,
  ROUTE_NAME_EVENT_OPERATOR_EVENT,
} from './module/event-operator';
import {
  SessionOperatorMappingPage, SessionOperatorInvitationPage, SessionOperatorSessionPage,
  SessionOperatorRegistrationPage,
  ROUTE_NAME_SESSION_OPERATOR_MAPPING, ROUTE_NAME_SESSION_OPERATOR_INVITATION,
  ROUTE_NAME_SESSION_OPERATOR_SESSION, ROUTE_NAME_SESSION_OPERATOR_REGISTRATION,
} from './module/session-operator';
import {
  JobPage, ROUTE_NAME_JOB, MyJobPage, ROUTE_NAME_MY_JOB,
} from './module/user-job';
import { store, persistor } from './redux/store';
import { messaging } from './firebase';
import { onNotificationReceived } from './notification-handler';
import {
  TemplatePage, ROUTE_DOCUMENT_GENERATOR_TEMPLATE,
  DocumentPage, ROUTE_DOCUMENT_GENERATOR_DOCUMENT,
} from './module/document-generator';
import {
  AssetPage, AssetScreeningPage, CertificationTypePage, CertificationRequestPage,
  CertificationScheduleSettingPage,
  ROUTE_NAME_ASSET, ROUTE_NAME_ASSET_DETAIL, ROUTE_NAME_ASSET_SCREENING,
  ROUTE_NAME_CERTIFICATION_TYPE, ROUTE_NAME_CERTIFICATION_REQUEST,
  ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL, ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING,
  ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL,
} from './module/e-certification';
import {
  ApprovalPage, ApprovalGroupPage, ROUTE_NAME_APPROVAL, ROUTE_NAME_APPROVAL_GROUP,
} from './module/approval';

moment.tz.setDefault('UTC');

export default () => {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      if (payload.notification) {
        onNotificationReceived(payload);
      }
    });
    return unsubscribe;
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <NavigationPrompt />
          <Notification />
          <div>
            <Switch>
              <PrivateRoute path={ROUTE_NAME_JOB} component={JobPage} />
              <PrivateRoute path={ROUTE_NAME_DASHBOARD} component={DashboardPage} />
              <PrivateRoute path={ROUTE_NAME_REPORT} component={ReportPage} />
              <PrivateRoute path={ROUTE_NAME_PROFILE} component={ProfilePage} />
              <PrivateRoute path={ROUTE_NAME_PROVINCE} component={ProvincePage} />
              <PrivateRoute path={ROUTE_NAME_CITY} component={CityPage} />
              <PrivateRoute path={ROUTE_NAME_DISTRICT} component={DistrictPage} />
              <PrivateRoute path={ROUTE_NAME_SUBDISTRICT} component={SubdistrictPage} />
              <PrivateRoute path={ROUTE_NAME_COMPANY} component={CompanyPage} />
              <PrivateRoute path={ROUTE_NAME_BRANCH} component={BranchPage} />
              <PrivateRoute path={ROUTE_NAME_APPLICATION} component={ApplicationPage} />
              <PrivateRoute path={ROUTE_NAME_SUMMARY} component={SummaryPage} />
              <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
              <PrivateRoute path={ROUTE_NAME_API_KEY} component={ApiKeyPage} />
              <PrivateRoute path={ROUTE_NAME_TOKEN} component={TokenPage} />
              <PrivateRoute path={ROUTE_NAME_PERMISSION} component={PermissionPage} />
              <PrivateRoute path={ROUTE_NAME_ROLE} component={RolePage} />
              <PrivateRoute path={ROUTE_NAME_USER} component={UserPage} />
              <PrivateRoute path={ROUTE_NAME_SERVICE} component={ServicePage} />
              <PrivateRoute path={ROUTE_NAME_SCOPE} component={ScopePage} />
              <PrivateRoute path={ROUTE_NAME_NEWS} component={NewsPage} />
              <PrivateRoute path={ROUTE_NAME_NEWS_CATEGORY} component={NewsCategoryPage} />
              <PrivateRoute path={ROUTE_NAME_CONFIG_GROUP} component={ConfigGroupPage} />
              <PrivateRoute path={ROUTE_NAME_CONFIG_ITEM} component={ConfigItemPage} />
              <PrivateRoute path={ROUTE_NAME_CONFIG_VALUE} component={ConfigValuePage} />
              <PrivateRoute path={ROUTE_NAME_CONFIG_ACTION} component={ConfigActionPage} />
              <PrivateRoute path={ROUTE_NAME_PROFILE_SYNC} component={ProfileSyncPage} />
              <PrivateRoute path={ROUTE_NAME_ADDITIONAL_FIELD} component={AdditionalFieldPage} />
              <PrivateRoute
                path={ROUTE_NAME_ATTENDANCE_SUMMARY}
                component={AttendanceSummaryPage}
              />
              <PrivateRoute path={ROUTE_NAME_HISTORY} component={HistoryPage} />
              <PrivateRoute path={ROUTE_NAME_MY_JOB} component={MyJobPage} />
              <PrivateRoute path={ROUTE_NAME_NOTIFICATION_TASK} component={NotificationTaskPage} />
              <PrivateRoute path={ROUTE_NAME_EMAIL} component={EmailPage} />
              <PrivateRoute path={ROUTE_NAME_SMS} component={SmsPage} />
              <PrivateRoute path={ROUTE_NAME_WHATSAPP} component={WhatsappPage} />
              <PrivateRoute path={ROUTE_NAME_PUSH_NOTIFICATION} component={PushNotificationPage} />
              <PrivateRoute path={ROUTE_NAME_FAQ} component={FaqPage} />
              <PrivateRoute path={ROUTE_NAME_FAQ_CATEGORY} component={FaqCategoryPage} />
              <PrivateRoute
                path={ROUTE_NAME_ANNOTATION_DESIGNER}
                component={AnnotationDesignerPage}
              />
              <PrivateRoute path={ROUTE_NAME_BANNER} component={BannerPage} />
              <PrivateRoute path={ROUTE_NAME_POPUP_BANNER} component={PopupBannerPage} />
              <PrivateRoute path={ROUTE_NAME_VOUCHER_CATEGORY} component={VoucherCategoryPage} />
              <PrivateRoute path={ROUTE_NAME_VOID_POINT_REQUEST} component={VoidPointRequestPage} />
              <PrivateRoute path={ROUTE_NAME_AUDIT_TRAIL} component={AuditTrailPage} />
              <PrivateRoute path={ROUTE_NAME_SHORTLINK_SCREEN} component={ShortlinkPage} />
              <PrivateRoute path={ROUTE_NAME_PICKUP_LOCATION} component={PickupLocationPage} />
              <PrivateRoute path={ROUTE_NAME_PACKET_TYPE} component={PacketTypePage} />
              <PrivateRoute path={ROUTE_NAME_PACKET} component={PacketPage} />
              <PrivateRoute path={ROUTE_NAME_PACKET_SUMMARY} component={PacketSummaryPage} />
              <PrivateRoute path={ROUTE_NAME_CUSTOMER} component={CustomerPage} />
              <PrivateRoute path={ROUTE_NAME_INDUSTRY} component={IndustryPage} />
              <PrivateRoute path={ROUTE_NAME_ACTIVITY} component={ActivityPage} />
              <PrivateRoute path={ROUTE_NAME_ACTIVITY_TYPE} component={ActivityTypePage} />
              <PrivateRoute path={ROUTE_NAME_COMPANY_MAPPING} component={CompanyMappingPage} />
              <PrivateRoute path={ROUTE_NAME_INFO_MAPPING} component={InfoMappingPage} />
              <PrivateRoute path={ROUTE_NAME_FORUM_CATEGORY} component={ForumCategoryPage} />
              <PrivateRoute path={ROUTE_NAME_FORUM_POST} component={ForumPostPage} />
              <PrivateRoute path={ROUTE_NAME_GAMEQR_CATEGORY} component={GameQRCategoryPage} />
              <PrivateRoute path={ROUTE_NAME_GAMEQR_CHALLENGE} component={ChallengePage} />
              <PrivateRoute
                path={ROUTE_NAME_GAMEQR_COMPLETED_CHALLENGE}
                component={CompletedChallangePage}
              />
              <PrivateRoute path={ROUTE_NAME_GAMEQR_SCANNED_CODE} component={ScannedCodePage} />
              <PrivateRoute path={ROUTE_NAME_POINT_POINT} component={PointPage} />
              <PrivateRoute path={ROUTE_NAME_PURCHASE_REQUEST} component={PurchaseRequestPage} />
              <PrivateRoute
                path={ROUTE_NAME_POINT_POINT_SUBMISSION}
                component={PointSubmissionPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_POINT_POINT_TRANSACTION}
                component={PointTransactionPage}
              />
              <PrivateRoute path={ROUTE_NAME_POINT_ACHIEVEMENT} component={AchievementPage} />
              <PrivateRoute path={ROUTE_NAME_POINT_LEADER_BOARD} component={LeaderBoardPage} />
              <PrivateRoute path={ROUTE_NAME_DISCUSSION} component={DiscussionPage} />
              <PrivateRoute
                path={ROUTE_NAME_DISCUSSION_DISCUSSION_COMMENT}
                component={DiscussionCommentPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_DISCUSSION_REPORTED_COMMENT}
                component={ReportedCommentPage}
              />
              <PrivateRoute path={ROUTE_NAME_VOUCHER_VOUCHER} component={VoucherPage} />
              <PrivateRoute path={ROUTE_NAME_VOUCHER_TOKEN_REQUEST} component={TokenRequestPage} />
              <PrivateRoute path={ROUTE_NAME_VOUCHER_TOKEN} component={VoucherTokenPage} />
              <PrivateRoute
                path={ROUTE_NAME_VOUCHER_TOKEN_IMPORT}
                component={VoucherTokenImportPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_VOUCHER_PURCHASE_REQUEST}
                component={VoucherPurchaseRequestPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_VOUCHER_PROMOTED_MERCHANT}
                component={PromotedMerchantPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_VOUCHER_TOKEN_ASSIGNMENT}
                component={VoucherTokenAssignmentPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_VOUCHER_GIFT_VOUCHER_REDEMPTION}
                component={GiftVoucherRedemptionPage}
              />
              <PrivateRoute path={ROUTE_NAME_PARTNER_MERCHANT} component={PartnerMerchantPage} />
              <PrivateRoute path={ROUTE_NAME_VOUCHER_USAGE} component={VoucherUsagePage} />
              <PrivateRoute
                path={ROUTE_NAME_AUTH_REGISTRATION_ATTEMPT}
                component={AuthRegistrationAttemptPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_AUTH_PASSWORD_RESET_ATTEMPT}
                component={AuthPasswordResetAttemptPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_LOCATION}
                component={LocationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_CATEGORY}
                component={EventCategoryPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_CATEGORY}
                component={SessionCategoryPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION}
                component={SessionPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SPONSOR_CATEGORY}
                component={SponsorCategoryPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SPEAKER}
                component={SpeakerPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_INVITATION}
                component={EventInvitationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SPONSOR}
                component={SponsorPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_REGISTRATION}
                component={EventRegistrationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_REGISTRATION}
                component={SessionRegistrationPage}
              />
              <PrivateRoute path={ROUTE_NAME_ROOM} component={RoomPage} />
              <PrivateRoute path={ROUTE_NAME_EVENT} component={EventPage} />
              <PrivateRoute
                path={ROUTE_NAME_DAILY_CHECKIN}
                component={DailyCheckinPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_REGISTRATION_REFERRAL}
                component={RegistrationReferralPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_CAPTCHA_REQUEST}
                component={CaptchaRequestPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_INVITATION}
                component={SessionInvitationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_DFORM_FORM}
                component={FormPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_DFORM_FIELD}
                component={FieldPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_DFORM_PREVIEW}
                component={PreviewPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_DFORM_RESPONSE}
                component={ResponsePage}
              />
              <PrivateRoute
                path={ROUTE_NAME_PAYMENT}
                component={PaymentPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_OPERATOR_MAPPING}
                component={EventOperatorMappingPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_OPERATOR_INVITATION}
                component={EventOperatorInvitationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_OPERATOR_EVENT}
                component={EventOperatorEventPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_OPERATOR_MAPPING}
                component={SessionOperatorMappingPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_OPERATOR_INVITATION}
                component={SessionOperatorInvitationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_OPERATOR_SESSION}
                component={SessionOperatorSessionPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_SESSION_OPERATOR_REGISTRATION}
                component={SessionOperatorRegistrationPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_EVENT_OPERATOR_REGISTRATION}
                component={EventOperatorRegistrationPage}
              />
              <PrivateRoute path={ROUTE_NAME_WORKING_SHIFT} component={WorkingShiftPage} />
              <PrivateRoute path={ROUTE_DOCUMENT_GENERATOR_TEMPLATE} component={TemplatePage} />
              <PrivateRoute path={ROUTE_DOCUMENT_GENERATOR_DOCUMENT} component={DocumentPage} />
              <PrivateRoute path={ROUTE_NAME_APPROVAL} component={ApprovalPage} />
              <PrivateRoute path={ROUTE_NAME_APPROVAL_GROUP} component={ApprovalGroupPage} />
              <PrivateRoute path={ROUTE_NAME_ASSET} component={AssetPage} />
              <PrivateRoute path={ROUTE_NAME_ASSET_DETAIL} component={AssetPage} />
              <PrivateRoute
                path={ROUTE_NAME_CERTIFICATION_TYPE}
                component={CertificationTypePage}
              />
              <PrivateRoute
                path={ROUTE_NAME_CERTIFICATION_REQUEST}
                component={CertificationRequestPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL}
                component={CertificationRequestPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING}
                component={CertificationScheduleSettingPage}
              />
              <PrivateRoute
                path={ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL}
                component={CertificationScheduleSettingPage}
              />
              <PrivateRoute path={ROUTE_NAME_INBOX} component={InboxPage} />
              <PrivateRoute path={ROUTE_NAME_ASSET_SCREENING} component={AssetScreeningPage} />
              {/* all routes except these routes below must be PrivateRoute */}
              <Route path={ROUTE_NAME_LOGIN} component={LoginPage} />
              <Route path="/" component={SplashPage} />
            </Switch>
          </div>
        </Router>
      </PersistGate>
    </Provider>
  );
};
