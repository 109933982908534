import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW,
  RXFIELD_CERTIFICATION_TYPE_DESCRIPTION,
  RXFIELD_CERTIFICATION_TYPE_NAME,
  RXFORM_CERTIFICATION_TYPE,
  RXSTATE_CERTIFICATION_TYPES,
  RXSTATE_CERTIFICATION_TYPE_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { rxformValidateCertificationType } from '../../validation';

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CERTIFICATION_TYPE_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.certificationTypePage.labelName}
          label={LocalizedString.certificationTypePage.labelName}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_CERTIFICATION_TYPE_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.certificationTypePage.labelDescription}
          label={LocalizedString.certificationTypePage.labelDescription}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const CertificationTypePage = ({
  downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage,
  onChangePageSize, onConfirmDeletePressed, onCreatePressed,
  onDeletePressed, onEditPressed, onRefresh, onSubmitPressed,
  onSavePressed, onSearchBarTextChanged, onSortPressed,
  addingEditing, onViewPressed, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_CERTIFICATION_TYPES}
    uiPage={RXSTATE_CERTIFICATION_TYPE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.certificationTypePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.certificationTypePage.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.certificationTypePage.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSubmitPressed={onSubmitPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={(id) => onViewPressed(id)}
    title={LocalizedString.certificationTypePage.title}
    useFullWidth
    createNewButtonCaption={LocalizedString.certificationTypePage
      .buttonCaptionCreateNewCertificationType}
    deleteButtonCaption={LocalizedString.certificationTypePage.buttonCaptionDeleteCertificationType}
    editButtonCaption={LocalizedString.certificationTypePage.buttonCaptionEditCertificationType}
    editPermissionName="ECERTIFICATION_GET_CERTIFICATION_TYPE"
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CERTIFICATION_TYPE,
  validate: rxformValidateCertificationType,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CertificationTypePage);

CertificationTypePage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
