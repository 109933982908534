import { change } from 'redux-form';
import {
  setAlertErrorMessage, setFunctionalPageMode, setApprovalGroupTappedId,
  downloadingDeletingApprovalGroup, addApprovalGroup, clearApprovalGroups,
  setApprovalGroupTableDataCustomerMembers,
} from '../simple-action';
import { downloadApprovalGroup, deleteApprovalGroup, transformCustomerGroupDropdownData } from '../../../helper';
import {
  PAGE_MODE_TABLE, RXFORM_APPROVAL_GROUP, RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
} from '../../../constant';
import downloadApprovalGroupsAsync from './downloadApprovalGroupsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingApprovalGroup(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiApprovalGroup;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteApprovalGroup(tappedId, token);
      dispatch(setApprovalGroupTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearApprovalGroups());
      dispatch(downloadApprovalGroupsAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    } else {
      const approvalGroup = await downloadApprovalGroup(tappedId, token);
      dispatch(addApprovalGroup(approvalGroup));
      dispatch(setApprovalGroupTableDataCustomerMembers(
        transformCustomerGroupDropdownData(approvalGroup.members),
      ));
      dispatch(change(
        RXFORM_APPROVAL_GROUP,
        RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
        transformCustomerGroupDropdownData(approvalGroup.members),
      ));
    }
  } finally {
    dispatch(downloadingDeletingApprovalGroup(false));
  }
};
