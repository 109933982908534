import { downloadingAssetLocations, setAssetLocations } from '../simple-action';
import { downloadAssetLocations, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiAsset } = getState();
  const { token } = authentication;
  const { assetLocationSearchBarText } = uiAsset;

  try {
    dispatch(downloadingAssetLocations(true));

    const list = await downloadAssetLocations(transformSearchText(assetLocationSearchBarText),
      token);

    if (list) {
      dispatch(setAssetLocations(list));
    }
  } finally {
    dispatch(downloadingAssetLocations(false));
  }
};
