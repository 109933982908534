import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  PAGE_MODE_VIEW, FILTER_TYPE_DROPDOWN, RXFIELD_PROFILE_SYNC_COMPANY,
  RXFIELD_PROFILE_SYNC_EMAIL, RXFIELD_PROFILE_SYNC_DEPARTMENT,
  RXFIELD_PROFILE_SYNC_PHONE, RXFIELD_PROFILE_SYNC_DIVISION,
  RXFIELD_PROFILE_SYNC_EMPLOYEE_ID, PAGE_MODE_EDIT,
  RXFIELD_PROFILE_SYNC_FULL_NAME, RXSTATE_PROFILE_SYNC_PAGE,
  RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID,
  RXSTATE_PROFILE_SYNCS, RXFORM_PROFILE_SYNC, STATUS_DISABLED,
  RXFIELD_PROFILE_SYNC_BRANCH, status, STATUS_ENABLED,
  RXFIELD_PROFILE_SYNC_DATE_OF_BIRTH,
  RXFIELD_PROFILE_SYNC_GENDER, genders, PICKER_MODE_DATE, RXFIELD_PROFILE_SYNC_BRANCH_ID,
  RXFIELD_PROFILE_SYNC_JOB_TITLE,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormDateTimePickerField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';
import { rxformValidateProfileSync } from '../../validation';

const renderDialogContent = (pageMode, addingEditing, defaultGenderValue) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_EMPLOYEE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderEmployeeId}
            label={LocalizedString.profileSyncScreen.placeholderEmployeeId}
            disabled={pageMode === PAGE_MODE_EDIT || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_FULL_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderFullName}
            label={LocalizedString.profileSyncScreen.placeholderFullName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_EMAIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderEmail}
            label={LocalizedString.profileSyncScreen.placeholderEmail}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_PHONE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderPhone}
            label={LocalizedString.profileSyncScreen.placeholderPhone}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_MANAGER_EMPLOYEE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderManagerEmployeeId}
            label={LocalizedString.profileSyncScreen.placeholderManagerEmployeeId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_GENDER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderGender}
            label={LocalizedString.profileSyncScreen.placeholderGender}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={genders}
            value={defaultGenderValue}
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_JOB_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.labelJobTitle}
            label={LocalizedString.profileSyncScreen.labelJobTitle}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_DEPARTMENT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderDepartment}
            label={LocalizedString.profileSyncScreen.placeholderDepartment}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_DIVISION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderDivision}
            label={LocalizedString.profileSyncScreen.placeholderDivision}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_COMPANY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderCompany}
            label={LocalizedString.profileSyncScreen.placeholderCompany}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_BRANCH_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.profileSyncScreen.placeholderBranchId}
            label={LocalizedString.profileSyncScreen.placeholderBranchId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROFILE_SYNC_DATE_OF_BIRTH}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.profileSyncScreen.placeholderDateOfBirth}
            label={LocalizedString.profileSyncScreen.placeholderDateOfBirth}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE}
            disableFuture
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const ProfileSyncPage = ({
  initialValues, onCancelAdvancedFilterPressed, onSavePressed,
  addingEditing, downloading, currentProfileSyncStatus,
  handleSubmit, onAppear, onAdvancedFilterPressed,
  onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, branches, loadingBranch,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onViewPressed, onChangeBranchText, onBranchOptionSelected,
  pageMode, enablingDisabling, onEnableDisablePressed,
  onConfirmEnableDisablePressed, onCreatePressed, onSubmitPressed, onEditPressed,
  isDuplicateAllowed, onDuplicatePressed,
}) => (
  <FunctionalPage
    data={RXSTATE_PROFILE_SYNCS}
    uiPage={RXSTATE_PROFILE_SYNC_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.profileSyncScreen.buttonCaptionEnable,
        disabled: currentProfileSyncStatus === STATUS_ENABLED || enablingDisabling,
        onPress: (tappedId) => onEnableDisablePressed(tappedId,
          LocalizedString.profileSyncScreen.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.profileSyncScreen.buttonCaptionDisable,
        disabled: currentProfileSyncStatus === STATUS_DISABLED || enablingDisabling,
        onPress: (tappedId) => onEnableDisablePressed(tappedId,
          LocalizedString.profileSyncScreen.msgDisableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDuplicate,
        disabled: !isDuplicateAllowed,
        onPress: (tappedId) => { onEditPressed(tappedId); onDuplicatePressed(); },
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.profileSyncScreen.labelBranch,
        field: RXFIELD_PROFILE_SYNC_BRANCH,
        type: FILTER_TYPE_DROPDOWN,
        data: branches,
        loading: loadingBranch,
        onChangeFilterText: onChangeBranchText,
        onFilterOptionSelected: (option) => onBranchOptionSelected(option, false),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.profileSyncScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.profileSyncScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.profileSyncScreen.labelEmployeeId, field: 'employeeId', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelFullName, field: 'fullName', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelEmail, field: 'email', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelManagerId, field: 'managerEmployeeId', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelJobTitle, field: 'jobTitle', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelDepartment, field: 'department', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelCompany, field: 'company', sorting: !downloading },
      { title: LocalizedString.profileSyncScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmEnableDisablePressed}
    onCreatePressed={onCreatePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.profileSyncScreen.buttonCaptionCreateNewProfileSync}
    title={LocalizedString.profileSyncScreen.title}
    editButtonCaption={LocalizedString.profileSyncScreen.buttonCaptionEditProfileSync}
    disableDelete
    useFullWidth
    usefullWidthDialog
    createPermissionName="AUTHEXT_CREATE_PROFILE"
    editPermissionName="AUTHEXT_EDIT_PROFILE"
  >
    {renderDialogContent(pageMode, addingEditing, initialValues.gender.label)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PROFILE_SYNC,
  validate: rxformValidateProfileSync,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProfileSyncPage);

ProfileSyncPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingBranch: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onConfirmEnableDisablePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  currentProfileSyncStatus: PropTypes.string.isRequired,
  onDuplicatePressed: PropTypes.string.isRequired,
};
