import { reset } from 'redux-form';
import {
  addingAssetScreening, clearAssets, setAlertErrorMessage, setAssetChildDialogVisibility,
  setAssetScreeningDialogVisibility,
  setAssetScreeningSelectedType,
} from '../simple-action';
import { addAssetScreening } from '../../../helper';
import { RXFORM_ASSET_SCREENING } from '../../../constant';
import downloadAssetsAsync from './downloadAssetsAsync';

export default (selectedAssets) => async (dispatch, getState) => {
  try {
    dispatch(addingAssetScreening(true));

    const { token } = getState().authentication;
    const { dialogMode, selectedType } = getState().uiAssetScreening;
    const assetIds = selectedAssets.map((x) => x.id);

    await addAssetScreening(dialogMode, selectedType, assetIds, token);

    dispatch(setAssetScreeningSelectedType(''));
    dispatch(setAssetScreeningDialogVisibility(false, ''));
    dispatch(reset(RXFORM_ASSET_SCREENING));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingAssetScreening(false));
    dispatch(setAssetChildDialogVisibility(false));
  }
};
