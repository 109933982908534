import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    width: 20,
    height: 20,
    backgroundColor: (props) => props.backgroundColor,
    margin: '0px 10px 0px 0px',
  },
}));

const ColorBox = ({
  color,
}) => {
  const colorProps = {
    backgroundColor: color,
  };

  const classes = useStyles(colorProps);

  return (
    <div className={classes.container} />
  );
};

export default ColorBox;

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
};
