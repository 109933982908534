import { setApprovalProviders, downloadingApprovalProviders } from '../simple-action';
import { downloadApprovalProviders } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingApprovalProviders(true));

    const { token } = getState().authentication;

    const result = await downloadApprovalProviders(token);

    dispatch(setApprovalProviders(result));
  } finally {
    dispatch(downloadingApprovalProviders(false));
  }
};
