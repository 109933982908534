import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  approval,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, PAGE_MODE_EDIT, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME,
  RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE,
  RXFIELD_EVENT_CATEGORY_DESCRIPTION, RXFIELD_EVENT_CATEGORY_IMAGE, RXFIELD_EVENT_CATEGORY_NAME,
  RXFORM_EVENT_CATEGORY,
  RXSTATE_EVENT_CATEGORIES, RXSTATE_EVENT_CATEGORY_PAGE, status,
  STATUS_DISABLED, STATUS_ENABLED,
  IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import { FormInitialValueShape } from '../../type';
import { renderReduxFormDropzonePicker, renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateEventCategory } from '../../validation';
import { FunctionalPage } from '../../../configuration/component';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (tappedData, addingEditing, initialImage,
  pageMode, onImageSelected, onChangeClearImage, clearImage) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CATEGORY_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.categoryPage.placeholderName}
              label={LocalizedString.categoryPage.labelName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CATEGORY_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.categoryPage.placeholderDescription}
              label={LocalizedString.categoryPage.labelDescription}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              multiline
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CATEGORY_IMAGE}
              component={renderReduxFormDropzonePicker}
              label={LocalizedString.categoryPage.labelImage}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              loading={addingEditing}
              onImageSelected={onImageSelected}
              onBlur={(e) => e.preventDefault()}
              defaultValue={initialImage}
              imageFileExtension={IMAGE_FILE_EXTENSIONS}
            />
          </Grid>
          {initialImage && pageMode === PAGE_MODE_EDIT
          && (
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CATEGORY_CLEAR_IMAGE}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.categoryPage.labelClearImage}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              placeholder={LocalizedString.categoryPage.placeholderClearImage}
              data={approval}
              value={clearImage}
              onOptionSelected={onChangeClearImage}
            />
          </Grid>
          )}
        </Grid>
      </Grid>
    </div>
);

const EventCategoryPage = ({
  tappedData, initialValues,
  addingEditing, downloading, enablingDisabling,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed,
  onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed,
  onEditPressed, onContextMenuPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  eventCategories, currentCategoryStatus, pageMode,
  onDeletePressed, onImageSelected, onSavePressed, onChangeClearImage, multiple,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_EVENT_CATEGORIES}
      uiPage={RXSTATE_EVENT_CATEGORY_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentCategoryStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
          disableMultiple: false,
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentCategoryStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
          disableMultiple: false,
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.categoryPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.categoryPage.labelDescription, field: 'description', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.categoryPage.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={
        (reason, message) => onConfirmContextMenuPressed(reason, message, multiple)
      }
      onCreatePressed={onCreatePressed}
      onEditPressed={(id) => onEditPressed(id, eventCategories)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, eventCategories)}
      createNewButtonCaption={LocalizedString.categoryPage.buttonCaptionCreateNewCategory}
      deleteButtonCaption={LocalizedString.categoryPage.buttonCaptionDeleteCategory}
      editButtonCaption={LocalizedString.categoryPage.buttonCaptionEditCategory}
      title={LocalizedString.categoryPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_CATEGORY"
      deletePermissionName="EVENT_DELETE_CATEGORY"
      editPermissionName="EVENT_EDIT_CATEGORY"
      selection
    >
      {renderDialogContent(tappedData, addingEditing, initialValues.image,
        pageMode, onImageSelected, onChangeClearImage, initialValues.clearImage)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_EVENT_CATEGORY,
  validate: rxformValidateEventCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventCategoryPage);

EventCategoryPage.propTypes = {
  tappedData: FormInitialValueShape,
  clearImage: PropTypes.string,
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  multiple: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeClearImage: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  eventCategories: PropTypes.objectOf(PropTypes.object).isRequired,
  currentCategoryStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};

EventCategoryPage.defaultProps = {
  tappedData: null,
  clearImage: '',
};
