import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearProvinces, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setProvinceAdvancedFilterDialogSelectedFilterString, setProvinceSearchText,
  setProvinceSelectedPageSize, setProvinceSelectedOrderBy, setProvinceTappedId,
  setProvinceSelectedTimezone,
  addEditProvinceAsync, downloadProvincesAsync, enableDisableProvinceAsync, resyncProvinceAsync,
  saveProvincesAsync,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_PROVINCES, PAGE_MODE_TABLE, RXFIELD_PROVINCE_TIME_ZONE, RXFORM_PROVINCE,
  MENUID_MASTERDATA_PROVINCE,
} from '../../constant';
import { getPermission, transformInitialValues } from '../../helper';
import LocalizedString from '../../localization';
import ProvincePage from './province.presentation';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const getInitialValues = (state) => {
  const { provinces, uiFunctionalPage, uiProvince } = state;
  const { data } = provinces;
  const { downloadingDeleting, tappedId } = uiProvince;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found) : {
    name: '',
    code: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiProvince.addingEditing,
  downloading: state.uiProvince.downloading,
  enablingDisabling: state.uiProvince.enablingDisabling,
  resyncing: state.uiProvince.resyncing,
  currentProvinceStatus: state.provinces.data[state.uiFunctionalPage.tappedId]
    ? state.provinces.data[state.uiFunctionalPage.tappedId].status : '',
  initialValues: getInitialValues(state),
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_PROVINCE));
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProvinceSearchText(''));
    dispatch(clearProvinces());
    dispatch(setProvinceSelectedPageSize(20));
    dispatch(setProvinceSelectedOrderBy(INITIAL_ORDER_BY_PROVINCES));
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadProvincesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setProvinceSelectedPageSize(pageSize));
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PROVINCE));
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncProvinceAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableProvinceAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setProvinceTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setProvinceTappedId(''));
  },
  onDownloadPressed: () => {
    dispatch(saveProvincesAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEditPressed: (id) => {
    dispatch(setProvinceTappedId(id));
  },
  onRefresh: (pageSize) => {
    dispatch(setProvinceSelectedPageSize(pageSize));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setProvinceAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearProvinces());
    dispatch(setProvinceSelectedOrderBy(INITIAL_ORDER_BY_PROVINCES));
    dispatch(downloadProvincesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, code }) => {
    try {
      await dispatch(addEditProvinceAsync(name, code));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setProvinceSearchText(text));
      dispatch(clearProvinces());
      await dispatch(downloadProvincesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setProvinceSelectedOrderBy(orderBy));
    dispatch(clearProvinces());
    dispatch(downloadProvincesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, code }) => {
    await dispatch(addEditProvinceAsync(name, code));
  },
  onTimeZoneOptionSelected: (option) => {
    if (option) {
      dispatch(setProvinceSelectedTimezone(option));
      dispatch(change(RXFORM_PROVINCE, RXFIELD_PROVINCE_TIME_ZONE, option));
    } else {
      dispatch(setProvinceSelectedTimezone(''));
      dispatch(change(RXFORM_PROVINCE, RXFIELD_PROVINCE_TIME_ZONE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setProvinceTappedId(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProvincePage);
