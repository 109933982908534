import {
  SET_APPROVAL_PROCESSES, CLEAR_APPROVAL_PROCESSES,
} from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_APPROVAL_PROCESSES: {
      return action.data;
    }
    case CLEAR_APPROVAL_PROCESSES:
      return state;
    default:
      return state;
  }
};
