import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_CERTIFICATION_TYPES,
  MENUID_E_CERTIFICATION_CERTIFICATION_TYPE,
  PAGE_MODE_TABLE,
  RXFORM_CERTIFICATION_TYPE,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import {
  addEditCertificationTypeAsync,
  downloadDeleteCertificationTypeAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
} from '../../redux/action';
import CertificationTypePage from './certification-type.presentation';
import {
  clearCertificationTypes, setCertificationTypeAdvancedFilterDialogSelectedFilterString,
  setCertificationTypeSearchText, setCertificationTypeSelectedOrderBy,
  setCertificationTypeSelectedPageSize, setCertificationTypeTappedId,
  setAlertConfirmationMessage,
} from '../../redux/action/simple-action';
import downloadCertificationTypesAsync from '../../redux/action/async/downloadCertificationTypesAsync';
import LocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const {
    certificationTypes, uiFunctionalPage, uiCertificationType,
  } = state;
  const { data } = certificationTypes;
  const { downloadingDeleting, tappedId } = uiCertificationType;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found,
    {
      name: found.name,
      description: found.description,
    })
    : {
      name: '',
      description: '',
    };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  addingEditing: state.uiCertificationType.addingEditing,
  downloading: state.uiCertificationType.downloading,
  downloadingDeleting: state.uiCertificationType.downloadingDeleting,
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_CERTIFICATION_TYPE));
    dispatch(setCertificationTypeSearchText(''));
    dispatch(clearCertificationTypes());
    dispatch(setCertificationTypeSelectedPageSize(20));
    dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_CERTIFICATION_TYPE));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadCertificationTypesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setCertificationTypeSelectedPageSize(pageSize));
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteCertificationTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setCertificationTypeTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setCertificationTypeTappedId(id));
    dispatch(setAlertConfirmationMessage(LocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setCertificationTypeTappedId(id));
    dispatch(downloadDeleteCertificationTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setCertificationTypeSelectedPageSize(pageSize));
    dispatch(clearCertificationTypes());
    dispatch(downloadCertificationTypesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, description,
  }) => {
    try {
      await dispatch(addEditCertificationTypeAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setCertificationTypeSelectedOrderBy(orderBy));
    dispatch(clearCertificationTypes());
    dispatch(downloadCertificationTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description,
  }) => {
    await dispatch(addEditCertificationTypeAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setCertificationTypeTappedId(id));
    dispatch(downloadDeleteCertificationTypeAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationTypePage);
