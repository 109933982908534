import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AccentButton, AlertBox, FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM, PAGE_MODE_TABLE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME, RESULT_TYPES, REVERSED_ISO_DATE_FORMAT,
  ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL, RXFIELD_ASSET_ASSET_DESCRIPTION,
  RXFIELD_ASSET_ASSET_NUMBER, RXFIELD_ASSET_ASSET_TYPE, RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
  RXFIELD_ASSET_CERTIFICATION_SCHEDULE_DATE, RXFIELD_ASSET_CERTIFICATION_TYPE, RXFIELD_ASSET_ISSUER,
  RXFIELD_ASSET_LAST_PROGRESS, RXFIELD_ASSET_LOCATION, RXFIELD_ASSET_OWNER, RXFIELD_ASSET_PART_CODE,
  RXFIELD_ASSET_SERIAL_NUMBER, RXFIELD_ASSET_SOURCE_ID, RXSTATE_ASSETS, RXSTATE_ASSET_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';
import CertificationScheduleSettingDetailPage from './detail';
import SetScheduleDialog from './set-schedule-dialog';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  setScheduleButton: {
    marginLeft: 8,
  },
}));

const CertificationScheduleSettingPage = ({
  assetTypes, certificationTypes, locations, managers, profiles,
  downloading, loadingAssetTypes, loadingCertificationTypes, loadingManagers, loadingLocations,
  loadingProfiles,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeAssetTypeText, onChangeCertTypeText, onChangeManagerText, onChangeLocationText,
  onChangePage, onChangePageSize, onChangeProfileText, onConfirmDeletePressed, onCreatePressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSetSchedulePressed,
  onSortPressed, onViewPressed,
  myConfigItems, pageMode,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_ASSETS}
        uiPage={RXSTATE_ASSET_PAGE}
        tableColumns={[
          { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloading },
          { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloading },
          { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetClass, field: 'category.name', sorting: !downloading },
          { title: LocalizedString.assetPage.labelLocation, field: 'location.name', sorting: !downloading },
          { title: LocalizedString.certificationScheduleSettingPage.labelType, field: 'certificationType', sorting: !downloading },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelExpired,
            field: 'certificateExpirationDate',
            sorting: !downloading,
            render: ({ certificateExpirationDate }) => (certificateExpirationDate
              ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
          },
          { title: LocalizedString.certificationScheduleSettingPage.labelLastProgress, field: 'certificateResultType', sorting: !downloading },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelSchedule,
            field: 'certificationScheduledDate',
            sorting: !downloading,
            render: ({ certificationScheduledDate }) => (certificationScheduledDate
              ? toMoment(certificationScheduledDate).format(REVERSED_ISO_DATE_FORMAT) : null),
          },
          { title: LocalizedString.certificationScheduleSettingPage.labelIssuer, field: 'certificateSubmitter.fullName', sorting: !downloading },
          { title: LocalizedString.certificationScheduleSettingPage.labelOwner, field: 'owner.fullName', sorting: !downloading },
        ]}
        filterColumns={[
          {
            title: LocalizedString.assetPage.labelId,
            field: RXFIELD_ASSET_SOURCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetDescription,
            field: RXFIELD_ASSET_ASSET_DESCRIPTION,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelPartCode,
            field: RXFIELD_ASSET_PART_CODE,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelSerialNumber,
            field: RXFIELD_ASSET_SERIAL_NUMBER,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetNumber,
            field: RXFIELD_ASSET_ASSET_NUMBER,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetClass,
            field: RXFIELD_ASSET_ASSET_TYPE,
            type: FILTER_TYPE_DROPDOWN,
            data: assetTypes,
            loading: loadingAssetTypes,
            onChangeFilterText: onChangeAssetTypeText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.assetPage.labelLocation,
            field: RXFIELD_ASSET_LOCATION,
            type: FILTER_TYPE_DROPDOWN,
            data: locations,
            loading: loadingLocations,
            onChangeFilterText: onChangeLocationText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelType,
            field: RXFIELD_ASSET_CERTIFICATION_TYPE,
            type: FILTER_TYPE_DROPDOWN,
            data: certificationTypes,
            loading: loadingCertificationTypes,
            onChangeFilterText: onChangeCertTypeText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.assetPage.labelExpired,
            field: RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelLastProgress,
            field: RXFIELD_ASSET_LAST_PROGRESS,
            type: FILTER_TYPE_DROPDOWN,
            data: RESULT_TYPES,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelSchedule,
            field: RXFIELD_ASSET_CERTIFICATION_SCHEDULE_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.certificationScheduleSettingPage.labelIssuer,
            field: RXFIELD_ASSET_ISSUER,
            type: FILTER_TYPE_DROPDOWN,
            data: managers,
            loading: loadingManagers,
            onChangeFilterText: onChangeManagerText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.assetPage.labelOwner,
            field: RXFIELD_ASSET_OWNER,
            type: FILTER_TYPE_DROPDOWN,
            data: profiles,
            loading: loadingProfiles,
            onChangeFilterText: onChangeProfileText,
            useDropdownValue: true,
          },
        ]}
        customSelectionActionButton={(
          <AccentButton
            variant="contained"
            caption={LocalizedString.certificationScheduleSettingPage.buttonCaptionSetSchedule}
            disabled={downloading}
            onClick={onSetSchedulePressed}
            size="small"
            style={classes.setScheduleButton}
          />
        )}
        onAppearWithDetailRoute={onAppear}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onCreatePressed={onCreatePressed}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSelectionChange={setSelectedRows}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        title={LocalizedString.certificationScheduleSettingPage.title}
        detailTitle={LocalizedString.certificationScheduleSettingPage.titleDetail}
        useFullWidth
        disableActions
        disableCreate={pageMode === PAGE_MODE_TABLE}
        disableEdit
        disableDelete
        selection
        useCreateButtonInViewMode
        createNewButtonCaption={LocalizedString.certificationScheduleSettingPage
          .buttonCaptionAddCertificate}
        createPermissionName="ECERTIFICATION_CREATE_ASSET_CERTIFICATE"
        editPermissionName="ECERTIFICATION_GET_ASSET"
        useTwoColumnFilterDialog
        contentMode={FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM}
        detailRoute={ROUTE_NAME_CERTIFICATION_SCHEDULE_SETTING_DETAIL}
      >
        <CertificationScheduleSettingDetailPage />
      </FunctionalPage>
      <SetScheduleDialog selectedAssets={selectedRows} />
      <AlertBox onConfirmPressed={(message, reason) => onConfirmDeletePressed(message, reason,
        history, myConfigItems)}
      />
    </div>
  );
};

export default CertificationScheduleSettingPage;

CertificationScheduleSettingPage.propTypes = {
  assetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  certificationTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  managers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingAssetTypes: PropTypes.bool.isRequired,
  loadingCertificationTypes: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loadingManagers: PropTypes.bool.isRequired,
  loadingProfiles: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAssetTypeText: PropTypes.func.isRequired,
  onChangeCertTypeText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangeManagerText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeProfileText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSetSchedulePressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  myConfigItems: PropTypes.object.isRequired,
  pageMode: PropTypes.string.isRequired,
};
