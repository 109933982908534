import {

} from '../action';
import { INITIAL_ORDER_BY_APPROVAL_GROUPS } from '../../constant';
import {
  DOWNLOADING_APPROVAL_GROUPS,
  SET_APPROVAL_GROUP_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_APPROVAL_GROUP_SEARCH_TEXT, SET_APPROVAL_GROUP_SELECTED_ORDER_BY,
  SET_APPROVAL_GROUP_SELECTED_PAGE_SIZE, SET_APPROVAL_GROUP_TAPPED_ID,
  SET_APPROVAL_GROUP_TABLE_DATA_CUSTOMER_MEMBERS,
  SET_APPROVAL_GROUP_SELECTED_MEMBER_GROUP,
  ADDING_APPROVAL_GROUP_MEMBER_GROUP,
  ADDING_EDITING_APPROVAL_GROUP,
  DOWNLOADING_DELETING_APPROVAL_GROUP,
  ENABLING_DISABLING_APPROVAL_GROUP,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  orderBy: INITIAL_ORDER_BY_APPROVAL_GROUPS,
  filterString: '',
  tableDataGroupMembers: [],
  selectedMemberGroup: '',
  addingCustomGroup: false,
  addingEditingApprovalGroup: false,
  downloadDeletingApprovalGroup: false,
  enablingDisablingApprovalGroup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_APPROVAL_GROUPS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_APPROVAL_GROUP:
      return { ...state, addingEditingApprovalGroup: action.status };
    case DOWNLOADING_DELETING_APPROVAL_GROUP:
      return { ...state, downloadDeletingApprovalGroup: action.status };
    case SET_APPROVAL_GROUP_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_APPROVAL_GROUP_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_APPROVAL_GROUP_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_APPROVAL_GROUP_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_APPROVAL_GROUP_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_APPROVAL_GROUP_TABLE_DATA_CUSTOMER_MEMBERS:
      return { ...state, tableDataGroupMembers: action.option };
    case SET_APPROVAL_GROUP_SELECTED_MEMBER_GROUP:
      return { ...state, selectedMemberGroup: action.option };
    case ADDING_APPROVAL_GROUP_MEMBER_GROUP:
      return { ...state, addingCustomGroup: action.status };
    case ENABLING_DISABLING_APPROVAL_GROUP:
      return { ...state, enablingDisablingApprovalGroup: action.status };
    default: return state;
  }
};
