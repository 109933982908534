import {
  ADD_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_FORM, CLEAR_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_FORM,
  SET_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
} from '../action';

const initialState = {
  filterString: '',
  advancedFilterForm: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ADD_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_FORM:
      return {
        ...state,
        advancedFilterForm: { ...state.advancedFilterForm, [action.field]: action.value },
      };
    case CLEAR_EDITABLE_TABLE_FIELD_ADVANCED_FILTER_FORM:
      return { ...state, advancedFilterForm: {} };
    default: return state;
  }
};
