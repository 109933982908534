import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import {
  COLOR_BODY_TEXT_LIGHT, COLOR_PRIMARY, COLOR_SECONDARY, COLOR_TEXT_BLACK_SECONDARY,
} from '../constant';
import { SimpleDataShape } from '../type';

const useStyles = makeStyles(() => ({
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
  headerContainer: {
    borderRadius: 4,
    border: `1px solid ${COLOR_SECONDARY}`,
    padding: '8px 16px',
    marginBottom: 16,
  },
  title: {
    color: COLOR_PRIMARY,
    marginBottom: 8,
  },
  labelText: {
    color: COLOR_BODY_TEXT_LIGHT,
  },
  valueText: {
    color: COLOR_TEXT_BLACK_SECONDARY,
  },
}));

const InformationCard = ({ data, loading }) => {
  const classes = useStyles();

  return (
    <Paper
      className={classes.headerContainer}
      elevation={0}
      square
    >
      {loading ? (
        <div className={classes.activityIndicator}>
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <div>
          {data.title && (<Typography variant="body1" className={classes.title}>{data.title}</Typography>)}
          <Grid container spacing={3}>
            {data.body.map((column, columnIndex) => (
              <Grid item sm md key={`column${columnIndex.toString()}`}>
                {column.row.map((row, rowIndex) => (
                  <div style={rowIndex % 2 === 0 ? { marginTop: 8 } : undefined}>
                    <Grid item>
                      <Typography variant="body2" className={classes.labelText}>
                        {row.label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.valueText}>
                        {row.value || '-'}
                      </Typography>
                    </Grid>
                  </div>
                ))}
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Paper>
  );
};

export default InformationCard;

const RowShape = PropTypes.shape({
  row: PropTypes.arrayOf(SimpleDataShape),
});

const InformationCardShape = PropTypes.shape({
  title: PropTypes.string,
  body: PropTypes.arrayOf(RowShape),
});

InformationCard.propTypes = {
  data: InformationCardShape.isRequired,
  loading: PropTypes.bool.isRequired,
};
