import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CERTIFICATION_STATUS_NEED, CERTIFICATION_STATUS_NO_NEED, ERR_TOKEN_SESSION_EXPIRED,
  INITIAL_ORDER_BY_ASSETS, MENUID_E_CERTIFICATION_ASSET_SCREENING, ROUTE_NAME_ASSET_SCREENING,
  ROUTE_NAME_LOGIN,
} from '../../constant';
import { removeAllStorage, transformDropdownData } from '../../helper';
import {
  clearAllStates, clearAssetLocations, clearAssets, clearAssetTypes, downloadAssetLocationsAsync,
  downloadAssetsAsync, downloadAssetTypesAsync, setActiveSideMenuItem, setAlertErrorMessage,
  setAssetAdvancedFilterDialogSelectedFilterString, setAssetLocationSearchText,
  setAssetScreeningDialogVisibility, setAssetSearchText, setAssetSelectedOrderBy,
  setAssetSelectedPageSize, setAssetTappedId, setAssetTypeSearchText,
} from '../../redux/action';
import { logoutAsync } from '../../../../redux/action';
import { downloadMyConfigItemsAsync } from '../../../configuration/redux/action';
import GlobalLocalizedString from '../../../../localization';
import AssetScreeningPage from './asset-screening.presentation';

const mapStateToProps = (state) => ({
  assetTypes: transformDropdownData(state.assetTypes.data),
  locations: transformDropdownData(state.assetLocations.data),
  downloading: state.uiAsset.downloading,
  loadingAssetTypes: state.uiAsset.downloadingAssetTypes,
  loadingLocations: state.uiAsset.downloadingAssetLocations,
  myConfigItems: state.myConfigItems,
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setAssetTypeSearchText(''));
    dispatch(clearAssetTypes());
    dispatch(downloadAssetTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setAssetScreeningDialogVisibility(false, ''));
    dispatch(setActiveSideMenuItem(MENUID_E_CERTIFICATION_ASSET_SCREENING));
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setAssetSearchText(''));
    dispatch(clearAssets());
    dispatch(setAssetSelectedPageSize(20));
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id').replace(/category/, 'category.id');
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: (history) => {
    dispatch(setAssetTappedId(''));
    history.push(ROUTE_NAME_ASSET_SCREENING);
  },
  onChangeAssetTypeText: async (text) => {
    try {
      dispatch(setAssetTypeSearchText(text));
      dispatch(clearAssetTypes());
      await dispatch(downloadAssetTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeLocationText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmLogoutPressed: async (message, reason, history, myConfigItems) => {
    if (message.toLowerCase().includes(ERR_TOKEN_SESSION_EXPIRED)) {
      await removeAllStorage();
      dispatch(clearAllStates());
      history.push(ROUTE_NAME_LOGIN);
    } else if (message === GlobalLocalizedString.common.msgLogoutConfirmation) {
      if (isEmpty(myConfigItems)) {
        await dispatch(downloadMyConfigItemsAsync())
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
      dispatch(logoutAsync(history.push, message))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      // insert confirmation function here
    }
  },
  onNeedCertificationPressed: () => {
    dispatch(setAssetScreeningDialogVisibility(true, CERTIFICATION_STATUS_NEED));
  },
  onNoNeedCertificationPressed: () => {
    dispatch(setAssetScreeningDialogVisibility(true, CERTIFICATION_STATUS_NO_NEED));
  },
  onRefresh: (pageSize) => {
    dispatch(setAssetSelectedPageSize(pageSize));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAssetAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearAssets());
    dispatch(setAssetSelectedOrderBy(INITIAL_ORDER_BY_ASSETS));
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setAssetSearchText(text));
      dispatch(clearAssets());
      await dispatch(downloadAssetsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetSelectedOrderBy(orderBy));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetScreeningPage);
