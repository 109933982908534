import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  CERTIFICATION_STATUS_NEED, INITIAL_ORDER_BY_CERTIFICATION_TYPES, PAGE_MODE_TABLE,
  RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE, RXFORM_ASSET_SCREENING,
} from '../../../constant';
import { transformDropdownData, transformInitialValues } from '../../../helper';
import {
  addAssetScreeningAsync, clearCertificationTypes, downloadCertificationTypesAsync,
  setAlertErrorMessage, setAssetScreeningDialogVisibility, setAssetScreeningSelectedType,
  setCertificationTypeAdvancedFilterDialogSelectedFilterString, setCertificationTypeSearchText,
  setCertificationTypeSelectedOrderBy, setCertificationTypeSelectedPageSize,
} from '../../../redux/action';
import AddEditCertificationScheduleSettingDialog from './confirmation-dialog.presentation';

const getInitialValues = (state) => {
  const { assets, uiFunctionalPage, uiAsset } = state;
  const { data } = assets;
  const { downloadingDeleting, tappedId } = uiAsset;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  certificationTypes: transformDropdownData(state.certificationTypes.data),
  adding: state.uiAssetScreening.adding,
  dialogVisibility: state.uiAssetScreening.dialogVisibility,
  loadingCertificationTypes: state.uiCertificationType.downloading,
  dialogMode: state.uiAssetScreening.dialogMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (dialogMode) => {
    dispatch(setAssetScreeningSelectedType(''));
    if (dialogMode === CERTIFICATION_STATUS_NEED) {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(''));
      dispatch(clearCertificationTypes());
      dispatch(setCertificationTypeSelectedPageSize(20));
      dispatch(setCertificationTypeSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_TYPES));
      dispatch(downloadCertificationTypesAsync(1))
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }
  },
  onCancelPressed: () => {
    dispatch(setAssetScreeningSelectedType(''));
    dispatch(reset(RXFORM_ASSET_SCREENING));
    dispatch(setAssetScreeningDialogVisibility(false, ''));
  },
  onChangeCertTypeText: async (text) => {
    try {
      dispatch(setCertificationTypeAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCertificationTypeSearchText(text));
      dispatch(clearCertificationTypes());
      await dispatch(downloadCertificationTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onCertTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setAssetScreeningSelectedType(option.value));
      dispatch(change(RXFORM_ASSET_SCREENING, RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE,
        option));
    } else {
      dispatch(setAssetScreeningSelectedType(''));
      dispatch(change(RXFORM_ASSET_SCREENING, RXFIELD_ASSET_SCREENING_CERTIFICATION_TYPE, ''));
    }
  },
  onSubmitPressed: async (selectedAssets) => {
    try {
      await dispatch(addAssetScreeningAsync(selectedAssets));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  AddEditCertificationScheduleSettingDialog,
);
