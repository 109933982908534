import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearWorkingShifts, setActiveSideMenuItem, setAlertErrorMessage,
  setWorkingShiftAdvancedFilterDialogSelectedFilterString,
  setWorkingShiftSearchText, setWorkingShiftSelectedPageSize,
  setWorkingShiftSelectedOrderBy, setWorkingShiftTappedId,
  addEditWorkingShiftAsync, downloadWorkingShiftsAsync,
  saveWorkingShiftsAsync, setWorkingShiftSelectedBranchId,
  setBranchAdvancedFilterDialogSelectedFilterString,
  setBranchSearchText, setDialogVisibility,
  clearBranches,
  downloadBranchesAsync,
  downloadDeleteWorkingShiftAsync,
  setAlertConfirmationMessage,
  enableDisableWorkingShiftAsync,
  setWorkingShiftDuplicateMode,
} from '../../redux/action';
import {
  INITIAL_ORDER_BY_WORKING_SHIFTS, MENUID_MASTERDATA_WORKING_SHIFT, PAGE_MODE_TABLE,
  RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_SHIFT_BRANCH,
  RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_WORKING_SHIFT_END_WORKING_HOUR,
  RXFIELD_WORKING_SHIFT_START_WORKING_HOUR, RXFORM_WORKING_SHIFT, TIME_FORMAT, TIME_MODE_CLOCK_IN,
  TIME_MODE_CLOCK_OUT, DIALOG_TYPE_ADD,
} from '../../constant';
import {
  convertClockInOutMinutesToHours, convertClockInOutHoursToMinutes, transformDropdownData,
  transformInitialValues, toMoment, toNumber, debounceSearch, getPermission,
} from '../../helper';
import WorkingShiftPage from './working-shift.presentation';
import resyncWorkingShiftAsync from '../../redux/action/async/resyncWorkingShiftAsync';
import LocalizedString from '../../localization';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../module/configuration/redux/action';

const createPermissionName = 'MDATA_CREATE_WORKINGSHIFT';

const getInitialValues = (state) => {
  const { workingShifts, uiWorkingShift, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId, duplicateMode } = uiWorkingShift;
  const { pageMode } = uiFunctionalPage;

  const found = (pageMode !== PAGE_MODE_TABLE && !downloadingDeleting) || duplicateMode
    ? workingShifts.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        branch: { label: found.branch?.name || '', value: found.branch?.id || '' },
        earliestClockInDriftTimeMinutes: convertClockInOutHoursToMinutes(
          found.earliestClockInDriftTimeMinutes, found.startWorkingHour, TIME_MODE_CLOCK_IN,
        ),
        autoClockOutAfterMinutes: convertClockInOutHoursToMinutes(
          found.autoClockOutAfterMinutes, found.endWorkingHour, TIME_MODE_CLOCK_OUT,
        ),
      })
    : {
      name: '',
      branch: { label: '', value: '' },
      startWorkingHour: toMoment().format(TIME_FORMAT),
      startWorkingHourMarginBeforeMinutes: '',
      startWorkingHourMarginAfterMinutes: '',
      endWorkingHour: toMoment().format(TIME_FORMAT),
      endWorkingHourMarginBeforeMinutes: '',
      endWorkingHourMarginAfterMinutes: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  branches: transformDropdownData(state.branches.data),
  loadingBranch: state.uiBranch.downloading,
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiWorkingShift.addingEditing,
  resyncing: state.uiWorkingShift.resyncing,
  isResyncAllowed: getPermission(state, 'MDATA_RESYNC_MASTERDATA'),
  downloading: state.uiWorkingShift.downloading,
  workingShifts: state.workingShifts.data,
  initialValues: getInitialValues(state),
  enablingDisabling: state.uiWorkingShift.enablingDisabling,
  isDuplicateAllowed: getPermission(state, createPermissionName),
  currentWorkShiftStatus: state.workingShifts.data[state.uiFunctionalPage.tappedId]
    ? state.workingShifts.data[state.uiFunctionalPage.tappedId].status : '',
}
);

const searchBranchDebounce = debounceSearch(
  (dispatch) => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_MASTERDATA_WORKING_SHIFT));
    dispatch(setWorkingShiftSearchText(''));
    dispatch(clearWorkingShifts());
    dispatch(setWorkingShiftSelectedPageSize(20));
    dispatch(setWorkingShiftSelectedOrderBy(INITIAL_ORDER_BY_WORKING_SHIFTS));
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/branch/, 'branch.id');
    dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearWorkingShifts());
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAutoClockOutChanged: (date) => {
    if (date) {
      dispatch(change(
        RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES, date,
      ));
    } else {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES, ''));
    }
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_WORKING_SHIFT));
    dispatch(setWorkingShiftDuplicateMode(false));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWorkingShiftsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWorkingShiftSelectedPageSize(pageSize));
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setWorkingShiftTappedId(''));

    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: () => {
    dispatch(saveWorkingShiftsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onEarliestClockInChanged: (date) => {
    if (date) {
      dispatch(change(
        RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, date,
      ));
    } else {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setWorkingShiftTappedId(id));

    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEndWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_END_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_END_WORKING_HOUR, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setWorkingShiftSelectedPageSize(pageSize));
    dispatch(clearWorkingShifts());
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setWorkingShiftAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearWorkingShifts());
    dispatch(setWorkingShiftSelectedOrderBy(INITIAL_ORDER_BY_WORKING_SHIFTS));
    dispatch(downloadWorkingShiftsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, startWorkingHour, startWorkingHourMarginBeforeMinutes, startWorkingHourMarginAfterMinutes,
    endWorkingHour, endWorkingHourMarginBeforeMinutes, endWorkingHourMarginAfterMinutes,
    earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes,
  }) => {
    try {
      await dispatch(addEditWorkingShiftAsync(
        name,
        startWorkingHour, toNumber(startWorkingHourMarginBeforeMinutes),
        toNumber(startWorkingHourMarginAfterMinutes),
        endWorkingHour, toNumber(endWorkingHourMarginBeforeMinutes),
        toNumber(endWorkingHourMarginAfterMinutes),
        convertClockInOutMinutesToHours(earliestClockInDriftTimeMinutes, startWorkingHour,
          endWorkingHour, TIME_MODE_CLOCK_IN),
        convertClockInOutMinutesToHours(autoClockOutAfterMinutes, startWorkingHour, endWorkingHour,
          TIME_MODE_CLOCK_OUT),
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWorkingShiftSearchText(text));
      dispatch(clearWorkingShifts());
      await dispatch(downloadWorkingShiftsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWorkingShiftSelectedOrderBy(orderBy));
    dispatch(clearWorkingShifts());
    dispatch(downloadWorkingShiftsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onStartWorkingHourChanged: (date) => {
    if (date) {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_START_WORKING_HOUR, date));
    } else {
      dispatch(change(RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_START_WORKING_HOUR, ''));
    }
  },
  onSubmitPressed: async ({
    name,
    startWorkingHour, startWorkingHourMarginBeforeMinutes, startWorkingHourMarginAfterMinutes,
    endWorkingHour, endWorkingHourMarginBeforeMinutes, endWorkingHourMarginAfterMinutes,
    earliestClockInDriftTimeMinutes, autoClockOutAfterMinutes,
  }) => {
    await dispatch(addEditWorkingShiftAsync(
      name,
      startWorkingHour, toNumber(startWorkingHourMarginBeforeMinutes),
      toNumber(startWorkingHourMarginAfterMinutes),
      endWorkingHour, toNumber(endWorkingHourMarginBeforeMinutes),
      toNumber(endWorkingHourMarginAfterMinutes),
      convertClockInOutMinutesToHours(earliestClockInDriftTimeMinutes, startWorkingHour,
        endWorkingHour, TIME_MODE_CLOCK_IN),
      convertClockInOutMinutesToHours(autoClockOutAfterMinutes, startWorkingHour, endWorkingHour,
        TIME_MODE_CLOCK_OUT),
    ));
  },
  onViewPressed: (id) => {
    dispatch(setWorkingShiftTappedId(id));

    dispatch(setBranchAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setBranchSearchText(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(downloadDeleteWorkingShiftAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeBranchText: (text) => {
    dispatch(setBranchSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchBranchDebounce(dispatch, true);
    }
  },
  onBranchOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setWorkingShiftSelectedBranchId(option.value));
      if (changeForm) {
        dispatch(change(
          RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_BRANCH, option,
        ));
      }
    } else {
      dispatch(setWorkingShiftSelectedBranchId(''));
      if (changeForm) {
        dispatch(change(
          RXFORM_WORKING_SHIFT, RXFIELD_WORKING_SHIFT_BRANCH, '',
        ));
      }
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.common.msgResyncConfirmation:
        dispatch(resyncWorkingShiftAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableWorkingShiftAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setWorkingShiftTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onDuplicatePressed: () => {
    dispatch(setDialogVisibility(true, DIALOG_TYPE_ADD,
      LocalizedString.workingShiftPage.buttonCaptionDuplicateWorkingShift));
    dispatch(setWorkingShiftDuplicateMode(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkingShiftPage);
