/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { CERT_REQUEST_DIALOG_MODE_SELECT_FILE } from '../../../constant';
import { EditableTableField, StyledDialog } from '../../../component';
import LocalizedString from '../../../localization';
import GlobalLocalizedString from '../../../../../localization';

const pdfIcon = require('../../../../../asset/pdf-icon.png');

const SelectFileDialog = ({
  assetDocuments,
  dialogVisibility, downloading,
  onSortPressed, onCancelPressed, onChangePage, onChangePageSize, onDownloadPressed, onPdfPressed,
  currentPage, selectedPageSize, totalCount,
  dialogMode, orderBy,
}) => {
  const [selectedAssetRows, setSelectedAssetRows] = useState([]);

  return (
    <StyledDialog
      disableSubmitButton={!selectedAssetRows.length || downloading}
      visibility={dialogVisibility && dialogMode === CERT_REQUEST_DIALOG_MODE_SELECT_FILE}
      onCancelPressed={onCancelPressed}
      onSubmitPressed={() => onDownloadPressed(selectedAssetRows)}
      title={LocalizedString.certificationRequestPage.titleSelectFile}
      submitButtonCaption={GlobalLocalizedString.common.buttonCaptionDownload}
    >
      <EditableTableField
        data={assetDocuments}
        loading={downloading}
        tableColumns={[
          {
            title: LocalizedString.certificationRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.certificationRequestPage.labelFileName, field: 'fileName', sorting: !downloading },
          {
            title: GlobalLocalizedString.common.labelAction,
            field: 'file',
            sorting: !downloading,
            render: ({ file, fileName }) => (file ? (
              <IconButton
                aria-label="download file"
                onClick={() => onPdfPressed(file, fileName)}
              >
                <img alt="cert-file" src={pdfIcon} width={20} height={20} />
              </IconButton>
            ) : null),
          },
        ]}
        disabled
        disableToolbar
        paging
        selection
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onSelectionChange={setSelectedAssetRows}
        onSortPressed={onSortPressed}
        currentPage={currentPage}
        totalCount={totalCount}
        selectedPageSize={selectedPageSize}
        orderBy={orderBy}
      />
    </StyledDialog>
  );
};

export default SelectFileDialog;

SelectFileDialog.propTypes = {
  assetDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onPdfPressed: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  dialogMode: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
