import { SET_CERTIFICATION_REQUEST_APPROVALS, CLEAR_CERTIFICATION_REQUEST_APPROVALS } from '../action';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CERTIFICATION_REQUEST_APPROVALS:
      return action.data;
    case CLEAR_CERTIFICATION_REQUEST_APPROVALS:
      return {};
    default:
      return state;
  }
};
