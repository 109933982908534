import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AccentButton, AlertBox, FunctionalPage } from '../../component';
import {
  CERTIFICATION_STATUSES, CERTIFICATION_STATUS_NEED, CERTIFICATION_STATUS_NO_NEED, COLOR_APPROVED,
  COLOR_APPROVED_LIGHTER, COLOR_BACKGROUND, COLOR_PRIMARY, COLOR_REJECTED, COLOR_REJECTED_LIGHTER,
  COLOR_SECONDARY, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM,
  FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_FOOTER, RXFIELD_ASSET_ASSET_DESCRIPTION,
  RXFIELD_ASSET_ASSET_NUMBER, RXFIELD_ASSET_ASSET_TYPE, RXFIELD_ASSET_LOCATION,
  RXFIELD_ASSET_PART_CODE, RXFIELD_ASSET_SCREENING_CERTIFICATION_STATUS,
  RXFIELD_ASSET_SERIAL_NUMBER, RXFIELD_ASSET_SOURCE_ID, RXSTATE_ASSETS, RXSTATE_ASSET_PAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';
import CertificationConfirmationDialog from './confirmation-dialog';

const useStyles = makeStyles(() => ({
  rowContainer: {
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR_BACKGROUND,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderLeft: `1px solid ${COLOR_SECONDARY}`,
    borderRight: `1px solid ${COLOR_SECONDARY}`,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
  },
  rightButton: {
    marginLeft: 8,
  },
}));

const styles = {
  statusContainer: {
    padding: 2,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const renderCertificationStatus = (certificationStatus) => {
  let statusProps;
  switch (certificationStatus) {
    case CERTIFICATION_STATUS_NO_NEED:
      statusProps = {
        color: COLOR_APPROVED,
        backgroundColor: COLOR_APPROVED_LIGHTER,
        label: LocalizedString.assetScreeningPage.labelNoNeedCertification,
      }; break;
    case CERTIFICATION_STATUS_NEED:
      statusProps = {
        color: COLOR_REJECTED,
        backgroundColor: COLOR_REJECTED_LIGHTER,
        label: LocalizedString.assetScreeningPage.labelNeedCertification,
      }; break;
    default: statusProps = {
      color: COLOR_PRIMARY,
      backgroundColor: COLOR_SECONDARY,
      label: certificationStatus,
    }; break;
  }

  if (certificationStatus && certificationStatus.toLowerCase() !== 'undefined') {
    return (
      <span style={{
        backgroundColor: statusProps.backgroundColor,
        border: `1px solid ${statusProps.color}`,
        ...styles.statusContainer,
      }}
      >
        <Typography variant="caption" style={{ color: statusProps.color }}>
          {statusProps.label}
        </Typography>
      </span>
    );
  }
  return null;
};

const AssetScreeningPage = ({
  assetTypes, locations,
  downloading, loadingAssetTypes, loadingLocations,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeAssetTypeText, onChangeLocationText, onChangePage, onChangePageSize,
  onConfirmLogoutPressed, onNeedCertificationPressed, onNoNeedCertificationPressed, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  myConfigItems,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_ASSETS}
        uiPage={RXSTATE_ASSET_PAGE}
        tableColumns={[
          {
            title: LocalizedString.assetScreeningPage.labelStatus, field: 'certificationStatus', sorting: !downloading, render: ({ certificationStatus }) => renderCertificationStatus(certificationStatus),
          },
          { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloading },
          { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloading },
          { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloading },
          { title: LocalizedString.assetPage.labelAssetClass, field: 'category.name', sorting: !downloading },
          { title: LocalizedString.assetPage.labelLocation, field: 'location.name', sorting: !downloading },
        ]}
        filterColumns={[
          {
            title: LocalizedString.assetScreeningPage.labelStatus,
            field: RXFIELD_ASSET_SCREENING_CERTIFICATION_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: CERTIFICATION_STATUSES,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.assetPage.labelId,
            field: RXFIELD_ASSET_SOURCE_ID,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetDescription,
            field: RXFIELD_ASSET_ASSET_DESCRIPTION,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelPartCode,
            field: RXFIELD_ASSET_PART_CODE,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelSerialNumber,
            field: RXFIELD_ASSET_SERIAL_NUMBER,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetNumber,
            field: RXFIELD_ASSET_ASSET_NUMBER,
            type: FILTER_TYPE_TEXT,
          },
          {
            title: LocalizedString.assetPage.labelAssetClass,
            field: RXFIELD_ASSET_ASSET_TYPE,
            type: FILTER_TYPE_DROPDOWN,
            data: assetTypes,
            loading: loadingAssetTypes,
            onChangeFilterText: onChangeAssetTypeText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.assetPage.labelLocation,
            field: RXFIELD_ASSET_LOCATION,
            type: FILTER_TYPE_DROPDOWN,
            data: locations,
            loading: loadingLocations,
            onChangeFilterText: onChangeLocationText,
            useDropdownValue: true,
          },
        ]}
        customSelectionActionButton={(
          <div className={classes.rowContainer}>
            <div style={{ flex: 1 }} />
            <AccentButton
              variant="outlined"
              caption={LocalizedString.assetScreeningPage.buttonCaptionNoNeedCertification}
              disabled={downloading}
              onClick={onNoNeedCertificationPressed}
              size="small"
            />
            <AccentButton
              variant="contained"
              caption={LocalizedString.assetScreeningPage.buttonCaptionNeedCertification}
              disabled={downloading}
              onClick={onNeedCertificationPressed}
              size="small"
              style={classes.rightButton}
            />
          </div>
        )}
        onAppear={onAppear}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSelectionChange={setSelectedRows}
        onSortPressed={onSortPressed}
        title={LocalizedString.assetScreeningPage.title}
        useFullWidth
        disableActions
        disableCreate
        disableEdit
        disableDelete
        disableView
        selection
        useTwoColumnFilterDialog
        contentMode={FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM}
        customSelectionActionButtonPosition={FUNCTIONAL_PAGE_SELECTION_BUTTON_POSITION_FOOTER}
      />
      <CertificationConfirmationDialog selectedAssets={selectedRows} />
      <AlertBox onConfirmPressed={(message, reason) => onConfirmLogoutPressed(message, reason,
        history, myConfigItems)}
      />
    </div>
  );
};

export default AssetScreeningPage;

AssetScreeningPage.propTypes = {
  assetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingAssetTypes: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAssetTypeText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmLogoutPressed: PropTypes.func.isRequired,
  onNeedCertificationPressed: PropTypes.func.isRequired,
  onNoNeedCertificationPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  myConfigItems: PropTypes.object.isRequired,
};
