import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
  RXFORM_ANNOTATION_DESIGNER, PDF_TYPE_TEXT,
  PDF_TYPE_QR_CODE, PDF_TYPE_IMAGE, FONT_STYLE,
  RXFIELD_ANNOTATION_DESIGNER_VALUE, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME,
  RXFIELD_ANNOTATION_DESIGNER_COLOR, RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X,
  RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y, RXFIELD_ANNOTATION_DESIGNER_ROTATION,
  RXFIELD_ANNOTATION_DESIGNER_PAGE, RXFIELD_ANNOTATION_DESIGNER_FONT_SIZE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_STYLE, RXFIELD_ANNOTATION_DESIGNER_WIDTH,
  RXFIELD_ANNOTATION_DESIGNER_HEIGHT, RXFIELD_ANNOTATION_DESIGNER_IMAGE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_COLOR,
} from '../../constant';
import {
  COLOR_PRIMARY,
  PAGE_MODE_VIEW,
  IMAGE_FILE_EXTENSIONS,
} from '../../../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormColorPickerField,
  renderReduxFormCheckboxOption,
  renderReduxFormDropzonePicker,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateAnnotationDesigner } from '../../validation';
import { BasePage, PDFList, UploadPDF } from '../../component';
import {
  FormInitialValueShape, SimpleDataShape, AnnotationShape,
} from '../../type';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: COLOR_PRIMARY,
    fontWeight: 500,
  },
}));

const renderField = (
  type, initialValues, addingEditing, pageMode,
  defaultFontValue, onFontOptionSelected,
  onImageSelected, selectedImage, onColorSelected, fontData,
) => {
  switch (type) {
    case PDF_TYPE_TEXT:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_VALUE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderValue}
                label={LocalizedString.annotationDesignerPage.placeholderValue}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_NAME}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderFontName}
                label={LocalizedString.annotationDesignerPage.placeholderFontName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={fontData}
                value={defaultFontValue}
                onOptionSelected={onFontOptionSelected}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_COLOR}
                component={renderReduxFormColorPickerField}
                label={LocalizedString.annotationDesignerPage.placeholderColor}
                value={initialValues.fontColor}
                onChangePressed={onColorSelected}
              />
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_SIZE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderFontSize}
                label={LocalizedString.annotationDesignerPage.placeholderFontSize}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_FONT_STYLE}
                component={renderReduxFormCheckboxOption}
                label={LocalizedString.annotationDesignerPage.placeholderFontStyle}
                data={FONT_STYLE}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                horizontal
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_ROTATION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderRotation}
                label={LocalizedString.annotationDesignerPage.placeholderRotation}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case PDF_TYPE_QR_CODE:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_VALUE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderValue}
                label={LocalizedString.annotationDesignerPage.placeholderValue}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_WIDTH}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderWidth}
                label={LocalizedString.annotationDesignerPage.placeholderWidth}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COLOR}
                component={renderReduxFormColorPickerField}
                label={LocalizedString.annotationDesignerPage.placeholderColor}
                value={initialValues.color}
                onChangePressed={onColorSelected}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_HEIGHT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderHeight}
                label={LocalizedString.annotationDesignerPage.placeholderHeight}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                multiline
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    case PDF_TYPE_IMAGE:
      return (
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_PAGE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.annotationDesignerPage.placeholderPage}
                label={LocalizedString.annotationDesignerPage.placeholderPage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                required
                type="number"
              />
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_X}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateX}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_COORDINATE_Y}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      label={LocalizedString.annotationDesignerPage.placeholderCoordinateY}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_WIDTH}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderWidth}
                      label={LocalizedString.annotationDesignerPage.placeholderWidth}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      required
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid item>
                    <Field
                      name={RXFIELD_ANNOTATION_DESIGNER_HEIGHT}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderHeight}
                      label={LocalizedString.annotationDesignerPage.placeholderHeight}
                      disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                      multiline
                      required
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Field
                name={RXFIELD_ANNOTATION_DESIGNER_IMAGE}
                component={renderReduxFormDropzonePicker}
                label={LocalizedString.annotationDesignerPage.placeholderImage}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                loading={addingEditing}
                onImageSelected={onImageSelected}
                onBlur={(e) => e.preventDefault()}
                defaultValue={initialValues?.imageValue || ''}
                imageFileExtension={IMAGE_FILE_EXTENSIONS}
                required
              />
            </Grid>
          </Grid>
        </Grid>
      );
    default: return (<></>);
  }
};

const renderDialogContent = (
  pdfType, initialValues, addingEditing, pageMode,
  defaultFontValue, onFontOptionSelected,
  onImageSelected, selectedImage, onColorSelected,
  fontData,
) => (
  <Grid>
    {renderField(pdfType, initialValues, addingEditing, pageMode,
      defaultFontValue, onFontOptionSelected,
      onImageSelected, selectedImage, onColorSelected, fontData)}
  </Grid>

);

const AnnotationDesignerPage = ({
  data, fontData, onAppear, initialValues, onImageSelected, selectedImage, pdfType,
  addingEditing, pageMode, onSavePressed, onDeletePressed, onEditPressed,
  onColorSelected, onFontOptionSelected, handleSubmit, onConfirmDeletePressed,
}) => {
  const classes = useStyles();

  return (
    <BasePage onConfirmPressed={onConfirmDeletePressed}>
      <div className={classes.headerField}>
        <Typography variant="h5" className={classes.title}>
          {LocalizedString.annotationDesignerPage.title}
        </Typography>
      </div>
      <div className={classes.container}>
        <PDFList
          data={data}
          onAppear={onAppear}
          onDeletePressed={onDeletePressed}
          onSubmitPressed={onSavePressed}
          onEditPressed={onEditPressed}
          handleSubmit={handleSubmit}
        >
          {renderDialogContent(
            pdfType, initialValues, addingEditing, pageMode,
            initialValues.fontName, onFontOptionSelected,
            onImageSelected, selectedImage, onColorSelected,
            fontData,
          )}
        </PDFList>
        <UploadPDF />
      </div>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_ANNOTATION_DESIGNER,
  validate: rxformValidateAnnotationDesigner,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AnnotationDesignerPage);

AnnotationDesignerPage.propTypes = {
  data: PropTypes.arrayOf(AnnotationShape).isRequired,
  fontData: PropTypes.arrayOf(SimpleDataShape).isRequired,
  initialValues: FormInitialValueShape.isRequired,

  addingEditing: PropTypes.bool.isRequired,

  onAppear: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onFontOptionSelected: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onColorSelected: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,

  selectedImage: PropTypes.string.isRequired,
  pdfType: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
};
