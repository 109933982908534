import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_SESSION_CATEGORIES,
  MENUID_EVENT_SESSION_CATEGORY,
  PAGE_MODE_TABLE,
  RXFORM_SESSION_CATEGORY,
} from '../../constant';
import { transformInitialValues } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  addEditSessionCategoryAsync,
  clearSessionCategories, downloadDeleteSessionCategoryAsync,
  downloadSessionCategoriesAsync, enableDisableSessionCategoryAsync,
  setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setSessionCategoryAdvancedFilterDialogSelectedFilterString,
  setSessionCategorySearchText,
  setSessionCategorySelectedOrderBy, setSessionCategorySelectedPageSize,
  setSessionCategoryTappedId,
} from '../../redux/action';
import SessionCategoryPage from './session-category.presentation';

const getInitialValues = (state) => {
  const { sessionCategories, uiSessionCategory, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionCategory;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionCategories.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        name: found.name,
        description: found.description,
      })
    : {
      name: '',
      description: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  addingEditing: state.uiSessionCategory.addingEditing,
  currentCategoryStatus: state.sessionCategories.data[state.uiFunctionalPage.tappedId]
    ? state.sessionCategories.data[state.uiFunctionalPage.tappedId].status : '',
  downloading: state.uiSessionCategory.downloading,
  enablingDisabling: state.uiSessionCategory.enablingDisabling,
  sessionCategories: state.sessionCategories.data,
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SESSION_CATEGORY));
    dispatch(setSessionCategorySearchText(''));
    dispatch(clearSessionCategories());
    dispatch(setSessionCategorySelectedPageSize(20));
    dispatch(setSessionCategorySelectedOrderBy(INITIAL_ORDER_BY_SESSION_CATEGORIES));
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION_CATEGORY));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionCategoriesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionCategorySelectedPageSize(pageSize));
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteSessionCategoryAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSessionCategoryAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSessionCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setSessionCategoryTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setSessionCategoryTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSessionCategoryTappedId(id));
    dispatch(downloadDeleteSessionCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionCategorySelectedPageSize(pageSize));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionCategories());
    dispatch(setSessionCategorySelectedOrderBy(INITIAL_ORDER_BY_SESSION_CATEGORIES));
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ name, description }) => {
    try {
      await dispatch(addEditSessionCategoryAsync(name, description));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionCategorySearchText(text));
      dispatch(clearSessionCategories());
      await dispatch(downloadSessionCategoriesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionCategorySelectedOrderBy(orderBy));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ name, description }) => {
    await dispatch(addEditSessionCategoryAsync(name, description));
  },
  onViewPressed: (id) => {
    dispatch(setSessionCategoryTappedId(id));
    dispatch(downloadDeleteSessionCategoryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionCategoryPage);
