import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    userJobMenu: {
      title: 'User Job',
    },
    myJobPage: {
      title: 'My Job',

      labelNo: 'No',
      labelName: 'Name',
      labelJobStatus: 'Job Status',
      labelStartTime: 'Start Time',
      labelEndTime: 'End Time',
      labelProgress: 'Progress',
      labelNotes: 'Notes',
      labelCreatedDate: 'Created Date',
      labelResultType: 'Result Type',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderResultType: 'Result Type',
      placeholderJobStatus: 'Job Status',
      placeholderJobStatusNotes: 'Job Status Notes',
      placeholderProgress: 'Progress',
      placeholderProgressNotes: 'Progress Notes',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderDeletedAfter: 'Deleted After',

      buttonCaptionDownload: 'Download',
      buttonCaptionDelete: 'DELETE',
    },
    jobPage: {
      title: 'Job',

      labelNo: 'No',
      labelName: 'Name',
      labelUser: 'User',
      labelJobStatus: 'Job Status',
      labelDescription: 'Description',
      labelUserID: 'User ID',
      labelUserName: 'User Name',
      labelJobStatusNotes: 'Job Status Notes',
      labelProgressNotes: 'Progress Notes',
      labelProgress: 'Progress',
      labelDeletedAfter: 'Deleted After',
      labelResultType: 'Result Type',
      labelStartTime: 'Start Time',
      labelEndTime: 'End Time',
      labelCreatedDate: 'Created Date',

      buttonCaptionDownload: 'Download',
      buttonCaptionDelete: 'Delete',

      placeholderName: 'Name',
      placeholderUser: 'User',
      placeholderJobStatus: 'Job Status',
      placeholderStartTime: 'Start Time',
      placeholderEndTime: 'End Time',
      placeholderProgress: 'Progress',
      placeholderNotes: 'Notes',
      placeholderCreatedDate: 'Created Date',
      placeholderDescription: 'Description',
      placeholderUserID: 'User ID',
      placeholderUserName: 'User Name',
      placeholderResultType: 'Result Type',
      placeholderJobStatusNotes: 'Job Status Notes',
      placeholderProgressNotes: 'Progress Notes',
      placeholderDeletedAfter: 'Deleted After',
    },
  },
  id: {
    userJobMenu: {
      title: 'Pekerjaan Pengguna',
    },
    myJobPage: {
      title: 'Pekerjaan Saya',

      labelNo: 'No',
      labelName: 'Nama',
      labelJobStatus: 'Status Pekerjaan',
      labelStartTime: 'Waktu Dimulai',
      labelEndTime: 'Waktu Selesai',
      labelProgress: 'Progress',
      labelNotes: 'Catatan',
      labelCreatedDate: 'Waktu Pembuatan',
      labelResultType: 'Tipe Hasil',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama Pengguna',
      placeholderResultType: 'Tipe Hasil',
      placeholderJobStatus: 'Status Pekerjaan',
      placeholderJobStatusNotes: 'Catatan Status Pekerjaan',
      placeholderProgress: 'Perkembangan',
      placeholderProgressNotes: 'Catatan Perkembangan',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderDeletedAfter: 'Dihapus setelah',

      buttonCaptionDownload: 'Unduh',
      buttonCaptionDelete: 'HAPUS',
    },
    jobPage: {
      title: 'Kerja',

      labelNo: 'No',
      labelName: 'Nama',
      labelUser: 'Pengguna',
      labelJobStatus: 'Status Pekerjaan',
      labelStartTime: 'Waktu Mulai',
      labelEndTime: 'Waktu Selesai',
      labelProgress: 'Kemajuan',
      labelNotes: 'Catatan',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',
      labelUserID: 'ID Pengguna',
      labelUserName: 'Nama Pengguna',
      labelJobStatusNotes: 'Catatan Status Kerja',
      labelProgressNotes: 'Catatan Kemajuan',
      labelDeletedAfter: 'Dihapus Setelah',
      labelResultType: 'Tipe Hasil',

      buttonCaptionDownload: 'Unduh',
      buttonCaptionDelete: 'Hapus',

      placeholderName: 'Nama',
      placeholderUser: 'Pengguna',
      placeholderJobStatus: 'Status Pekerjaan',
      placeholderStartTime: 'Waktu Mulai',
      placeholderEndTime: 'Waktu Selesai',
      placeholderProgress: 'Kemajuan',
      placeholderNotes: 'Catatan',
      placeholderCreatedDate: 'Tanggal Pembuatan',
      placeholderDescription: 'Deskripsi',
      placeholderUserID: 'ID Pengguna',
      placeholderUserName: 'Nama pengguna',
      placeholderResultType: 'Tipe Hasil',
      placeholderJobStatusNotes: 'Catatan Status Kerja',
      placeholderProgressNotes: 'Catatan Kemajuan',
      placeholderDeletedAfter: 'Dihapus Setelah',
    },
  },
});

export default LocalizedString;
