import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, withStyles,
} from '@material-ui/core';
import { Close } from 'mdi-material-ui';
import LocalizedString from '../../../localization';
import { COLOR_DANGEROUS, COLOR_ICON } from '../../../constant';
import AccentButton from '../../accent-button';
import Stepper from './stepper';

const styles = (() => ({
  paperWithStepper: {
    minHeight: '96vh',
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerButtonText: {
    color: COLOR_DANGEROUS,
    marginRight: 20,
  },
  closeHeaderButton: {
    color: COLOR_ICON,
    marginRight: 20,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'auto',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'flex-end',
  },
  title: {
    '& .MuiTypography-h6': {
      fontSize: '14px',
    },
    padding: '16px 24px 8px 24px',
    color: COLOR_ICON,
  },
}));

const AddEditDialog = ({
  stepContent, stepTitle,
  disabled, submitting, usefullWidthDialog, visibility,
  handleSubmit, onCancelPressed, onClosePressed, onError, onSubmitPressed,
  classes, children,
  buttonCaption, headerContainerStyle, title,
}) => {
  const onCancelPress = () => {
    onCancelPressed();
    onClosePressed();
  };

  const onHandleSubmit = async (e) => {
    try {
      await onSubmitPressed(e);
      onClosePressed();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Dialog
      open={visibility}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={stepContent.length > 0 || usefullWidthDialog}
      classes={stepContent.length > 0 ? { paper: classes.paperWithStepper }
        : { paper: classes.paper }}
    >
      <div className={`${classes.headerContainer} ${headerContainerStyle}`}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        {stepContent.length > 0 && (
          <AccentButton
            onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
            variant="text"
            caption={LocalizedString.common.buttonCaptionCancel}
            className={classes.headerButtonText}
            disabled={disabled || submitting}
            size="small"
          />
        )}
        {stepContent.length === 0 && (
          <IconButton
            onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
            disabled={disabled || submitting}
            size="small"
            className={classes.closeHeaderButton}
          >
            <Close fontSize="inherit" />
          </IconButton>
        )}
      </div>

      <DialogContent classes={stepContent.length > 0 ? { root: classes.dialogContent } : undefined}>
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className={classes.form}
        >
          {stepContent.length > 0 ? (
            <Stepper
              stepTitle={stepTitle}
              stepContent={stepContent}
              addButton={(
                <AccentButton
                  variant="contained"
                  type="submit"
                  loading={submitting}
                  caption={LocalizedString.common.buttonCaptionSave}
                  disabled={disabled || submitting}
                  size="small"
                />
              )}
              handleSubmit={handleSubmit}
            />
          ) : children}

          <DialogActions className={classes.buttonContainer}>
            {stepContent.length === 0 && (
              <AccentButton
                onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
                variant="outlined"
                caption={LocalizedString.common.buttonCaptionCancel}
                disabled={disabled || submitting}
                size="small"
              />
            )}

            {stepContent.length === 0 && (
              <AccentButton
                variant="contained"
                type="submit"
                loading={submitting}
                disableElevation
                caption={buttonCaption}
                disabled={disabled || submitting}
                size="small"
              />
            )}
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AddEditDialog);

AddEditDialog.propTypes = {
  stepContent: PropTypes.arrayOf(PropTypes.node).isRequired,
  stepTitle: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  usefullWidthDialog: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func,
  onClosePressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  buttonCaption: PropTypes.string.isRequired,
  headerContainerStyle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

AddEditDialog.defaultProps = {
  disabled: false,
  usefullWidthDialog: false,
  onCancelPressed: () => {},
  children: (<div />),
  headerContainerStyle: '',
};
