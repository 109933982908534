import {
  SET_APPROVAL_PROVIDERS, CLEAR_APPROVAL_PROVIDERS,
} from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_APPROVAL_PROVIDERS: {
      return action.data;
    }
    case CLEAR_APPROVAL_PROVIDERS:
      return state;
    default:
      return state;
  }
};
