import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  setActiveSideMenuItem, setAlertErrorMessage, setAlertConfirmationMessage,
  clearApprovalGroups, setApprovalGroupSearchText, setApprovalGroupSelectedPageSize,
  setApprovalGroupSelectedOrderBy, setApprovalGroupTappedId,
  setApprovalGroupAdvancedFilterDialogSelectedFilterString,
  setApprovalGroupSelectedMemberGroup, downloadApprovalGroupsAsync,
  downloadDeleteApprovalGroupAsync, addEditApprovalGroupAsync,
  enableDisableApprovalGroupAsync,
} from '../../redux/action';
import {
  setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText,
  clearProfiles,
  downloadProfilesAsync,
} from '../../../../redux/action';
import {
  INITIAL_ORDER_BY_APPROVAL_GROUPS, MENUID_APPROVAL_GROUP, PAGE_MODE_TABLE,
  RXFORM_APPROVAL_GROUP, RXFIELD_APPROVAL_GROUP_NAME, RXFIELD_APPROVAL_GROUP_DESCRIPTION,
  RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
} from '../../constant';
import {
  transformInitialValues, debounceSearch,
  transformCustomerGroupDropdownData,
} from '../../helper';
import ApprovalGroupPage from './approval-group.presentation';
import { addApprovalGroup, addingApprovalGroupMemberGroup, setApprovalGroupTableDataCustomerMembers } from '../../redux/action/simple-action';
import GlobalLocalizedString from '../../../../localization';

const transformGroupMembers = (data) => (data.length > 0 ? data.map((x) => (x.id
  ? { label: x.fullName, value: x.id, email: x.email } : x)) : []);

const getInitialValues = (state) => {
  const { approvalGroups, uiApprovalGroup, uiFunctionalPage } = state;
  const { downloadDeletingApprovalGroup, tappedId, tableDataGroupMembers } = uiApprovalGroup;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadDeletingApprovalGroup
    ? approvalGroups.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    [RXFIELD_APPROVAL_GROUP_NAME]: found?.name ? found?.name : '',
    [RXFIELD_APPROVAL_GROUP_DESCRIPTION]: found?.description ? found?.description : '',
    [RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS]:
      tableDataGroupMembers?.length > 0 ? transformGroupMembers(tableDataGroupMembers) : [],
  }) : {
    [RXFIELD_APPROVAL_GROUP_NAME]: '',
    [RXFIELD_APPROVAL_GROUP_DESCRIPTION]: '',
    [RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS]:
      tableDataGroupMembers?.length > 0 ? tableDataGroupMembers : [],
  };
  return initVal;
};

const mapStateToProps = (state) => {
  const currentApplicableGroupMembers = state.uiApprovalGroup.tableDataGroupMembers;
  const groupMembers = transformCustomerGroupDropdownData(state.profiles.data);

  return ({
    pageMode: state.uiFunctionalPage.pageMode,
    downloading: state.uiApprovalGroup.downloading,
    downloadDeletingApprovalGroup: state.uiApprovalGroup.downloadDeletingApprovalGroup,
    addingEditingApprovalGroup: state.uiApprovalGroup.addingEditingApprovalGroup,
    initialValues: getInitialValues(state),
    tappedId: state.uiApprovalGroup.tappedId,
    groupMembers: currentApplicableGroupMembers.length > 0
      ? groupMembers.filter((x) => currentApplicableGroupMembers.every((y) => y.value !== x.value))
      : groupMembers,
    downloadingGroupMembers: state.uiProfile.downloading,
    tableDataGroupMembers: state.uiApprovalGroup.tableDataGroupMembers,
    selectedMemberGroup: state.uiApprovalGroup.selectedMemberGroup,
    tappedApprovalGroup: state.approvalGroups.data[state.uiApprovalGroup.tappedId],
    addingCustomGroup: state.uiApprovalGroup.addingCustomGroup,
    enablingDisabling: state.uiApprovalGroup.enablingDisablingApprovalGroup,
    currentApprovalGroupStatus: state.approvalGroups.data[state.uiFunctionalPage.tappedId]
      ? state.approvalGroups.data[state.uiFunctionalPage.tappedId].status : '',
  });
};

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearProfiles());
  dispatch(downloadProfilesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_APPROVAL_GROUP));
    dispatch(setApprovalGroupSearchText(''));
    dispatch(clearApprovalGroups());
    dispatch(setApprovalGroupSelectedPageSize(20));
    dispatch(setApprovalGroupSelectedOrderBy(INITIAL_ORDER_BY_APPROVAL_GROUPS));
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_APPROVAL_GROUP));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadApprovalGroupsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setApprovalGroupSelectedPageSize(pageSize));
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setApprovalGroupSelectedPageSize(pageSize));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setApprovalGroupSearchText(text));
      dispatch(clearApprovalGroups());
      await dispatch(downloadApprovalGroupsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setApprovalGroupSelectedOrderBy(orderBy));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setApprovalGroupTappedId(id));
    dispatch(setApprovalGroupTableDataCustomerMembers([]));
    dispatch(downloadDeleteApprovalGroupAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearApprovalGroups());
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearApprovalGroups());
    dispatch(setApprovalGroupAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setApprovalGroupSelectedOrderBy(INITIAL_ORDER_BY_APPROVAL_GROUPS));
    dispatch(downloadApprovalGroupsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onEditPressed: (id) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setApprovalGroupTappedId(id));
    dispatch(setApprovalGroupTableDataCustomerMembers([]));
    dispatch(downloadDeleteApprovalGroupAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setApprovalGroupTappedId(''));
    dispatch(setApprovalGroupTableDataCustomerMembers([]));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setApprovalGroupTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onAddGroupMembersPressed: async (defaultValue, newInitialValue, selectedOption, currentData) => {
    if (selectedOption) {
      try {
        dispatch(addingApprovalGroupMemberGroup(true));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            members: defaultValue.length > 0
              ? [...defaultValue, selectedOption] : [selectedOption],
          };
          await dispatch(addApprovalGroup(result));
          dispatch(setApprovalGroupTableDataCustomerMembers(result.members));
          dispatch(change(
            RXFORM_APPROVAL_GROUP,
            RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS,
            result.members,
          ));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue.filter((x) => (x.value !== selectedOption.value)), selectedOption]
            : [selectedOption];
          await dispatch(setApprovalGroupTableDataCustomerMembers(newData));
          dispatch(change(RXFORM_APPROVAL_GROUP, RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS, newData));
        }
        dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
        dispatch(setProfileSearchText(''));
        dispatch(clearProfiles());
        dispatch(downloadProfilesAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setApprovalGroupSelectedMemberGroup(null));
        dispatch(addingApprovalGroupMemberGroup(false));
      }
    }
  },
  onDeleteGroupMembersPressed: async (newValue, currentData) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
      const result = { ...currentData, members: newValue };
      await dispatch(addApprovalGroup(result));
      dispatch(setApprovalGroupTableDataCustomerMembers(result.members));
    } else {
      dispatch(setApprovalGroupTableDataCustomerMembers(newValue));
    }
    dispatch(change(RXFORM_APPROVAL_GROUP, RXFIELD_APPROVAL_GROUP_GROUP_MEMBERS, newValue));
  },
  onChangeMemberGroupsText: async (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onMemberGroupOptionSelected: (option) => {
    if (option) {
      dispatch(setApprovalGroupSelectedMemberGroup(option));
    } else {
      dispatch(setApprovalGroupSelectedMemberGroup(null));
    }
  },
  onSubmitPressed: async ({ name, description, members: memberGroups }) => {
    const members = memberGroups.map((memberGroup) => memberGroup.value);
    const body = {
      name,
      description,
      members,
    };
    dispatch(addEditApprovalGroupAsync(body));
  },
  onSavePressed: async ({ name, description, members: memberGroups }) => {
    const members = memberGroups.map((memberGroup) => memberGroup.value);
    const body = {
      name,
      description,
      members,
    };
    dispatch(addEditApprovalGroupAsync(body));
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteApprovalGroupAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableApprovalGroupAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setApprovalGroupTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalGroupPage);
