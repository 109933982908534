import { connect } from 'react-redux';
import { getData } from '../../../helper';
import {
  clearAssetDocuments, downloadAssetDocumentsAsync, setAlertErrorMessage,
  setAssetDocumentSelectedOrderBy, setAssetDocumentSelectedPageSize,
  setCertificationRequestDialogVisibility,
} from '../../../redux/action';
import SelectFileDialog from './select-file-dialog.presentation';

const mapStateToProps = (state) => ({
  assetDocuments: getData(state.assetDocuments, state.uiAssetDocument),
  dialogVisibility: state.uiCertificationRequest.dialogVisibility,
  downloading: state.uiAssetDocument.downloading,
  currentPage: state.assetDocuments?.meta?.currentPage || 0,
  selectedPageSize: state.uiAssetDocument?.selectedPageSize || 1,
  totalCount: state.assetDocuments?.meta?.totalCount || 0,
  dialogMode: state.uiCertificationRequest.dialogMode,
  orderBy: state.uiAssetDocument?.orderBy || '',
});

const mapDispatchToProps = (dispatch) => ({
  onSortPressed: (orderBy) => {
    dispatch(setAssetDocumentSelectedOrderBy(orderBy));
    dispatch(clearAssetDocuments());
    dispatch(downloadAssetDocumentsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetDocumentsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetDocumentSelectedPageSize(pageSize));
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDownloadPressed: (selectedRows) => {
    const data = selectedRows.map((x) => ({ url: x.file, fileName: x.fileName }));
    const fetchFile = (file) => fetch(file.url).then((res) => res.blob());

    const exportFile = (file, fileName) => {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.setAttribute('download', fileName);
      a.click();
    };

    for (let i = 0; i < data.length; i += 1) {
      fetchFile(data[i]).then((file) => exportFile(file, data[i].fileName));
    }

    dispatch(setCertificationRequestDialogVisibility(false, ''));
  },
  onPdfPressed: async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectFileDialog);
