import { downloadingAssetTypes, setAssetTypes } from '../simple-action';
import { downloadAssetTypes, transformSearchText } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiAsset } = getState();
  const { token } = authentication;
  const { assetTypeSearchBarText } = uiAsset;

  try {
    dispatch(downloadingAssetTypes(true));

    const list = await downloadAssetTypes(transformSearchText(assetTypeSearchBarText), token);

    if (list) {
      dispatch(setAssetTypes(list));
    }
  } finally {
    dispatch(downloadingAssetTypes(false));
  }
};
