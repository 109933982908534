import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Paper } from '@material-ui/core';
import { FunctionalPage, InformationCard, StyledTab } from '../../component';
import {
  CERT_CATEGORIES, COLOR_SECONDARY, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME, REVERSED_ISO_DATE_FORMAT, ROUTE_NAME_ASSET_DETAIL,
  RXFIELD_ASSET_ASSET_DESCRIPTION, RXFIELD_ASSET_ASSET_NUMBER, RXFIELD_ASSET_ASSET_TYPE,
  RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE, RXFIELD_ASSET_CERTIFICATE_START_DATE,
  RXFIELD_ASSET_CERTIFICATION_CATEGORY, RXFIELD_ASSET_CERTIFICATION_TYPE, RXFIELD_ASSET_LOCATION,
  RXFIELD_ASSET_PART_CODE, RXFIELD_ASSET_SERIAL_NUMBER, RXFIELD_ASSET_SOURCE_ID,
  RXSTATE_ASSETS, RXSTATE_ASSET_PAGE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import AssetChildTab from './asset-child-tab';
import CertificatesTab from './certificates-tab';
import DocumentTab from './document-tab';
import SettingTab from './setting-tab';

const certActiveIcon = require('../../../../asset/cert-active.png');
const certInactiveIcon = require('../../../../asset/cert-inactive.png');
const documentActiveIcon = require('../../../../asset/document-active.png');
const documentInactiveIcon = require('../../../../asset/document-inactive.png');
const settingActiveIcon = require('../../../../asset/setting-active.png');
const settingInactiveIcon = require('../../../../asset/setting-inactive.png');
const assetChildActiveIcon = require('../../../../asset/asset-child-active.png');
const assetChildInactiveIcon = require('../../../../asset/asset-child-inactive.png');

const useStyles = makeStyles(() => ({
  contentContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
    borderLeft: `1px solid ${COLOR_SECONDARY}`,
    borderRight: `1px solid ${COLOR_SECONDARY}`,
    padding: 8,
  },
}));

const renderDetailContent = (initialValues, tabs, activeTab, classes) => {
  let component;
  switch (activeTab) {
    case tabs[0].label: component = (<CertificatesTab />); break;
    case tabs[1].label: component = (<DocumentTab />); break;
    case tabs[2].label: component = (<SettingTab />); break;
    case tabs[3].label: component = (<AssetChildTab />); break;
    default: break;
  }

  return (
    <Paper className={classes.contentContainer}>
      {component}
    </Paper>
  );
};

const AssetPage = ({
  initialValues, assetTypes, certificationTypes, locations,
  downloading, downloadingDeleting, hasAssetChildPermission, hasCertificationsPermission,
  hasDocumentPermission, hasSettingPermission, loadingAssetTypes, loadingCertificationTypes,
  loadingLocations,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeAssetTypeText, onChangeCertTypeText, onChangeLocationText, onChangePage, onChangePageSize,
  onCreatePressed, onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onTabPressed, onViewPressed,
  activeTab,
}) => {
  const classes = useStyles();

  const informationCardData = {
    body: [
      {
        row: [
          { label: LocalizedString.assetPage.labelId, value: initialValues?.sourceId },
          {
            label: LocalizedString.assetPage.labelDescription,
            value: initialValues?.assetDescription,
          },
        ],
      },
      {
        row: [
          { label: LocalizedString.assetPage.labelAssetNumber, value: initialValues?.assetNumber },
          { label: LocalizedString.assetPage.labelPartCode, value: initialValues?.partCode },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.assetPage.labelSerialNumber,
            value: initialValues?.serialNumber,
          },
          { label: LocalizedString.assetPage.labelLocation, value: initialValues?.location?.name },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.assetPage.labelCertType,
            value: initialValues?.certificationType,
          },
          { label: LocalizedString.assetPage.labelOwner, value: initialValues?.owner?.name },
        ],
      },
      {
        row: [
          {
            label: LocalizedString.assetPage.labelExpired,
            value: initialValues?.certificateExpirationDate ? toMoment(initialValues?.certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : '',
          },
          {
            label: LocalizedString.assetPage.labelAssetClass,
            value: initialValues?.category?.name,
          },
        ],
      },
    ],
  };

  const tabs = [
    {
      label: LocalizedString.assetPage.buttonCaptionCertifications,
      activeIcon: certActiveIcon,
      inactiveIcon: certInactiveIcon,
      hasPermission: hasCertificationsPermission,
    },
    {
      label: LocalizedString.assetPage.buttonCaptionDocument,
      activeIcon: documentActiveIcon,
      inactiveIcon: documentInactiveIcon,
      hasPermission: hasDocumentPermission,
    },
    {
      label: LocalizedString.assetPage.buttonCaptionSetting,
      activeIcon: settingActiveIcon,
      inactiveIcon: settingInactiveIcon,
      hasPermission: hasSettingPermission,
    },
    {
      label: LocalizedString.assetPage.buttonCaptionAssetChild,
      activeIcon: assetChildActiveIcon,
      inactiveIcon: assetChildInactiveIcon,
      hasPermission: hasAssetChildPermission,
    },
  ];
  const defaultTab = tabs[0].label;

  return (
    <FunctionalPage
      data={RXSTATE_ASSETS}
      uiPage={RXSTATE_ASSET_PAGE}
      tableColumns={[
        {
          title: LocalizedString.assetPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloading },
        { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloading },
        { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloading },
        { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloading },
        { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloading },
        { title: LocalizedString.assetPage.labelAssetClass, field: 'category.name', sorting: !downloading },
        { title: LocalizedString.assetPage.labelLocation, field: 'location.name', sorting: !downloading },
        { title: LocalizedString.assetPage.labelCertType, field: 'certificationType', sorting: !downloading },
        { title: LocalizedString.assetPage.labelCertCategory, field: 'certificationCategory', sorting: !downloading },
        {
          title: LocalizedString.assetPage.labelCertDate,
          field: 'certificateStartDate',
          sorting: !downloading,
          render: ({ certificateStartDate }) => (certificateStartDate
            ? toMoment(certificateStartDate).format(REVERSED_ISO_DATE_FORMAT) : null),
        },
        {
          title: LocalizedString.assetPage.labelCertExpired,
          field: 'certificateExpirationDate',
          sorting: !downloading,
          render: ({ certificateExpirationDate }) => (certificateExpirationDate
            ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.assetPage.labelId,
          field: RXFIELD_ASSET_SOURCE_ID,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.assetPage.labelAssetDescription,
          field: RXFIELD_ASSET_ASSET_DESCRIPTION,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.assetPage.labelPartCode,
          field: RXFIELD_ASSET_PART_CODE,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.assetPage.labelSerialNumber,
          field: RXFIELD_ASSET_SERIAL_NUMBER,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.assetPage.labelAssetNumber,
          field: RXFIELD_ASSET_ASSET_NUMBER,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.assetPage.labelAssetClass,
          field: RXFIELD_ASSET_ASSET_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: assetTypes,
          loading: loadingAssetTypes,
          onChangeFilterText: onChangeAssetTypeText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.assetPage.labelLocation,
          field: RXFIELD_ASSET_LOCATION,
          type: FILTER_TYPE_DROPDOWN,
          data: locations,
          loading: loadingLocations,
          onChangeFilterText: onChangeLocationText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.assetPage.labelCertType,
          field: RXFIELD_ASSET_CERTIFICATION_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: certificationTypes,
          loading: loadingCertificationTypes,
          onChangeFilterText: onChangeCertTypeText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.assetPage.labelCertCategory,
          field: RXFIELD_ASSET_CERTIFICATION_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: CERT_CATEGORIES,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.assetPage.labelCertDate,
          field: RXFIELD_ASSET_CERTIFICATE_START_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.assetPage.labelCertExpired,
          field: RXFIELD_ASSET_CERTIFICATE_EXPIRATION_DATE,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
      ]}
      onAppearWithDetailRoute={(id) => onAppear(id, defaultTab)}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onCreatePressed={onCreatePressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={(id, history) => onViewPressed(id, history, defaultTab)}
      createNewButtonCaption={LocalizedString.certificationRequestPage
        .buttonCaptionCreateNewCertificationRequest}
      title={LocalizedString.assetPage.title}
      detailTitle={LocalizedString.assetPage.titleDetail}
      useFullWidth
      disableEdit
      disableDelete
      editPermissionName="ECERTIFICATION_GET_ASSET"
      useTwoColumnFilterDialog
      contentMode={FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM}
      detailRoute={ROUTE_NAME_ASSET_DETAIL}
    >
      <InformationCard data={informationCardData} loading={downloadingDeleting} />
      <StyledTab data={tabs} onTabPressed={onTabPressed} activeTab={activeTab} />
      {renderDetailContent(initialValues, tabs, activeTab, classes)}
    </FunctionalPage>
  );
};

export default AssetPage;

AssetPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  assetTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  certificationTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  locations: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  hasAssetChildPermission: PropTypes.bool.isRequired,
  hasCertificationsPermission: PropTypes.bool.isRequired,
  hasDocumentPermission: PropTypes.bool.isRequired,
  hasSettingPermission: PropTypes.bool.isRequired,
  loadingAssetTypes: PropTypes.bool.isRequired,
  loadingCertificationTypes: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeAssetTypeText: PropTypes.func.isRequired,
  onChangeCertTypeText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};
