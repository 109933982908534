import {
  SCHEDULING_ASSET, SET_CERTIFICATION_SCHEDULE_SETTING_DIALOG_VISIBILITY,
  SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_RESULT_TYPE,
  SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_FILE,
} from '../action';

const initialState = {
  scheduling: false,
  dialogVisibility: false,
  dialogMode: '',
  selectedFile: '',
  selectedResultType: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCHEDULING_ASSET:
      return { ...state, scheduling: action.status };
    case SET_CERTIFICATION_SCHEDULE_SETTING_DIALOG_VISIBILITY:
      return { ...state, dialogVisibility: action.status, dialogMode: action.mode };
    case SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_RESULT_TYPE:
      return { ...state, selectedResultType: action.option };
    case SET_CERTIFICATION_SCHEDULE_SETTING_SELECTED_FILE:
      return { ...state, selectedFile: action.option };
    default: return state;
  }
};
