import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_PARTNER_MERCHANTS, MENUID_PARTNER_MERCHANT, PAGE_MODE_TABLE,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_PARTNER_MERCHANT_COMPANY,
  RXFORM_PARTNER_MERCHANT, RXFIELD_PARTNER_MERCHANT_DESCRIPTION,
  RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData,
} from '../../helper';
import {
  clearPartnerMerchants, downloadDeletePartnerMerchantAsync, copyingPartnerMerchantDeepLink,
  downloadPartnerMerchantsAsync,
  setActiveSideMenuItem, setAlertErrorMessage,
  setPartnerMerchantSearchText, setPartnerMerchantSelectedOrderBy,
  setPartnerMerchantSelectedPageSize, setPartnerMerchantTappedId,
  setAdvancedFilterDialogSelectedFilterString,
  setCityAdvancedFilterDialogSelectedFilterString,
  setCitySearchText, clearCities, setPartnerMerchantSelectedCompany,
  addEditPartnerMerchantAsync, setAlertConfirmationMessage,
  setCompanyAdvancedFilterDialogSelectedFilterStringMData,
  setCompanySearchTextMData, clearCompaniesMData,
} from '../../redux/action';
import PartnerMerchantPage from './partner-merchant.presentation';
import { downloadCitiesAsync, downloadMDataCompaniesAsync } from '../../../../redux/action';
import GlobalLocalizedString from '../../../../localization';

const getInitialValues = (state) => {
  const { uiFunctionalPage, uiPartnerMerchant, partnerMerchants } = state;
  const { data } = partnerMerchants;
  const { downloadingDeleting, tappedId } = uiPartnerMerchant;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found, {
    logo: found.company && found.company.logo
      ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.company.logo}` : null,
    banner: found.company && found.company.banner
      ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.company.banner}` : null,
    company: found.company ? { label: found.company.name, value: found.company.id } : null,
    name: found.company?.name || '',
    address: found.company?.address || '',
    city: found.company?.city?.name || '',
    website: found.company?.website || '',
    playStoreUrl: found.company?.playStoreUrl || '',
    appStoreUrl: found.company?.appStoreUrl || '',
  }) : {
    name: '',
    address: '',
    city: '',
    website: '',
    playStoreUrl: '',
    appStoreUrl: '',
    logo: '',
    banner: '',
  };
  return result;
};

const mapStateToProps = (state) => ({
  downloading: state.uiPartnerMerchant.downloading,
  downloadingDeleting: state.uiPartnerMerchant.downloadingDeleting,
  addingEditing: state.uiPartnerMerchant.addingEditing,
  initialValues: getInitialValues(state),
  vouchers: Object.values(state.vouchers.data)
    .map((item) => ({ label: item.title, value: item.id })),
  loadingCity: state.uiCity.downloading,
  cities: transformDropdownData(state.cities.data),
  pageMode: state.uiFunctionalPage.pageMode,
  companies: transformDropdownData(state.companiesMdata.data),
  loadingCompanies: state.uiCompany.downloading,
  copying: state.uiPartnerMerchant.copying,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PARTNER_MERCHANT));
    dispatch(setPartnerMerchantSearchText(''));
    dispatch(clearPartnerMerchants());
    dispatch(setPartnerMerchantSelectedPageSize(20));
    dispatch(setPartnerMerchantSelectedOrderBy(INITIAL_ORDER_BY_PARTNER_MERCHANTS));
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadPartnerMerchantsAsync(pageNo + 1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setPartnerMerchantSelectedPageSize(pageSize));
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onCopyPressed: (status) => {
    dispatch(copyingPartnerMerchantDeepLink(!status));
  },
  onRefresh: (pageSize) => {
    dispatch(setPartnerMerchantSelectedPageSize(pageSize));
    dispatch(clearPartnerMerchants());
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSearchBarTextChanged: (text) => {
    dispatch(setPartnerMerchantSearchText(text));
    dispatch(clearPartnerMerchants());
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onSortPressed: (orderBy) => {
    dispatch(setPartnerMerchantSelectedOrderBy(orderBy));
    dispatch(clearPartnerMerchants());
    dispatch(downloadPartnerMerchantsAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onViewPressed: (id) => {
    dispatch(setPartnerMerchantTappedId(id));
    dispatch(downloadDeletePartnerMerchantAsync())
      .catch((error) => { dispatch(setAlertErrorMessage(error)); });
  },
  onChangeCityText: async (text) => {
    try {
      dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setCitySearchText(text));
      dispatch(clearCities());
      await dispatch(downloadCitiesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onAdvancedFilterPressed: () => {
    dispatch(setCityAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCitySearchText(''));
    dispatch(clearCities());
    dispatch(downloadCitiesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/city/, 'company.city.id');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearPartnerMerchants());
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(clearPartnerMerchants());
    dispatch(setPartnerMerchantSelectedOrderBy(INITIAL_ORDER_BY_PARTNER_MERCHANTS));
    dispatch(downloadPartnerMerchantsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCompanyOptionSelected: (option) => {
    if (option) {
      dispatch(setPartnerMerchantSelectedCompany(option));
      dispatch(change(RXFORM_PARTNER_MERCHANT, RXFIELD_PARTNER_MERCHANT_COMPANY, option));
    } else {
      dispatch(setPartnerMerchantSelectedCompany(''));
      dispatch(change(RXFORM_PARTNER_MERCHANT, RXFIELD_PARTNER_MERCHANT_COMPANY, ''));
    }
  },
  onChangeCompanyText: async (text) => {
    try {
      dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
      dispatch(setCompanySearchTextMData(text));
      dispatch(clearCompaniesMData());
      await dispatch(downloadMDataCompaniesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSavePressed: async ({
    description, shortName, tagLine, termsAndConditions,
  }) => {
    try {
      await dispatch(addEditPartnerMerchantAsync(
        description, shortName, tagLine, termsAndConditions,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSubmitPressed: async ({
    description, shortName, tagLine, termsAndConditions,
  }) => {
    await dispatch(addEditPartnerMerchantAsync(
      description, shortName, tagLine, termsAndConditions,
    ));
  },
  onEditPressed: (id) => {
    dispatch(setPartnerMerchantTappedId(id));
    dispatch(downloadDeletePartnerMerchantAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(setPartnerMerchantSelectedCompany(null));
    dispatch(reset(RXFORM_PARTNER_MERCHANT));
  },
  onDeletePressed: (id) => {
    dispatch(setPartnerMerchantTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeletePartnerMerchantAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCreatePressed: () => {
    dispatch(setPartnerMerchantTappedId(''));
    dispatch(setPartnerMerchantSelectedCompany(null));
    dispatch(setCompanyAdvancedFilterDialogSelectedFilterStringMData('status=enabled'));
    dispatch(setCompanySearchTextMData(''));
    dispatch(clearCompaniesMData());
    dispatch(downloadMDataCompaniesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeDescriptionText: (text) => {
    dispatch(change(RXFORM_PARTNER_MERCHANT, RXFIELD_PARTNER_MERCHANT_DESCRIPTION, text));
  },
  onChangeTermsAndConditionsText: (text) => {
    dispatch(change(RXFORM_PARTNER_MERCHANT, RXFIELD_PARTNER_MERCHANT_TERMS_AND_CONDITIONS, text));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerMerchantPage);
