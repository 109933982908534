import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  ADVANCED_FILTER_MODE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS,
  MENUID_EVENT_OPERATOR_MAPPING, PAGE_MODE_TABLE, RXFIELD_EVENT_OPERATOR_MAPPING_EVENT,
  RXFIELD_EVENT_OPERATOR_MAPPING_USER, RXFORM_EVENT_OPERATOR_MAPPING,
} from '../../constant';
import {
  clearEvents, downloadEventsAsync, setEventAdvancedFilterDialogSelectedFilterString,
  setEventSearchText,
} from '../../../event/redux/action';
import {
  debounceSearch, toMoment, transformDropdownData, transformInitialValues,
  transformUserDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  clearEventOperatorMappings, clearProfiles, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setEventOperatorMappingAdvancedFilterDialogSelectedFilterString,
  setEventOperatorMappingSelectedEvent, setEventOperatorMappingSelectedOrderBy,
  setEventOperatorMappingSelectedPageSize, setEventOperatorMappingSearchText,
  setEventOperatorMappingSelectedUser, setEventOperatorMappingTappedId,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  addEditEventOperatorMappingAsync, downloadDeleteEventOperatorMappingAsync,
  downloadEventOperatorMappingsAsync, enableDisableEventOperatorMappingAsync,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import EventOperatorMappingPage from './event-operator-mapping.presentation';

const getInitialValues = (state) => {
  const { eventOperatorMappings, uiEventOperatorMapping, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiEventOperatorMapping;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? eventOperatorMappings.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      userId: { label: found.user.fullName, value: found.user.id },
      eventId: { label: found.event.name, value: found.event.id },
      validUntil: found?.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
    }) : {
      userId: { label: '', value: '' },
      eventId: { label: '', value: '' },
      validUntil: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  events: transformDropdownData(state.events.data),
  users: transformUserDropdownData(state.profiles.data),
  addingEditing: state.uiEventOperatorMapping.addingEditing,
  downloading: state.uiEventOperatorMapping.downloading,
  downloadingDeleting: state.uiEventOperatorMapping.downloadingDeleting,
  enablingDisabling: state.uiEventOperatorMapping.enablingDisabling,
  loadingUser: state.uiProfile.downloading,
  loadingEvent: state.uiEvent.downloading,
  currentEventOperatorMappingStatus: state.eventOperatorMappings
    .data[state.uiFunctionalPage.tappedId]
    ? state.eventOperatorMappings.data[state.uiFunctionalPage.tappedId].status : '',
  pageMode: state.uiFunctionalPage.pageMode,
});

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(setEventSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearEvents());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: () => {
    dispatch(setEventOperatorMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_OPERATOR_MAPPING));
    dispatch(setEventOperatorMappingSearchText(''));
    dispatch(clearEventOperatorMappings());
    dispatch(setEventOperatorMappingSelectedPageSize(20));
    dispatch(setEventOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS));
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/user/, 'user.id').replace(/event/, 'event.id');
    dispatch(setEventOperatorMappingAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearEventOperatorMappings());
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_EVENT_OPERATOR_MAPPING));
    dispatch(setEventOperatorMappingSelectedEvent(''));
    dispatch(setEventOperatorMappingSelectedUser(''));
  },
  onChangeEventText: (text, mode) => {
    dispatch(setEventSearchText(text));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch, true);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadEventOperatorMappingsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setEventOperatorMappingSelectedPageSize(pageSize));
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text, mode) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(mode === ADVANCED_FILTER_MODE ? '' : 'status=enabled'));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch, true);
    }
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteEventOperatorMappingAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableEventOperatorMappingAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setEventOperatorMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setEventOperatorMappingTappedId(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileSearchText(''));
    dispatch(setEventSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearEvents());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setEventOperatorMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setEventOperatorMappingTappedId(id));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setProfileSearchText(''));
    dispatch(setEventSearchText(''));
    dispatch(clearProfiles());
    dispatch(clearEvents());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadEventsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadDeleteEventOperatorMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEventOptionSelected: (option) => {
    if (option) {
      dispatch(setEventOperatorMappingSelectedEvent(option));
      dispatch(change(RXFORM_EVENT_OPERATOR_MAPPING, RXFIELD_EVENT_OPERATOR_MAPPING_EVENT, option));
    } else {
      dispatch(setEventOperatorMappingSelectedEvent(''));
      dispatch(change(RXFORM_EVENT_OPERATOR_MAPPING, RXFIELD_EVENT_OPERATOR_MAPPING_EVENT, ''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setEventOperatorMappingSelectedUser(option));
      dispatch(change(RXFORM_EVENT_OPERATOR_MAPPING, RXFIELD_EVENT_OPERATOR_MAPPING_USER, option));
    } else {
      dispatch(setEventOperatorMappingSelectedUser(''));
      dispatch(change(RXFORM_EVENT_OPERATOR_MAPPING, RXFIELD_EVENT_OPERATOR_MAPPING_USER, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setEventOperatorMappingSelectedPageSize(pageSize));
    dispatch(clearEventOperatorMappings());
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setEventOperatorMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEventOperatorMappings());
    dispatch(setEventOperatorMappingSelectedOrderBy(INITIAL_ORDER_BY_EVENT_OPERATOR_MAPPINGS));
    dispatch(downloadEventOperatorMappingsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({ validUntil }) => {
    try {
      await dispatch(addEditEventOperatorMappingAsync(validUntil));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setEventOperatorMappingSearchText(text));
      dispatch(clearEventOperatorMappings());
      await dispatch(downloadEventOperatorMappingsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setEventOperatorMappingSelectedOrderBy(orderBy));
    dispatch(clearEventOperatorMappings());
    dispatch(downloadEventOperatorMappingsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({ validUntil }) => {
    await dispatch(addEditEventOperatorMappingAsync(validUntil));
  },
  onViewPressed: (id) => {
    dispatch(setEventOperatorMappingTappedId(id));
    dispatch(downloadDeleteEventOperatorMappingAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventOperatorMappingPage);
