import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage, SectionTitle } from '../../component';
import {
  ADVANCED_FILTER_MODE,
  EVENT_OPERATOR_INVITATION_STATUS, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PAGE_MODE_TABLE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_EVENT_OPERATOR_INVITATION_EMAIL, RXFIELD_EVENT_OPERATOR_INVITATION_END_TIME,
  RXFIELD_EVENT_OPERATOR_INVITATION_EVENT, RXFIELD_EVENT_OPERATOR_INVITATION_EVENT_ID,
  RXFIELD_EVENT_OPERATOR_INVITATION_FULL_NAME, RXFIELD_EVENT_OPERATOR_INVITATION_INVITATION_STATUS,
  RXFIELD_EVENT_OPERATOR_INVITATION_LOCATION, RXFIELD_EVENT_OPERATOR_INVITATION_NAME,
  RXFIELD_EVENT_OPERATOR_INVITATION_PHONE, RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS,
  RXFIELD_EVENT_OPERATOR_INVITATION_REGISTERED_ON, RXFIELD_EVENT_OPERATOR_INVITATION_RESENT_COUNT,
  RXFIELD_EVENT_OPERATOR_INVITATION_SHORT_DESCRIPTION, RXFIELD_EVENT_OPERATOR_INVITATION_START_TIME,
  RXFIELD_EVENT_OPERATOR_INVITATION_TIME_ZONE, RXFIELD_EVENT_OPERATOR_INVITATION_USER_ID,
  RXFORM_EVENT_OPERATOR_INVITATION, RXSTATE_EVENT_OPERATOR_INVITATION_PAGE,
  RXSTATE_EVENT_OPERATOR_INVITATIONS, status,
} from '../../constant';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape } from '../../type';
import { rxformValidateEventOperatorInvitation } from '../../validation';

const renderDialogContent = (events, profiles, addingEditing, downloadingEvents,
  downloadingProfiles, onChangeEventText, onChangeRecipientText, onEventOptionSelected,
  onRecipientsOptionSelected, pageMode) => {
  if (pageMode === PAGE_MODE_TABLE) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_EVENT_OPERATOR_INVITATION_EVENT}
            component={renderReduxFormOutlinedDropdownTextField}
            label={LocalizedString.eventInvitationPage.labelEvent}
            placeholder={LocalizedString.eventInvitationPage.placeholderEvent}
            disabled={addingEditing}
            data={events}
            loading={downloadingEvents}
            onChangeText={onChangeEventText}
            onOptionSelected={onEventOptionSelected}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_EVENT_OPERATOR_INVITATION_RECIPIENTS}
            component={renderReduxFormAutocompleteCheckboxField}
            label={LocalizedString.eventInvitationPage.labelRecipients}
            disabled={addingEditing}
            data={profiles}
            loading={downloadingProfiles}
            onChangeText={onChangeRecipientText}
            onOptionSelected={onRecipientsOptionSelected}
            onBlur={(e) => e.preventDefault()}
            required
          />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderEmail}
              label={LocalizedString.eventInvitationPage.labelEmail}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_RESENT_COUNT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderResentCount}
              label={LocalizedString.eventInvitationPage.labelResentCount}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_INVITATION_STATUS}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderInvitationStatus}
              label={LocalizedString.eventInvitationPage.labelInvitationStatus}
              disabled
              data={EVENT_OPERATOR_INVITATION_STATUS}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_REGISTERED_ON}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.eventInvitationPage.placeholderRegisteredOn}
              label={LocalizedString.eventInvitationPage.labelRegisteredOn}
              disabled
              pickerMode={PICKER_MODE_DATE_TIME}
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.eventInvitationPage.labelEvent} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_EVENT_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderId}
              label={LocalizedString.eventInvitationPage.labelId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderName}
              label={LocalizedString.eventInvitationPage.labelName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderShortDescription}
              label={LocalizedString.eventInvitationPage.labelShortDescription}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_LOCATION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderLocation}
              label={LocalizedString.eventInvitationPage.labelLocation}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_START_TIME}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.eventInvitationPage.placeholderStartTime}
              label={LocalizedString.eventInvitationPage.labelStartTime}
              disabled
              pickerMode={PICKER_MODE_DATE_TIME}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_END_TIME}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.eventInvitationPage.placeholderEndTime}
              label={LocalizedString.eventInvitationPage.labelEndTime}
              disabled
              pickerMode={PICKER_MODE_DATE_TIME}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_TIME_ZONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderTimeZone}
              label={LocalizedString.eventInvitationPage.labelTimeZone}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.eventInvitationPage.labelUser} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderId}
              label={LocalizedString.eventInvitationPage.labelId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_FULL_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventInvitationPage.placeholderFullName}
              label={LocalizedString.eventInvitationPage.labelFullName}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_INVITATION_PHONE}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.eventInvitationPage.placeholderPhone}
              label={LocalizedString.eventInvitationPage.labelPhone}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const EventOperatorInvitationPage = ({
  events, profiles,
  addingEditing, downloading, downloadingEvents, downloadingProfiles, isResendAllowed, resending,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeEventText, onChangeRecipientText,
  onConfirmContextMenuPressed, onCreatePressed, onDeletePressed, onDownloadPressed,
  onEventOptionSelected, onRecipientsOptionSelected, onRefresh, onResendPressed,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENT_OPERATOR_INVITATIONS}
    uiPage={RXSTATE_EVENT_OPERATOR_INVITATION_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.eventInvitationPage.buttonCaptionResend,
        disabled: downloading || resending || !isResendAllowed,
        onPress: (tappedId) => onResendPressed(tappedId),
      },
    ]}
    tableColumns={[
      {
        title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.eventInvitationPage.labelRecipient, field: 'email', sorting: !downloading },
      { title: LocalizedString.eventInvitationPage.labelEventName, field: 'event.name', sorting: !downloading },
      { title: LocalizedString.eventInvitationPage.labelInvitationStatus, field: 'invitationStatus', sorting: !downloading },
      { title: LocalizedString.eventInvitationPage.labelResentCount, field: 'resentCount', sorting: !downloading },
      { title: LocalizedString.eventInvitationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.eventInvitationPage.labelEvent,
        field: 'event',
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: downloadingEvents,
        onChangeFilterText: (text) => onChangeEventText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.eventInvitationPage.labelRecipient,
        field: 'email',
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.eventInvitationPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.eventInvitationPage.labelInvitationStatus,
        field: 'invitationStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: EVENT_OPERATOR_INVITATION_STATUS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.eventInvitationPage.labelStatus,
        field: 'status',
        type: FILTER_TYPE_DROPDOWN,
        data: status,
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.eventInvitationPage
      .buttonCaptionCreateNewEventInvitation}
    deleteButtonCaption={LocalizedString.eventInvitationPage.buttonCaptionDeleteEventInvitation}
    title={LocalizedString.eventInvitationPage.title}
    disableEdit
    useFullWidth
    createPermissionName="EVENT_CREATE_EVENT_INVITATION_FOR_OPERATOR"
    deletePermissionName="EVENT_DELETE_EVENT_INVITATION_FOR_OPERATOR"
    savePermissionName="EVENT_DOWNLOAD_EVENT_INVITATION_FOR_OPERATOR"
  >
    {renderDialogContent(events, profiles, addingEditing, downloadingEvents,
      downloadingProfiles, onChangeEventText, onChangeRecipientText, onEventOptionSelected,
      onRecipientsOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_EVENT_OPERATOR_INVITATION,
  validate: rxformValidateEventOperatorInvitation,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventOperatorInvitationPage);

EventOperatorInvitationPage.propTypes = {
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingEvents: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  isResendAllowed: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeRecipientText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEventOptionSelected: PropTypes.func.isRequired,
  onRecipientsOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResendPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
