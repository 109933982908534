import { ADDING_ASSET_SCREENING, SET_ASSET_SCREENING_DIALOG_VISIBILITY, SET_ASSET_SCREENING_SELECTED_TYPE } from '../action';

const initialState = {
  adding: false,
  dialogVisibility: false,
  selectedType: null,
  dialogMode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_ASSET_SCREENING:
      return { ...state, adding: action.status };
    case SET_ASSET_SCREENING_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    case SET_ASSET_SCREENING_DIALOG_VISIBILITY:
      return { ...state, dialogVisibility: action.status, dialogMode: action.mode };
    default: return state;
  }
};
