import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  RXFORM_VOUCHER_TOKEN, RXSTATE_VOUCHER_TOKENS,
  DATE_TIME_FORMAT_WITHOUT_PIPE, PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  COLOR_DISABLED_ROW, COLOR_BACKGROUND, RXFIELD_VOUCHER_TOKEN_VALID_FROM,
  RXFIELD_VOUCHER_TOKEN_VALID_UNTIL, RXFIELD_VOUCHER_TOKEN_CREATED_DATE,
  RXFIELD_VOUCHER_TOKEN_VOUCHER_ID, RXFIELD_VOUCHER_TOKEN_VOUCHER,
  RXFIELD_VOUCHER_TOKEN_MERCHANT, RXFIELD_VOUCHER_TOKEN_PURCHASED_BY,
  RXFIELD_VOUCHER_TOKEN_CLAIMED_BY, RXFIELD_VOUCHER_TOKEN_REVOKED_BY, RXFIELD_VOUCHER_TOKEN_CODE,
  RXFIELD_VOUCHER_TOKEN_IS_PURCHASED, approval, RXFIELD_VOUCHER_TOKEN_IS_CLAIMED,
  RXFIELD_VOUCHER_TOKEN_IS_REVOKED, RXFIELD_VOUCHER_TOKEN_PURCHASED_ON,
  RXFIELD_VOUCHER_TOKEN_PURCHASED_BY_ID, RXFIELD_VOUCHER_TOKEN_REVOKED_ON,
  RXFIELD_VOUCHER_TOKEN_REVOKED_BY_ID, RXFIELD_VOUCHER_TOKEN_REVOCATION_NOTES,
  RXFIELD_VOUCHER_TOKEN_CLAIM_NOTES, RXFIELD_VOUCHER_TOKEN_CLAIMED_ON,
  RXFIELD_VOUCHER_TOKEN_CLAIMED_BY_ID, RXFIELD_VOUCHER_TOKEN_VOUCHER_IMAGE,
  RXSTATE_VOUCHER_TOKEN_PAGE, FILTER_TYPE_TEXT, FILTER_TYPE_SWITCH,
  RXFIELD_VOUCHER_TOKEN_VOUCHER_NAME, RXFIELD_VOUCHER_TOKEN_MERCHANT_NAME,
  RXFIELD_VOUCHER_TOKEN_PURCHASED_POINT, RXFIELD_VOUCHER_TOKEN_PURCHASED_NOTES,
  RXFIELD_VOUCHER_TOKEN_REMARK,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { toMoment } from '../../helper';

const renderDialogContent = (
  initialValues,
) => {
  const hidePurchaseSubField = !initialValues.isPurchased
  || initialValues.isPurchased.value === false;
  const hideClaimSubField = !initialValues.isClaimed
  || initialValues.isClaimed.value === false;
  const hideRevokeSubField = !initialValues.isRevoked
  || initialValues.isRevoked.value === false;
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderCode}
            label={LocalizedString.voucherTokenPage.placeholderCode}
            disabled
            secureTextEntry
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_VALID_FROM}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderValidFrom}
            label={LocalizedString.voucherTokenPage.placeholderValidFrom}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderValidUntil}
            label={LocalizedString.voucherTokenPage.placeholderValidUntil}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_VOUCHER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderVoucherName}
            label={LocalizedString.voucherTokenPage.placeholderVoucherName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderMerchantName}
            label={LocalizedString.voucherTokenPage.placeholderMerchantName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_IS_PURCHASED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchased}
            label={LocalizedString.voucherTokenPage.placeholderPurchased}
            disabled
            data={approval}
            value={initialValues.isPurchased}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_PURCHASED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchasedOn}
            label={LocalizedString.voucherTokenPage.placeholderPurchasedOn}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_PURCHASED_POINT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchasedPoint}
            label={LocalizedString.voucherTokenPage.placeholderPurchasedPoint}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_PURCHASED_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchasedNotes}
            label={LocalizedString.voucherTokenPage.placeholderPurchasedNotes}
            disabled
            hidden={hidePurchaseSubField}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_PURCHASED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchasedById}
            label={LocalizedString.voucherTokenPage.placeholderPurchasedById}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_PURCHASED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderPurchasedBy}
            label={LocalizedString.voucherTokenPage.placeholderPurchasedBy}
            disabled
            hidden={hidePurchaseSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_IS_REVOKED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRevoked}
            label={LocalizedString.voucherTokenPage.placeholderRevoked}
            disabled
            data={approval}
            value={initialValues.isRevoked}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_REVOKED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRevokedOn}
            label={LocalizedString.voucherTokenPage.placeholderRevokedOn}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_REVOKED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRevokedById}
            label={LocalizedString.voucherTokenPage.placeholderRevokedById}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_REVOKED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRevokedBy}
            label={LocalizedString.voucherTokenPage.placeholderRevokedBy}
            disabled
            hidden={hideRevokeSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_REVOCATION_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRevocationNotes}
            label={LocalizedString.voucherTokenPage.placeholderRevocationNotes}
            disabled
            hidden={hideRevokeSubField}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_REMARK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderRemark}
            label={LocalizedString.voucherTokenPage.placeholderRemark}
            disabled
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_IS_CLAIMED}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.voucherTokenPage.placeholderClaimed}
            label={LocalizedString.voucherTokenPage.placeholderClaimed}
            disabled
            data={approval}
            value={initialValues.isClaimed}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_CLAIMED_ON}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderClaimedOn}
            label={LocalizedString.voucherTokenPage.placeholderClaimedOn}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_CLAIMED_BY_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderClaimedById}
            label={LocalizedString.voucherTokenPage.placeholderClaimedById}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_CLAIMED_BY}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderClaimedBy}
            label={LocalizedString.voucherTokenPage.placeholderClaimedBy}
            disabled
            hidden={hideClaimSubField}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_CLAIM_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.voucherTokenPage.placeholderClaimNotes}
            label={LocalizedString.voucherTokenPage.placeholderClaimNotes}
            disabled
            hidden={hideClaimSubField}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_VOUCHER_TOKEN_VOUCHER_IMAGE}
            component={renderReduxFormImageInputField}
            label={LocalizedString.voucherTokenPage.placeholderVoucherImage}
            disabled
            defaultValue={initialValues.voucherImage}
            useFullWidthImage
            fullImageWidth="100%"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const VoucherTokenPage = ({
  downloading, isRevokeAllowed,
  handleSubmit, onAppear, onChangePage,
  onChangePageSize, onRefresh,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed, initialValues,
  vouchers, loadingVoucher, onChangeVoucherText,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  merchants, loadingMerchant, onChangeMerchantText,
  purchasers, revokers, claimers, onDownloadPressed,
  onChangePurchaserText, onChangeRevokerText, onChangeClaimerText,
  loadingPurchasers, loadingRevokers, loadingClaimers,
  revoking, onRevokePressed, onConfirmPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_VOUCHER_TOKENS}
    uiPage={RXSTATE_VOUCHER_TOKEN_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.voucherTokenPage.buttonCaptionRevoke,
        disabled: revoking || !isRevokeAllowed,
        onPress: (tappedId) => onRevokePressed(tappedId,
          LocalizedString.voucherTokenPage.msgRevokeConfirmation),
      },
    ]}
    onConfirmDeletePressed={onConfirmPressed}
    filterColumns={[
      {
        title: LocalizedString.voucherTokenPage.placeholderValidFrom,
        field: RXFIELD_VOUCHER_TOKEN_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderValidUntil,
        field: RXFIELD_VOUCHER_TOKEN_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderCreatedDate,
        field: RXFIELD_VOUCHER_TOKEN_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderPurchasedDate,
        field: RXFIELD_VOUCHER_TOKEN_PURCHASED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderClaimedDate,
        field: RXFIELD_VOUCHER_TOKEN_CLAIMED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderRevokedDate,
        field: RXFIELD_VOUCHER_TOKEN_REVOKED_ON,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderVoucherId,
        field: RXFIELD_VOUCHER_TOKEN_VOUCHER_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderVoucherName,
        field: RXFIELD_VOUCHER_TOKEN_VOUCHER,
        type: FILTER_TYPE_DROPDOWN,
        data: vouchers,
        loading: loadingVoucher,
        onChangeFilterText: onChangeVoucherText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderMerchantName,
        field: RXFIELD_VOUCHER_TOKEN_MERCHANT,
        type: FILTER_TYPE_DROPDOWN,
        data: merchants,
        loading: loadingMerchant,
        onChangeFilterText: onChangeMerchantText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderPurchased,
        field: RXFIELD_VOUCHER_TOKEN_IS_PURCHASED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderPurchasedBy,
        field: RXFIELD_VOUCHER_TOKEN_PURCHASED_BY,
        type: FILTER_TYPE_DROPDOWN,
        data: purchasers,
        loading: loadingPurchasers,
        onChangeFilterText: onChangePurchaserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderClaimed,
        field: RXFIELD_VOUCHER_TOKEN_IS_CLAIMED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderClaimedBy,
        field: RXFIELD_VOUCHER_TOKEN_CLAIMED_BY,
        type: FILTER_TYPE_DROPDOWN,
        data: claimers,
        loading: loadingClaimers,
        onChangeFilterText: onChangeClaimerText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderRevoked,
        field: RXFIELD_VOUCHER_TOKEN_IS_REVOKED,
        type: FILTER_TYPE_SWITCH,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderRevokedBy,
        field: RXFIELD_VOUCHER_TOKEN_REVOKED_BY,
        type: FILTER_TYPE_DROPDOWN,
        data: revokers,
        loading: loadingRevokers,
        onChangeFilterText: onChangeRevokerText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherTokenPage.placeholderRemark,
        field: RXFIELD_VOUCHER_TOKEN_REMARK,
        type: FILTER_TYPE_TEXT,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    tableColumns={[
      {
        title: LocalizedString.voucherTokenPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.voucherTokenPage.labelCode,
        field: 'code',
        sorting: !downloading,
        render: ({ code }) => {
          const firstFourDigit = code.slice(0, 4);
          const restOfDigitAsAsterisk = code.slice(4).replace(/./g, '*');
          return `${firstFourDigit}${restOfDigitAsAsterisk}`;
        },
      },
      { title: LocalizedString.voucherTokenPage.labelVoucherName, field: 'voucher.title', sorting: !downloading },
      { title: LocalizedString.voucherTokenPage.labelMerchantName, field: 'voucher.merchant.name', sorting: !downloading },
      {
        title: LocalizedString.voucherTokenPage.labelValidFrom,
        field: 'validFrom',
        sorting: !downloading,
        render: ({ validFrom }) => (validFrom
          ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.voucherTokenPage.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.voucherTokenPage.labelPurchased, field: 'isPurchased', sorting: !downloading },
      { title: LocalizedString.voucherTokenPage.labelClaimed, field: 'isClaimed', sorting: !downloading },
      { title: LocalizedString.voucherTokenPage.labelRevoked, field: 'isRevoked', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onDownloadPressed={onDownloadPressed}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.voucherTokenPage.title}
    useFullWidth
    disableCreate
    disableEdit
    disableDelete
    savePermissionName="VOUCHER_GET_TOKEN"
    rowStyle={({
      isClaimed, isRevoked, validFrom, validUntil,
    }) => {
      const notValidYet = toMoment().isBefore(validFrom);
      const alreadyInvalid = toMoment().isAfter(validUntil);
      return ({
        backgroundColor: isClaimed || isRevoked || notValidYet || alreadyInvalid
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      });
    }}
  >
    {renderDialogContent(
      initialValues,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER_TOKEN,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherTokenPage);

VoucherTokenPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  vouchers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  purchasers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  revokers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  claimers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  isRevokeAllowed: PropTypes.bool.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  loadingPurchasers: PropTypes.bool.isRequired,
  loadingRevokers: PropTypes.bool.isRequired,
  loadingClaimers: PropTypes.bool.isRequired,
  loadingVoucher: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  revoking: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onChangePurchaserText: PropTypes.func.isRequired,
  onChangeRevokerText: PropTypes.func.isRequired,
  onChangeClaimerText: PropTypes.func.isRequired,
  onChangeVoucherText: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onRevokePressed: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
};
