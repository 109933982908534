import { reset } from 'redux-form';
import {
  clearAssets, schedulingAsset, setAlertErrorMessage,
  setCertificationScheduleSettingDialogVisibility,
} from '../simple-action';
import { scheduleAsset } from '../../../helper';
import downloadAssetsAsync from './downloadAssetsAsync';
import { RXFORM_SET_SCHEDULE_DIALOG } from '../../../constant';

export default (scheduledDate, selectedAssets) => async (dispatch, getState) => {
  try {
    dispatch(schedulingAsset(true));

    const { token } = getState().authentication;
    const assetIds = selectedAssets.map((x) => x.id);
    await scheduleAsset(scheduledDate, assetIds, token);

    dispatch(setCertificationScheduleSettingDialogVisibility(false, ''));
    dispatch(reset(RXFORM_SET_SCHEDULE_DIALOG));
    dispatch(clearAssets());
    dispatch(downloadAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(schedulingAsset(false));
  }
};
