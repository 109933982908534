import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, FormControl, FormHelperText, InputLabel, makeStyles,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_ICON, COLOR_POLYGON, COLOR_SECONDARY,
} from '../constant';
import BootstrapInput from './bootstrap-input';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: '12px 8px 4px',
    '& label.MuiInputLabel-root': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '70%',
    },
    '& label.MuiInputLabel-shrink': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_SECONDARY,
      },
    },
    width: '100%',
    minWidth: 100,
  },
  labelContainer: {
    marginBottom: 10,
  },
  label: {
    flexDirection: 'row',
    display: 'flex',
    color: COLOR_ICON,
    fontWeight: 400,
    marginLeft: -12,
  },
  requiredSymbol: {
    color: COLOR_POLYGON,
    fontSize: 14,
    fontWeight: 400,
    marginTop: -2,
    marginLeft: 2,
  },
  loadingIcon: {
    color: COLOR_SECONDARY,
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const AutocompleteTextField = ({
  options,
  disabled, error, hidden, loading, required,
  onChangeText, onOptionSelected,
  helperText, label, value,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const isRequired = required ? '*' : '';

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          {label && (
            <div className={classes.labelContainer}>
              <InputLabel shrink htmlFor="bootstrap-input" className={classes.label}>
                {label}
                <Typography className={classes.requiredSymbol}>
                  {isRequired}
                </Typography>
              </InputLabel>
            </div>
          )}
          <Autocomplete
            autoSelect
            clearOnEscape
            value={value}
            disabled={disabled}
            loading={loading}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionLabel={(option) => (typeof option === 'object' ? option.label : (option || ''))}
            onChange={(event, option) => onOptionSelected(option)}
            onInputChange={(event, text) => onChangeText(text)}
            options={options}
            renderInput={(params) => (
              <BootstrapInput
                {...params}
                ref={params.InputProps.ref}
                label=""
                disabled={disabled}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading
                        ? <CircularProgress className={classes.loadingIcon} size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                {...props}
                size="small"
              />
            )}
          />
          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default AutocompleteTextField;

AutocompleteTextField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  onChangeText: PropTypes.func,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.string]),
};

AutocompleteTextField.defaultProps = {
  disabled: false,
  error: false,
  hidden: false,
  loading: false,
  required: false,
  onChangeText: () => {},
  onOptionSelected: () => {},
  helperText: '',
  label: '',
  value: '',
};
