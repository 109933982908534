import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  setAlertErrorMessage, setAnnotationDesignTappedId,
  setCreateAnnotationDialogPdfType,
  setCreateAnnotationDialogVisibility, setSelectedFont,
} from '../../redux/action';
import { DIALOG_TYPE_ADD, RXFORM_ANNOTATION_DESIGNER } from '../../constant';
import GlobalLocalizedString from '../../../../localization';
import AddEditDialog from './add-edit-dialog.presentation';

const mapStateToProps = (state, ownProps) => {
  const defaultButtonCaption = state.uiPdfList.dialogType === DIALOG_TYPE_ADD
    ? GlobalLocalizedString.common.buttonCaptionAdd
    : GlobalLocalizedString.common.buttonCaptionSave;

  return ({
    title: state.uiPdfList.title,
    buttonCaption: ownProps.buttonCaption || defaultButtonCaption,
    dialogType: state.uiPdfList.dialogType,
    visibility: state.uiPdfList.dialogVisibility,
    pdfType: state.uiPdfList.pdfType,
    selectedImageName: state.uiAnnotationDesigner.selectedImageName,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onClosePressed: () => {
    dispatch(setCreateAnnotationDialogVisibility(false, '', ''));
    dispatch(setCreateAnnotationDialogPdfType(''));
    dispatch(setSelectedFont(''));
    dispatch(setAnnotationDesignTappedId(''));
    dispatch(reset(RXFORM_ANNOTATION_DESIGNER));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDialog);
