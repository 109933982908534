import { changingAssetReminderFlag, setAlertErrorMessage } from '../simple-action';
import { changeAssetReminderFlag } from '../../../helper';
import downloadAssetAsync from './downloadAssetAsync';

export default (reminderFlag) => async (dispatch, getState) => {
  try {
    dispatch(changingAssetReminderFlag(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAsset;

    await changeAssetReminderFlag(tappedId, reminderFlag, token);
    dispatch(downloadAssetAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(changingAssetReminderFlag(false));
  }
};
