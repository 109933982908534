import { checkingCertificationRequestApprovalPermission, setHasCertificationRequestApprovalPermission } from '../simple-action';
import { checkCertificationRequestApprovalPermission } from '../../../helper';

export default () => async (dispatch, getState) => {
  const { authentication, uiCertificationRequest } = getState();
  const { token } = authentication;
  const { tappedId } = uiCertificationRequest;

  try {
    dispatch(checkingCertificationRequestApprovalPermission(true));

    const result = await checkCertificationRequestApprovalPermission(tappedId, token);
    dispatch(setHasCertificationRequestApprovalPermission(result));
  } finally {
    dispatch(checkingCertificationRequestApprovalPermission(false));
  }
};
