import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, CircularProgress, makeStyles, Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { COLOR_BODY_TEXT_MEDIUM_LIGHT, COLOR_PRIMARY } from '../../../constant';
import { AccentButton } from '../../../component';
import LocalizedString from '../../../localization';
import GlobalLocalizedString from '../../../../../localization';
import { FormInitialValueShape } from '../../../type';

const useStyles = makeStyles(() => ({
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: COLOR_BODY_TEXT_MEDIUM_LIGHT,
  },
}));

const SettingTab = ({
  initialValues, changing, downloading,
  onAppear, onSubmitPressed,
}) => {
  const [reminderFlag, setReminderFlag] = useState(initialValues?.hasReminder || false);

  const classes = useStyles();

  useEffect(onAppear, []);

  if (downloading) {
    return (
      <div className={classes.activityIndicator}>
        <CircularProgress color="inherit" />
      </div>
    );
  }
  return (
    <div className={classes.rowContainer}>
      <div className={classes.rowContainer} style={{ flex: 1 }}>
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          style={{ marginRight: 8, color: COLOR_PRIMARY }}
          checked={reminderFlag}
          onChange={() => setReminderFlag(!reminderFlag)}
          disabled={changing}
        />
        <Typography variant="caption" className={classes.text}>
          {LocalizedString.assetPage.labelExpiredReminder}
        </Typography>
      </div>

      <AccentButton
        onClick={() => onSubmitPressed(reminderFlag)}
        variant="contained"
        caption={GlobalLocalizedString.common.buttonCaptionSave}
        disabled={downloading}
        loading={changing}
      />
    </div>
  );
};

export default SettingTab;

SettingTab.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  changing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
};
