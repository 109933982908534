import { connect } from 'react-redux';
import {
  clearFunctionalPageAdvancedFilterForm, setAdvancedFilterDialogSelectedFilterString,
  setAdvancedFilterDialogVisibility, setDialogVisibility, setFunctionalPageMode,
  setFunctionalPageSelectedRow,
  setFunctionalPageTappedId, setNotificationDialogVisibility, setTotalPersonCount,
  setBackToUrl,
} from '../../redux/action';
import {
  DIALOG_TYPE_ADD, FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM, PAGE_MODE_CREATE, PAGE_MODE_EDIT,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW,
} from '../../constant';
import { getData, getPermission } from '../../helper';
import MenuList from '../../drawer-menu';
import FunctionalPage from './functional-page.presentation';

const getMenuPath = (state, ownProps) => {
  const { uiBasePage: { activeMenuId }, uiFunctionalPage: { pageMode } } = state;

  const selectedMenu = MenuList.find((menu) => menu.id === activeMenuId);
  if (selectedMenu) {
    if (selectedMenu.level > 1) {
      const parent = MenuList.find((menu) => menu.id === selectedMenu.parentId);
      return {
        grandChildTitle: pageMode !== PAGE_MODE_TABLE ? ownProps.title : '',
        childTitle: selectedMenu.label,
        parentTitle: parent.label,
      };
    }
    return { parentTitle: selectedMenu.label };
  }
  return {};
};

const mapStateToProps = (state, ownProps) => {
  const pageState = ownProps.data ? state[ownProps.data] : null;
  const pageUiState = ownProps.uiPage ? state[ownProps.uiPage] : null;

  return ({
    data: getData(pageState, pageUiState),
    downloading: pageUiState?.downloading || false,
    currentPage: pageState?.meta?.currentPage || 0,
    totalCount: pageState?.meta?.totalCount || 0,
    selectedPageSize: pageUiState?.selectedPageSize || 1,
    searchBarText: pageUiState?.searchBarText || '',
    orderBy: pageUiState?.orderBy || '',
    addingEditing: pageUiState?.addingEditing || false,
    downloadingDeleting: pageUiState?.downloadingDeleting || false,
    enablingDisabling: pageUiState?.enablingDisabling || false,
    saving: pageUiState?.saving || false,
    sendingNotification: pageUiState?.sendingNotification || false,
    cropping: state.cropImage.cropping,
    drawerVisibility: state.uiBasePage.visibility,
    menuPath: getMenuPath(state, ownProps),
    pageMode: state.uiFunctionalPage.pageMode,
    tappedId: state.uiFunctionalPage.tappedId,
    filterString: state.uiFunctionalPage.filterString || '',
    createPermission: getPermission(state, ownProps.createPermissionName),
    deletePermission: getPermission(state, ownProps.deletePermissionName),
    editPermission: getPermission(state, ownProps.editPermissionName),
    sendNotificationPermission: getPermission(state, ownProps.sendNotificationPermissionName),
    savePermission: getPermission(state, ownProps.savePermissionName),
    backToUrl: state.uiFunctionalPage.backToUrl,
  });
};

const mapDispatchToProps = (dispatch) => ({
  onCreateNewPressed: (contentMode, title, useCreateButtonInViewMode) => {
    if (contentMode === FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM) {
      if (!useCreateButtonInViewMode) {
        dispatch(setFunctionalPageMode(PAGE_MODE_CREATE));
      }
    } else {
      dispatch(setDialogVisibility(true, DIALOG_TYPE_ADD, title));
    }
  },
  onFunctionalPageAdvancedFilterPressed: () => {
    dispatch(setAdvancedFilterDialogVisibility(true));
  },
  onFunctionalPageAppear: () => {
    dispatch(setFunctionalPageTappedId(''));
    dispatch(setDialogVisibility(false, DIALOG_TYPE_ADD, ''));
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearFunctionalPageAdvancedFilterForm());
  },
  onFunctionalPageBackPressed: () => {
    dispatch(setFunctionalPageTappedId(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
  },
  onFunctionalPageEditPressed: (id) => {
    dispatch(setFunctionalPageTappedId(id));
    dispatch(setFunctionalPageMode(PAGE_MODE_EDIT));
  },
  onFunctionalPageViewPressed: (id) => {
    dispatch(setFunctionalPageTappedId(id));
    dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
  },
  onFunctionalPageWithDetailRouteAppear: (id) => {
    if (id) {
      dispatch(setFunctionalPageMode(PAGE_MODE_VIEW));
      dispatch(setFunctionalPageTappedId(id));
    } else {
      dispatch(setDialogVisibility(false, DIALOG_TYPE_ADD, ''));
      dispatch(setAdvancedFilterDialogSelectedFilterString(''));
      dispatch(clearFunctionalPageAdvancedFilterForm());
    }
  },
  onMorePressed: (id) => {
    dispatch(setFunctionalPageTappedId(id));
  },
  onSelectionActionPressed: (selectedRow) => {
    dispatch(setFunctionalPageSelectedRow(selectedRow));
    dispatch(setFunctionalPageTappedId(''));
  },
  onSendNotificationPressed: () => {
    dispatch(setNotificationDialogVisibility(true));
    dispatch(setTotalPersonCount(0));
  },
  onNavigateToRedirectFrom: (history, url) => {
    history.push(url);
    dispatch(setBackToUrl(''));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FunctionalPage);
