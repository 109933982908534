import {
  addingEditingAssetDocument, clearAssetDocuments, setAlertErrorMessage,
} from '../simple-action';
import { addAssetDocument } from '../../../helper';
import downloadAssetDocumentsAsync from './downloadAssetDocumentsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingAssetDocument(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAsset;
    const { fileName, label, fileContent } = getState().uiAssetDocument.selectedFile;

    await addAssetDocument(tappedId, fileName, label, fileContent, token);

    dispatch(clearAssetDocuments());
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingAssetDocument(false));
  }
};
