import { setCertificationRequestApprovals, downloadingCertificationRequestApprovals } from '../simple-action';
import { downloadCertificationRequestApprovals } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingCertificationRequestApprovals(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCertificationRequest;

    const result = await downloadCertificationRequestApprovals(tappedId, token);
    dispatch(setCertificationRequestApprovals(result));
  } finally {
    dispatch(downloadingCertificationRequestApprovals(false));
  }
};
