import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  makeStyles, Dialog, DialogActions,
  DialogContent, DialogTitle, Grid,
} from '@material-ui/core';
import {
  Document, pdfjs, Page,
} from 'react-pdf';
import {
  COLOR_HORIZONTAL_LINE,
  RXFIELD_UPLOAD_PDF_DESCRIPTION,
  RXFIELD_UPLOAD_PDF_NAME,
  RXFORM_UPLOAD_PDF,
  COLOR_BACKGROUND,
  COLOR_DANGEROUS,
  COLOR_PRIMARY,
  COLOR_TEXT_ON_PRIMARY,
  FILE_EXTENSION_PDF,
} from '../../constant';
import { AccentButton, FilePickerField } from '../../../../component';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    padding: 10,
    margin: 10,
    backgroundColor: COLOR_BACKGROUND,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 0px',
  },
  buttonUploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px',
  },
  buttonUpload: {
    borderRadius: 10,
    alignSelf: 'stretch',
  },
  buttonUploadForm: {
    borderRadius: 10,
    alignSelf: 'stretch',
    width: '100%',
  },
  input: {
    display: 'none',
  },
  horizontalLine: {
    width: '100%',
    border: `1px solid ${COLOR_HORIZONTAL_LINE}`,
    margin: '20px 0px',
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
  },
  page: {
    width: '100%',
  },
  buttonDialogContainer: {
    justifyContent: 'center',
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
    padding: '0px 10px',
  },
}));

const UploadPDFPage = ({
  onAppear, onFileChange, onDocumentLoadSuccess,
  onDocumentLoadError, templatePdf, totalPages,
  uploadingPdfTemplate, visibility, usefullWidthDialog,
  onCanceledPressed, onUploadButtonPressed, onClosePressed, disabled,
  onSavePressed, handleSubmit,
}) => {
  const classes = useStyles();

  useEffect(() => {
    onAppear();
    // eslint-disable-next-line
  }, [onAppear]);

  const renderPages = () => {
    if (totalPages > 0) {
      return Array.from(new Array(totalPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} className={classes.page} />
      ));
    }
    return (
      <p>{LocalizedString.annotationDesignerPage.labelNoPage}</p>
    );
  };

  const renderDialog = () => {
    const onCancelPress = () => {
      onCanceledPressed();
      onClosePressed();
    };

    return (
      <Dialog
        open={visibility}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={usefullWidthDialog}
        classes={{ paper: classes.paper }}
      >
        <div className={`${classes.headerContainer}`}>
          <DialogTitle className={classes.title}>
            {
            LocalizedString.annotationDesignerPage.buttonCaptionUploadTemplate
          }
          </DialogTitle>
        </div>

        <DialogContent classes={{ root: classes.dialogContent }}>
          <form
            onSubmit={handleSubmit(onSavePressed)}
            className={classes.form}
          >
            <Grid container spacing={3}>
              <Grid item sm md>
                <Grid item>
                  <Field
                    name={RXFIELD_UPLOAD_PDF_NAME}
                    component={renderReduxFormOutlinedTextField}
                    placeholder={LocalizedString.annotationDesignerPage.placeholderName}
                    label={LocalizedString.annotationDesignerPage.placeholderName}
                    disabled={uploadingPdfTemplate}
                    required
                  />
                </Grid>
                <Grid item sm md>
                  <Grid item>
                    <Field
                      name={RXFIELD_UPLOAD_PDF_DESCRIPTION}
                      component={renderReduxFormOutlinedTextField}
                      placeholder={LocalizedString.annotationDesignerPage.placeholderDescription}
                      label={LocalizedString.annotationDesignerPage.placeholderDescription}
                      disabled={uploadingPdfTemplate}
                      multiline
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm md>
                <Grid item>
                  <FilePickerField
                    label={LocalizedString.annotationDesignerPage.placeholderFile}
                    acceptedFileExtension={FILE_EXTENSION_PDF}
                    onFileSelected={(e) => onFileChange(e)}
                    disabled={uploadingPdfTemplate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <DialogActions className={classes.buttonDialogContainer}>
              <AccentButton
                onClick={() => onCancelPress(onCanceledPressed, onClosePressed)}
                variant="text"
                caption={GlobalLocalizedString.common.buttonCaptionCancel}
                className={classes.buttonText}
                disabled={disabled || uploadingPdfTemplate}
                size="small"
              />
              <AccentButton
                variant="contained"
                type="submit"
                className={classes.button}
                loading={uploadingPdfTemplate}
                disableElevation
                caption={GlobalLocalizedString.common.buttonCaptionAdd}
                disabled={disabled || uploadingPdfTemplate}
                size="small"
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  return (


    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <AccentButton
          onClick={onUploadButtonPressed}
          caption={LocalizedString.annotationDesignerPage.buttonCaptionUploadTemplate}
          style={classes.buttonUpload}
          disabled={uploadingPdfTemplate}
        />
      </div>
      {renderDialog(
        visibility, usefullWidthDialog, onCanceledPressed, onClosePressed,
        disabled, onSavePressed, handleSubmit,
      )}
      <div className={classes.horizontalLine} />
      {
        !!templatePdf
        && (
        <div className={classes.pdfContainer}>
          <Document
            file={templatePdf}
            onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}
            onLoadError={(error) => onDocumentLoadError(error)}
          >
            {renderPages()}
          </Document>
        </div>
        )
      }

    </div>
  );
};

export default reduxForm({
  form: RXFORM_UPLOAD_PDF,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(UploadPDFPage);

UploadPDFPage.propTypes = {
  onAppear: PropTypes.func.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onDocumentLoadSuccess: PropTypes.func.isRequired,
  onDocumentLoadError: PropTypes.func.isRequired,
  onCanceledPressed: PropTypes.func.isRequired,
  onClosePressed: PropTypes.func.isRequired,
  onUploadButtonPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,

  visibility: PropTypes.bool.isRequired,
  uploadingPdfTemplate: PropTypes.bool.isRequired,
  usefullWidthDialog: PropTypes.bool,
  disabled: PropTypes.bool,

  templatePdf: PropTypes.string.isRequired,

  totalPages: PropTypes.number.isRequired,
};

UploadPDFPage.defaultProps = {
  usefullWidthDialog: false,
  disabled: false,
};
