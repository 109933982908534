import {
  clearAnnotationDesigns,
  deletingAnnotationDesign,
  setAnnotationDesignSelectedImage,
  setAnnotationDesignTappedId,
  setAnnotationDesigns,
} from '../simple-action';

const deleteData = (data, id) => {
  const deletedIndex = data.annotations.findIndex((x) => x.id === id);

  data.annotations.splice(deletedIndex, 1);

  const newData = {
    ...data,
    annotations: data.annotations,
  };

  return newData;
};

export default () => async (dispatch, getState) => {
  const { data } = getState().annotationDesigners;
  const { tappedId } = getState().uiAnnotationDesigner;

  try {
    dispatch(deletingAnnotationDesign(true));

    const newData = deleteData(data, tappedId);

    dispatch(clearAnnotationDesigns());
    dispatch(setAnnotationDesigns(newData));
    dispatch(setAnnotationDesignTappedId(''));
    dispatch(setAnnotationDesignSelectedImage('', ''));
  } finally {
    dispatch(deletingAnnotationDesign(false));
  }
};
