import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  ADVANCED_FILTER_MODE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_EVENT_OPERATOR_MAPPING_EVENT, RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_ID,
  RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_NAME, RXFIELD_EVENT_OPERATOR_MAPPING_USER,
  RXFIELD_EVENT_OPERATOR_MAPPING_USER_EMAIL, RXFIELD_EVENT_OPERATOR_MAPPING_USER_FULL_NAME,
  RXFIELD_EVENT_OPERATOR_MAPPING_USER_ID, RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL,
  RXFORM_EVENT_OPERATOR_MAPPING, RXSTATE_EVENT_OPERATOR_MAPPING_PAGE,
  RXSTATE_EVENT_OPERATOR_MAPPINGS, STATUS_DISABLED, STATUS_ENABLED,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateEventOperatorMapping } from '../../validation';

const renderDialogContent = (initialValues, events, users, addingEditing, downloadingDeleting,
  loadingEvent, loadingUser, onChangeEventText, onChangeUserText, onEventOptionSelected,
  onUserOptionSelected, pageMode) => {
  if (pageMode === PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderUserId}
              label={LocalizedString.mappingPage.placeholderUserId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_USER_FULL_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderFullName}
              label={LocalizedString.mappingPage.placeholderFullName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_USER_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderEmail}
              label={LocalizedString.mappingPage.placeholderEmail}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderEventId}
              label={LocalizedString.mappingPage.placeholderEventId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_EVENT_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderEventName}
              label={LocalizedString.mappingPage.placeholderEventName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.mappingPage.placeholderValidUntil}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_MAPPING_USER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.mappingPage.placeholderUser}
            label={LocalizedString.mappingPage.placeholderUser}
            disabled={addingEditing || downloadingDeleting}
            data={users}
            value={initialValues.userId}
            loading={loadingUser}
            onChangeText={onChangeUserText}
            onOptionSelected={onUserOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_MAPPING_EVENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.mappingPage.placeholderEventName}
            label={LocalizedString.mappingPage.placeholderEventName}
            disabled={addingEditing || downloadingDeleting}
            data={events}
            value={initialValues.eventId}
            loading={loadingEvent}
            onChangeText={onChangeEventText}
            onOptionSelected={onEventOptionSelected}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_OPERATOR_MAPPING_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.mappingPage.placeholderValidUntil}
            dateFormat={PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT}
            disabled={addingEditing || downloadingDeleting}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const EventOperatorMappingPage = ({
  initialValues, events, users,
  addingEditing, downloading, downloadingDeleting, enablingDisabling, loadingEvent, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeEventText, onChangePage, onChangePageSize, onChangeUserText, onConfirmContextMenuPressed,
  onContextMenuPressed, onCreatePressed, onDeletePressed, onEditPressed, onEventOptionSelected,
  onUserOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  currentEventOperatorMappingStatus, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_EVENT_OPERATOR_MAPPINGS}
    uiPage={RXSTATE_EVENT_OPERATOR_MAPPING_PAGE}
    moreMenus={[
      {
        caption: GlobalLocalizedString.common.buttonCaptionEnable,
        disabled: currentEventOperatorMappingStatus === STATUS_ENABLED || enablingDisabling,
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDisable,
        disabled: currentEventOperatorMappingStatus === STATUS_DISABLED || enablingDisabling,
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgDisableConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.mappingPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.mappingPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.mappingPage.labelEmail, field: 'user.email', sorting: !downloading },
      { title: LocalizedString.mappingPage.labelEventName, field: 'event.name', sorting: !downloading },
      {
        title: LocalizedString.mappingPage.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.mappingPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.mappingPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.mappingPage.labelValidUntil,
        field: 'validUntil',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.mappingPage.labelUserName,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: (text) => onChangeUserText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.mappingPage.labelEventName,
        field: 'event',
        type: FILTER_TYPE_DROPDOWN,
        data: events,
        loading: loadingEvent,
        onChangeFilterText: (text) => onChangeEventText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.mappingPage.buttonCaptionCreateNewMapping}
    deleteButtonCaption={LocalizedString.mappingPage.buttonCaptionDeleteMapping}
    editButtonCaption={LocalizedString.mappingPage.buttonCaptionEditMapping}
    title={LocalizedString.mappingPage.title}
    createPermissionName="EVENT_CREATE_EVENT_OPERATOR_MAPPING"
    deletePermissionName="EVENT_DELETE_EVENT_OPERATOR_MAPPING"
    editPermissionName="EVENT_EDIT_EVENT_OPERATOR_MAPPING"
    useFullWidth
  >
    {renderDialogContent(initialValues, events, users, addingEditing, downloadingDeleting,
      loadingEvent, loadingUser, onChangeEventText, onChangeUserText, onEventOptionSelected,
      onUserOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_EVENT_OPERATOR_MAPPING,
  validate: rxformValidateEventOperatorMapping,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventOperatorMappingPage);

EventOperatorMappingPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEventOptionSelected: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentEventOperatorMappingStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
};
