/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Check } from 'mdi-material-ui';
import { FunctionalPage } from '../../component';
import {
  CERT_CATEGORIES, CERT_REQUEST_STATUSES, CERT_STATUS_APPROVED, CERT_STATUS_REJECTED,
  CERT_STATUS_WAITING_FOR_APPROVAL, COLOR_APPROVED, COLOR_APPROVED_LIGHTER, COLOR_IN_PROGRESS,
  COLOR_IN_PROGRESS_LIGHTER, COLOR_PRIMARY, COLOR_REJECTED, COLOR_REJECTED_LIGHTER, COLOR_SECONDARY,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT, FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM, PAGE_MODE_CREATE,
  ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL, RXFIELD_CERTIFICATION_REQUEST_APPROVAL_STATUS,
  RXFIELD_CERTIFICATION_REQUEST_CATEGORY, RXFIELD_CERTIFICATION_REQUEST_OWNER,
  RXFIELD_CERTIFICATION_REQUEST_PIC, RXFIELD_CERTIFICATION_REQUEST_REQUEST_NUMBER,
  RXFIELD_CERTIFICATION_REQUEST_TYPE, RXSTATE_CERTIFICATION_REQUEST_PAGE,
  RXSTATE_CERTIFICATION_REQUESTS,
} from '../../constant';
import LocalizedString from '../../localization';
import { SimpleDataShape } from '../../type';
import CertificationRequestDetailPage from './detail';
import CreateCertificatioRequestPage from './create';

const assetChildActiveIcon = require('../../../../asset/asset-child-active.png');
const assetChildInactiveIcon = require('../../../../asset/asset-child-inactive.png');

const styles = {
  hyperlinkText: {
    color: COLOR_PRIMARY,
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
  statusContainer: {
    padding: 2,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const renderApprovalStatus = (approvalStatus) => {
  let statusProps;
  switch (approvalStatus) {
    case CERT_STATUS_APPROVED:
      statusProps = {
        color: COLOR_APPROVED,
        backgroundColor: COLOR_APPROVED_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelApproved,
      }; break;
    case CERT_STATUS_WAITING_FOR_APPROVAL:
      statusProps = {
        color: COLOR_IN_PROGRESS,
        backgroundColor: COLOR_IN_PROGRESS_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelOnProgress,
      }; break;
    case CERT_STATUS_REJECTED:
      statusProps = {
        color: COLOR_REJECTED,
        backgroundColor: COLOR_REJECTED_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelRejected,
      }; break;
    default: statusProps = {
      color: COLOR_PRIMARY,
      backgroundColor: COLOR_SECONDARY,
      label: approvalStatus,
    }; break;
  }

  if (approvalStatus) {
    return (
      <span style={{
        backgroundColor: statusProps.backgroundColor,
        border: `1px solid ${statusProps.color}`,
        ...styles.statusContainer,
      }}
      >
        <Typography variant="caption" style={{ color: statusProps.color }}>
          {statusProps.label}
        </Typography>
      </span>
    );
  }
  return null;
};

const CertificationRequestPage = ({
  certificationTypes, owners, pics,
  downloading, hasApprovalPermission, hasAssetPermission,
  loadingCertificationTypes, loadingOwners, loadingPics,
  onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeCertTypeText, onChangeOwnerText, onChangePicText, onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed,
  onViewPressed,
  pageMode,
}) => {
  const tabs = [
    {
      label: LocalizedString.certificationRequestPage.buttonCaptionAsset,
      activeIcon: assetChildActiveIcon,
      inactiveIcon: assetChildInactiveIcon,
      hasPermission: hasAssetPermission,
    },
    {
      label: LocalizedString.certificationRequestPage.buttonCaptionApproval,
      iconName: Check,
      hasPermission: hasApprovalPermission,
    },
  ];
  const defaultTab = tabs[1].label;

  return (
    <FunctionalPage
      data={RXSTATE_CERTIFICATION_REQUESTS}
      uiPage={RXSTATE_CERTIFICATION_REQUEST_PAGE}
      tableColumns={[
        {
          title: LocalizedString.certificationRequestPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.certificationRequestPage.labelId,
          field: 'requestNumber',
          sorting: !downloading,
          render: ({ requestNumber }) => (requestNumber
            ? (<Typography variant="caption" style={styles.hyperlinkText}>{requestNumber}</Typography>)
            : null),
        },
        { title: LocalizedString.certificationRequestPage.labelPic, field: 'pic.fullName', sorting: !downloading },
        { title: LocalizedString.certificationRequestPage.labelOwner, field: 'assetLocation.name', sorting: !downloading },
        { title: LocalizedString.certificationRequestPage.labelCategory, field: 'certificationCategory' },
        { title: LocalizedString.certificationRequestPage.labelType, field: 'certificationType.name', sorting: !downloading },
        {
          title: LocalizedString.certificationRequestPage.labelStatus,
          field: 'approvalStatus',
          sorting: !downloading,
          render: ({ approvalStatus }) => renderApprovalStatus(approvalStatus),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.certificationRequestPage.labelId,
          field: RXFIELD_CERTIFICATION_REQUEST_REQUEST_NUMBER,
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.certificationRequestPage.labelPic,
          field: RXFIELD_CERTIFICATION_REQUEST_PIC,
          type: FILTER_TYPE_DROPDOWN,
          data: pics,
          loading: loadingPics,
          onChangeFilterText: onChangePicText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.certificationRequestPage.labelOwner,
          field: RXFIELD_CERTIFICATION_REQUEST_OWNER,
          type: FILTER_TYPE_DROPDOWN,
          data: owners,
          loading: loadingOwners,
          onChangeFilterText: onChangeOwnerText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.certificationRequestPage.labelCategory,
          field: RXFIELD_CERTIFICATION_REQUEST_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: CERT_CATEGORIES,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.certificationRequestPage.labelType,
          field: RXFIELD_CERTIFICATION_REQUEST_TYPE,
          type: FILTER_TYPE_DROPDOWN,
          data: certificationTypes,
          loading: loadingCertificationTypes,
          onChangeFilterText: onChangeCertTypeText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.certificationRequestPage.labelStatus,
          field: RXFIELD_CERTIFICATION_REQUEST_APPROVAL_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: CERT_REQUEST_STATUSES,
          useDropdownValue: true,
        },
      ]}
      onAppearWithDetailRoute={(id) => onAppear(id, defaultTab)}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onViewPressed={(id, history) => onViewPressed(id, history, defaultTab)}
      createNewButtonCaption={LocalizedString.certificationRequestPage
        .buttonCaptionCreateNewCertificationRequest}
      title={pageMode === PAGE_MODE_CREATE
        ? LocalizedString.certificationRequestPage.titleCreate
        : LocalizedString.certificationRequestPage.title}
      detailTitle={LocalizedString.certificationRequestPage.titleDetail}
      useFullWidth
      disableEdit
      disableDelete
      createPermissionName="ECERTIFICATION_CREATE_CERTIFICATION_REQUEST"
      contentMode={FUNCTIONAL_PAGE_CONTENT_MODE_CUSTOM}
      detailRoute={ROUTE_NAME_CERTIFICATION_REQUEST_DETAIL}
      disableActions
      useTwoColumnFilterDialog
    >
      {pageMode === PAGE_MODE_CREATE ? (<CreateCertificatioRequestPage />)
        : (<CertificationRequestDetailPage tabs={tabs} />)}
    </FunctionalPage>
  );
};

export default CertificationRequestPage;

CertificationRequestPage.propTypes = {
  certificationTypes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  owners: PropTypes.arrayOf(SimpleDataShape).isRequired,
  pics: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  hasApprovalPermission: PropTypes.bool.isRequired,
  hasAssetPermission: PropTypes.bool.isRequired,
  loadingCertificationTypes: PropTypes.bool.isRequired,
  loadingOwners: PropTypes.bool.isRequired,
  loadingPics: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCertTypeText: PropTypes.func.isRequired,
  onChangeOwnerText: PropTypes.func.isRequired,
  onChangePicText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
