import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  setAlertConfirmationMessage, setAlertErrorMessage, setActiveSideMenuItem,
  setFormAdvancedFilterDialogSelectedFilterString, setFormSearchText,
  clearForms, setFormSelectedPageSize, setFormSelectedOrderBy,
  downloadFormsAsync, downloadDeleteFormAsync,
  enableDisableFormAsync, setFormTappedId, addEditFormAsync,
  setFormSelectedVisibilityStatus,
  setFormSelectedAllowDirectResponse,
  setFormSelectedCloseResponse, setFormSelectedDetectDuplicatedResponse,
  duplicateFormAsync, setFormDuplicateDialogVisibility,
} from '../../redux/action';
import {
  PAGE_MODE_TABLE,
  MENUID_DFORM_FORM,
  RXFORM_FORM,
  RXFORM_DUPLICATE_FORM_DIALOG,
  INITIAL_ORDER_BY_FORMS,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  RXFIELD_DFORM_FORM_VISIBILITY_STATUS,
  RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE,
  RXFIELD_DFORM_FORM_CLOSE_RESPONSE,
  RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE,
  approval,
} from '../../constant';
import { transformInitialValues, toMoment, getPermission } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import FormPage from './form.presentation';

const createPermissionName = 'DFORM_CREATE_FORM_RESPONSE';

const getInitialValues = (state) => {
  const { forms, uiForm, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiForm;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? forms.data[tappedId] : {};

  const getYesNoValue = (value) => {
    if (value === true) return approval[0];
    if (value === false) return approval[1];
    return '';
  };

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        name: found?.name || '',
        description: found?.description || '',
        createdDate: found?.createdDate ? toMoment(found.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        validFrom: found?.validFrom ? toMoment(found.validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        validUntil: found?.validUntil ? toMoment(found.validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        visibilityStatus: found?.visibilityStatus || '',
        closeFormResponse: getYesNoValue(found?.closeFormResponse) || '',
        allowDirectUserResponse: getYesNoValue(found?.allowDirectUserResponse) || '',
        detectDuplicatedResponseBasedOnReferenceId: getYesNoValue(found?.detectDuplicatedResponseBasedOnReferenceId) || '',
        title: found?.title || '',
        tag: found?.tag || '',
      })
    : {
      name: '',
      description: found?.description || '',
      createdDate: '',
      validFrom: '',
      validUntil: '',
      visibilityStatus: '',
      closeFormResponse: '',
      allowDirectUserResponse: '',
      detectDuplicatedResponseBasedOnReferenceId: '',
      title: '',
      tag: '',
    };
  return initVal;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  addingEditing: state.uiForm.addingEditing,
  downloading: state.uiForm.downloading,
  enablingDisabling: state.uiForm.enablingDisabling,
  duplicateDialogVisibility: state.uiForm.duplicateDialogVisibility,
  duplicatingForm: state.uiForm.duplicatingForm,
  isDuplicateAllowed: getPermission(state, createPermissionName),
  createPermissionName,
  currentFormStatus: state.forms.data[state.uiFunctionalPage.tappedId]
    ? state.forms.data[state.uiFunctionalPage.tappedId].status : '',
  pageMode: state.uiFunctionalPage.pageMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_DFORM_FORM));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(setFormSelectedPageSize(20));
    dispatch(setFormSelectedOrderBy(INITIAL_ORDER_BY_FORMS));
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(filterString));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_FORM));
    dispatch(setFormDuplicateDialogVisibility(false));
  },
  onCancelDialogPressed: () => {
    dispatch(reset(RXFORM_DUPLICATE_FORM_DIALOG));
    dispatch(setFormDuplicateDialogVisibility(false));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadFormsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setFormSelectedPageSize(pageSize));
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(downloadDeleteFormAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableFormAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setFormTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: () => {
    dispatch(setFormTappedId(''));
  },
  onDeletePressed: (id) => {
    dispatch(setFormTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDuplicatePressed: (id) => {
    dispatch(setFormTappedId(id));
    dispatch(setFormDuplicateDialogVisibility(true));
  },
  onEditPressed: (id) => {
    dispatch(setFormTappedId(id));
    dispatch(downloadDeleteFormAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setFormSelectedPageSize(pageSize));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearForms());
    dispatch(setFormSelectedOrderBy(INITIAL_ORDER_BY_FORMS));
    dispatch(downloadFormsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setFormSearchText(text));
      dispatch(clearForms());
      await dispatch(downloadFormsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSendFormPressed: async (values) => {
    try {
      await dispatch(duplicateFormAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSavePressed: async ({
    name, description, title, tag, visibilityStatus, validFrom, validUntil,
    closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
  }) => {
    try {
      await dispatch(addEditFormAsync(
        name, description, title, tag, visibilityStatus, validFrom, validUntil,
        closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
      ));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setFormSelectedOrderBy(orderBy));
    dispatch(clearForms());
    dispatch(downloadFormsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, description, title, tag, visibilityStatus, validFrom, validUntil,
    closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
  }) => {
    await dispatch(addEditFormAsync(
      name, description, title, tag, visibilityStatus, validFrom, validUntil,
      closeFormResponse, allowDirectUserResponse, detectDuplicatedResponseBasedOnReferenceId,
    ));
  },
  onViewPressed: (id) => {
    dispatch(setFormTappedId(id));
    dispatch(downloadDeleteFormAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setFormSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_VISIBILITY_STATUS, option));
    } else {
      dispatch(setFormSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_VISIBILITY_STATUS, ''));
    }
  },
  onAllowDirectResponseOptionSelected: (option) => {
    if (option) {
      dispatch(setFormSelectedAllowDirectResponse(option));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE, option));
    } else {
      dispatch(setFormSelectedAllowDirectResponse(''));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_ALLOW_DIRECT_RESPONSE, ''));
    }
  },
  onCloseResponseOptionSelected: (option) => {
    if (option) {
      dispatch(setFormSelectedCloseResponse(option));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_CLOSE_RESPONSE, option));
    } else {
      dispatch(setFormSelectedCloseResponse(''));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_CLOSE_RESPONSE, ''));
    }
  },
  onDetectDuplicatedResponseBasedOnReferenceIdOptionSelected: (option) => {
    if (option) {
      dispatch(setFormSelectedDetectDuplicatedResponse(option));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE, option));
    } else {
      dispatch(setFormSelectedDetectDuplicatedResponse(''));
      dispatch(change(RXFORM_FORM, RXFIELD_DFORM_FORM_DETECT_DUPLICATED_RESPONSE, ''));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormPage);
