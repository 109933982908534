import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import {
  CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD, CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT,
  DEFAULT_MAX_FILE_SIZE, PICKER_MODE_DATE, RESULT_TYPES,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_EXPIRED_DATE, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_ISSUE_DATE, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_REMARK,
  RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE, RXFORM_ASSET_CERTIFICATE,
} from '../../../constant';
import { StyledDialog } from '../../../component';
import LocalizedString from '../../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormDropzonePicker,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField,
} from '../../../../../redux-form-rendererer';
import { rxformValidateAssetCertificate } from '../../../validation';
import { FormInitialValueShape } from '../../../type';

const FILE_EXTENSIONS = {
  'application/pdf': [],
  'text/csv': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};

const AddEditCertificationScheduleSettingDialog = ({
  initialValues, selectedAssets,
  addingEditing, dialogVisibility, downloadingDeleting,
  handleSubmit, onCancelPressed, onFileSelected, onResultTypeOptionSelected,
  onSavePressed,
  dialogMode,
}) => (
  <StyledDialog
    submitting={addingEditing}
    visibility={dialogVisibility && (dialogMode === CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD
        || dialogMode === CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT)}
    onCancelPressed={onCancelPressed}
    onSubmitPressed={handleSubmit((values) => onSavePressed(values, selectedAssets))}
    title={dialogMode === CERT_SCHEDULE_SETTING_DIALOG_MODE_ADD
      ? LocalizedString.certificationScheduleSettingPage.titleAddCertificate
      : LocalizedString.certificationScheduleSettingPage.titleEditCertificate}
  >
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_ISSUE_DATE}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.certificationScheduleSettingPage.placeholderDate}
            label={LocalizedString.certificationScheduleSettingPage.labelIssueDate}
            disabled={addingEditing || downloadingDeleting}
            pickerMode={PICKER_MODE_DATE}
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_EXPIRED_DATE}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.certificationScheduleSettingPage.placeholderDate}
            label={LocalizedString.certificationScheduleSettingPage.labelExpiredDate}
            disabled={addingEditing || downloadingDeleting}
            pickerMode={PICKER_MODE_DATE}
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.certificationScheduleSettingPage.placeholderDropdown}
            label={LocalizedString.certificationScheduleSettingPage.labelResultType}
            disabled={addingEditing || downloadingDeleting}
            data={RESULT_TYPES}
            value={initialValues[RXFIELD_CERTIFICATION_SCHEDULE_SETTING_RESULT_TYPE]}
            onOptionSelected={onResultTypeOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_REMARK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.certificationScheduleSettingPage.placeholderText}
            label={LocalizedString.certificationScheduleSettingPage.labelRemark}
            disabled={addingEditing || downloadingDeleting}
            multiline
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_CERTIFICATION_SCHEDULE_SETTING_FILE}
            component={renderReduxFormDropzonePicker}
            disabled={addingEditing || downloadingDeleting}
            helperText={LocalizedString.certificationScheduleSettingPage.msgFileValidation}
            onBlur={(e) => e.preventDefault()}
            acceptedFileExtension={FILE_EXTENSIONS}
            onFileSelected={onFileSelected}
            maxSize={DEFAULT_MAX_FILE_SIZE}
            required
            hidden={dialogMode === CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT}
          />
        </Grid>
      </Grid>
    </Grid>
  </StyledDialog>
);

export default reduxForm({
  form: RXFORM_ASSET_CERTIFICATE,
  validate: rxformValidateAssetCertificate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AddEditCertificationScheduleSettingDialog);

AddEditCertificationScheduleSettingDialog.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  selectedAssets: PropTypes.arrayOf(PropTypes.string).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  dialogVisibility: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onResultTypeOptionSelected: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  dialogMode: PropTypes.string.isRequired,
};
