import { setApprovalProcesses, downloadingApprovalProcesses } from '../simple-action';
import { downloadApprovalProcesses } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingApprovalProcesses(true));

    const { token } = getState().authentication;

    const result = await downloadApprovalProcesses(token);

    dispatch(setApprovalProcesses(result));
  } finally {
    dispatch(downloadingApprovalProcesses(false));
  }
};
