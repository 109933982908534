import { downloadingAssetCertificates, setAssetCertificates } from '../simple-action';
import { downloadAssetCertificates, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, assetCertificates, uiAssetCertificate,
  } = getState();
  const { token } = authentication;
  const { meta } = assetCertificates;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiAssetCertificate;

  try {
    dispatch(downloadingAssetCertificates(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadAssetCertificates(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setAssetCertificates(list));
    }
  } finally {
    dispatch(downloadingAssetCertificates(false));
  }
};
