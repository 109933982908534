import { reset } from 'redux-form';
import {
  addingEditingCertificationType, clearCertificationTypes,
  setAlertErrorMessage, setFunctionalPageMode,
} from '../simple-action';
import { addCertificationType, editCertificationType } from '../../../helper';
import { PAGE_MODE_TABLE, RXFORM_CERTIFICATION_TYPE } from '../../../constant';
import downloadCertificationTypesAsync from './downloadCertificationTypesAsync';

export default (
  name, description,
) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCertificationType(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCertificationType;
    if (tappedId) {
      await editCertificationType(
        tappedId, name, description, token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addCertificationType(
        name, description, token,
      );
    }

    dispatch(reset(RXFORM_CERTIFICATION_TYPE));
    dispatch(clearCertificationTypes());
    dispatch(downloadCertificationTypesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingCertificationType(false));
  }
};
