import { downloadingApprovalAttachment } from '../simple-action';
import { getDownloadFileExtension, saveApprovalAttachment } from '../../../helper';
import '../../../../../component/functional-page/download-file.css';

export default (item) => async (dispatch, getState) => {
  const { global } = getState().myConfigItems;

  try {
    dispatch(downloadingApprovalAttachment(true));
    const { fileName, filePath } = item;
    const fileExtension = () => {
      if (filePath && filePath.length) {
        const split = filePath.split('.');
        return split[1] ? `.${split[1]}` : getDownloadFileExtension(global?.exportFormat || '');
      }
      return getDownloadFileExtension(global?.exportFormat || '');
    };

    const response = await saveApprovalAttachment(filePath);

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}${fileExtension()}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(downloadingApprovalAttachment(false));
  }
};
