import {
  addingAssetChild, clearAssetChildren, setAlertErrorMessage,
  setAssetChildAdvancedFilterDialogSelectedFilterString, setAssetChildDialogVisibility,
  setAssetChildSearchText, setAssetChildSelectedOrderBy, setAssetChildSelectedPageSize,
} from '../simple-action';
import { addAssetChild } from '../../../helper';
import { INITIAL_ORDER_BY_ASSET_CHILDREN } from '../../../constant';
import downloadAssetChildrenAsync from './downloadAssetChildrenAsync';

export default (assetIds) => async (dispatch, getState) => {
  try {
    dispatch(addingAssetChild(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAsset;

    await addAssetChild(tappedId, assetIds, token);

    dispatch(clearAssetChildren());
    dispatch(setAssetChildAdvancedFilterDialogSelectedFilterString(`assetParent.id=${tappedId}`));
    dispatch(setAssetChildSearchText(''));
    dispatch(setAssetChildSelectedPageSize(20));
    dispatch(setAssetChildSelectedOrderBy(INITIAL_ORDER_BY_ASSET_CHILDREN));
    dispatch(downloadAssetChildrenAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingAssetChild(false));
    dispatch(setAssetChildDialogVisibility(false));
  }
};
