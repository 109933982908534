import { connect } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';
import { REVERSED_ISO_DATE_FORMAT, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE, RXFORM_SET_SCHEDULE_DIALOG } from '../../../constant';
import { toMoment } from '../../../helper';
import { scheduleAssetAsync, setAlertErrorMessage, setCertificationScheduleSettingDialogVisibility } from '../../../redux/action';
import SetScheduleDialog from './set-schedule-dialog.presentation';

const selector = formValueSelector(RXFORM_SET_SCHEDULE_DIALOG);

const mapStateToProps = (state) => ({
  dialogVisibility: state.uiCertificationScheduleSetting.dialogVisibility,
  scheduling: state.uiCertificationScheduleSetting.scheduling,
  currDateValues: selector(state, RXFIELD_CERTIFICATION_SCHEDULE_SETTING_DATE),
  dialogMode: state.uiCertificationScheduleSetting.dialogMode,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(reset(RXFORM_SET_SCHEDULE_DIALOG));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SET_SCHEDULE_DIALOG));
    dispatch(setCertificationScheduleSettingDialogVisibility(false, ''));
  },
  onSavePressed: async ({ scheduledDate }, selectedAssets) => {
    try {
      await dispatch(scheduleAssetAsync(toMoment(scheduledDate).format(REVERSED_ISO_DATE_FORMAT),
        selectedAssets));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetScheduleDialog);
