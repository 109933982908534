import { connect } from 'react-redux';
import { change, reset, formValueSelector } from 'redux-form';
import moment from 'moment';
import {
  debounceSearch, isAdditionalItemYoutubeUrlType, localDateToUtc, toMoment, transformDropdownData,
  transformInitialValues,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  INITIAL_ORDER_BY_SESSIONS, MENUID_EVENT_SESSION, PAGE_MODE_TABLE,
  RXFORM_SESSION, RXFIELD_SESSION_MEDIA, RXFIELD_SESSION_CAN_PAY_USING_POINT,
  RXFIELD_SESSION_CATEGORY, RXFIELD_SESSION_ROOM_NAME, RXFIELD_SESSION_EVENT_NAME,
  RXFIELD_SESSION_FEATURED, RXFIELD_SESSION_NEED_APPROVAL,
  RXFIELD_SESSION_REGISTRATION_REWARD_TYPE, RXFIELD_SESSION_VISIBILITY_STATUS,
  RXFIELD_SESSION_WHO_CAN_REGISTER, RXFIELD_SESSION_CLOCK_INSIDE_AREA,
  RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS,
  RXFIELD_SESSION_DESCRIPTION, RXFIELD_SESSION_SPEAKERS,
  RXFIELD_SESSION_REGISTRATION_REWARD_POINT,
  RXFIELD_SESSION_FEATURED_ORDER,
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  SESSION_REWARD_TYPE_VOUCHER,
  SESSION_REWARD_TYPE_BOTH,
  RXFIELD_SESSION_TYPE,
  RXFIELD_SESSION_SURVEY_FORM_ID,
  RXFIELD_SESSION_PRICE,
  RXFIELD_SESSION_EARLIEST_CLOCK,
  RXFIELD_SESSION_MAX_CLOCK,
  RXFIELD_SESSION_QUOTA,
  RXFIELD_SESSION_NORMAL_PRICE,
  RXFIELD_SESSION_ADDITTIONAL_ITEMS,
  approval,
  RXFIELD_SESSION_COMMENT_ACCESS,
  RXFIELD_SESSION_ENABLE_COMMENT,
  SESSION_QR_CODE_PATH,
  SESSION_TYPE,
  RXFIELD_SESSION_BANNER,
  REST_BASE_URL,
  REST_URL_IMAGE_STORAGE,
} from '../../constant';
import {
  addEditSessionAsync, dowloadSessionsAsync,
  clearSessionRooms, clearSessions, dowloadDeleteSessionAsync, downloadSessionRoomsAsync,
  enableDisableSessionAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setSessionAdvancedFilterDialogSelectedFilterString, setSessionSearchText,
  setSessionSelectedOrderBy, setSessionSelectedPageSize,
  setSessionTappedId, setSessionsSelectedAddMediaMenu,
  setSessionSelectedVoucher, setSessionSelectedCanPayUsingPoint,
  setSessionSelectedCategoryName, setSessionSelectedRoom,
  setSessionSelectedEventName, setSessionSelectedFeaturedEvent,
  setSessionSelectedNeedApproval, setSessionSelectedRewardType,
  setSessionSelectedVisibilityStatus, setSessionSelectedWhoCanRegister,
  setSessionSelectedClockInsideArea, clearEvents, downloadEventsAsync,
  setEventAdvancedFilterDialogSelectedFilterString, setEventSearchText, clearSpeakers,
  downloadSpeakersAsync, clearSessionCategories, downloadSessionCategoriesAsync,
  setSpeakerAdvancedFilterDialogSelectedFilterString, setSpeakerSearchText, addingSessionSpeakers,
  setSessionTableDataSpeakers, setSessionSelectedSpeakers, addSession,
  setSessionCategoryAdvancedFilterDialogSelectedFilterString, setSessionCategorySearchText,
  setSessionSelectedType,
  setSessionSelectedSurveyForm,
  setSessionSelectedEnableComment,
  copyingSessionQrCode, copyingSessionDeepLink, sendSessionNotificationAsync, setRoomSearchText,
  clearRooms, downloadRoomsAsync, setRoomAdvancedFilterDialogSelectedFilterString,
  setSessionBannerImage,
} from '../../redux/action';
import {
  clearVouchers, downloadVouchersAsync, setVoucherAdvancedFilterDialogSelectedFilterString,
  setVoucherSearchText,
} from '../../../voucher/redux/action';
import SessionPage from './session.presentation';
import {
  clearForms, downloadFormsAsync, setFormAdvancedFilterDialogSelectedFilterString,
  setFormSearchText,
} from '../../../dform/redux/action';

const transformVoucherDropdownData = (data) => Object.values(data).map((item) => (
  {
    label: item.voucher.voucherName,
    value: item.voucher.id,
  }
));

const transformSpeakers = (data) => (data.length > 0 ? data.map((x) => (x.id
  ? { label: x.name, value: x.id } : x)) : []);

const getInitialValues = (state) => {
  const {
    sessions, uiSession, uiFunctionalPage,
  } = state;
  const {
    downloadingDeleting, tappedId, tableDataSpeakers,
    selectedSurveyForm,
  } = uiSession;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessions.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found, {
      enableComment: found.enableComment ? approval[0] : approval[1],
      eventName: found.event.name ? found.event.name : '',
      roomName: found.room.name ? found.room.name : '',
      categoryName: found.category.name ? found.category.name : '',
      registrationRewardVouchers: found?.registrationRewardVouchers
        ? transformVoucherDropdownData(found?.registrationRewardVouchers) : [],
      speakers: tableDataSpeakers?.length > 0 ? transformSpeakers(tableDataSpeakers) : [],
      clockInMustBeInsideArea: found?.clockInMustBeInsideArea
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      isFeatured: found?.isFeatured
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      needApproval: found?.needApproval
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      canPayUsingPoint: found?.canPayUsingPoint
        ? { label: GlobalLocalizedString.common.labelYes, value: true }
        : { label: GlobalLocalizedString.common.labelNo, value: false },
      startTime: found?.startTime ? toMoment(found?.startTime, found.room.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      endTime: found?.endTime ? toMoment(found?.endTime, found.room.location?.city?.province.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
      media: found.media ? found.media.map((x, i) => ({
        ...x,
        tableData: { id: i },
      })) : [],
      surveyForm: found.surveyFormId ? selectedSurveyForm : {
        label: '',
        value: '',
      },
      additionalItems: found.additionalItems ? found.additionalItems.map((x) => ({
        ...x,
        url: isAdditionalItemYoutubeUrlType(x) ? x.path : undefined,
      })) : [],
      clockInQrCode: found.randomId ? `${SESSION_QR_CODE_PATH}${found.randomId}` : null,
      banner: found.banner ? `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${found.banner}` : null,
    }) : {
      enableComment: approval[1],
      eventName: '',
      roomName: '',
      categoryName: '',
      registrationRewardVouchers: [],
      speakers: tableDataSpeakers?.length > 0 ? tableDataSpeakers : [],
      clockInMustBeInsideArea: '',
      isFeatured: '',
      needApproval: '',
      canPayUsingPoint: '',
      media: [],
      earliestClockInBeforeMinutes: 0,
      maxClockInMinutes: 0,
      featuredOrder: 0,
      registrationRewardPoint: 0,
      quota: 0,
      normalPrice: 0,
      price: 0,
      surveyFormId: {
        label: '',
        value: '',
      },
      banner: null,
    };
  return initVal;
};

const getDiscusId = (state) => {
  const { sessions, uiSession, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSession;
  const { pageMode } = uiFunctionalPage;

  return pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessions.data[tappedId].discussionId : '';
};

const selector = formValueSelector(RXFORM_SESSION);

const mapStateToProps = (state) => ({
  addingEditing: state.uiSession.addingEditing,
  downloading: state.uiSession.downloading,
  downloadingDeleting: state.uiSession.downloadingDeleting,
  rooms: transformDropdownData(state.rooms.data),
  loadingRooms: state.uiRoom.downloading,
  sessionRooms: transformDropdownData(state.sessionRooms),
  loadingSessionRooms: state.uiSession.downloadingSessionRooms,
  locations: transformDropdownData(state.locations.data),
  pageMode: state.uiFunctionalPage.pageMode,
  initialValues: getInitialValues(state),
  selectedAddMediaMenu: state.uiSession.selectedAddMediaMenu,
  vouchers: transformDropdownData(state.vouchers.data),
  loadingVoucher: state.uiVoucher.downloading,
  events: transformDropdownData(state.events.data),
  loadingEvent: state.uiEvent.downloading,
  sessionCategories: transformDropdownData(state.sessionCategories.data),
  loadingSessionCategory: state.uiSessionCategory.downloading,
  valueRewardType: state.uiSession.selectedRewardType,
  formSelectedRewardType: selector(state, RXFIELD_SESSION_REGISTRATION_REWARD_TYPE),
  speaker: transformDropdownData(state.speakers.data),
  loadingSpeaker: state.uiSpeaker.downloading,
  tableDataSpeakers: state.uiSession.tableDataSpeakers,
  selectedSpeakers: state.uiSession.selectedSpeakers,
  addingSessionSpeaker: state.uiSession.addingSessionSpeaker,
  tappedSession: state.sessions.data[state.uiSession.tappedId],
  formSelectedIsFeatured: selector(state, RXFIELD_SESSION_FEATURED),
  voucherValues: selector(state, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS) || [],
  mediaValues: selector(state, RXFIELD_SESSION_MEDIA) || [],
  surveyFormData: transformDropdownData(state.forms.data),
  loadingSurveyForm: state.uiForm.downloading,
  typeValues: selector(state, RXFIELD_SESSION_TYPE),
  selectedEnableComment: state.uiSession.selectedEnableComment,
  copying: state.uiSession.copying,
  copyingDeepLink: state.uiSession.copyingDeepLink,
  currentSessionStatus: state.sessions.data[state.uiFunctionalPage.tappedId]
    ? state.sessions.data[state.uiFunctionalPage.tappedId].status : '',
  enablingDisabling: state.uiSession.enablingDisabling,
  sendingNotification: state.uiSession.sendingNotification,
  sendingNotifications: state.notification.sendingNotifications,
  discusId: getDiscusId(state),
  selectedBannerImage: state.uiSession.selectedBannerImage,
});

const searchRoomDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchVoucherDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchEventDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSessionCategoryDebouce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSpeakerDebouce = debounceSearch(
  (dispatch) => {
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const searchSurveyFormDebouce = debounceSearch(
  (dispatch) => {
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
);

const validFrom = localDateToUtc(moment().format());
const validUntil = localDateToUtc(moment().format());

const convertFilterStringVouchers = (`validFrom<=${validFrom}|validUntil>=${validUntil}|status=Enabled`);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SESSION));
    dispatch(setSessionSearchText(''));
    dispatch(clearSessions());
    dispatch(setSessionSelectedPageSize(20));
    dispatch(setSessionSelectedOrderBy(INITIAL_ORDER_BY_SESSIONS));
    dispatch(dowloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAddAdditionalItemsPressed: (data) => {
    const result = data.map((x) => ({
      ...x,
      content: !isAdditionalItemYoutubeUrlType(x) ? x?.content : undefined,
      url: isAdditionalItemYoutubeUrlType(x) ? x?.url : undefined,
    }));
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ADDITTIONAL_ITEMS, result));
  },
  onAddMediaPressed: (data) => {
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_MEDIA, data));
  },
  onAddMediaMenuSelected: (menu) => {
    dispatch(setSessionsSelectedAddMediaMenu(menu));
  },
  onAddSpeakerPressed: (data) => {
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SPEAKERS, data));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setSessionSelectedRoom(''));
    dispatch(setRoomAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setRoomSearchText(''));
    dispatch(clearRooms());
    dispatch(downloadRoomsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedEventName(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedCategoryName(''));
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionCategorySearchText(''));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedSurveyForm(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/roomName/, 'room.id').replace(/event/, 'event.id')
      .replace(/categoryName/, 'category.id')
      .replace(/surveyForm/, 'surveyFormId');
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessions());
    dispatch(dowloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION));
    dispatch(setSessionSelectedEnableComment(''));
    dispatch(setSessionBannerImage(''));
  },
  onCanPayPointOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedCanPayUsingPoint(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CAN_PAY_USING_POINT, option));
    } else {
      dispatch(setSessionSelectedCanPayUsingPoint(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CAN_PAY_USING_POINT, ''));
    }
  },
  onCategoryNameOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedCategoryName(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CATEGORY, option));
    } else {
      dispatch(setSessionSelectedCategoryName(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CATEGORY, ''));
    }
  },
  onChangeCategoryNameText: async (text, changeAdvancedFilter) => {
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString(
      changeAdvancedFilter ? '' : 'status=enabled',
    ));
    dispatch(setSessionCategorySearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionCategoryDebouce(dispatch);
    }
  },
  onChangeDescriptionText: (text) => {
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_DESCRIPTION, text));
  },
  onChangeEventNameText: async (text, changeAdvancedFilter) => {
    dispatch(setEventAdvancedFilterDialogSelectedFilterString(
      changeAdvancedFilter ? '' : 'status=enabled|visibilityStatus=visible',
    ));
    dispatch(setEventSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEventDebounce(dispatch);
    }
  },
  onChangeRoomOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedRoom(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ROOM_NAME, option));
    } else {
      dispatch(setSessionSelectedRoom(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ROOM_NAME, ''));
    }
  },
  onChangeRoomText: async (text, changeAdvancedFilter) => {
    dispatch(setRoomAdvancedFilterDialogSelectedFilterString(
      changeAdvancedFilter ? '' : 'status=enabled',
    ));
    dispatch(setRoomSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchRoomDebounce(dispatch);
    }
  },
  onChangeSurveyFormOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedSurveyForm(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SURVEY_FORM_ID, option));
    } else {
      dispatch(setSessionSelectedSurveyForm(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SURVEY_FORM_ID, ''));
    }
  },
  onChangeSurveyFormText: async (text, changeAdvanceFilter) => {
    dispatch(setFormAdvancedFilterDialogSelectedFilterString(
      changeAdvanceFilter ? '' : 'status=enabled',
    ));
    dispatch(setFormSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSurveyFormDebouce(dispatch);
    }
  },
  onChangeVoucherText: async (text) => {
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(convertFilterStringVouchers));
    dispatch(setVoucherSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchVoucherDebounce(dispatch);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(dowloadSessionsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionSelectedPageSize(pageSize));
    dispatch(dowloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCommentAccessOptionSelected: (option) => {
    if (option) {
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_COMMENT_ACCESS, option));
    } else {
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_COMMENT_ACCESS, ''));
    }
  },
  onContextMenuPressed: (id, message) => {
    dispatch(setSessionTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onConfirmContextMenuPressed: (reason, message) => {
    switch (message) {
      case GlobalLocalizedString.common.msgDeleteConfirmation:
        dispatch(dowloadDeleteSessionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default:
        dispatch(enableDisableSessionAsync())
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
    }
  },
  onCopyPressed: (status) => {
    dispatch(copyingSessionQrCode(!status));
  },
  onCopyDeepLinkPressed: (status) => {
    dispatch(copyingSessionDeepLink(!status));
  },
  onCreatePressed: () => {
    dispatch(setSessionTappedId(''));
    dispatch(reset(RXFORM_SESSION));
    dispatch(setSessionSelectedEnableComment(approval[1]));
    dispatch(setSessionSelectedRoom(''));

    dispatch(clearSessionRooms());

    dispatch(setSessionSelectedEventName(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedCategoryName(''));
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSessionCategorySearchText(''));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedVoucher([]));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(convertFilterStringVouchers));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionTableDataSpeakers([]));
    dispatch(setSessionSelectedSpeakers(null));
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(''));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedSurveyForm(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onClockInsideAreaOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedClockInsideArea(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CLOCK_INSIDE_AREA, option));
    } else {
      dispatch(setSessionSelectedClockInsideArea(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CLOCK_INSIDE_AREA, ''));
    }
  },
  onDeleteAdditionalItemsPressed: (data) => {
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ADDITTIONAL_ITEMS, data));
  },
  onDeleteMediaPressed: (data) => {
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_MEDIA, data));
  },
  onDeletePressed: (id) => {
    dispatch(setSessionTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setSessionTappedId(id));
    dispatch(dowloadDeleteSessionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });

    dispatch(setSessionSelectedRoom(''));

    dispatch(setSessionSelectedEventName(''));
    dispatch(setEventAdvancedFilterDialogSelectedFilterString('status=enabled|visibilityStatus=visible'));
    dispatch(setEventSearchText(''));
    dispatch(clearEvents());
    dispatch(downloadEventsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedCategoryName(''));
    dispatch(setSessionCategoryAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSessionCategorySearchText(''));
    dispatch(clearSessionCategories());
    dispatch(downloadSessionCategoriesAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedVoucher([]));
    dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(convertFilterStringVouchers));
    dispatch(setVoucherSearchText(''));
    dispatch(clearVouchers());
    dispatch(downloadVouchersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedSpeakers(null));
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(''));
    dispatch(clearSpeakers());
    dispatch(downloadSpeakersAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));

    dispatch(setSessionSelectedSurveyForm(''));
    dispatch(setFormAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setFormSearchText(''));
    dispatch(clearForms());
    dispatch(downloadFormsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onEnableCommentOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedEnableComment(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ENABLE_COMMENT, option));
    } else {
      dispatch(setSessionSelectedEnableComment(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ENABLE_COMMENT, ''));
    }
  },
  onEventNameOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedEventName(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_EVENT_NAME, option));
      dispatch(clearSessionRooms());
      dispatch(downloadSessionRoomsAsync(option.value))
        .catch((error) => dispatch(setAlertErrorMessage(error)));
    } else {
      dispatch(setSessionSelectedEventName(''));
      dispatch(setSessionSelectedRoom(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_ROOM_NAME, ''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_EVENT_NAME, ''));
      dispatch(clearSessionRooms());
    }
  },
  onIsFeaturedOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedFeaturedEvent(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_FEATURED, option));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_FEATURED_ORDER, 0));
    } else {
      dispatch(setSessionSelectedFeaturedEvent(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_FEATURED, ''));
    }
  },
  onNeedApprovalOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedNeedApproval(option.value));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_NEED_APPROVAL, option));
    } else {
      dispatch(setSessionSelectedNeedApproval(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_NEED_APPROVAL, ''));
    }
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionSelectedPageSize(pageSize));
    dispatch(clearSessions());
    dispatch(dowloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRewardTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedRewardType(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_TYPE, option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_POINT, 0));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS, []));
      dispatch(setSessionSelectedVoucher([]));

      if (option === SESSION_REWARD_TYPE_VOUCHER || option === SESSION_REWARD_TYPE_BOTH) {
        dispatch(setVoucherAdvancedFilterDialogSelectedFilterString(convertFilterStringVouchers));
        dispatch(setVoucherSearchText(''));
        dispatch(clearVouchers());
        dispatch(downloadVouchersAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    } else {
      dispatch(setSessionSelectedRewardType(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_TYPE, ''));
    }
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessions());
    dispatch(setSessionSelectedOrderBy(INITIAL_ORDER_BY_SESSIONS));
    dispatch(dowloadSessionsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    name, shortDescription, description, startTime, endTime, earliestClockInBeforeMinutes,
    maxClockInMinutes, quota, featuredOrder, normalPrice, price,
    registrationRewardVouchers, registrationRewardPoint, speakers, media, additionalItems,
    commentAccess, banner,
  }) => {
    try {
      await dispatch(addEditSessionAsync(name, shortDescription, description, startTime,
        endTime, earliestClockInBeforeMinutes, maxClockInMinutes, quota, featuredOrder,
        normalPrice, price, registrationRewardVouchers, registrationRewardPoint, speakers, media,
        additionalItems, commentAccess, banner));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionSearchText(text));
      dispatch(clearSessions());
      await dispatch(dowloadSessionsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionSelectedOrderBy(orderBy));
    dispatch(clearSessions());
    dispatch(dowloadSessionsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    name, shortDescription, description, startTime, endTime, earliestClockInBeforeMinutes,
    maxClockInMinutes, quota, featuredOrder, normalPrice, price, registrationRewardVouchers,
    registrationRewardPoint, speakers, media, additionalItems, commentAccess, banner,
  }) => {
    await dispatch(addEditSessionAsync(name, shortDescription, description, startTime,
      endTime, earliestClockInBeforeMinutes, maxClockInMinutes, quota, featuredOrder,
      normalPrice, price, registrationRewardVouchers, registrationRewardPoint, speakers, media,
      additionalItems, commentAccess, banner));
  },
  onTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedType(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_TYPE, option));

      dispatch(setSessionSelectedRewardType('None'));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_TYPE, ''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_POINT, 0));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS, []));

      dispatch(setSessionSelectedSurveyForm(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SURVEY_FORM_ID, ''));

      dispatch(setSessionSelectedClockInsideArea(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CLOCK_INSIDE_AREA, ''));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_EARLIEST_CLOCK, 0));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_MAX_CLOCK, 0));

      dispatch(setSessionSelectedWhoCanRegister(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_WHO_CAN_REGISTER, ''));

      dispatch(setSessionSelectedNeedApproval(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_NEED_APPROVAL, ''));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_QUOTA, 0));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_NORMAL_PRICE, 0));

      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_PRICE, 0));

      dispatch(setSessionSelectedCanPayUsingPoint(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_CAN_PAY_USING_POINT, ''));

      if (option === SESSION_TYPE[0]) {
        dispatch(setSessionSelectedSurveyForm(''));
        dispatch(setFormAdvancedFilterDialogSelectedFilterString(''));
        dispatch(setFormSearchText(''));
        dispatch(clearForms());
        dispatch(downloadFormsAsync(1))
          .catch((error) => dispatch(setAlertErrorMessage(error)));
      }
    } else {
      dispatch(setSessionSelectedType(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_TYPE, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setSessionTappedId(id));
    dispatch(dowloadDeleteSessionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onVoucherOptionSelected: (option) => {
    if (option.length > 0) {
      dispatch(setSessionSelectedVoucher(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS, option));
    } else {
      dispatch(setSessionSelectedVoucher([]));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_REGISTRATION_REWARD_VOUCHERS, []));
    }
  },
  onVisibilityStatusOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedVisibilityStatus(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_VISIBILITY_STATUS, option));
    } else {
      dispatch(setSessionSelectedVisibilityStatus(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_VISIBILITY_STATUS, ''));
    }
  },
  onWhoCanRegisterOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedWhoCanRegister(option));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_WHO_CAN_REGISTER, option));
    } else {
      dispatch(setSessionSelectedWhoCanRegister(''));
      dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_WHO_CAN_REGISTER, ''));
    }
  },
  onAddSpeakersPressed: async (defaultValue, newInitialValue, selectedOption,
    currentData) => {
    if (selectedOption) {
      try {
        dispatch(addingSessionSpeakers(true));
        if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
          const result = {
            ...currentData,
            speakers: defaultValue.length > 0
              ? [...defaultValue, selectedOption] : [selectedOption],
          };
          await dispatch(addSession(result));
          dispatch(setSessionTableDataSpeakers(result.speakers));
          dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SPEAKERS,
            result.speakers));
        } else {
          const newData = newInitialValue.length > 0
            ? [...newInitialValue.filter((x) => (x.value !== selectedOption.value)), selectedOption]
            : [selectedOption];
          await dispatch(setSessionTableDataSpeakers(newData));
          dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SPEAKERS, newData));
        }
        dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
        dispatch(setSpeakerSearchText(''));
        dispatch(clearSpeakers());
        dispatch(downloadSpeakersAsync(1))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          });
      } finally {
        dispatch(setSessionSelectedSpeakers(null));
        dispatch(addingSessionSpeakers(false));
      }
    }
  },
  onChangeSpeakersText: async (text) => {
    dispatch(setSpeakerAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSpeakerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSpeakerDebouce(dispatch);
    }
  },
  onDeleteSpeakersPressed: async (newValue, currentData) => {
    if (Object.prototype.hasOwnProperty.call(currentData, 'id')) {
      const result = { ...currentData, speakers: newValue };
      await dispatch(addSession(result));
      dispatch(setSessionTableDataSpeakers(result.speakers));
    } else {
      dispatch(setSessionTableDataSpeakers(newValue));
    }
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_SPEAKERS, newValue));
  },
  onSpeakersOptionSelected: (option) => {
    if (option) {
      dispatch(setSessionSelectedSpeakers(option));
    } else {
      dispatch(setSessionSelectedSpeakers(null));
    }
  },
  onSendToMePressed: () => {
    dispatch(sendSessionNotificationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onBannerImageSelected: (image, fileName, url) => {
    dispatch(setSessionBannerImage(url));
    dispatch(change(RXFORM_SESSION, RXFIELD_SESSION_BANNER, image));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionPage);
