import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  ADVANCED_FILTER_MODE,
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_SESSION_OPERATOR_MAPPING_SESSION, RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_ID,
  RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_NAME, RXFIELD_SESSION_OPERATOR_MAPPING_USER,
  RXFIELD_SESSION_OPERATOR_MAPPING_USER_EMAIL, RXFIELD_SESSION_OPERATOR_MAPPING_USER_FULL_NAME,
  RXFIELD_SESSION_OPERATOR_MAPPING_USER_ID, RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL,
  RXFORM_SESSION_OPERATOR_MAPPING, RXSTATE_SESSION_OPERATOR_MAPPING_PAGE,
  RXSTATE_SESSION_OPERATOR_MAPPINGS, STATUS_DISABLED, STATUS_ENABLED,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateSessionOperatorMapping } from '../../validation';

const renderDialogContent = (initialValues, sessions, users, addingEditing, downloadingDeleting,
  loadingSession, loadingUser, onChangeSessionText, onChangeUserText, onSessionOptionSelected,
  onUserOptionSelected, pageMode) => {
  if (pageMode === PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_USER_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderUserId}
              label={LocalizedString.mappingPage.placeholderUserId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_USER_FULL_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderFullName}
              label={LocalizedString.mappingPage.placeholderFullName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_USER_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderEmail}
              label={LocalizedString.mappingPage.placeholderEmail}
              disabled
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderSessionId}
              label={LocalizedString.mappingPage.placeholderSessionId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_SESSION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.mappingPage.placeholderSessionName}
              label={LocalizedString.mappingPage.placeholderSessionName}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.mappingPage.placeholderValidUntil}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_MAPPING_USER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.mappingPage.placeholderUser}
            label={LocalizedString.mappingPage.placeholderUser}
            disabled={addingEditing || downloadingDeleting}
            data={users}
            value={initialValues.userId}
            loading={loadingUser}
            onChangeText={onChangeUserText}
            onOptionSelected={onUserOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_MAPPING_SESSION}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.mappingPage.placeholderSessionName}
            label={LocalizedString.mappingPage.placeholderSessionName}
            disabled={addingEditing || downloadingDeleting}
            data={sessions}
            value={initialValues.sessionId}
            loading={loadingSession}
            onChangeText={onChangeSessionText}
            onOptionSelected={onSessionOptionSelected}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SESSION_OPERATOR_MAPPING_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.mappingPage.placeholderValidUntil}
            dateFormat={PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT}
            disabled={addingEditing || downloadingDeleting}
            required
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SessionOperatorMappingPage = ({
  initialValues, sessions, users,
  addingEditing, downloading, downloadingDeleting, enablingDisabling, loadingSession, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeSessionText, onChangePage, onChangePageSize, onChangeUserText,
  onConfirmContextMenuPressed, onContextMenuPressed, onCreatePressed, onDeletePressed,
  onEditPressed, onSessionOptionSelected, onUserOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_SESSION_OPERATOR_MAPPINGS}
    uiPage={RXSTATE_SESSION_OPERATOR_MAPPING_PAGE}
    moreMenus={[
      {
        caption: GlobalLocalizedString.common.buttonCaptionEnable,
        disabled: (data) => (data.status === STATUS_ENABLED || enablingDisabling),
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDisable,
        disabled: (data) => (data.status === STATUS_DISABLED || enablingDisabling),
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          GlobalLocalizedString.common.msgDisableConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.mappingPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.mappingPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.mappingPage.labelEmail, field: 'user.email', sorting: !downloading },
      { title: LocalizedString.mappingPage.labelSessionName, field: 'session.name', sorting: !downloading },
      {
        title: LocalizedString.mappingPage.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.mappingPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.mappingPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.mappingPage.labelValidUntil,
        field: 'validUntil',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.mappingPage.labelUserName,
        field: 'user',
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.mappingPage.labelSessionName,
        field: 'session',
        type: FILTER_TYPE_DROPDOWN,
        data: sessions,
        loading: loadingSession,
        onChangeFilterText: (text) => onChangeSessionText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.mappingPage
      .buttonCaptionCreateNewMapping}
    deleteButtonCaption={LocalizedString.mappingPage.buttonCaptionDeleteMapping}
    editButtonCaption={LocalizedString.mappingPage.buttonCaptionEditMapping}
    title={LocalizedString.mappingPage.title}
    createPermissionName="EVENT_CREATE_SESSION_OPERATOR_MAPPING"
    deletePermissionName="EVENT_DELETE_SESSION_OPERATOR_MAPPING"
    editPermissionName="EVENT_EDIT_SESSION_OPERATOR_MAPPING"
    useFullWidth
  >
    {renderDialogContent(initialValues, sessions, users, addingEditing, downloadingDeleting,
      loadingSession, loadingUser, onChangeSessionText, onChangeUserText, onSessionOptionSelected,
      onUserOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SESSION_OPERATOR_MAPPING,
  validate: rxformValidateSessionOperatorMapping,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SessionOperatorMappingPage);

SessionOperatorMappingPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  sessions: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingSession: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangeSessionText: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onSessionOptionSelected: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
