import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, RXFIELD_DISTRICT_NAME,
  RXFIELD_DISTRICT_CITY, RXFORM_DISTRICT, RXSTATE_DISTRICTS, RXSTATE_DISTRICT_PAGE, STATUS_DISABLED,
  STATUS_ENABLED, status, RXFIELD_DISTRICT_CODE, FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_FORMAT, ADVANCED_FILTER_MODE,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateDistrict } from '../../validation';

const renderDialogContent = (defaultValue, cities, addingEditing, loadingCity,
  onChangeCityText, onCityOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DISTRICT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.districtScreen.placeholderDistrictName}
            label={LocalizedString.districtScreen.placeholderDistrictName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_DISTRICT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.districtScreen.placeholderDistrictCode}
            label={LocalizedString.districtScreen.placeholderDistrictCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_DISTRICT_CITY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.districtScreen.placeholderCityName}
            label={LocalizedString.districtScreen.placeholderCityName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={cities}
            value={defaultValue}
            loading={loadingCity}
            onChangeText={onChangeCityText}
            onOptionSelected={onCityOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const DistrictPage = ({
  initialValues, cities,
  addingEditing, downloading, enablingDisabling, loadingCity, isResyncAllowed, resyncing,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeCityText, onConfirmContextMenuPressed,
  onContextMenuPressed, onCreatePressed, onDownloadPressed, onEditPressed,
  onCityOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  currentDistrictStatus, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_DISTRICTS}
    uiPage={RXSTATE_DISTRICT_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.common.buttonCaptionEnable,
        disabled: currentDistrictStatus === STATUS_ENABLED || enablingDisabling,
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          LocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionDisable,
        disabled: currentDistrictStatus === STATUS_DISABLED || enablingDisabling,
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          LocalizedString.common.msgDisableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionResync,
        disabled: resyncing || !isResyncAllowed,
        onPress: (tappedId) => onContextMenuPressed(tappedId,
          LocalizedString.common.msgResyncConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.districtScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.districtScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.districtScreen.labelCity, field: 'city.name', sorting: !downloading },
      { title: LocalizedString.districtScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      { title: LocalizedString.districtScreen.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
      { title: LocalizedString.districtScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.districtScreen.labelCity,
        field: 'city',
        type: FILTER_TYPE_DROPDOWN,
        data: cities,
        loading: loadingCity,
        onChangeFilterText: (text) => onChangeCityText(text, ADVANCED_FILTER_MODE),
        useDropdownValue: true,
      },
      {
        title: LocalizedString.districtScreen.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.districtScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDownloadPressed={onDownloadPressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.districtScreen.buttonCaptionCreateNewDistrict}
    deleteButtonCaption={LocalizedString.districtScreen.buttonCaptionDeleteDistrict}
    editButtonCaption={LocalizedString.districtScreen.buttonCaptionEditDistrict}
    title={LocalizedString.districtScreen.title}
    disableDelete
    createPermissionName="MDATA_CREATE_DISTRICT"
    editPermissionName="MDATA_EDIT_DISTRICT"
    savePermissionName="MDATA_GET_DISTRICT"
    useFullWidth
  >
    {renderDialogContent(initialValues.city.label, cities, addingEditing, loadingCity,
      onChangeCityText, onCityOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_DISTRICT,
  validate: rxformValidateDistrict,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DistrictPage);

DistrictPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  isResyncAllowed: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentDistrictStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
};
