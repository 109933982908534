import {
  addCurrentUser, addToken, loggingInWithKeyCloak, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage,
  setAlertWarningMessage, setDrawerVisibility,
  setBackToUrl,
} from '../simple-action';
import { loginWithKeyCloak } from '../../../helper';
import LocalizedString from '../../../localization';
import { MENUID_DASHBOARD, ROUTE_NAME_DASHBOARD } from '../../../constant';

export default (authCode, navigateTo, redirectUrl, backTo) => async (dispatch, getState) => {
  try {
    dispatch(loggingInWithKeyCloak(true));
    const storedToken = getState().authentication?.token || '';
    if (storedToken) return;

    const auth = await loginWithKeyCloak(authCode);
    const { token, user } = auth;

    if (!user.permissions.WEB_ADMIN_LOGIN) {
      throw new Error(LocalizedString.loginScreen.errMsgNoPermissionToLogin);
    }

    dispatch(addToken(token));
    dispatch(addCurrentUser(user));

    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD));
    dispatch(setDrawerVisibility(true));
    if (redirectUrl) {
      dispatch(setBackToUrl(backTo));
      navigateTo(redirectUrl);
    } else {
      navigateTo(ROUTE_NAME_DASHBOARD);
    }
  } finally {
    dispatch(setAlertErrorMessage(''));
    dispatch(setAlertInfoMessage(''));
    dispatch(setAlertWarningMessage(''));
    dispatch(setAlertConfirmationMessage(''));
    dispatch(setAlertInputMessage('', ''));
    dispatch(loggingInWithKeyCloak(false));
  }
};
