import { downloadingApprovals, setApprovals } from '../simple-action';
import { downloadApprovals, transformSearchText } from '../../../helper';

export default (selectedPageNo) => async (dispatch, getState) => {
  const {
    authentication, approvals, uiApproval,
  } = getState();
  const { token } = authentication;
  const { meta, data } = approvals;
  const { selectedPageSize, searchBarText, orderBy } = uiApproval;
  const { filterString } = getState().uiApproval;

  const downloadedItemsCount = Object.keys(data).length;
  const totalItemCount = meta.totalCount;

  if (downloadedItemsCount > 0 && downloadedItemsCount >= totalItemCount) { return; }

  try {
    dispatch(downloadingApprovals(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadApprovals(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      transformSearchText(searchBarText),
      filterString,
      token,
    );

    if (list) {
      dispatch(setApprovals(list));
    }
  } finally {
    dispatch(downloadingApprovals(false));
  }
};
