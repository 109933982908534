import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FormInitialValueShape } from '../../type';
import {
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormDateTimePickerField, renderReduxFormAutocompleteCheckboxField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { FunctionalPage, SectionTitle } from '../../component';
import { rxformValidateEventInvitation } from '../../validation';
import {
  EVENT_INVITATION_STATUS,
  FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_TEXT,
  PAGE_MODE_VIEW,
  RXFIELD_EVENT_INVITATION_EMAIL, RXFIELD_EVENT_INVITATION_INVITATION_STATUS,
  RXFIELD_EVENT_INVITATION_REGISTERED_ON, RXFIELD_EVENT_INVITATION_RESENT_COUNT,
  RXFORM_EVENT_INVITATION, RXSTATE_EVENT_INVITATIONS, RXSTATE_EVENT_INVITATION_PAGE,
  status, FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_EVENT_INVITATION_SHORT_DESCRIPTION,
  RXFIELD_EVENT_INVITATION_START_TIME,
  RXFIELD_EVENT_INVITATION_END_TIME,
  RXFIELD_EVENT_INVITATION_EVENT_ID,
  RXFIELD_EVENT_INVITATION_USER_ID,
  RXFIELD_EVENT_INVITATION_FULL_NAME,
  RXFIELD_EVENT_INVITATION_PHONE,
  PAGE_MODE_TABLE,
  RXFIELD_EVENT_INVITATION_EVENT,
  RXFIELD_EVENT_INVITATION_RECIPIENTS,
  RXFIELD_EVENT_INVITATION_LOCATION,
  RXFIELD_EVENT_INVITATION_NAME,
  RXFIELD_EVENT_INVITATION_TIME_ZONE,
} from '../../constant';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
}));

const renderDialogContent = (addingEditing, pageMode, initialValues,
  profiles, downloadingProfiles, onRecipientsTypeSelected,
  events, downloadingEvents, onEventsTypeSelected, recipientValue, onChangeEventText,
  onChangeRecipientText) => (
  pageMode === PAGE_MODE_TABLE
    ? (
      <Grid>
        <Grid container spacing={3}>
          <Grid item md>
            <Field
              name={RXFIELD_EVENT_INVITATION_EVENT}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventInvitationPage.labelEvent}
              placeholder={LocalizedString.eventInvitationPage.placeholderEvent}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={events}
              loading={downloadingEvents}
              onChangeText={onChangeEventText}
              onOptionSelected={onEventsTypeSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item md>
            <Field
              name={RXFIELD_EVENT_INVITATION_RECIPIENTS}
              component={renderReduxFormAutocompleteCheckboxField}
              label={LocalizedString.eventInvitationPage.labelRecipients}
              data={profiles}
              loading={downloadingProfiles}
              onChangeText={onChangeRecipientText}
              onOptionSelected={(option) => onRecipientsTypeSelected(option)}
              onBlur={(e) => e.preventDefault()}
              required={recipientValue.length === 0}
            />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_EMAIL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderEmail}
                label={LocalizedString.eventInvitationPage.labelEmail}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_RESENT_COUNT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderResentCount}
                label={LocalizedString.eventInvitationPage.labelResentCount}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_INVITATION_STATUS}
                component={renderReduxFormOutlinedDropdownTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderInvitationStatus}
                label={LocalizedString.eventInvitationPage.labelInvitationStatus}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                data={EVENT_INVITATION_STATUS}
                value={initialValues.invitationStatus}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_REGISTERED_ON}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.eventInvitationPage.placeholderRegisteredOn}
                label={LocalizedString.eventInvitationPage.labelRegisteredOn}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                pickerMode={PICKER_MODE_DATE_TIME}
              />
            </Grid>
          </Grid>
        </Grid>
        <SectionTitle title={LocalizedString.eventInvitationPage.labelEvent} />
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_EVENT_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderId}
                label={LocalizedString.eventInvitationPage.labelId}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderName}
                label={LocalizedString.eventInvitationPage.labelName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_SHORT_DESCRIPTION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderShortDescription}
                label={LocalizedString.eventInvitationPage.labelShortDescription}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_LOCATION}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderLocation}
                label={LocalizedString.eventInvitationPage.labelLocation}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_START_TIME}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.eventInvitationPage.placeholderStartTime}
                label={LocalizedString.eventInvitationPage.labelStartTime}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                pickerMode={PICKER_MODE_DATE_TIME}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_END_TIME}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.eventInvitationPage.placeholderEndTime}
                label={LocalizedString.eventInvitationPage.labelEndTime}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
                pickerMode={PICKER_MODE_DATE_TIME}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_TIME_ZONE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderTimeZone}
                label={LocalizedString.eventInvitationPage.labelTimeZone}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
        </Grid>
        <SectionTitle title={LocalizedString.eventInvitationPage.labelUser} />
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_USER_ID}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderId}
                label={LocalizedString.eventInvitationPage.labelId}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_FULL_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.eventInvitationPage.placeholderFullName}
                label={LocalizedString.eventInvitationPage.labelFullName}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_EVENT_INVITATION_PHONE}
                component={renderReduxFormDateTimePickerField}
                placeholder={LocalizedString.eventInvitationPage.placeholderPhone}
                label={LocalizedString.eventInvitationPage.labelPhone}
                disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
);

const EventInvitationPage = ({
  initialValues, onAdvancedFilterPressed, addingEditing, downloading,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, eventInvitations, pageMode,
  onDeletePressed, events, profiles, downloadingProfiles,
  onRecipientsTypeSelected, downloadingEvents, onEventsTypeSelected,
  onResendPressed, resending, recipientValue, onChangeEventText, onChangeRecipientText,
  onDownloadPressed, isResendAllowed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_EVENT_INVITATIONS}
      uiPage={RXSTATE_EVENT_INVITATION_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.eventInvitationPage.buttonCaptionResend,
          disabled: downloading || resending || !isResendAllowed,
          onPress: (tappedId) => onResendPressed(tappedId),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.eventInvitationPage.labelRecipient, field: 'email', sorting: !downloading },
        { title: LocalizedString.eventInvitationPage.labelEventName, field: 'event.name', sorting: !downloading },
        { title: LocalizedString.eventInvitationPage.labelInvitationStatus, field: 'invitationStatus', sorting: !downloading },
        { title: LocalizedString.eventInvitationPage.labelResentCount, field: 'resentCount', sorting: !downloading },
        { title: LocalizedString.eventInvitationPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventInvitationPage.labelEvent,
          field: 'event',
          type: FILTER_TYPE_DROPDOWN,
          data: events,
          loading: downloadingEvents,
          onChangeFilterText: (text) => onChangeEventText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventInvitationPage.labelRecipient,
          field: 'email',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.eventInvitationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.eventInvitationPage.labelInvitationStatus,
          field: 'invitationStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: EVENT_INVITATION_STATUS,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventInvitationPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
          useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, eventInvitations)}
      createNewButtonCaption={
        LocalizedString.eventInvitationPage.buttonCaptionCreateNewEventInvitation
      }
      deleteButtonCaption={LocalizedString.eventInvitationPage.buttonCaptionDeleteEventInvitation}
      title={LocalizedString.eventInvitationPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      disableEdit
      useFullWidth
      usefullWidthDialog
      createPermissionName="EVENT_CREATE_EVENT_INVITATION"
      deletePermissionName="EVENT_DELETE_EVENT_INVITATION"
      savePermissionName="EVENT_GET_EVENT_INVITATION"
    >
      {renderDialogContent(addingEditing, pageMode, initialValues,
        profiles, downloadingProfiles, onRecipientsTypeSelected,
        events, downloadingEvents, onEventsTypeSelected, recipientValue, onChangeEventText,
        onChangeRecipientText)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_EVENT_INVITATION,
  validate: rxformValidateEventInvitation,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventInvitationPage);

EventInvitationPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventInvitations: PropTypes.objectOf(PropTypes.object).isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  recipientValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingEvents: PropTypes.bool.isRequired,
  downloadingProfiles: PropTypes.bool.isRequired,
  isResendAllowed: PropTypes.bool.isRequired,
  resending: PropTypes.bool.isRequired,
  pageMode: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeEventText: PropTypes.func.isRequired,
  onChangeRecipientText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEventsTypeSelected: PropTypes.func.isRequired,
  onRecipientsTypeSelected: PropTypes.func.isRequired,
  onResendPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
