import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN, NOTIFICATION_RECIPIENT_TYPES, NOTIFICATION_TASK_STATUS,
  NOTIFICATION_TASK_STATUS_PROCESSING, NOTIFICATION_TASK_STATUS_QUEUING,
  NOTIFICATION_TASK_TITLE_MAX_LENGTH, PAGE_MODE_VIEW, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  RXFIELD_CREATED_DATE, RXFIELD_NOTIFICATION_TASK_BODY, RXFIELD_NOTIFICATION_TASK_END_TIME,
  RXFIELD_NOTIFICATION_TASK_ERROR, RXFIELD_NOTIFICATION_TASK_FILTERED_RECIPIENTS,
  RXFIELD_NOTIFICATION_TASK_NOTES, RXFIELD_NOTIFICATION_TASK_PROCESSED,
  RXFIELD_NOTIFICATION_TASK_PROGRESS, RXFIELD_NOTIFICATION_TASK_PROGRESS_NOTES,
  RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE, RXFIELD_NOTIFICATION_TASK_SENT,
  RXFIELD_NOTIFICATION_TASK_START_TIME, RXFIELD_NOTIFICATION_TASK_TASK_STATUS,
  RXFIELD_NOTIFICATION_TASK_TITLE, RXFIELD_NOTIFICATION_TASK_TOTAL, RXFIELD_NOTIFICATION_TASK_URL,
  RXFORM_NOTIFICATION_TASK, RXSTATE_NOTIFICATION_TASK_PAGE, RXSTATE_NOTIFICATION_TASKS,
  RXFIELD_NOTIFICATION_TASK_IMAGE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormImageInputField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../type';
import DuplicateDialog from './duplicate-dialog';

const useStyles = makeStyles(() => ({
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const NotificationTaskPage = ({
  initialValues,
  canceling, downloading, downloadingDeleting, downloadingTotalPersonCount,
  duplicateDialogVisibility, isDuplicateAllowed, isCancelTaskAllowed, sendingNotification,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed, onChangePage,
  onChangePageSize, onConfirmDeletePressed, onCountPressed, onDeletePressed, onDuplicatePressed,
  onRecipientTypeOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged,
  onSendNotificationPressed, onSortPressed, onViewPressed,
  totalPersonCount, currentTaskStatus, pageMode,
  onImageSelected,
}) => {
  const classes = useStyles();

  return (
    <div>
      <DuplicateDialog
        downloadingTotalPersonCount={downloadingTotalPersonCount}
        submitting={sendingNotification}
        visibility={duplicateDialogVisibility}
        handleSubmit={handleSubmit}
        onCancelPressed={onCancelPressed}
        onCountPressed={onCountPressed}
        onRecipientTypeOptionSelected={onRecipientTypeOptionSelected}
        onSendPressed={onSendNotificationPressed}
        totalPersonCount={totalPersonCount}
        onImageSelected={onImageSelected}
        initialImage={initialValues?.image || ''}
      />

      <FunctionalPage
        data={RXSTATE_NOTIFICATION_TASKS}
        uiPage={RXSTATE_NOTIFICATION_TASK_PAGE}
        moreMenus={[
          {
            caption: LocalizedString.notificationTaskScreen.buttonCaptionCancelTask,
            disabled: (currentTaskStatus !== NOTIFICATION_TASK_STATUS_PROCESSING
              && currentTaskStatus !== NOTIFICATION_TASK_STATUS_QUEUING) || !isCancelTaskAllowed,
            onPress: (tappedId) => onDeletePressed(tappedId),
          },
          {
            caption: LocalizedString.notificationTaskScreen.buttonCaptionDuplicate,
            disabled: !isDuplicateAllowed,
            onPress: (tappedId) => onDuplicatePressed(tappedId),
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.notificationTaskScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          {
            title: LocalizedString.notificationTaskScreen.labelTitle,
            field: 'title',
            render: ({ title }) => (title.length > 30 ? `${title.substring(0, NOTIFICATION_TASK_TITLE_MAX_LENGTH)}...` : title),
            sorting: !downloading,
          },
          {
            title: LocalizedString.notificationTaskScreen.labelStartTime,
            field: 'startTime',
            render: ({ startTime }) => (startTime
              ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
            sorting: !downloading,
          },
          {
            title: LocalizedString.notificationTaskScreen.labelProgress,
            field: 'progress',
            render: ({ progress }) => `${progress}%`,
            sorting: !downloading,
          },
          { title: LocalizedString.notificationTaskScreen.labelNotes, field: 'progressNotes', sorting: !downloading },
          { title: LocalizedString.notificationTaskScreen.labelStatus, field: 'taskStatus', sorting: !downloading },
          {
            title: LocalizedString.notificationTaskScreen.labelEndTime,
            field: 'endTime',
            render: ({ endTime }) => (endTime
              ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
              : null),
            sorting: !downloading,
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.notificationTaskScreen.labelCreatedDate,
            field: RXFIELD_CREATED_DATE,
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.notificationTaskScreen.labelStatus,
            field: RXFIELD_NOTIFICATION_TASK_TASK_STATUS,
            type: FILTER_TYPE_DROPDOWN,
            data: NOTIFICATION_TASK_STATUS,
          },
          {
            title: LocalizedString.notificationTaskScreen.labelRecipientType,
            field: RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE,
            type: FILTER_TYPE_DROPDOWN,
            data: NOTIFICATION_RECIPIENT_TYPES,
          },
        ]}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onDeletePressed={onDeletePressed}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        disableCreate
        disableDelete={initialValues.taskStatus !== NOTIFICATION_TASK_STATUS_PROCESSING
          && initialValues.taskStatus !== NOTIFICATION_TASK_STATUS_QUEUING}
        disableEdit
        deleteButtonCaption={LocalizedString.notificationTaskScreen.buttonCaptionCancelTask}
        title={LocalizedString.notificationTaskScreen.title}
        customDeleteButtonMenuCaption
        useFullWidth
        deletePermissionName="EVENT_DELETE_EVENT"
      >
        {((downloadingDeleting || canceling) && pageMode === PAGE_MODE_VIEW) && (
          <div className={classes.activityIndicator}>
            <CircularProgress color="inherit" />
          </div>
        )}

        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_TITLE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderTitle}
                label={LocalizedString.notificationTaskScreen.placeholderTitle}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                multiline
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_BODY}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderBody}
                label={LocalizedString.notificationTaskScreen.placeholderBody}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                multiline
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_URL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderUrl}
                label={LocalizedString.notificationTaskScreen.placeholderUrl}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_FILTERED_RECIPIENTS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderFilteredRecipients}
                label={LocalizedString.notificationTaskScreen.placeholderFilteredRecipients}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_START_TIME}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.notificationTaskScreen.placeholderStartTime}
                placeholder={LocalizedString.notificationTaskScreen.placeholderStartTime}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_END_TIME}
                component={renderReduxFormDateTimePickerField}
                label={LocalizedString.notificationTaskScreen.placeholderEndTime}
                placeholder={LocalizedString.notificationTaskScreen.placeholderEndTime}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_NOTES}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderNotes}
                label={LocalizedString.notificationTaskScreen.placeholderNotes}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_IMAGE}
                component={renderReduxFormImageInputField}
                label={LocalizedString.notificationTaskScreen.placeholderImage}
                onBlur={(e) => e.preventDefault()}
                defaultValue={initialValues.image}
                useFullWidthImage
                useAvatarWithoutCropper
                fullImageWidth="100%"
                useCropper={false}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_TASK_STATUS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderTaskStatus}
                label={LocalizedString.notificationTaskScreen.placeholderTaskStatus}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_RECIPIENT_TYPE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderRecipientType}
                label={LocalizedString.notificationTaskScreen.placeholderRecipientType}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_PROGRESS}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderProgress}
                label={LocalizedString.notificationTaskScreen.placeholderProgress}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_PROGRESS_NOTES}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderProgressNotes}
                label={LocalizedString.notificationTaskScreen.placeholderProgressNotes}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_TOTAL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderTotal}
                label={LocalizedString.notificationTaskScreen.placeholderTotal}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_PROCESSED}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderProcessed}
                label={LocalizedString.notificationTaskScreen.placeholderProcessed}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_SENT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderSent}
                label={LocalizedString.notificationTaskScreen.placeholderSent}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_NOTIFICATION_TASK_ERROR}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.notificationTaskScreen.placeholderError}
                label={LocalizedString.notificationTaskScreen.placeholderError}
                disabled={downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              />
            </Grid>
          </Grid>
        </Grid>
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_NOTIFICATION_TASK,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(NotificationTaskPage);

NotificationTaskPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  canceling: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloadingTotalPersonCount: PropTypes.bool.isRequired,
  duplicateDialogVisibility: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  isCancelTaskAllowed: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCountPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onRecipientTypeOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  totalPersonCount: PropTypes.number.isRequired,
  currentTaskStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
};
