import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, InputLabel, makeStyles, Select, MenuItem, Typography,
} from '@material-ui/core';
import { Lightbulb } from 'mdi-material-ui';
import { KeyboardArrowDown } from '@material-ui/icons';
import {
  COLOR_BODY_TEXT, COLOR_BODY_TEXT_LIGHTER, COLOR_DANGEROUS, COLOR_ICON, COLOR_POLYGON,
  COLOR_SECONDARY, COLOR_TERTIARY,
} from '../constant';
import { SimpleDataShape } from '../type';
import BootstrapInput from './bootstrap-input';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: '12px 8px 3px',
    '& label.MuiInputLabel-root': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '70%',
    },
    '& label.MuiInputLabel-shrink': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_SECONDARY,
      },
    },
    width: '100%',
    minWidth: 100,
  },
  root: {
    marginTop: -16,
  },
  outlined: {
    height: 14.5,
  },
  label: {
    flexDirection: 'row',
    display: 'flex',
    color: COLOR_ICON,
    fontWeight: 400,
    marginLeft: -12,
    marginBottom: -20,
  },
  requiredSymbol: {
    color: COLOR_POLYGON,
    fontSize: 14,
    fontWeight: 400,
    marginTop: -2,
    marginLeft: 2,
  },
  icon: {
    color: COLOR_BODY_TEXT_LIGHTER,
    marginTop: -8,
  },
  helperContainer: {
    margin: '10px 0px 10px 0px',
  },
  rowHelperContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  goldText: {
    fontWeight: 'bold',
    color: COLOR_TERTIARY,
  },
  blackText: {
    color: COLOR_BODY_TEXT,
    marginLeft: 38,
  },
  greenText: {
    fontStyle: 'italic',
    color: COLOR_DANGEROUS,
  },
  selectInputLabel: {
    marginRight: '5px',
  },
  selectInputMultilineLabel: {
    marginRight: '5px',
    transform: 'translate(12px, 12px) scale(1)',
  },
  textField: {
    fontSize: '12px',
  },
}));

const OutlinedDropdownField = ({
  editable, error, hidden, useHelperIcon,
  onOptionSelected, data, defaultValue, required,
  label, helperText, placeholder, secondaryHelperText, subHelperText, value,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? '');
  const isRequired = required ? '*' : '';

  const classes = useStyles();

  const handleChange = (event) => {
    const selected = data.find((x) => (typeof x === 'object'
      ? x.value === event.target.value : x === event.target.value));
    onOptionSelected(selected);
    setSelectedValue(selected);
  };

  const getValue = () => {
    if (selectedValue) {
      if (typeof selectedValue === 'object') {
        if (typeof selectedValue.value === 'boolean') return selectedValue.value.toString();
        return selectedValue.value;
      }
      return selectedValue;
    }
    if (typeof value === 'object') {
      if (typeof value.value === 'boolean') return value.value.toString();
      return value.value;
    }
    return value;
  };

  const labelClass = label?.length > 25
    ? classes.selectInputMultilineLabel
    : classes.selectInputLabel;
  const fieldSize = label?.length > 25
    ? 'normal'
    : 'small';

  if (!hidden) {
    const val = getValue();
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error} size={fieldSize}>
          <InputLabel shrink htmlFor="bootstrap-input" className={`${classes.label} ${labelClass}`}>
            {label}
            <Typography className={classes.requiredSymbol}>
              {isRequired}
            </Typography>
          </InputLabel>

          <Select
            value={val ? val.toString() : ''}
            onChange={handleChange}
            required={required}
            IconComponent={KeyboardArrowDown}
            {...props}
            input={<BootstrapInput />}
            classes={{ icon: classes.icon, root: classes.root, outlined: classes.outlined }}
          >
            {data.map((x) => (
              <MenuItem value={typeof x === 'object' ? x.value : x} key={typeof x === 'object' ? x.value : x}>
                {typeof x === 'object' ? x.label : x}
              </MenuItem>
            ))}
          </Select>

          {useHelperIcon ? (
            <div className={classes.helperContainer}>
              <FormHelperText className={classes.greenText}>{helperText}</FormHelperText>
              <div className={classes.rowHelperContainer}>
                <Lightbulb style={{ color: COLOR_TERTIARY }} />
                <FormHelperText className={classes.goldText}>{secondaryHelperText}</FormHelperText>
              </div>
              <FormHelperText className={classes.blackText}>{subHelperText}</FormHelperText>
            </div>
          )
            : (<FormHelperText className={classes.greenText}>{helperText}</FormHelperText>)}
        </FormControl>
      </div>
    );
  }
  return null;
};

export default OutlinedDropdownField;

OutlinedDropdownField.propTypes = {
  editable: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  useHelperIcon: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  secondaryHelperText: PropTypes.string,
  subHelperText: PropTypes.string,
  defaultValue: PropTypes.oneOfType([SimpleDataShape, PropTypes.object, PropTypes.string]),
  data: PropTypes.oneOfType([PropTypes.arrayOf(SimpleDataShape),
    PropTypes.arrayOf(PropTypes.object), PropTypes.arrayOf(PropTypes.string)]).isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

OutlinedDropdownField.defaultProps = {
  editable: false,
  required: false,
  error: false,
  hidden: false,
  useHelperIcon: false,
  onOptionSelected: () => {},
  helperText: null,
  label: null,
  placeholder: null,
  secondaryHelperText: null,
  subHelperText: null,
  defaultValue: null,
  value: null,
};
