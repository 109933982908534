import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  FILTER_TYPE_DATE_RANGE, PICKER_MODE_DATE_TIME, FILTER_TYPE_DROPDOWN, FILTER_TYPE_TEXT,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_CREATED_DATE, RXFIELD_POINT_SUBMISSION_AMOUNT,
  RXFIELD_POINT_SUBMISSION_CALLBACK_URL, RXFIELD_POINT_SUBMISSION_LABEL,
  RXFIELD_POINT_SUBMISSION_MERCHANT_ID, RXFIELD_POINT_SUBMISSION_MERCHANT_NAME,
  RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID, RXFIELD_POINT_SUBMISSION_REQUEST_STATUS,
  RXFIELD_POINT_SUBMISSION_REQUEST_STATUS_NOTES, RXFIELD_POINT_SUBMISSION_TAGS,
  RXFIELD_POINT_SUBMISSION_USER_FULLNAME, RXFIELD_POINT_SUBMISSION_VALID_UNTIL,
  RXFORM_POINT_SUBMISSION, RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION, approval,
  RXSTATE_POINT_SUBMISSIONS, RXSTATE_POINT_SUBMISSION_PAGE, SUBMISSION_STATUS_VALUES,
  RXFIELD_POINT_SUBMISSION_USER_ID, RXFIELD_POINT_SUBMISSION_USER, PAGE_MODE_VIEW,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormSimpleDropdownField,
  renderReduxFormDateTimePickerField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidatePointSubmission } from '../../validation';

const renderDialogContent = (
  addingEditing, pageMode,
  initialValues, loadingUser, users,
  onChangeUserText, onUserOptionSelected,
  onSendNotificationOptionSelected, pointValidForAllowOverride,
) => {
  if (pageMode !== PAGE_MODE_VIEW) {
    return (
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item sm md>
            <Field
              name={RXFIELD_POINT_SUBMISSION_USER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderUser}
              label={LocalizedString.pointSubmissionPage.placeholderUser}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={users}
              value={initialValues.userId}
              loading={loadingUser}
              onChangeText={onChangeUserText}
              onOptionSelected={onUserOptionSelected}
              hidden={pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_AMOUNT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderAmount}
              label={LocalizedString.pointSubmissionPage.placeholderAmount}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_LABEL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderLabel}
              label={LocalizedString.pointSubmissionPage.placeholderLabel}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderValidUntil}
              label={LocalizedString.pointSubmissionPage.placeholderValidUntil}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              pickerMode={PICKER_MODE_DATE_TIME}
              dateFormat={PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT}
              required={pointValidForAllowOverride}
              hidden={!pointValidForAllowOverride}
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
              label={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderTags}
              label={LocalizedString.pointSubmissionPage.placeholderTags}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_POINT_SUBMISSION_SEND_NOTIFICATION}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.pointSubmissionPage.placeholderSendNotification}
              label={LocalizedString.pointSubmissionPage.placeholderSendNotification}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.sendNotification}
              onOptionSelected={onSendNotificationOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_USER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderUserId}
            label={LocalizedString.pointSubmissionPage.placeholderUserId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_USER_FULLNAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderUserName}
            label={LocalizedString.pointSubmissionPage.placeholderUserName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
            label={LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderLabel}
            label={LocalizedString.pointSubmissionPage.placeholderLabel}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_REQUEST_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderSubmissionStatus}
            label={LocalizedString.pointSubmissionPage.placeholderSubmissionStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_REQUEST_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderSubmissionStatusNotes}
            label={LocalizedString.pointSubmissionPage.placeholderSubmissionStatusNotes}
            multiline
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_AMOUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderAmount}
            label={LocalizedString.pointSubmissionPage.placeholderAmount}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_MERCHANT_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantId}
            label={LocalizedString.pointSubmissionPage.placeholderMerchantId}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_MERCHANT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderMerchantName}
            label={LocalizedString.pointSubmissionPage.placeholderMerchantName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderValidUntil}
            label={LocalizedString.pointSubmissionPage.placeholderValidUntil}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
            dateFormat={PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderTags}
            label={LocalizedString.pointSubmissionPage.placeholderTags}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_POINT_SUBMISSION_CALLBACK_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.pointSubmissionPage.placeholderCallbackUrl}
            label={LocalizedString.pointSubmissionPage.placeholderCallbackUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const PointSubmissionPage = ({
  initialValues, users, downloading, pageMode, isRetryAllowed, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangePage, onChangePageSize, onChangeUserText, onRefresh,
  onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onViewPressed,
  onRetryPressed, reprocessing, onConfirmRetryPressed, onUserOptionSelected,
  addingEditing, onSubmitPressed, onCreatePressed, onSendNotificationOptionSelected,
  pointValidForAllowOverride,
}) => (
  <FunctionalPage
    data={RXSTATE_POINT_SUBMISSIONS}
    uiPage={RXSTATE_POINT_SUBMISSION_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.pointSubmissionPage.buttonCaptionRetry,
        disabled: reprocessing || !isRetryAllowed,
        onPress: (tappedId) => onRetryPressed(tappedId),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.pointSubmissionPage.placeholderCreatedDate,
        field: RXFIELD_CREATED_DATE,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderValidUntil,
        field: RXFIELD_POINT_SUBMISSION_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderMerchantName,
        field: RXFIELD_POINT_SUBMISSION_MERCHANT_NAME,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderMerchantReferenceID,
        field: RXFIELD_POINT_SUBMISSION_MERCHANT_REFERENCE_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderUserName,
        field: RXFIELD_POINT_SUBMISSION_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.pointSubmissionPage.placeholderSubmissionStatus,
        field: RXFIELD_POINT_SUBMISSION_REQUEST_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: SUBMISSION_STATUS_VALUES,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.pointSubmissionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.pointSubmissionPage.labelUserName, field: 'user.fullName', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelAmount, field: 'amount', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelLabel, field: 'label', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelMerchantName, field: 'merchantName', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelSubmissionStatus, field: 'requestStatus', sorting: !downloading },
      { title: LocalizedString.pointSubmissionPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onCreatePressed={onCreatePressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSubmitPressed={onSubmitPressed}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.pointSubmissionPage.title}
    onConfirmDeletePressed={onConfirmRetryPressed}
    createNewButtonCaption={
        LocalizedString.pointSubmissionPage.btnCaptionCreateNewPointSubmission
      }
    useFullWidth
    disableDelete
    disableEdit
    createPermissionName="POINT_CREATE_POINT_SUBMISSION"
  >
    {renderDialogContent(
      addingEditing, pageMode, initialValues, loadingUser,
      users, onChangeUserText, onUserOptionSelected,
      onSendNotificationOptionSelected, pointValidForAllowOverride,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_POINT_SUBMISSION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: rxformValidatePointSubmission,
})(PointSubmissionPage);

PointSubmissionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  isRetryAllowed: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  reprocessing: PropTypes.bool.isRequired,
  pointValidForAllowOverride: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationOptionSelected: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onRetryPressed: PropTypes.func.isRequired,
  onConfirmRetryPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};
