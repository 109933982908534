import {
  clearAssetDocuments, downloadingDeletingAssetDocument, setAlertErrorMessage,
  setAssetDocumentTappedId,
} from '../simple-action';
import { deleteAssetDocument } from '../../../helper';
import downloadAssetDocumentsAsync from './downloadAssetDocumentsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingAssetDocument(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAssetDocument;

    await deleteAssetDocument(tappedId, token);
    dispatch(setAssetDocumentTappedId(''));
    dispatch(clearAssetDocuments());
    dispatch(downloadAssetDocumentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(downloadingDeletingAssetDocument(false));
  }
};
