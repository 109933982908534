import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  transformDropdownData,
  transformInitialValues,
  toMoment,
  debounceSearch, transformUserDropdownData, getTimeSymbol,
} from '../../helper';
import LocalizedString from '../../localization';
import sessionRegistrationPage from './session-registration.presentation';
import {
  INITIAL_ORDER_BY_SESSION_REGISTRATIONS, MENUID_EVENT_SESSION_REGISTRATION,
  PAGE_MODE_TABLE, RXFORM_SESSION_REGISTRATION, DATE_TIME_FORMAT_WITHOUT_PIPE,
} from '../../constant';
import {
  clearSessionRegistrations, clearSessions, clearProfiles,
  saveSessionRegistrationsAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setSessionAdvancedFilterDialogSelectedFilterString,
  setSessionRegistrationAdvancedFilterDialogSelectedFilterString,
  setSessionRegistrationSearchText, setSessionRegistrationSelectedOrderBy,
  setSessionRegistrationSelectedPageSize, setSessionRegistrationTappedId,
  setSessionSearchText, setProfileAdvancedFilterDialogSelectedFilterString,
  setProfileSearchText, downloadSessionRegistrationAsync,
  downloadSessionRegistrationsAsync, downloadSessionsAsync, setAlertInputMessage,
  approveSessionRegistrationAsync, rejectSessionRegistrationAsync,
} from '../../redux/action';
import { downloadManagersAsync, downloadProfilesAsync } from '../../../../redux/action';
import {
  clearEmployees, clearManagers, downloadEmployeesAsync, setHistoryEmployeeSearchText,
  setProfileManagerSearchText,
} from '../../../attendance/redux/action';
import { downloadMyConfigItemsAsync, setDownloadRequestTimeoutDialogVisibility } from '../../../configuration/redux/action';

const getApproveRejectedBy = (approvedRejectedBy) => (approvedRejectedBy.email
  ? `${approvedRejectedBy?.fullName} (${approvedRejectedBy?.email})`
  : approvedRejectedBy?.fullName);

const getInitialValues = (state) => {
  const { sessionRegistrations, uiSessionRegistration, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiSessionRegistration;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? sessionRegistrations.data[tappedId] : {};

  const initVal = Object.keys(found).length > 0
    ? transformInitialValues(found,
      {
        approvedRejectedOn: found.approvedRejectedOn ? toMoment(found.approvedRejectedOn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        clockInTime: found.clockInTime ? toMoment(found.clockInTime, found.session?.room?.location?.city?.province?.timeZone) : '',
        clockInStatus: found?.clockInStatus ? found?.clockInStatus : '',
        paymentChannel: found?.payment?.paymentChannel ? found?.payment?.paymentChannel : '',
        paymentStatus: found?.payment?.paymentStatus ? found?.payment?.paymentStatus : '',
        sessionStartTime: found.session?.startTime ? toMoment(found.session.startTime, found.session?.room?.location?.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        sessionEndTime: found.session.endTime ? toMoment(found.session.endTime, found.session?.room?.location?.city?.province?.timeZone).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : '',
        price: found?.price === 0
          ? LocalizedString.sessionRegistrationPage.labelFree : found?.price,
        approvedRejectedBy: found?.approvedRejectedBy ? getApproveRejectedBy(found?.approvedRejectedBy) : '',
        sessionTimeZone: getTimeSymbol(found.session?.room?.location?.city?.province?.timeZone),
      })
    : {
      clockInTime: '',
      sessionStartTime: '',
      sessionEndTime: '',
    };
  return initVal;
};

const searchManagerDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchEmployeeDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchProfileDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const searchSessionDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
);

const mapStateToProps = (state) => ({
  pageMode: state.uiFunctionalPage.pageMode,
  sessions: transformDropdownData(state.sessions.data),
  profiles: transformUserDropdownData(state.profiles.data),
  employees: transformUserDropdownData(state.employees.data),
  managers: transformUserDropdownData(state.managers.data),
  sessionRegistrations: state.sessionRegistrations.data,
  addingEditing: state.uiSessionRegistration.addingEditing,
  downloadingDeleting: state.uiSessionRegistration.downloadingDeleting,
  downloading: state.uiSessionRegistration.downloading,
  downloadingSessions: state.uiSession.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  downloadingEmployee: state.uiHistory.downloading,
  downloadingManagers: state.uiProfile.downloadingManagers,
  approving: state.uiSessionRegistration.approving,
  rejecting: state.uiSessionRegistration.rejecting,
  initialValues: getInitialValues(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setSessionRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_EVENT_SESSION_REGISTRATION));
    dispatch(setSessionRegistrationSearchText(''));
    dispatch(clearSessionRegistrations());
    dispatch(setSessionRegistrationSelectedPageSize(20));
    dispatch(setSessionRegistrationSelectedOrderBy(INITIAL_ORDER_BY_SESSION_REGISTRATIONS));
    dispatch(downloadSessionRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(downloadMyConfigItemsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(clearEmployees());
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setProfileManagerSearchText(''));
    dispatch(clearManagers());
    dispatch(downloadManagersAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));

    dispatch(setSessionAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setSessionSearchText(''));
    dispatch(clearSessions());
    dispatch(downloadSessionsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString
      .replace(/session/, 'session.id')
      .replace(/participant/, 'user.id')
      .replaceAll(/registeredOn/g, 'payment.createdDate')
      .replaceAll(/approvedOn/g, 'approvedRejectedOn')
      .replace(/approvedBy/, 'approvedRejectedBy.id')
      .replace(/rejectedBy/, 'approvedRejectedBy.id')
      .replace(/paymentChannel/, 'payment.paymentChannel')
      .replace(/paymentStatus/, 'payment.paymentStatus');
    dispatch(setSessionRegistrationAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearSessionRegistrations());
    dispatch(downloadSessionRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApprovePressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.sessionRegistrationPage
      .msgApproveSessionRegistration,
    LocalizedString.sessionRegistrationPage.placeholderNotes));
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_SESSION_REGISTRATION));
  },
  onChangePage: (pageNo) => {
    dispatch(downloadSessionRegistrationsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setSessionRegistrationSelectedPageSize(pageSize));
    dispatch(downloadSessionRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeSessionText: (text) => {
    dispatch(setSessionAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setSessionSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchSessionDebounce(dispatch);
    }
  },
  onChangeParticipantText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchProfileDebounce(dispatch);
    }
  },
  onChangeApprovedByText: (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchEmployeeDebounce(dispatch);
    }
  },
  onChangeRejectedByText: (text) => {
    dispatch(setProfileManagerSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchManagerDebounce(dispatch);
    }
  },
  onConfirmApproveRejectPressed: (reason, message) => {
    switch (message) {
      case LocalizedString.sessionRegistrationPage.msgApproveSessionRegistration:
        dispatch(approveSessionRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      case LocalizedString.sessionRegistrationPage.msgRejectSessionRegistration:
        dispatch(rejectSessionRegistrationAsync(reason))
          .catch((error) => {
            dispatch(setAlertErrorMessage(error));
          }); break;
      default: break;
    }
  },
  onDownloadPressed: () => {
    dispatch(saveSessionRegistrationsAsync())
      .catch((error) => {
        if (error.code && error.code === 504) {
          dispatch(setDownloadRequestTimeoutDialogVisibility(true));
        } else {
          dispatch(setAlertErrorMessage(error));
        }
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setSessionRegistrationSelectedPageSize(pageSize));
    dispatch(clearSessionRegistrations());
    dispatch(downloadSessionRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRejectPressed: () => {
    dispatch(setAlertInputMessage(LocalizedString.sessionRegistrationPage
      .msgRejectSessionRegistration,
    LocalizedString.sessionRegistrationPage.placeholderNotes));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setSessionRegistrationAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearSessionRegistrations());
    dispatch(setSessionRegistrationSelectedOrderBy(INITIAL_ORDER_BY_SESSION_REGISTRATIONS));
    dispatch(downloadSessionRegistrationsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setSessionRegistrationSearchText(text));
      dispatch(clearSessionRegistrations());
      await dispatch(downloadSessionRegistrationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setSessionRegistrationSelectedOrderBy(orderBy));
    dispatch(clearSessionRegistrations());
    dispatch(downloadSessionRegistrationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onViewPressed: (id) => {
    dispatch(setSessionRegistrationTappedId(id));
    dispatch(downloadSessionRegistrationAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(sessionRegistrationPage);
