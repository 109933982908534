import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  clearProfileSync, setActiveSideMenuItem, setAlertConfirmationMessage, setAlertErrorMessage,
  setProfileSyncSearchText, setAdvancedFilterDialogSelectedFilterString,
  setProfileSyncSelectedPageSize, setProfileSyncSelectedOrderBy,
  downloadProfileSyncsAsync, addEditProfileSyncAsync,
  setBranchAdvancedFilterDialogSelectedFilterString, setBranchSearchText,
  setProfileSyncSelectedBranchId, clearBranches, setProfileSyncTappedId,
  enableDisableProfileSyncAsync, downloadProfileSyncAsync, setProfileSyncDuplicateMode,
  setDialogVisibility,
} from '../../redux/action';
import {
  downloadBranchesAsync,
} from '../../../../redux/action';
import {
  INITIAL_ORDER_BY_PROFILE_SYNC, PAGE_MODE_TABLE, REVERSED_ISO_DATE_FORMAT,
  MENUID_CONFIGURATION_PROFILE_SYNC, RXFIELD_PROFILE_SYNC_BRANCH, RXFORM_PROFILE_SYNC,
  DIALOG_TYPE_ADD,
} from '../../constant';
import {
  transformInitialValues, transformDropdownData, toMoment, getPermission,
} from '../../helper';
import ProfileSyncPage from './profile-sync.presentation';
import LocalizedString from '../../localization';

const getInitialValues = (state) => {
  const { profileSyncs, uiFunctionalPage, uiProfileSync } = state;
  const { data } = profileSyncs;
  const { downloadingDeleting, tappedId, duplicateMode } = uiProfileSync;
  const { pageMode } = uiFunctionalPage;

  const found = (pageMode !== PAGE_MODE_TABLE && !downloadingDeleting) || duplicateMode
    ? data[tappedId] : {};
  const result = Object.keys(found || {}).length > 0
    ? transformInitialValues(found,
      {
        branch: found.branch ? { label: found.branch.name, value: found.branch.id } : '',
        gender: { label: found.gender, value: found.gender },
        dateOfBirth: found.dateOfBirth ? found.dateOfBirth : '',
      }) : {
      fullName: '',
      branch: { label: '', value: '' },
      gender: { label: '', value: '' },
    };
  return result;
};

const mapStateToProps = (state) => ({
  branches: transformDropdownData(state.branches.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiProfileSync.addingEditing,
  downloading: state.uiProfileSync.downloading,
  enablingDisabling: state.uiProfileSync.enablingDisabling,
  resyncing: state.uiProfileSync.resyncing,
  currentProfileSyncStatus: state.profileSyncs.data[state.uiFunctionalPage.tappedId]
    ? state.profileSyncs.data[state.uiFunctionalPage.tappedId].status : '',
  initialValues: getInitialValues(state),
  loadingBranch: state.uiBranch.downloading,
  isDuplicateAllowed: getPermission(state, 'AUTHEXT_CREATE_PROFILE'),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_CONFIGURATION_PROFILE_SYNC));
    dispatch(setProfileSyncSearchText(''));
    dispatch(clearProfileSync());
    dispatch(setProfileSyncSelectedPageSize(20));
    dispatch(setProfileSyncSelectedOrderBy(INITIAL_ORDER_BY_PROFILE_SYNC));
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAdvancedFilterPressed: () => {
    dispatch(setBranchAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setBranchSearchText(''));
    dispatch(setProfileSyncSelectedBranchId(''));
    dispatch(clearBranches());
    dispatch(downloadBranchesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeBranchText: async (text) => {
    try {
      dispatch(setBranchAdvancedFilterDialogSelectedFilterString(''));
      dispatch(setBranchSearchText(text));
      dispatch(clearBranches());
      await dispatch(downloadBranchesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onBranchOptionSelected: (option, changeForm = true) => {
    if (option) {
      dispatch(setProfileSyncSelectedBranchId(option.value));
      if (changeForm) {
        dispatch(change(RXFORM_PROFILE_SYNC, RXFIELD_PROFILE_SYNC_BRANCH, option));
      }
    } else {
      dispatch(setProfileSyncSelectedBranchId(''));
      if (changeForm) { dispatch(change(RXFORM_PROFILE_SYNC, RXFIELD_PROFILE_SYNC_BRANCH, '')); }
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setProfileSyncSelectedBranchId(''));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace('|branch', '|branchId');
    dispatch(setAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearProfileSync());
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadProfileSyncsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setProfileSyncSelectedPageSize(pageSize));
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_PROFILE_SYNC));
    dispatch(setProfileSyncDuplicateMode(false));
  },

  onContextMenuPressed: (id, message) => {
    dispatch(setProfileSyncTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onCreatePressed: async () => {
    dispatch(setProfileSyncTappedId(''));
  },
  onEnableDisablePressed: (id, message) => {
    dispatch(setProfileSyncTappedId(id));
    dispatch(setAlertConfirmationMessage(message));
  },
  onConfirmEnableDisablePressed: () => {
    dispatch(enableDisableProfileSyncAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onEditPressed: (id) => {
    dispatch(setProfileSyncTappedId(id));
    dispatch(downloadProfileSyncAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onRefresh: (pageSize) => {
    dispatch(setProfileSyncSelectedPageSize(pageSize));
    dispatch(clearProfileSync());
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearProfileSync());
    dispatch(setProfileSyncSelectedOrderBy(INITIAL_ORDER_BY_PROFILE_SYNC));
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSavePressed: async ({
    id, fullName, email, phone, managerEmployeeId, jobTitle,
    department, division, company, branchId, dateOfBirth, gender,
  }) => {
    const dateOfBirthConvert = dateOfBirth === '' ? null : toMoment(dateOfBirth).format(REVERSED_ISO_DATE_FORMAT);

    try {
      await dispatch(addEditProfileSyncAsync(id, fullName, email, phone, managerEmployeeId,
        jobTitle, department, division, company, branchId, dateOfBirthConvert, gender));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setProfileSyncSearchText(text));
      dispatch(clearProfileSync());
      await dispatch(downloadProfileSyncsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setProfileSyncSelectedOrderBy(orderBy));
    dispatch(clearProfileSync());
    dispatch(downloadProfileSyncsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async ({
    employeeId, fullName, email, phone, managerEmployeeId, jobTitle,
    department, division, company, branchId, dateOfBirth, gender,
  }) => {
    const dateOfBirthConvert = dateOfBirth === '' ? null : toMoment(dateOfBirth).format(REVERSED_ISO_DATE_FORMAT);

    await dispatch(addEditProfileSyncAsync(employeeId, fullName, email, phone, managerEmployeeId,
      jobTitle, department, division, company, branchId, dateOfBirthConvert, gender));
  },
  onViewPressed: (id) => {
    dispatch(setProfileSyncTappedId(id));
    dispatch(downloadProfileSyncAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDuplicatePressed: () => {
    dispatch(setDialogVisibility(true, DIALOG_TYPE_ADD,
      LocalizedString.profileSyncScreen.labelDuplicateProfileSync));
    dispatch(setProfileSyncDuplicateMode(true));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSyncPage);
