/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Grid, CircularProgress, Avatar, InputAdornment, IconButton, Snackbar,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormAutocompleteCheckboxField, renderReduxFormAutocompleteTableField,
  renderReduxFormDateTimePickerField, renderReduxFormEditableTableField,
  renderReduxFormDropzonePicker,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
  renderReduxFormRichTextEditorField, renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  FilePickerField, FunctionalPage, DropzonePicker, NumberTextFieldWithoutArrowDial, SectionTitle,
  AccentButton, BootstrapInput,
  Comment,
} from '../../component';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, EVENT_TYPE, FILTER_TYPE_DATE_RANGE,
  IMAGE_SOURCE_URI_PREFIX, PAGE_MODE_VIEW, REST_BASE_URL, REST_URL_IMAGE_STORAGE, REWARDS_TYPE,
  RXFIELD_EVENT_CAN_PAY_USING_POINT, RXFIELD_EVENT_CATEGORY,
  RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA, RXFIELD_EVENT_DESCRIPTION,
  RXFIELD_EVENT_EARLIEST_CLOCK_IN, RXFIELD_EVENT_END_TIME, RXFIELD_EVENT_EVENT_TYPE,
  RXFIELD_EVENT_FEATURED, RXFIELD_EVENT_FEATURED_ORDER, RXFIELD_EVENT_LOCATION,
  RXFIELD_EVENT_MAX_CLOCK_IN, RXFIELD_EVENT_MEDIA, RXFIELD_EVENT_NAME, RXFIELD_EVENT_NEEDS_APPROVAL,
  RXFIELD_EVENT_PRICE, RXFIELD_EVENT_QUOTA, RXFIELD_EVENT_REGISTRATION_REWARD_POINT,
  RXFIELD_EVENT_REGISTRATION_REWARD_TYPE, RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER,
  RXFIELD_EVENT_SHORT_DESCRIPTION, RXFIELD_EVENT_START_TIME, RXFIELD_EVENT_VIISIBILITY_STATUS,
  RXFIELD_EVENT_WHO_CAN_REGISTER, RXFORM_EVENT, RXSTATE_EVENTS, RXSTATE_EVENT_PAGE,
  visibilityStatuses, WHO_CAN_REGISTER,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, FILTER_TYPE_DROPDOWN,
  FILTER_EVENT_TYPE, FILTER_TYPE_SWITCH, RXFIELD_EVENT_NORMAL_PRICE,
  RXFIELD_EVENT_ENABLE_COMMENT, RXFIELD_EVENT_COMMENT_ACCESS, COMMENT_ACCESSES,
  RXFIELD_EVENT_DISCUSSION_ID, status, RXFIELD_EVENT_ADDITTIONAL_ITEMS, ADDITIONAL_ITEMS_TYPES,
  ADDITIONAL_ITEMS_VISIBILITY_TIMES, ADDITIONAL_ITEMS_VIEW_ACCESSES, ADDITIONAL_ITEM_TYPE_IMAGE,
  FILTER_TYPE_TEXT, STATUS_ENABLED, STATUS_DISABLED, RXFIELD_EVENT_SPEAKERS,
  RXFIELD_EVENT_SURVEY_FORM, RXFIELD_EVENT_CLOCK_IN_QR_CODE, RXFIELD_EVENT_DEEP_LINK,
  PAGE_MODE_TABLE, COLOR_PRIMARY, snackbarDuration, REST_BASE_URL_EVENT, RXFIELD_EVENT_BANNER,
  RXFIELD_EVENT_LANDING_PAGE, IMAGE_FILE_EXTENSIONS,
} from '../../constant';
import {
  getAdditionalItemFileExtension, getAdditionalItemIcon, isAdditionalItemYoutubeUrlType, toMoment,
} from '../../helper';
import { rxformValidateEvent } from '../../validation';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
}));

const renderDialogContent = (addingEditing, pageMode, initialValues, onClockInAreaOptionSelected,
  onRewardTypeOptionSelected, registrationRewardType, vouchers, downloadingVoucher,
  onVoucherOptionSelected, onEventTypeOptionSelected, categories, downloadingCategory,
  onCategoryOptionSelected, locations, downloadingLocation, onLocationOptionSelected,
  onFeaturOptionSelected, onWhoCanRegisterOptionSelected,
  onNeedApprovalOptionSelected, onCanPayUsingPointOptionSelected,
  onVisibilityStatusOptionSelected, downloadingDeleting, onAddMediaMenuSelected,
  selectedAddMediaMenu, onAddMediaPressed, onDeleteMediaPressed, onChangeLocationText,
  onChangeDescriptionText, formSelectedIsFeatured, onChangeCategoryText,
  onChangeRewardVoucherText, formRegistrationRewardVoucher, onEnableCommentOptionSelected,
  onCommentAccessOptionSelected, selectedEnableComment, onDeleteAdditionalItemsPressed,
  onAddAdditionalItemsPressed, speakers, loadingSpeakers, tableDataSpeakers, selectedSpeakers,
  addingEventSpeaker, tappedEvent, surveyForms, downloadingSurveyForms, onAddSpeakersPressed,
  onChangeSpeakersText, onDeleteSpeakersPressed, onSpeakersOptionSelected, onChangeSurveyFormText,
  onSurveyFormOptionSelected, onCopyPressed, copying, typeValues, onBannerImageSelected) => {
  const hiddenFieldRewardPoint = registrationRewardType === REWARDS_TYPE[1] || registrationRewardType === REWARDS_TYPE[3] || registrationRewardType === '';
  const requireFieldRewardPoint = registrationRewardType === REWARDS_TYPE[0]
    || registrationRewardType === REWARDS_TYPE[2];
  const hiddenFieldRewardVoucher = registrationRewardType === REWARDS_TYPE[0] || registrationRewardType === REWARDS_TYPE[3] || registrationRewardType === '';
  const requireFieldRewardvoucher = formRegistrationRewardVoucher.length === 0
    && (registrationRewardType === REWARDS_TYPE[1] || registrationRewardType === REWARDS_TYPE[2]);
  const isEventTypeRegular = typeValues === EVENT_TYPE[1];
  return (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_NAME}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelName}
              placeholder={LocalizedString.eventPage.placeholderName}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_SHORT_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelShortDescription}
              placeholder={LocalizedString.eventPage.placeholderShortDescription}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_EVENT_TYPE}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelEventType}
              placeholder={LocalizedString.eventPage.placeholderEventType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={EVENT_TYPE}
              onOptionSelected={onEventTypeOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_START_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.eventPage.labelStartTime}
              placeholder={LocalizedString.eventPage.placeholderStartTime}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_END_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.eventPage.labelEndTime}
              placeholder={LocalizedString.eventPage.placeholderEndTime}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_EARLIEST_CLOCK_IN}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelEarliestClock}
              placeholder={LocalizedString.eventPage.placeholderEarliestClock}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_MAX_CLOCK_IN}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelMaxClock}
              placeholder={LocalizedString.eventPage.placeholderMaxClock}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CLOCK_IN_MUST_INSIDE_AREA}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelClockInMustBeInsideArea}
              placeholder={LocalizedString.eventPage.placeholderClockInMustBeInsideArea}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              onOptionSelected={onClockInAreaOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_REGISTRATION_REWARD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelRegistrationRewardType}
              placeholder={LocalizedString.eventPage.placeholderRegistrationRewardType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={REWARDS_TYPE}
              onOptionSelected={onRewardTypeOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_REGISTRATION_REWARD_POINT}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelRegistrationRewardPoint}
              placeholder={LocalizedString.eventPage.placeholderRegistrationRewardPoint}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={hiddenFieldRewardPoint}
              onBlur={(e) => e.preventDefault()}
              type="number"
              required={requireFieldRewardPoint}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_REGISTRATION_REWARD_VOUCHER}
              component={renderReduxFormAutocompleteCheckboxField}
              label={LocalizedString.eventPage.labelRegistrationRewardVoucher}
              placeholder={LocalizedString.eventPage.placeholderRegistrationRewardVoucher}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={vouchers}
              loading={downloadingVoucher}
              onChangeText={onChangeRewardVoucherText}
              onOptionSelected={onVoucherOptionSelected}
              value={initialValues.registrationRewardVoucherIds}
              hidden={hiddenFieldRewardVoucher}
              onBlur={(e) => e.preventDefault()}
              required={requireFieldRewardvoucher}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_SURVEY_FORM}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventPage.labelSurveyForm}
              placeholder={LocalizedString.eventPage.placeholderSurveyForm}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={surveyForms}
              loading={downloadingSurveyForms}
              onChangeText={(e) => onChangeSurveyFormText(e, pageMode)}
              onOptionSelected={onSurveyFormOptionSelected}
              onBlur={(e) => e.preventDefault()}
              hidden={isEventTypeRegular}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_ENABLE_COMMENT}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.placeholderEnableComment}
              placeholder={LocalizedString.eventPage.placeholderEnableComment}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.enableComment}
              onOptionSelected={onEnableCommentOptionSelected}
              required
              onBlur={(e) => e.preventDefault()}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_COMMENT_ACCESS}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.placeholderCommentAccess}
              placeholder={LocalizedString.eventPage.placeholderCommentAccess}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={COMMENT_ACCESSES}
              onOptionSelected={onCommentAccessOptionSelected}
              required={selectedEnableComment?.value === approval[0].value}
              hidden={selectedEnableComment?.value === approval[1].value}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.placeholderDiscussionId}
              placeholder={LocalizedString.eventPage.placeholderDiscussionId}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CLOCK_IN_QR_CODE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.placeholderClockInQrCode}
              placeholder={LocalizedString.eventPage.placeholderClockInQrCode}
              disabled
              hidden={pageMode === PAGE_MODE_TABLE}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.clockInQrCode}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_DEEP_LINK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventPage.placeholderDeeplink}
              label={LocalizedString.eventPage.placeholderDeeplink}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.deepLink}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_LANDING_PAGE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.eventPage.placeholderLandingPage}
              label={LocalizedString.eventPage.placeholderLandingPage}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.landingPage}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventPage.labelCategory}
              placeholder={LocalizedString.eventPage.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={categories}
              loading={downloadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_LOCATION}
              component={renderReduxFormOutlinedDropdownTextField}
              label={LocalizedString.eventPage.labelLocation}
              placeholder={LocalizedString.eventPage.placeholderLocation}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={locations}
              loading={downloadingLocation}
              onChangeText={onChangeLocationText}
              onOptionSelected={onLocationOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_FEATURED}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelFeatured}
              placeholder={LocalizedString.eventPage.placeholderFeatured}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              onOptionSelected={onFeaturOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_FEATURED_ORDER}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelFeaturedOrder}
              placeholder={LocalizedString.eventPage.placeholderFeaturedOrder}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              hidden={!formSelectedIsFeatured?.value}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_WHO_CAN_REGISTER}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelWhoCanRegister}
              placeholder={LocalizedString.eventPage.placeholderWhoCanRegister}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={WHO_CAN_REGISTER}
              onOptionSelected={onWhoCanRegisterOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_NEEDS_APPROVAL}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelNeedApproval}
              placeholder={LocalizedString.eventPage.placeholderNeedApproval}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              onOptionSelected={onNeedApprovalOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_QUOTA}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelQuota}
              placeholder={LocalizedString.eventPage.placeholderQuota}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_NORMAL_PRICE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelNormalPrice}
              placeholder={LocalizedString.eventPage.placeholderNormalPrice}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_PRICE}
              component={renderReduxFormOutlinedTextField}
              label={LocalizedString.eventPage.labelPrice}
              placeholder={LocalizedString.eventPage.placeholderPrice}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_CAN_PAY_USING_POINT}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelCanPayUsingPoint}
              placeholder={LocalizedString.eventPage.placeholderCanPayUsingPoint}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              onOptionSelected={onCanPayUsingPointOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_VIISIBILITY_STATUS}
              component={renderReduxFormSimpleDropdownField}
              label={LocalizedString.eventPage.labelVisibilityStatus}
              placeholder={LocalizedString.eventPage.placeholderVisibilityStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              onOptionSelected={onVisibilityStatusOptionSelected}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_EVENT_BANNER}
              component={renderReduxFormDropzonePicker}
              label={LocalizedString.eventPage.placeholderBanner}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              loading={downloadingDeleting || addingEditing}
              onImageSelected={onBannerImageSelected}
              onBlur={(e) => e.preventDefault()}
              defaultValue={initialValues.banner}
              imageFileExtension={IMAGE_FILE_EXTENSIONS}
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.eventPage.labelMedia} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.eventPage.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loading={downloadingDeleting}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.eventPage.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.eventPage.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.eventPage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.eventPage.labelOrder}
                    label={LocalizedString.eventPage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.eventPage.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: ({ value, onChange }) => (
                  <BootstrapInput
                    placeholder={LocalizedString.eventPage.labelYoutube}
                    label={LocalizedString.eventPage.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.eventPage.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: ({ onChange }) => (
                  <DropzonePicker
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.eventPage.labelDescription} />
      <Grid container>
        <Grid item>
          <Field
            name={RXFIELD_EVENT_DESCRIPTION}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.eventPage.labelDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onChangeText={onChangeDescriptionText}
            loading={downloadingDeleting}
          />
        </Grid>
      </Grid>

      {isEventTypeRegular
        ? null
        : (<SectionTitle title={LocalizedString.eventPage.labelSpeakers} />)}
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_SPEAKERS}
            component={renderReduxFormAutocompleteTableField}
            label={LocalizedString.eventPage.placeholderSpeakers}
            currentTableData={tableDataSpeakers}
            defaultValue={initialValues.speakers}
            options={speakers}
            adding={addingEventSpeaker}
            disableAdd={pageMode === PAGE_MODE_VIEW}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loadingOptions={loadingSpeakers}
            onAddPressed={(defaultValue, newInitialValue, selectedOption,
              currentData) => onAddSpeakersPressed(defaultValue, newInitialValue,
              selectedOption, currentData)}
            onChangeOptionText={(text) => onChangeSpeakersText(text)}
            onDeletePressed={(newValue, currentData) => onDeleteSpeakersPressed(
              newValue, currentData,
            )}
            onOptionSelected={onSpeakersOptionSelected}
            selectedOption={selectedSpeakers}
            optionPlaceholder={LocalizedString.eventPage.placeholderSpeakers}
            currentTappedData={tappedEvent}
            onBlur={(e) => e.preventDefault()}
            hidden={isEventTypeRegular}
            required={!isEventTypeRegular}
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.eventPage.labelAdditionalItems} />
      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_EVENT_ADDITTIONAL_ITEMS}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.eventPage.labelAdditionalItems}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddAdditionalItemsPressed}
            onDeletePressed={onDeleteAdditionalItemsPressed}
            defaultValue={initialValues.additionalItems}
            requiredTableColumnField={['order', 'label', 'type', 'viewAccess', 'visibilityTime']}
            optionalTableColumnField={['content', 'url']}
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.eventPage.labelOrder, field: 'order', sorting: false, type: 'numeric',
              },
              { title: LocalizedString.eventPage.labelLabel, field: 'label', sorting: false },
              {
                title: LocalizedString.eventPage.labelType, field: 'type', sorting: false, lookup: ADDITIONAL_ITEMS_TYPES,
              },
              {
                title: LocalizedString.eventPage.labelViewAccess, field: 'viewAccess', sorting: false, lookup: ADDITIONAL_ITEMS_VIEW_ACCESSES,
              },
              {
                title: LocalizedString.eventPage.labelVisibilityTime, field: 'visibilityTime', sorting: false, lookup: ADDITIONAL_ITEMS_VISIBILITY_TIMES,
              },
              {
                title: LocalizedString.eventPage.labelContent,
                field: 'content',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (!isAdditionalItemYoutubeUrlType(prop)) {
                      if (prop.type === ADDITIONAL_ITEM_TYPE_IMAGE) {
                        if (prop.path) {
                          return (<Avatar variant="square" src={prop.path} />);
                        }
                        if (prop.content) {
                          return (
                            <Avatar
                              variant="square"
                              src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.content)}
                            />
                          );
                        }
                      } else {
                        const { icon: Icon, color } = getAdditionalItemIcon(prop);
                        return (<Icon style={{ color, width: 40, height: 40 }} />);
                      }
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: (props) => {
                  const { onChange, rowData } = props;
                  if (rowData.type === ADDITIONAL_ITEM_TYPE_IMAGE) {
                    return (
                      <DropzonePicker
                        onImageSelected={(path) => onChange({ content: path })}
                      />
                    );
                  }
                  if (rowData.type && !isAdditionalItemYoutubeUrlType(rowData)) {
                    return (
                      <FilePickerField
                        acceptedFileExtension={getAdditionalItemFileExtension(rowData)}
                        onFileSelected={(url) => onChange({ content: url })}
                        disabled={isAdditionalItemYoutubeUrlType(rowData)}
                      />
                    );
                  }
                  return null;
                },
              },
              {
                title: LocalizedString.eventPage.labelUrl,
                field: 'url',
                sorting: false,
                render: (prop) => {
                  if (prop) {
                    if (isAdditionalItemYoutubeUrlType(prop)) {
                      return prop?.url || prop?.path || null;
                    }
                    return null;
                  }
                  return null;
                },
                editComponent: (props) => {
                  const { onChange, rowData } = props;
                  if (rowData.type && isAdditionalItemYoutubeUrlType(rowData)) {
                    return (
                      <BootstrapInput
                        onChange={(e) => onChange(e.target.value)}
                        defaultValue={rowData?.url || rowData?.path || null}
                      />
                    );
                  }
                  return null;
                },
              },
            ]}
          />
        </Grid>
      )}

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.eventPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
  );
};

const EventPage = ({
  initialValues, addingEditing, downloading, handleSubmit, onAppear,
  onApplyAdvancedFilterPressed, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmContextMenuPressed, onCreatePressed, onEditPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, events, pageMode,
  onDeletePressed, onSavePressed, onClockInAreaOptionSelected, onRewardTypeOptionSelected,
  formSelectedRegistrationRewardType, vouchers, downloadingVoucher, onVoucherOptionSelected,
  onEventTypeOptionSelected, categories, downloadingCategory, onCategoryOptionSelected,
  locations, downloadingLocation, onLocationOptionSelected, onFeaturOptionSelected,
  onWhoCanRegisterOptionSelected, onNeedApprovalOptionSelected,
  onCanPayUsingPointOptionSelected, onVisibilityStatusOptionSelected, downloadingDeleting,
  onAddMediaMenuSelected, selectedAddMediaMenu, onAddMediaPressed, onDeleteMediaPressed,
  onChangeCategoryText, onChangeLocationText, onChangeDescriptionText, formSelectedIsFeatured,
  onChangeRewardVoucherText, formRegistrationRewardVoucher, onAdvancedFilterPressed,
  onEnableCommentOptionSelected, onCommentAccessOptionSelected, selectedEnableComment,
  onDeleteAdditionalItemsPressed, onAddAdditionalItemsPressed, currentEventStatus,
  enablingDisabling, onContextMenuPressed, speakers, loadingSpeakers, tableDataSpeakers,
  selectedSpeakers, addingEventSpeaker, tappedEvent, surveyForms, downloadingSurveyForms,
  onAddSpeakersPressed, onChangeSpeakersText, onDeleteSpeakersPressed, onSpeakersOptionSelected,
  onChangeSurveyFormText, onSurveyFormOptionSelected, onCopyPressed, copying, typeValues,
  onSendToMePressed, sendingNotifications,
  sendingNotification, discusId, onBannerImageSelected,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_EVENTS}
      uiPage={RXSTATE_EVENT_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentEventStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentEventStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: GlobalLocalizedString.common.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.eventPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.eventPage.labelCategory, field: 'category.name', sorting: !downloading },
        { title: LocalizedString.eventPage.labelLocation, field: 'location.name', sorting: !downloading },
        { title: LocalizedString.eventPage.labelEventType, field: 'eventType', sorting: !downloading },
        {
          title: LocalizedString.eventPage.labelStartTime,
          field: 'startTime',
          sorting: !downloading,
          render: ({ startTime }) => (startTime
            ? toMoment(startTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.eventPage.labelEndTime,
          field: 'endTime',
          sorting: !downloading,
          render: ({ endTime }) => (endTime
            ? toMoment(endTime).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
        {
          title: LocalizedString.eventPage.labelCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventPage.labelStartTime,
          field: 'startTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          useTimeZone: true,
        },
        {
          title: LocalizedString.eventPage.labelEndTime,
          field: 'endTime',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          useTimeZone: true,
        },
        {
          title: LocalizedString.eventPage.labelCategory,
          field: 'category',
          type: FILTER_TYPE_DROPDOWN,
          data: categories,
          loading: downloadingCategory,
          onChangeFilterText: (text) => onChangeCategoryText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventPage.labelLocation,
          field: 'location',
          type: FILTER_TYPE_DROPDOWN,
          data: locations,
          loading: downloadingLocation,
          onChangeFilterText: (text) => onChangeLocationText(text, true),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventPage.labelEventType,
          field: 'eventType',
          type: FILTER_TYPE_DROPDOWN,
          data: FILTER_EVENT_TYPE,
        },
        {
          title: LocalizedString.eventPage.labelWhoCanRegister,
          field: 'whoCanRegister',
          type: FILTER_TYPE_DROPDOWN,
          data: WHO_CAN_REGISTER,
        },
        {
          title: LocalizedString.eventPage.labelClockInMustBeInsideArea,
          field: 'clockInMustBeInsideArea',
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.eventPage.labelNeedApproval,
          field: 'needApproval',
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.eventPage.labelFeatured,
          field: 'isFeatured',
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.eventPage.labelCanPayUsingPoint,
          field: 'canPayUsingPoint',
          type: FILTER_TYPE_SWITCH,
          data: approval,
        },
        {
          title: LocalizedString.eventPage.labelRegistrationRewardType,
          field: 'registrationRewardType',
          type: FILTER_TYPE_DROPDOWN,
          data: REWARDS_TYPE,
        },
        {
          title: LocalizedString.eventPage.placeholderEnableComment,
          field: 'enableComment',
          type: FILTER_TYPE_SWITCH,
        },
        {
          title: LocalizedString.eventPage.placeholderCommentAccess,
          field: 'commentAccess',
          type: FILTER_TYPE_DROPDOWN,
          data: COMMENT_ACCESSES,
        },
        {
          title: LocalizedString.eventPage.placeholderDiscussionId,
          field: 'discussionId',
          type: FILTER_TYPE_TEXT,
        },
        {
          title: LocalizedString.eventPage.placeholderSurveyForm,
          field: 'surveyFormId',
          type: FILTER_TYPE_DROPDOWN,
          data: surveyForms,
          loading: downloadingSurveyForms,
          onChangeFilterText: onChangeSurveyFormText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.eventPage.labelVisibilityStatus,
          field: 'visibilityStatus',
          type: FILTER_TYPE_DROPDOWN,
          data: visibilityStatuses,
        },
        {
          title: LocalizedString.eventPage.labelStatus,
          field: 'status',
          type: FILTER_TYPE_DROPDOWN,
          data: status,
        },
      ]}
      handleSubmit={handleSubmit}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onEditPressed={(id) => onEditPressed(id, events)}
      onRefresh={onRefresh}
      onSavePressed={onSavePressed}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSendToMePressed={onSendToMePressed}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, events)}
      createNewButtonCaption={LocalizedString.eventPage.buttonCaptionCreateNewEvent}
      deleteButtonCaption={LocalizedString.eventPage.buttonCaptionDeleteEvent}
      editButtonCaption={LocalizedString.eventPage.buttonCaptionEditEvent}
      title={LocalizedString.eventPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_EVENT"
      deletePermissionName="EVENT_DELETE_EVENT"
      editPermissionName="EVENT_EDIT_EVENT"
    >
      {pageMode === PAGE_MODE_VIEW && (
        <div className={classes.buttonContainer}>
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotifications}
            loading={sendingNotification}
            disableElevation
            caption={LocalizedString.eventPage.buttonCaptionSendToMe}
            onClick={onSendToMePressed}
            size="small"
          />
        </div>
      )}

      {renderDialogContent(addingEditing, pageMode, initialValues, onClockInAreaOptionSelected,
        onRewardTypeOptionSelected, formSelectedRegistrationRewardType,
        vouchers, downloadingVoucher, onVoucherOptionSelected,
        onEventTypeOptionSelected, categories, downloadingCategory, onCategoryOptionSelected,
        locations, downloadingLocation, onLocationOptionSelected, onFeaturOptionSelected,
        onWhoCanRegisterOptionSelected, onNeedApprovalOptionSelected,
        onCanPayUsingPointOptionSelected, onVisibilityStatusOptionSelected, downloadingDeleting,
        onAddMediaMenuSelected, selectedAddMediaMenu, onAddMediaPressed, onDeleteMediaPressed,
        onChangeLocationText, onChangeDescriptionText, formSelectedIsFeatured,
        onChangeCategoryText, onChangeRewardVoucherText, formRegistrationRewardVoucher,
        onEnableCommentOptionSelected, onCommentAccessOptionSelected, selectedEnableComment,
        onDeleteAdditionalItemsPressed, onAddAdditionalItemsPressed, speakers, loadingSpeakers,
        tableDataSpeakers, selectedSpeakers, addingEventSpeaker, tappedEvent, surveyForms,
        downloadingSurveyForms, onAddSpeakersPressed, onChangeSpeakersText, onDeleteSpeakersPressed,
        onSpeakersOptionSelected, onChangeSurveyFormText, onSurveyFormOptionSelected,
        onCopyPressed, copying, typeValues, onBannerImageSelected)}
      {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} path={REST_BASE_URL_EVENT} />)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_EVENT,
  validate: rxformValidateEvent,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(EventPage);

EventPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  formRegistrationRewardVoucher: PropTypes.arrayOf(SimpleDataShape).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  surveyForms: PropTypes.arrayOf(PropTypes.object).isRequired,
  vouchers: PropTypes.arrayOf(PropTypes.object).isRequired,
  events: PropTypes.objectOf(PropTypes.object).isRequired,
  formSelectedIsFeatured: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingCategory: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  downloadingLocation: PropTypes.bool.isRequired,
  downloadingVoucher: PropTypes.bool.isRequired,
  selectedEnableComment: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  pageMode: PropTypes.string.isRequired,
  formSelectedRegistrationRewardType: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddAdditionalItemsPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onBannerImageSelected: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onChangeLocationText: PropTypes.func.isRequired,
  onChangeRewardVoucherText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCommentAccessOptionSelected: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteAdditionalItemsPressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableCommentOptionSelected: PropTypes.func.isRequired,
  onCanPayUsingPointOptionSelected: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onClockInAreaOptionSelected: PropTypes.func.isRequired,
  onEventTypeOptionSelected: PropTypes.func.isRequired,
  onFeaturOptionSelected: PropTypes.func.isRequired,
  onLocationOptionSelected: PropTypes.func.isRequired,
  onNeedApprovalOptionSelected: PropTypes.func.isRequired,
  onRewardTypeOptionSelected: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onVoucherOptionSelected: PropTypes.func.isRequired,
  onWhoCanRegisterOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentEventStatus: PropTypes.string.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadingSpeakers: PropTypes.bool.isRequired,
  tableDataSpeakers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  selectedSpeakers: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  addingEventSpeaker: PropTypes.bool.isRequired,
  tappedEvent: FormInitialValueShape,
  downloadingSurveyForms: PropTypes.bool.isRequired,
  onAddSpeakersPressed: PropTypes.func.isRequired,
  onChangeSpeakersText: PropTypes.func.isRequired,
  onDeleteSpeakersPressed: PropTypes.func.isRequired,
  onSpeakersOptionSelected: PropTypes.func.isRequired,
  onChangeSurveyFormText: PropTypes.func.isRequired,
  onSurveyFormOptionSelected: PropTypes.func.isRequired,
  typeValues: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onSendToMePressed: PropTypes.func.isRequired,
  sendingNotifications: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
};

EventPage.defaultProps = {
  selectedEnableComment: '',
  selectedSpeakers: null,
  tappedEvent: {},
  typeValues: '',
};
