import { reset } from 'redux-form';
import {
  addingEditingAssetCertificate, clearAssetCertificates, setAlertErrorMessage,
  setCertificationScheduleSettingDialogVisibility,
  setCertificationScheduleSettingSelectedFile, setCertificationScheduleSettingSelectedResultType,
} from '../simple-action';
import { addAssetCertificate, editAssetCertificate } from '../../../helper';
import { RXFORM_ASSET_CERTIFICATE } from '../../../constant';
import downloadAssetCertificatesAsync from './downloadAssetCertificatesAsync';

export default (remark, issuedDate, expiredDate) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingAssetCertificate(true));

    const { token } = getState().authentication;
    const { tappedId: assetId } = getState().uiAsset;
    const { tappedId } = getState().uiAssetCertificate;
    const { data } = getState().assetCertificates;
    const {
      selectedFile: { fileName, fileContent }, selectedResultType,
    } = getState().uiCertificationScheduleSetting;

    if (tappedId) {
      const found = data[tappedId];
      const selectedFileName = found?.file ? undefined : fileName;
      const selectedFileContent = found?.file ? undefined : fileContent;

      await editAssetCertificate(tappedId, assetId, selectedFileName, remark, issuedDate,
        expiredDate, selectedResultType, selectedFileContent, token);
    } else {
      await addAssetCertificate(assetId, fileName, remark, issuedDate, expiredDate,
        selectedResultType, fileContent, token);
    }

    dispatch(setCertificationScheduleSettingDialogVisibility(false, ''));
    dispatch(setCertificationScheduleSettingSelectedFile(''));
    dispatch(setCertificationScheduleSettingSelectedResultType(''));
    dispatch(reset(RXFORM_ASSET_CERTIFICATE));
    dispatch(clearAssetCertificates());
    dispatch(downloadAssetCertificatesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingAssetCertificate(false));
  }
};
