import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import { toMoment, toCurrency } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DROPDOWN, FILTER_TYPE_DATE_RANGE,
  PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_FORMAT, RXSTATE_PAYMENT_PAGE,
  RXSTATE_PAYMENTS, RXFORM_PAYMENT, FILTER_TYPE_TEXT,
  PAYMENT_CHANNEL_OPTIONS, PAYMENT_STATUS_OPTIONS,
  RXFIELD_PAYMENT_TRANSACTION_ID,
  RXFIELD_PAYMENT_VALID_UNTIL,
  RXFIELD_PAYMENT_TOTAL,
  RXFIELD_PAYMENT_LAST_CHANGED_STATUS,
  RXFIELD_PAYMENT_LAST_STATUS_CHECK,
  RXFIELD_PAYMENT_PAYMENT_CHANNEL,
  RXFIELD_PAYMENT_PAYMENT_STATUS,
  RXFIELD_PAYMENT_EXTERNAL_TRANSACTION_ID,
  RXFIELD_PAYMENT_PAYMENT_STATUS_NOTES,
  RXFIELD_PAYMENT_PAYMENT_URL,
  RXFIELD_PAYMENT_PAYMENT_CODE,
  RXFIELD_PAYMENT_PROVIDER,
  RXFIELD_PAYMENT_RAW_REQUEST,
  RXFIELD_PAYMENT_RAW_CALLBACK,
  RXFIELD_PAYMENT_RAW_RESPONSE,
  PROVIDER_OPTIONS,
} from '../../constant';

const renderDialogContent = () => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelTransactionId}
            label={LocalizedString.paymentPage.labelTransactionId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_TOTAL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelTotal}
            label={LocalizedString.paymentPage.labelTotal}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_PAYMENT_CHANNEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentChannel}
            label={LocalizedString.paymentPage.labelPaymentChannel}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_PAYMENT_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentStatus}
            label={LocalizedString.paymentPage.labelPaymentStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_PAYMENT_STATUS_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentStatusNotes}
            label={LocalizedString.paymentPage.labelPaymentStatusNotes}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_PAYMENT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentCode}
            label={LocalizedString.paymentPage.labelPaymentCode}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_RAW_REQUEST}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentRawRequest}
            label={LocalizedString.paymentPage.labelPaymentRawRequest}
            disabled
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PAYMENT_RAW_RESPONSE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentRawResponse}
            label={LocalizedString.paymentPage.labelPaymentRawResponse}
            disabled
            multiline
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_VALID_UNTIL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelValidUntil}
            label={LocalizedString.paymentPage.labelValidUntil}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_LAST_CHANGED_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelLastChangedStatus}
            label={LocalizedString.paymentPage.labelLastChangedStatus}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_LAST_STATUS_CHECK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelLastStatusCheck}
            label={LocalizedString.paymentPage.labelLastStatusCheck}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_EXTERNAL_TRANSACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelExternalTransactionId}
            label={LocalizedString.paymentPage.labelExternalTransactionId}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_PAYMENT_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelUrl}
            label={LocalizedString.paymentPage.labelUrl}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_PROVIDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelProvider}
            label={LocalizedString.paymentPage.labelProvider}
            disabled
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_PAYMENT_RAW_CALLBACK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.paymentPage.labelPaymentRawCallback}
            label={LocalizedString.paymentPage.labelPaymentRawCallback}
            disabled
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const PaymentPage = ({
  onAppear, downloading, downloadingDeleting, onSortPressed, onViewPressed,
  onChangePage, onChangePageSize, onRefresh, onSearchBarTextChanged,
  initialValues,
  onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onRepublishPressed, onConfirmPressed, republishing, isRepublishAllowed,
}) => (
  <FunctionalPage
    data={RXSTATE_PAYMENTS}
    uiPage={RXSTATE_PAYMENT_PAGE}
    tableColumns={[
      {
        title: LocalizedString.paymentPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.paymentPage.labelTransactionId,
        field: RXFIELD_PAYMENT_TRANSACTION_ID,
        sorting: !downloading,
      },
      {
        title: LocalizedString.paymentPage.labelTotal,
        field: RXFIELD_PAYMENT_TOTAL,
        sorting: !downloading,
        render: ({ total }) => (total
          ? toCurrency(total) : toCurrency(0)),
      },
      {
        title: LocalizedString.paymentPage.labelPaymentChannel,
        field: RXFIELD_PAYMENT_PAYMENT_CHANNEL,
        sorting: !downloading,
      },
      {
        title: LocalizedString.paymentPage.labelPaymentStatus,
        field: RXFIELD_PAYMENT_PAYMENT_STATUS,
        sorting: !downloading,
      },
      {
        title: LocalizedString.paymentPage.labelValidUntil,
        field: RXFIELD_PAYMENT_VALID_UNTIL,
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.paymentPage.labelCreatedDate,
        field: 'createdDate',
        sorting: !downloading,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.paymentPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.paymentPage.labelValidUntil,
        field: RXFIELD_PAYMENT_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
      {
        title: LocalizedString.paymentPage.labelTransactionId,
        field: RXFIELD_PAYMENT_TRANSACTION_ID,
        type: FILTER_TYPE_TEXT,
      },
      {
        title: LocalizedString.paymentPage.labelPaymentChannel,
        field: 'paymentChannel',
        type: FILTER_TYPE_DROPDOWN,
        data: PAYMENT_CHANNEL_OPTIONS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.paymentPage.labelPaymentStatus,
        field: 'paymentStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: PAYMENT_STATUS_OPTIONS,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.paymentPage.labelProvider,
        field: RXFIELD_PAYMENT_PROVIDER,
        type: FILTER_TYPE_DROPDOWN,
        data: PROVIDER_OPTIONS,
        useDropdownValue: true,
      },
    ]}
    moreMenus={[
      {
        caption: LocalizedString.paymentPage.buttonCaptionRepublish,
        disabled: republishing || !isRepublishAllowed,
        onPress: (tappedId) => onRepublishPressed(tappedId),
      },
    ]}
    onConfirmDeletePressed={onConfirmPressed}
    onAppear={onAppear}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.paymentPage.title}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
  >
    {renderDialogContent(downloadingDeleting, initialValues)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PAYMENT,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PaymentPage);

PaymentPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  isRepublishAllowed: PropTypes.bool.isRequired,
  republishing: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onRepublishPressed: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
};
