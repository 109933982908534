import { addCertificationRequest, downloadingDeletingCertificationRequest } from '../simple-action';
import { downloadCertificationRequest } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingCertificationRequest(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiCertificationRequest;

    const result = await downloadCertificationRequest(tappedId, token);
    dispatch(addCertificationRequest(result));
  } finally {
    dispatch(downloadingDeletingCertificationRequest(false));
  }
};
