import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE,
  DRAW_MODE_MARKER, DRAW_MODE_POLYGON, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_LOCATION_ADDRESS, RXFIELD_LOCATION_AREA, RXFIELD_LOCATION_CITY,
  RXFIELD_LOCATION_DESCRIPTION, RXFIELD_LOCATION_EMAIL, RXFIELD_LOCATION_GOOGLE_MAP_URL,
  RXFIELD_LOCATION_LATITUDE, RXFIELD_LOCATION_LONGITUDE, RXFIELD_LOCATION_NAME,
  RXFIELD_LOCATION_PHONE, RXFIELD_LOCATION_POSTAL_CODE, RXFORM_LOCATION, RXSTATE_LOCATIONS,
  RXSTATE_LOCATION_PAGE, status, STATUS_DISABLED, STATUS_ENABLED, timezoneOffset,
  ADVANCED_FILTER_MODE,
} from '../../constant';
import { FormInitialValueShape, PointShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormEditableTableField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { rxformValidateLocation } from '../../validation';
import { FunctionalPage, MapDrawer, SectionTitle } from '../../../configuration/component';
import { toMoment } from '../../helper';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  heading: {
    margin: 0,
  },
}));

const renderDialogContent = (currentFormValues, tappedData, center, marker, defaultAreaValue,
  defaultCityValue, cities, polygon, addingEditing, addingPolygon, loadingCity,
  onChangeCityText, onChangeLatitudeText, onChangeLongitudeText,
  onCityOptionSelected, onDrawingCompleted, pageMode, classes) => (
    <div>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderLocationName}
              label={LocalizedString.locationPage.placeholderLocationName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_DESCRIPTION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderDescription}
              label={LocalizedString.locationPage.placeholderDescription}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_PHONE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderPhone}
              label={LocalizedString.locationPage.placeholderPhone}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_EMAIL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderEmail}
              label={LocalizedString.locationPage.placeholderEmail}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_ADDRESS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderAddress}
              label={LocalizedString.locationPage.placeholderAddress}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              multiline
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_CITY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.locationPage.placeholderCityName}
              label={LocalizedString.locationPage.placeholderCityName}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              data={cities}
              value={defaultCityValue}
              loading={loadingCity}
              onChangeText={onChangeCityText}
              onOptionSelected={onCityOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_LOCATION_POSTAL_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.locationPage.placeholderPostalCode}
              label={LocalizedString.locationPage.placeholderPostalCode}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>

      <SectionTitle title={LocalizedString.locationPage.labelGoogleMap} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_LOCATION_GOOGLE_MAP_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.locationPage.placeholderGoogleMapUrl}
            label={LocalizedString.locationPage.placeholderGoogleMapUrl}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_LOCATION_LATITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.locationPage.placeholderLatitude}
            label={LocalizedString.locationPage.placeholderLatitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            type="number"
            onChangeText={(e) => onChangeLatitudeText(e, currentFormValues.longitude)}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_LOCATION_LONGITUDE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.locationPage.placeholderLongitude}
            label={LocalizedString.locationPage.placeholderLongitude}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
            onChangeText={(e) => onChangeLongitudeText(e, currentFormValues.latitude)}
            type="number"
          />
        </Grid>
      </Grid>

      <MapDrawer
        drawingMode={DRAW_MODE_MARKER}
        center={center}
        marker={marker}
        onDrawingCompleted={(data, drawingMode) => onDrawingCompleted(data, drawingMode,
          tappedData)}
        disabled={pageMode === PAGE_MODE_VIEW}
      />

      <SectionTitle
        title={LocalizedString.locationPage.labelBorderArea}
        textStyle={classes.heading}
      />

      {addingPolygon ? (<CircularProgress />) : (
        <Field
          name={RXFIELD_LOCATION_AREA}
          component={renderReduxFormEditableTableField}
          disabled
          defaultValue={defaultAreaValue}
          tableColumns={[
            {
              title: LocalizedString.locationPage.labelNo, field: 'no', sorting: false, width: 40,
            },
            { title: LocalizedString.locationPage.labelLatitude, field: 'lat', sorting: false },
            { title: LocalizedString.locationPage.labelLongitude, field: 'lng', sorting: false },
          ]}
          disableToolbar
          required
        />
      )}

      <MapDrawer
        drawingMode={DRAW_MODE_POLYGON}
        center={marker}
        marker={isEmpty(polygon) ? marker : null}
        polygon={polygon}
        onDrawingCompleted={(data, drawingMode) => onDrawingCompleted(data, drawingMode,
          tappedData)}
        disabled={pageMode === PAGE_MODE_VIEW}
      />
    </div>
);

const LocationPage = ({
  currentFormValues, tappedData, center, marker, initialValues, cities, polygon, provinces,
  addingEditing, addingPolygon, downloading, enablingDisabling, loadingCity, loadingProvince,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelPressed, onChangeCityText, onChangeLatitudeText,
  onChangeLongitudeText, onChangePage, onChangePageSize, onChangeProvinceText, onCityOptionSelected,
  onConfirmContextMenuPressed, onCreatePressed, onDownloadPressed, onDrawingCompleted,
  onEditPressed, onContextMenuPressed,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed,
  locations, currentLocationStatus, pageMode,
  onDeletePressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_LOCATIONS}
      uiPage={RXSTATE_LOCATION_PAGE}
      moreMenus={[
        {
          caption: GlobalLocalizedString.common.buttonCaptionEnable,
          disabled: currentLocationStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: GlobalLocalizedString.common.buttonCaptionDisable,
          disabled: currentLocationStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            GlobalLocalizedString.common.msgDisableConfirmation),
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.locationPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.locationPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.locationPage.labelCity, field: 'city.name', sorting: !downloading },
        { title: LocalizedString.locationPage.labelAddress, field: 'address', sorting: !downloading },
        { title: LocalizedString.locationPage.labelStatus, field: 'status', sorting: !downloading },
        {
          title: GlobalLocalizedString.common.placeholderCreatedDate,
          field: 'createdDate',
          sorting: !downloading,
          render: ({ createdDate }) => (createdDate
            ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.eventRegistrationPage.labelCreatedDate,
          field: 'createdDate',
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.locationPage.labelCity,
          field: 'city',
          type: FILTER_TYPE_DROPDOWN,
          data: cities,
          loading: loadingCity,
          onChangeFilterText: (text) => onChangeCityText(text, ADVANCED_FILTER_MODE),
          useDropdownValue: true,
        },
        {
          title: LocalizedString.locationPage.labelProvince,
          field: 'province',
          type: FILTER_TYPE_DROPDOWN,
          data: provinces,
          loading: loadingProvince,
          onChangeFilterText: onChangeProvinceText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.locationPage.labelTimeZone, field: 'timeZone', type: FILTER_TYPE_DROPDOWN, data: timezoneOffset,
        },
        {
          title: LocalizedString.locationPage.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={(id) => onEditPressed(id, locations)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, locations)}
      createNewButtonCaption={LocalizedString.locationPage.buttonCaptionCreateNewLocation}
      deleteButtonCaption={LocalizedString.locationPage.buttonCaptionDeleteLocation}
      editButtonCaption={LocalizedString.locationPage.buttonCaptionEditLocation}
      title={LocalizedString.locationPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      onDeletePressed={onDeletePressed}
      useFullWidth
      createPermissionName="EVENT_CREATE_LOCATION"
      deletePermissionName="EVENT_DELETE_LOCATION"
      editPermissionName="EVENT_EDIT_LOCATION"
      savePermissionName="EVENT_GET_LOCATION"
    >
      {renderDialogContent(currentFormValues, tappedData, center, marker, initialValues.area,
        initialValues.city.label, cities, polygon, addingEditing, addingPolygon, loadingCity,
        onChangeCityText, onChangeLatitudeText, onChangeLongitudeText,
        onCityOptionSelected, onDrawingCompleted, pageMode, classes)}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_LOCATION,
  validate: rxformValidateLocation,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LocationPage);

LocationPage.propTypes = {
  currentFormValues: FormInitialValueShape.isRequired,
  tappedData: FormInitialValueShape,
  center: PointShape,
  marker: PointShape,
  initialValues: FormInitialValueShape.isRequired,
  cities: PropTypes.arrayOf(SimpleDataShape).isRequired,
  polygon: PropTypes.arrayOf(PointShape).isRequired,
  provinces: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  addingPolygon: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingCity: PropTypes.bool.isRequired,
  loadingProvince: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeCityText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeLatitudeText: PropTypes.func.isRequired,
  onChangeLongitudeText: PropTypes.func.isRequired,
  onChangeProvinceText: PropTypes.func.isRequired,
  onCityOptionSelected: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onDrawingCompleted: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  locations: PropTypes.objectOf(PropTypes.object).isRequired,
  currentLocationStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
};

LocationPage.defaultProps = {
  tappedData: null,
  center: null,
  marker: null,
};
