import { SET_MY_ASSET_LOCATIONS, CLEAR_MY_ASSET_LOCATIONS } from '../action';

export default (state = [], action) => {
  switch (action.type) {
    case SET_MY_ASSET_LOCATIONS:
      return action.data;
    case CLEAR_MY_ASSET_LOCATIONS:
      return [];
    default:
      return state;
  }
};
