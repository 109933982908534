import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT, INITIAL_ORDER_BY_ASSET_CERTIFICATES, PAGE_MODE_TABLE,
} from '../../../constant';
import {
  getData, getPermission, transformInitialValues,
} from '../../../helper';
import GlobalLocalizedString from '../../../../../localization';
import {
  clearAssetCertificates, downloadAssetCertificatesAsync, downloadDeleteAssetCertificateAsync,
  setAlertConfirmationMessage, setAlertErrorMessage,
  setAssetCertificateAdvancedFilterDialogSelectedFilterString, setAssetCertificateSearchText,
  setAssetCertificateSelectedOrderBy, setAssetCertificateSelectedPageSize,
  setAssetCertificateTappedId, setCertificationScheduleSettingDialogVisibility,
} from '../../../redux/action';
import CertificationScheduleSettingPage from './detail.presentation';

const getInitialValues = (state) => {
  const { assets, uiFunctionalPage, uiAsset } = state;
  const { data } = assets;
  const { downloadingDeleting, tappedId } = uiAsset;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : {};
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  assetCertificates: getData(state.assetCertificates, state.uiAssetCertificate),
  downloadingAssetCertificates: state.uiAssetCertificate.downloading,
  downloadingDeleting: state.uiAsset.downloading,
  hasDeleteCertificationsPermission: getPermission(state, 'ECERTIFICATION_DELETE_ASSET_CERTIFICATE'),
  hasEditCertificationsPermission: getPermission(state, 'ECERTIFICATION_EDIT_ASSET_CERTIFICATE'),
  hasGetCertificationsPermission: getPermission(state, 'ECERTIFICATION_GET_ASSET_CERTIFICATE'),
  currentPage: state.assetCertificates?.meta?.currentPage || 0,
  totalCount: state.assetCertificates?.meta?.totalCount || 0,
  selectedPageSize: state.uiAssetCertificate?.selectedPageSize || 1,
  orderBy: state.uiAssetCertificate?.orderBy || '',
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: (tappedAssetId) => {
    dispatch(setCertificationScheduleSettingDialogVisibility(false, ''));
    dispatch(setAssetCertificateAdvancedFilterDialogSelectedFilterString(`asset.id=${tappedAssetId}`));
    dispatch(setAssetCertificateSearchText(''));
    dispatch(clearAssetCertificates());
    dispatch(setAssetCertificateSelectedPageSize(20));
    dispatch(setAssetCertificateSelectedOrderBy(INITIAL_ORDER_BY_ASSET_CERTIFICATES));
    dispatch(downloadAssetCertificatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetCertificatesAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetCertificateSelectedPageSize(pageSize));
    dispatch(downloadAssetCertificatesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (id) => {
    dispatch(setAssetCertificateTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onEditPressed: (id) => {
    dispatch(setAssetCertificateTappedId(id));
    dispatch(setCertificationScheduleSettingDialogVisibility(true,
      CERT_SCHEDULE_SETTING_DIALOG_MODE_EDIT));
    dispatch(downloadDeleteAssetCertificateAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onPdfPressed: async (assetNumber, fileUrl, issuedDate) => {
    try {
      const extension = fileUrl?.match(/(\.[^.]*)$/g)[0];
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `asset_certificate_${assetNumber}_${issuedDate}${extension}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetCertificateSelectedOrderBy(orderBy));
    dispatch(clearAssetCertificates());
    dispatch(downloadAssetCertificatesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificationScheduleSettingPage);
