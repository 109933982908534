import { connect } from 'react-redux';
import { reset } from 'redux-form';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, INITIAL_ORDER_BY_PARENTLESS_ASSETS, RXFORM_ASSET_CHILD_TAB,
} from '../../../constant';
import {
  getData, getPermission, toMoment, transformDropdownData,
} from '../../../helper';
import {
  addAssetChildAsync, clearAssetChildren, clearAssetLocations, clearAssetTypes,
  clearEditableTableFieldAdvancedFilterForm,
  clearParentlessAssets, deleteAssetChildAsync, downloadAssetChildrenAsync,
  downloadAssetLocationsAsync, downloadAssetTypesAsync, downloadParentlessAssetsAsync,
  setAlertErrorMessage, setAssetChildAdvancedFilterDialogSelectedFilterString,
  setAssetChildDialogVisibility, setAssetChildSearchText, setAssetChildSelectedOrderBy,
  setAssetChildSelectedPageSize, setAssetLocationSearchText, setAssetTypeSearchText,
  setEditableTableFieldAdvancedFilterDialogSelectedFilterString,
  setParentlessAssetAdvancedFilterDialogSelectedFilterString, setParentlessAssetSearchText,
  setParentlessAssetSelectedOrderBy, setParentlessAssetSelectedPageSize,
} from '../../../redux/action';
import AssetChildTab from './asset-child-tab.presentation';

const getParentlessAssetsData = (state) => {
  const { data, meta } = state.parentlessAssets;
  const { parentlessAssetSelectedPageSize } = state.uiAssetChild;

  return Object.values(data).map((item, i) => ({
    ...item,
    no: i + 1 + ((meta.currentPage - 1) * parentlessAssetSelectedPageSize),
    createdDate: item.createdDate
      ? toMoment(item.createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
    lastModifiedDate: item.lastModifiedDate
      ? toMoment(item.lastModifiedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null,
  }));
};

const mapStateToProps = (state) => ({
  assetChildren: getData(state.assetChildren, state.uiAssetChild),
  assetTypes: transformDropdownData(state.assetTypes.data),
  locations: transformDropdownData(state.assetLocations.data),
  parentlessAssets: getParentlessAssetsData(state),
  adding: state.uiAssetChild.adding,
  deleting: state.uiAssetChild.deleting,
  dialogVisibility: state.uiAssetChild.dialogVisibility,
  downloading: state.uiAssetChild.downloading,
  downloadingParentlessAssets: state.uiAssetChild.downloadingParentlessAssets,
  hasCreatePermission: getPermission(state, 'ECERTIFICATION_ADD_ASSET_CHILDREN'),
  hasDeletePermission: getPermission(state, 'ECERTIFICATION_DELETE_ASSET_CHILDREN'),
  loadingAssetTypes: state.uiAsset.downloadingAssetTypes,
  loadingLocations: state.uiAsset.downloadingAssetLocations,
  currentPage: state.assetChildren?.meta?.currentPage || 0,
  parentlessAssetCurrentPage: state.parentlessAssets?.meta?.currentPage || 0,
  parentlessAssetTotalCount: state.parentlessAssets?.meta?.totalCount || 0,
  parentlessAssetSelectedPageSize: state.uiAssetChild?.selectedPageSize || 1,
  selectedPageSize: state.uiAssetChild?.selectedPageSize || 1,
  totalCount: state.assetChildren?.meta?.totalCount || 0,
  orderBy: state.uiAssetChild?.orderBy || '',
  parentlessAssetFilterString: state.uiAssetChild.parentlessAssetFilterString,
  parentlessAssetOrderBy: state.uiAssetChild?.parentlessAssetOrderBy || '',
  parentlessAssetSearchBarText: state.uiAssetChild.parentlessAssetSearchBarText,
});

const mapDispatchToProps = (dispatch) => ({
  onAddPressed: () => {
    dispatch(setEditableTableFieldAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearEditableTableFieldAdvancedFilterForm());
    dispatch(setAssetChildDialogVisibility(true));
    dispatch(clearParentlessAssets());
    dispatch(setParentlessAssetAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setParentlessAssetSearchText(''));
    dispatch(setParentlessAssetSelectedPageSize(20));
    dispatch(setParentlessAssetSelectedOrderBy(INITIAL_ORDER_BY_PARENTLESS_ASSETS));
    dispatch(downloadParentlessAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onAppear: (tappedAssetId) => {
    dispatch(setAssetChildDialogVisibility(false));
    dispatch(clearAssetChildren());
    dispatch(setAssetChildAdvancedFilterDialogSelectedFilterString(`assetParent.id=${tappedAssetId}`));
    dispatch(setAssetChildSearchText(''));
    dispatch(setAssetChildSelectedPageSize(20));
    dispatch(setAssetChildSelectedOrderBy(INITIAL_ORDER_BY_PARENTLESS_ASSETS));
    dispatch(downloadAssetChildrenAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onApplyParentlessAssetAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/location/, 'location.id').replace(/category/, 'category.id');
    dispatch(setParentlessAssetAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearParentlessAssets());
    dispatch(downloadParentlessAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onCancelPressed: () => {
    dispatch(reset(RXFORM_ASSET_CHILD_TAB));
    dispatch(setAssetChildDialogVisibility(false));
  },
  onChangeAssetTypeText: async (text) => {
    try {
      dispatch(setAssetTypeSearchText(text));
      dispatch(clearAssetTypes());
      await dispatch(downloadAssetTypesAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangeLocationText: async (text) => {
    try {
      dispatch(setAssetLocationSearchText(text));
      dispatch(clearAssetLocations());
      await dispatch(downloadAssetLocationsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadAssetChildrenAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangePageSize: (pageSize) => {
    dispatch(setAssetChildSelectedPageSize(pageSize));
    dispatch(downloadAssetChildrenAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeParentlessAssetPage: (pageNo) => {
    dispatch(downloadParentlessAssetsAsync(pageNo + 1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeParentlessAssetPageSize: (pageSize) => {
    dispatch(setParentlessAssetSelectedPageSize(pageSize));
    dispatch(downloadParentlessAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onDeletePressed: (newData, oldData) => {
    dispatch(deleteAssetChildAsync(oldData.id))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onParentlessAssetAdvancedFilterPressed: () => {
    dispatch(setAssetTypeSearchText(''));
    dispatch(clearAssetTypes());
    dispatch(downloadAssetTypesAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(setAssetLocationSearchText(''));
    dispatch(clearAssetLocations());
    dispatch(downloadAssetLocationsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onParentlessAssetSearchBarTextChanged: async (text) => {
    try {
      dispatch(setParentlessAssetSearchText(text));
      dispatch(clearParentlessAssets());
      await dispatch(downloadParentlessAssetsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onParentlessAssetSortPressed: (orderBy) => {
    dispatch(setParentlessAssetSelectedOrderBy(orderBy));
    dispatch(clearParentlessAssets());
    dispatch(downloadParentlessAssetsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onResetParentlessAssetAdvancedFilterPressed: () => {
    dispatch(setParentlessAssetAdvancedFilterDialogSelectedFilterString(''));
    dispatch(clearParentlessAssets());
    dispatch(setParentlessAssetSelectedOrderBy(INITIAL_ORDER_BY_PARENTLESS_ASSETS));
    dispatch(downloadParentlessAssetsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSortPressed: (orderBy) => {
    dispatch(setAssetChildSelectedOrderBy(orderBy));
    dispatch(clearAssetChildren());
    dispatch(downloadAssetChildrenAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: async (selectedAssets) => {
    try {
      await dispatch(addAssetChildAsync(selectedAssets));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetChildTab);
