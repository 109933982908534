import { connect } from 'react-redux';
import {
  change,
} from 'redux-form';
import {
  DIALOG_TYPE_EDIT,
  MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER,
  PDF_TYPE_IMAGE,
  RXFIELD_ANNOTATION_DESIGNER_FONT_NAME,
  RXFIELD_ANNOTATION_DESIGNER_IMAGE,
  RXFORM_ANNOTATION_DESIGNER,
} from '../../constant';
import {
  sortAsc,
  transformInitialValues,
} from '../../helper';
import {
  renderAnnotationAsync, setActiveSideMenuItem,
  setAlertErrorMessage, setColorPickerHex, downloadFontAsync,
  setSelectedFont,
  setAnnotationDesignSelectedImage,
  setCreateAnnotationDialogVisibility,
  setCreateAnnotationDialogPdfType,
  setAnnotationDesignTappedId,
  deleteAnnotationAsync,
  setAlertConfirmationMessage,
  setPDFTemplate,
} from '../../redux/action';
import AnnotationDesignerPage from './annotation-designer.presentation';
import GlobalizedString from '../../../../localization';

const getFontStyle = (item) => {
  let fontStyle = '';

  fontStyle = item.isBold ? fontStyle.concat('Bold') : fontStyle;
  fontStyle = item.isBold && item.isItalic ? fontStyle.concat(',') : fontStyle;
  fontStyle = item.isItalic ? fontStyle.concat('Italic') : fontStyle;

  return fontStyle;
};

const getInitialValues = (state) => {
  const {
    annotationDesigners, uiAnnotationDesigner,
  } = state;
  const { data } = annotationDesigners;
  const { annotations } = data;
  const { downloadingDeleting, tappedId } = uiAnnotationDesigner;

  const found = !downloadingDeleting && tappedId ? annotations.find((x) => x.id === tappedId) : {};
  const result = Object.keys(found).length > 0 ? transformInitialValues(found,
    {
      fontName: { label: found?.fontName, value: found?.fontName },
      fontStyle: getFontStyle(found),
      imageValue: found.type === PDF_TYPE_IMAGE ? found.value : '',
    })
    : {
      fontName: { label: '', value: '' },
    };
  return result;
};

const transformFormData = (data) => {
  const response = Object.values(data).map((x) => ({
    label: x.name,
    value: x.name,
  }));

  return response;
};

const mapStateToProps = (state) => ({
  data: state.annotationDesigners?.data?.annotations
    .sort((a, b) => sortAsc(a.page, b.page) || sortAsc(a.y, b.y)),
  fontData: transformFormData(state.fonts.data),
  pageMode: state.uiFunctionalPage.pageMode,
  addingEditing: state.uiAnnotationDesigner.addingEditing,
  downloading: state.uiAnnotationDesigner.downloading,
  downloadingDeleting: state.uiAnnotationDesigner.downloadingDeleting,
  pdfType: state.uiPdfList.pdfType,
  initialValues: getInitialValues(state),
  selectedImage: state.uiAnnotationDesigner.selectedImage,
  fontNameVisibility: state.uiAnnotationDesigner.fontNameVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_PDF_MANAGEMENT_ANNOTATION_DESIGNER));
    dispatch(downloadFontAsync());
    dispatch(setPDFTemplate(''));
  },
  onImageSelected: (image, fileName, url) => {
    dispatch(setAnnotationDesignSelectedImage(url, fileName));
    dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_IMAGE, image));
  },
  onFontOptionSelected: (option) => {
    if (option) {
      dispatch(setSelectedFont(option));
      dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME, option));
    } else {
      dispatch(setSelectedFont(''));
      dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_FONT_NAME, ''));
    }
  },
  onSavePressed: async (submitValue, pdfType, imageFileName) => {
    try {
      await dispatch(renderAnnotationAsync(submitValue, pdfType, imageFileName));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onColorSelected: (color) => {
    dispatch(setColorPickerHex(color));
  },
  onDeletePressed: (id) => {
    dispatch(setAnnotationDesignTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalizedString.common.msgDeleteConfirmation));
  },
  onConfirmDeletePressed: () => {
    dispatch(deleteAnnotationAsync());
  },
  onEditPressed: (id, type, color) => {
    dispatch(setAnnotationDesignTappedId(id));
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_EDIT, type));
    dispatch(setCreateAnnotationDialogPdfType(type));
    dispatch(setColorPickerHex(color));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationDesignerPage);
