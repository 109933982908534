import { INITIAL_ORDER_BY_ASSET_CHILDREN, INITIAL_ORDER_BY_PARENTLESS_ASSETS } from '../../constant';
import {
  ADDING_ASSET_CHILD, DELETING_ASSET_CHILD, DOWNLOADING_ASSET_CHILDREN,
  DOWNLOADING_PARENTLESS_ASSETS, SET_ASSET_CHILD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_ASSET_CHILD_DIALOG_VISIBILITY, SET_ASSET_CHILD_SEARCH_TEXT, SET_ASSET_CHILD_SELECTED_ORDER_BY,
  SET_ASSET_CHILD_SELECTED_PAGE_SIZE,
  SET_PARENTLESS_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_PARENTLESS_ASSET_SEARCH_TEXT, SET_PARENTLESS_ASSET_SELECTED_ORDER_BY,
  SET_PARENTLESS_ASSET_SELECTED_PAGE_SIZE,
} from '../action';

const initialState = {
  adding: false,
  deleting: false,
  dialogVisibility: false,
  downloading: false,
  downloadingParentlessAssets: false,
  selectedPageSize: 20,
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_ASSET_CHILDREN,
  parentlessAssetSelectedPageSize: 20,
  parentlessAssetSearchBarText: '',
  parentlessAssetFilterString: '',
  parentlessAssetOrderBy: INITIAL_ORDER_BY_PARENTLESS_ASSETS,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADDING_ASSET_CHILD:
      return { ...state, adding: action.status };
    case DELETING_ASSET_CHILD:
      return { ...state, deleting: action.status };
    case DOWNLOADING_ASSET_CHILDREN:
      return { ...state, downloading: action.status };
    case DOWNLOADING_PARENTLESS_ASSETS:
      return { ...state, downloadingParentlessAssets: action.status };
    case SET_ASSET_CHILD_DIALOG_VISIBILITY:
      return { ...state, dialogVisibility: action.status };
    case SET_ASSET_CHILD_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_ASSET_CHILD_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_ASSET_CHILD_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_ASSET_CHILD_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_PARENTLESS_ASSET_SELECTED_PAGE_SIZE:
      return { ...state, parentlessAssetSelectedPageSize: action.size };
    case SET_PARENTLESS_ASSET_SEARCH_TEXT:
      return { ...state, parentlessAssetSearchBarText: action.text };
    case SET_PARENTLESS_ASSET_SELECTED_ORDER_BY:
      return { ...state, parentlessAssetOrderBy: action.order };
    case SET_PARENTLESS_ASSET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, parentlessAssetFilterString: action.text };
    default: return state;
  }
};
