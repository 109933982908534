import {
  loggingIn, addToken, addCurrentUser, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage, setAlertWarningMessage,
  setDrawerVisibility,
  clearCaptcha,
} from '../simple-action';
import { login, transformConfigValueBoolean } from '../../../helper';
import LocalizedString from '../../../localization';
import { MENUID_DASHBOARD, ROUTE_NAME_DASHBOARD } from '../../../module/attendance';
import downloadMyProfileAsync from './downloadMyProfileAsync';
import { USER_TYPE_SYSTEM } from '../../../constant';
import { MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST, ROUTE_NAME_CERTIFICATION_REQUEST } from '../../../module/e-certification';

export default (username, password, captchaText, navigateTo) => async (dispatch, getState) => {
  if (!username || !password) {
    throw new Error(LocalizedString.loginScreen.errEmptyUsernamePassword);
  }

  if (!transformConfigValueBoolean(getState().myConfigItems?.auth?.useCaptchaForLoginApi || '')) {
    await dispatch(clearCaptcha());
  }

  const { data } = getState().captcha;
  const { redirectionParam } = getState().uiLogin;

  try {
    dispatch(loggingIn(true));
    const auth = await login(username, password, data?.id, captchaText);
    const { token, user } = auth;

    if (!user.permissions.WEB_ADMIN_LOGIN) {
      throw new Error(LocalizedString.loginScreen.errMsgNoPermissionToLogin);
    }

    dispatch(addToken(token));
    dispatch(addCurrentUser(user));
    if (user?.type !== USER_TYPE_SYSTEM) {
      dispatch(downloadMyProfileAsync())
        .catch((error) => {
          dispatch(setAlertErrorMessage(error));
        });
    }

    dispatch(setActiveSideMenuItem(redirectionParam.startsWith(ROUTE_NAME_CERTIFICATION_REQUEST)
      ? MENUID_E_CERTIFICATION_CERTIFICATION_REQUEST : MENUID_DASHBOARD));
    dispatch(setDrawerVisibility(true));
    navigateTo(redirectionParam || ROUTE_NAME_DASHBOARD);
  } finally {
    dispatch(setAlertErrorMessage(''));
    dispatch(setAlertInfoMessage(''));
    dispatch(setAlertWarningMessage(''));
    dispatch(setAlertConfirmationMessage(''));
    dispatch(setAlertInputMessage('', ''));
    dispatch(loggingIn(false));
  }
};
