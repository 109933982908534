import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Avatar, CircularProgress, Grid,
} from '@material-ui/core';
import {
  BootstrapInput, Comment, DropzonePicker, FunctionalPage, NumberTextFieldWithoutArrowDial,
  SectionTitle,
} from '../../component';
import {
  clickActions, CLICK_ACTION_OPEN_URL, CLICK_ACTION_SHOW_BANNER_BODY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  IMAGE_SOURCE_URI_PREFIX, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, RXFIELD_BANNER_ANDROID_APP_ID, RXFIELD_BANNER_BODY,
  RXFIELD_BANNER_CLICK_ACTION, RXFIELD_BANNER_DOWNLOAD_URL, RXFIELD_BANNER_DISCUSSION_ID,
  RXFIELD_BANNER_IOS_APP_ID, RXFIELD_BANNER_MEDIA, RXFIELD_BANNER_ORDER,
  RXFIELD_BANNER_SHORT_DESCRIPTION, RXFIELD_BANNER_TAGS, RXFIELD_BANNER_TITLE, RXFIELD_BANNER_URL,
  RXFIELD_BANNER_VALID_FROM, RXFIELD_BANNER_VALID_UNTIL, RXFIELD_BANNER_SHORT_URL,
  RXFIELD_BANNER_REACTION_ID, RXFIELD_BANNER_VIEW_COUNT, RXFORM_BANNER, RXSTATE_BANNERS,
  RXSTATE_BANNER_PAGE, PAGE_MODE_TABLE, RXFIELD_BANNER_COMPANY, FILTER_TYPE_DATE_RANGE,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, FILTER_TYPE_DROPDOWN, ADVANCED_FILTER_MODE,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormEditableTableField,
  renderReduxFormRichTextEditorField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
} from '../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateBanner } from '../../validation';

const BannerPage = ({
  initialValues,
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAddMediaMenuSelected, onAddMediaPressed, onAppear, onCancelPressed,
  onChangeBodyText, onChangePage, onChangePageSize, onChangeShortDescriptionText,
  onClickActionOptionSelected, onConfirmDeletePressed, onCreatePressed, onDeleteMediaPressed,
  onDeletePressed, onEditPressed, onRefresh, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, loadingCompanies, companies,
  shortDescriptionLength, onCompanyOptionSelected, onChangeCompanyText,
  pageMode, selectedAddMediaMenu, selectedClickAction, discusId,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_BANNERS}
    uiPage={RXSTATE_BANNER_PAGE}
    tableColumns={[
      {
        title: LocalizedString.bannerScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.bannerScreen.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.bannerScreen.labelCompany, field: 'company.name', sorting: !downloading },
      { title: LocalizedString.bannerScreen.labelOrder, field: 'order', sorting: !downloading },
      {
        title: LocalizedString.bannerScreen.labelValidFrom,
        field: 'validFrom',
        render: ({ validFrom }) => (toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
        sorting: !downloading,
      },
      {
        title: LocalizedString.bannerScreen.labelValidUntil,
        field: 'validUntil',
        render: ({ validUntil }) => (toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
        sorting: !downloading,
      },
      { title: LocalizedString.bannerScreen.labelViewCount, field: 'viewCount', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.bannerScreen.placeholderValidFrom,
        field: RXFIELD_BANNER_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.bannerScreen.placeholderValidUntil,
        field: RXFIELD_BANNER_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.bannerScreen.placeholderAction,
        field: RXFIELD_BANNER_CLICK_ACTION,
        type: FILTER_TYPE_DROPDOWN,
        data: clickActions,
      },
      {
        title: LocalizedString.bannerScreen.labelCompany,
        field: RXFIELD_BANNER_COMPANY,
        type: FILTER_TYPE_DROPDOWN,
        data: companies,
        loading: loadingCompanies,
        onChangeFilterText: (text) => onChangeCompanyText(text, ADVANCED_FILTER_MODE),
        onFilterOptionSelected: onCompanyOptionSelected,
        useDropdownValue: true,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.bannerScreen.buttonCaptionCreateNewBanner}
    deleteButtonCaption={LocalizedString.bannerScreen.buttonCaptionDeleteBanner}
    editButtonCaption={LocalizedString.bannerScreen.buttonCaptionEditBanner}
    title={LocalizedString.bannerScreen.title}
    useFullWidth
    usefullWidthDialog
    createPermissionName="CORE_CREATE_BANNER"
    deletePermissionName="CORE_DELETE_BANNER"
    editPermissionName="CORE_EDIT_BANNER"
  >
    <Grid container spacing={3}>
      <Grid item xs sm md>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderTitle}
            label={LocalizedString.bannerScreen.placeholderTitle}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.bannerScreen.placeholderValidFrom}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.bannerScreen.placeholderValidUntil}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            pickerMode={PICKER_MODE_DATE_TIME}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_CLICK_ACTION}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.bannerScreen.placeholderAction}
            label={LocalizedString.bannerScreen.placeholderAction}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={clickActions}
            value={initialValues.clickAction}
            onOptionSelected={onClickActionOptionSelected}
            required
          />
        </Grid>
        {selectedClickAction === CLICK_ACTION_OPEN_URL && (
          <Grid item>
            <Field
              name={RXFIELD_BANNER_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.bannerScreen.placeholderUrl}
              label={LocalizedString.bannerScreen.placeholderUrl}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        )}
        <Grid item>
          <Field
            name={RXFIELD_BANNER_ORDER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderOrder}
            label={LocalizedString.bannerScreen.placeholderOrder}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_TAGS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderTags}
            label={LocalizedString.bannerScreen.placeholderTags}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_SHORT_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderShortDescription}
            helperText={`${shortDescriptionLength} ${LocalizedString.bannerScreen.labelCharacter}`}
            label={LocalizedString.bannerScreen.placeholderShortDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onChangeText={(e) => onChangeShortDescriptionText(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item xs sm md>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_ANDROID_APP_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderAndroidAppId}
            label={LocalizedString.bannerScreen.placeholderAndroidAppId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_IOS_APP_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderIosAppId}
            label={LocalizedString.bannerScreen.placeholderIosAppId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_DOWNLOAD_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderDownloadUrl}
            label={LocalizedString.bannerScreen.placeholderDownloadUrl}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_COMPANY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.bannerScreen.placeholderCompany}
            label={LocalizedString.bannerScreen.placeholderCompany}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={companies}
            value={initialValues.company}
            loading={loadingCompanies}
            onChangeText={onChangeCompanyText}
            onOptionSelected={onCompanyOptionSelected}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_VIEW_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderViewCount}
            label={LocalizedString.bannerScreen.placeholderViewCount}
            disabled
            hidden={pageMode === PAGE_MODE_TABLE}
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_SHORT_URL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderShortURL}
            label={LocalizedString.bannerScreen.placeholderShortURL}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_REACTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderReactionId}
            label={LocalizedString.bannerScreen.placeholderReactionId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_BANNER_DISCUSSION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.bannerScreen.placeholderDiscussionId}
            label={LocalizedString.bannerScreen.placeholderDiscussionId}
            disabled
          />
        </Grid>
      </Grid>
    </Grid>

    {selectedClickAction === CLICK_ACTION_SHOW_BANNER_BODY && (
      <Grid item sm={12}>
        <Field
          name={RXFIELD_BANNER_BODY}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.bannerScreen.placeholderBody}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeBodyText}
          loading={downloadingDeleting}
        />
      </Grid>
    )}

    <SectionTitle title={LocalizedString.bannerScreen.labelMedia} />

    {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
      <Grid item sm={12}>
        <Field
          name={RXFIELD_BANNER_MEDIA}
          component={renderReduxFormEditableTableField}
          label={LocalizedString.bannerScreen.labelMedia}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onAddPressed={onAddMediaPressed}
          onDeletePressed={onDeleteMediaPressed}
          defaultValue={initialValues.media}
          addMenuList={[
            {
              caption: LocalizedString.bannerScreen.buttonCaptionYoutube,
              onPress: () => onAddMediaMenuSelected('Youtube'),
            },
            {
              caption: LocalizedString.bannerScreen.buttonCaptionImage,
              onPress: () => onAddMediaMenuSelected('Image'),
            },
          ]}
          requiredTableColumnField={['order']}
          optionalTableColumnField={['content', 'link']}
          disableEdit
          disableToolbar={pageMode === PAGE_MODE_VIEW}
          tableColumns={[
            {
              title: LocalizedString.bannerScreen.labelOrder,
              field: 'order',
              sorting: false,
              editable: 'onAdd',
              // eslint-disable-next-line react/prop-types
              editComponent: ({ value, onChange }) => (
                <NumberTextFieldWithoutArrowDial
                  placeholder={LocalizedString.bannerScreen.labelOrder}
                  label={LocalizedString.bannerScreen.labelOrder}
                  variant="outlined"
                  type="number"
                  defaultValue={value}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                />
              ),
            },
            {
              title: LocalizedString.bannerScreen.labelYoutube,
              field: 'link',
              sorting: false,
              editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
              render: (prop) => {
                if (prop) {
                  if (prop.path) {
                    if (prop.type === 'Youtube' && !prop.tableData.editing) {
                      const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                      return path;
                    }
                    if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                      onAddMediaMenuSelected('Youtube');
                    }
                  }
                  if (prop.link) {
                    return prop.link.path;
                  }
                  return null;
                }
                return null;
              },
              // eslint-disable-next-line react/prop-types
              editComponent: ({ value, onChange }) => (
                <BootstrapInput
                  placeholder={LocalizedString.bannerScreen.labelYoutube}
                  label={LocalizedString.bannerScreen.labelYoutube}
                  variant="outlined"
                  defaultValue={value}
                  onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                  fullWidth
                />
              ),
            },
            {
              title: LocalizedString.bannerScreen.labelImage,
              field: 'content',
              sorting: false,
              editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
              render: (prop) => {
                if (prop) {
                  if (prop.path) {
                    if (prop.type === 'Image' && !prop.tableData.editing) {
                      return (<Avatar variant="square" src={prop.path} />);
                    }
                    if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                      onAddMediaMenuSelected('Image');
                    }
                  }
                  if (prop.content) {
                    return (
                      <Avatar
                        variant="square"
                        src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                      />
                    );
                  }
                  return null;
                }
                return null;
              },
              // eslint-disable-next-line react/prop-types
              editComponent: ({ onChange }) => (
                <DropzonePicker
                  onImageSelected={(path) => onChange({ path, type: 'Image' })}
                />
              ),
            },
          ]}
          required
        />
      </Grid>
    )}

    {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} />)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_BANNER,
  validate: rxformValidateBanner,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(BannerPage);

BannerPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeBodyText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeShortDescriptionText: PropTypes.func.isRequired,
  onClickActionOptionSelected: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  shortDescriptionLength: PropTypes.number.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string.isRequired,
  selectedClickAction: PropTypes.string.isRequired,
  discusId: PropTypes.string.isRequired,
  onCompanyOptionSelected: PropTypes.func.isRequired,
  onChangeCompanyText: PropTypes.func.isRequired,
  loadingCompanies: PropTypes.bool.isRequired,
  companies: PropTypes.arrayOf(SimpleDataShape).isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
};
