/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Divider, IconButton, Grid, makeStyles, Paper, Typography,
} from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import {
  AccentButton, EditableTableField, StyledTab, VerticalSpacer,
} from '../../../component';
import {
  CERT_STATUS_APPROVED, CERT_STATUS_PENDING, CERT_STATUS_REJECTED, CERT_STATUS_WAITING_FOR_APPROVAL,
  COLOR_APPROVED, COLOR_APPROVED_LIGHTER, COLOR_IN_PROGRESS, COLOR_IN_PROGRESS_LIGHTER,
  COLOR_PRIMARY, COLOR_REJECTED, COLOR_REJECTED_LIGHTER, COLOR_SECONDARY, REVERSED_ISO_DATE_FORMAT,
  REVERSED_ISO_DATE_TIME_FORMAT_WITHOUT_T, RXFIELD_CERTIFICATION_REQUEST_CATEGORY,
  RXFIELD_CERTIFICATION_REQUEST_OWNER, RXFIELD_CERTIFICATION_REQUEST_PIC,
  RXFIELD_CERTIFICATION_REQUEST_TYPE, RXFORM_CERTIFICATION_REQUEST,
} from '../../../constant';
import { toMoment } from '../../../helper';
import GlobalLocalizedString from '../../../../../localization';
import LocalizedString from '../../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../../redux-form-rendererer';
import { FormInitialValueShape, StyledTabShape } from '../../../type';
import SelectFileDialog from '../select-file-dialog';

const pdfIcon = require('../../../../../asset/pdf-icon.png');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderBottom: `1px solid ${COLOR_SECONDARY}`,
    borderLeft: `1px solid ${COLOR_SECONDARY}`,
    borderRight: `1px solid ${COLOR_SECONDARY}`,
    padding: 8,
  },
  activityIndicator: {
    margin: '11px 0px 11px 0px',
    justifyContent: 'center',
    display: 'flex',
  },
  subtitle: {
    color: COLOR_PRIMARY,
    fontWeight: 'bold',
  },
  divider: {
    backgroundColor: COLOR_SECONDARY,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rejectButton: {
    color: COLOR_REJECTED,
    borderColor: COLOR_REJECTED,
    marginRight: 8,
  },
}));

const styles = {
  statusContainer: {
    padding: 2,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const renderStepStatus = (initialValues, stepStatus) => {
  let statusProps;
  switch (stepStatus) {
    case CERT_STATUS_APPROVED:
      statusProps = {
        color: COLOR_APPROVED,
        backgroundColor: COLOR_APPROVED_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelApproved,
      }; break;
    case CERT_STATUS_WAITING_FOR_APPROVAL:
      statusProps = {
        color: COLOR_IN_PROGRESS,
        backgroundColor: COLOR_IN_PROGRESS_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelOnProgress,
      }; break;
    case CERT_STATUS_REJECTED:
      statusProps = {
        color: COLOR_REJECTED,
        backgroundColor: COLOR_REJECTED_LIGHTER,
        label: LocalizedString.certificationRequestPage.labelRejected,
      }; break;
    case CERT_STATUS_PENDING:
      statusProps = initialValues.approvalStatus !== CERT_STATUS_REJECTED ? {
        color: COLOR_PRIMARY,
        backgroundColor: COLOR_SECONDARY,
        label: stepStatus,
      } : null; break;
    default: statusProps = {
      color: COLOR_PRIMARY,
      backgroundColor: COLOR_SECONDARY,
      label: stepStatus,
    }; break;
  }

  if (stepStatus && statusProps) {
    return (
      <span style={{
        backgroundColor: statusProps.backgroundColor,
        border: `1px solid ${statusProps.color}`,
        ...styles.statusContainer,
      }}
      >
        <Typography variant="caption" style={{ color: statusProps.color }}>
          {statusProps.label}
        </Typography>
      </span>
    );
  }
  return null;
};

const renderDetailContent = (initialValues, approvals, tabs, downloadingApprovals,
  downloadingDeleting, onPdfPressed, activeTab, classes) => {
  let component;
  switch (activeTab) {
    case tabs[0].label: component = (
      <EditableTableField
        data={initialValues?.assets}
        loading={downloadingDeleting}
        tableColumns={[
          {
            title: LocalizedString.assetPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          {
            title: GlobalLocalizedString.common.labelAction,
            field: 'file',
            sorting: !downloadingDeleting,
            render: ({ id }) => (
              <IconButton
                onClick={() => onPdfPressed(id)}
                disabled={downloadingDeleting}
                size="small"
              >
                <img alt="cert-file" src={pdfIcon} width={20} height={20} />
              </IconButton>
            ),
          },
          { title: LocalizedString.assetPage.labelId, field: 'sourceId', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelAssetDescription, field: 'assetDescription', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelPartCode, field: 'partCode', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelSerialNumber, field: 'serialNumber', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelAssetNumber, field: 'assetNumber', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelAssetClass, field: 'category.name', sorting: !downloadingDeleting },
          { title: LocalizedString.assetPage.labelLocation, field: 'location.name', sorting: !downloadingDeleting },
          {
            title: LocalizedString.assetPage.labelExpired,
            field: 'certificateExpirationDate',
            sorting: !downloadingDeleting,
            render: ({ certificateExpirationDate }) => (certificateExpirationDate
              ? toMoment(certificateExpirationDate).format(REVERSED_ISO_DATE_FORMAT) : null),
          },
        ]}
        disabled
        disableToolbar
      />
    ); break;
    case tabs[1].label: component = (
      <EditableTableField
        data={approvals}
        loading={downloadingApprovals}
        tableColumns={[
          { title: LocalizedString.certificationRequestPage.labelName, field: 'approvers[0].user.fullName', sorting: !downloadingApprovals },
          { title: LocalizedString.certificationRequestPage.labelTitle, field: 'stepLabel', sorting: !downloadingApprovals },
          {
            title: LocalizedString.certificationRequestPage.labelStart,
            field: 'approvalStartedAt',
            sorting: !downloadingApprovals,
            render: ({ approvalStartedAt }) => (approvalStartedAt
              ? toMoment(approvalStartedAt).format(REVERSED_ISO_DATE_TIME_FORMAT_WITHOUT_T) : null),
          },
          {
            title: LocalizedString.certificationRequestPage.labelEnd,
            field: 'statusChangedAt',
            sorting: !downloadingApprovals,
            render: ({ statusChangedAt, stepStatus }) => (statusChangedAt
              && (stepStatus === CERT_STATUS_APPROVED || stepStatus === CERT_STATUS_REJECTED)
              ? toMoment(statusChangedAt).format(REVERSED_ISO_DATE_TIME_FORMAT_WITHOUT_T) : null),
          },
          { title: LocalizedString.certificationRequestPage.labelRemarks, field: 'notes', sorting: !downloadingApprovals },
          {
            title: LocalizedString.certificationRequestPage.labelStatus, field: 'stepStatus', sorting: !downloadingApprovals, render: ({ stepStatus }) => renderStepStatus(initialValues, stepStatus),
          },
        ]}
        disabled
        disableToolbar
      />
    ); break;
    default: break;
  }

  return (
    <Paper className={classes.contentContainer}>
      {component}
    </Paper>
  );
};

const renderActivityIndicator = (classes) => (
  <div className={classes.activityIndicator}>
    <CircularProgress color="inherit" />
  </div>
);

const CertificationRequestDetailPage = ({
  initialValues, approvals, tabs,
  approving, checkingApprovalPermission, downloadingApprovals, downloadingDeleting,
  hasApprovalPermission, rejecting,
  onAppear, onApproveRejectPressed, onPdfPressed, onTabPressed,
  activeTab,
}) => {
  const classes = useStyles();

  const isApprovalTabActive = activeTab === tabs[1].label;

  useEffect(() => onAppear(isApprovalTabActive), [onAppear, isApprovalTabActive]);

  if (downloadingDeleting) {
    return renderActivityIndicator(classes);
  }
  return (
    <div className={classes.container}>
      <Typography variant="body2" className={classes.subtitle}>
        {LocalizedString.certificationRequestPage.labelCertificationRequest}
      </Typography>

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_OWNER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelAssetOwner}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_PIC}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelPic}
              disabled
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_CATEGORY}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelCategory}
              disabled
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CERTIFICATION_REQUEST_TYPE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.certificationRequestPage.placeholderSelect}
              label={LocalizedString.certificationRequestPage.labelCertificationType}
              disabled
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Paper className={classes.contentContainer}>
        <StyledTab data={tabs} onTabPressed={onTabPressed} activeTab={activeTab} />

        {renderDetailContent(initialValues, approvals, tabs, downloadingApprovals,
          downloadingDeleting, onPdfPressed, activeTab, classes)}

        {(checkingApprovalPermission || hasApprovalPermission) && (<VerticalSpacer />)}

        {(checkingApprovalPermission || hasApprovalPermission)
        && (<Divider className={classes.divider} />)}

        {(checkingApprovalPermission || hasApprovalPermission) && (<VerticalSpacer />)}

        {checkingApprovalPermission && renderActivityIndicator(classes)}

        {!checkingApprovalPermission && hasApprovalPermission && (
        <div className={classes.rowContainer}>
          <div style={{ flex: 1 }} />
          <AccentButton
            onClick={() => onApproveRejectPressed(
              LocalizedString.certificationRequestPage.msgRejection1Confirmation,
              LocalizedString.certificationRequestPage.msgRejection2Confirmation,
              LocalizedString.certificationRequestPage.labelRemarks,
              LocalizedString.certificationRequestPage.placeholderRemarks,
            )}
            variant="outlined"
            caption={LocalizedString.certificationRequestPage.buttonCaptionReject}
            disabled={approving || downloadingDeleting || downloadingApprovals}
            loading={rejecting}
            style={classes.rejectButton}
          />
          <AccentButton
            onClick={() => onApproveRejectPressed(
              LocalizedString.certificationRequestPage.msgApproval1Confirmation,
              LocalizedString.certificationRequestPage.msgApproval2Confirmation,
              LocalizedString.certificationRequestPage.labelRemarks,
              LocalizedString.certificationRequestPage.placeholderRemarks,
            )}
            variant="contained"
            caption={LocalizedString.certificationRequestPage.buttonCaptionApprove}
            disabled={rejecting || downloadingDeleting || downloadingApprovals}
            loading={approving}
          />
        </div>
        )}
      </Paper>

      <SelectFileDialog />
    </div>
  );
};

export default reduxForm({
  form: RXFORM_CERTIFICATION_REQUEST,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CertificationRequestDetailPage);

CertificationRequestDetailPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  approvals: PropTypes.arrayOf(PropTypes.object).isRequired,
  tabs: PropTypes.arrayOf(StyledTabShape).isRequired,
  approving: PropTypes.bool.isRequired,
  checkingApprovalPermission: PropTypes.bool.isRequired,
  downloadingApprovals: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  rejecting: PropTypes.bool.isRequired,
  hasApprovalPermission: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApproveRejectPressed: PropTypes.func.isRequired,
  onPdfPressed: PropTypes.func.isRequired,
  onTabPressed: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
};
