import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  firstStyle: {
    height: (props) => props.height,
  },
}));

const VerticalSpacer = ({ ...props }) => {
  const { firstStyle } = useStyles(props);
  return (
    <div className={`${firstStyle}`} />
  );
};

export default VerticalSpacer;

VerticalSpacer.propTypes = {
  height: PropTypes.number,
};

VerticalSpacer.defaultProps = {
  height: 8,
};
