import {
  sendGetRequest, sendPostRequest, sendPutRequest,
} from '../../helper';
import { sendPostFileRequest } from '../../helper/helper';
import {
  REST_URL_FONT_LIST, REST_URL_UPLOAD_TEMPLATE, REST_URL_SUBMIT_ANNOTATION,
  REST_URL_PREVIEW_PDF,
} from './constant';

export * from '../../helper';

export const downloadFont = async (token) => {
  const response = await sendGetRequest(REST_URL_FONT_LIST, token);
  return response;
};

export const submitAnnotation = async (body, token) => {
  const response = await sendPostRequest(REST_URL_SUBMIT_ANNOTATION, body, token);
  return response;
};

export const editAnnotation = async (body, token) => {
  const response = await sendPutRequest(REST_URL_SUBMIT_ANNOTATION, body, token);
  return response;
};

export const previewPdf = async (body, token) => {
  const response = await sendPostFileRequest(REST_URL_PREVIEW_PDF, body, token);
  return response;
};

export const uploadPDFTemplate = async (base64File, token) => {
  const body = {
    file: base64File,
  };

  const response = await sendPostRequest(REST_URL_UPLOAD_TEMPLATE, body, token);
  return response;
};
