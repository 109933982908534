import {
  ADDING_EDITING_CERTIFICATION_REQUEST, DOWNLOADING_CERTIFICATION_REQUESTS,
  DOWNLOADING_DELETING_CERTIFICATION_REQUEST, SET_CERTIFICATION_REQUEST_DIALOG_VISIBILITY,
  SET_CERTIFICATION_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_CERTIFICATION_REQUEST_SEARCH_TEXT, SET_CERTIFICATION_REQUEST_SELECTED_ORDER_BY,
  SET_CERTIFICATION_REQUEST_SELECTED_PAGE_SIZE, SET_CERTIFICATION_REQUEST_TAPPED_ID,
  SET_CERTIFICATION_REQUEST_ACTIVE_TAB, DOWNLOADING_MY_ASSET_LOCATIONS,
  SET_CERTIFICATION_REQUEST_SELECTED_CATEGORY, SET_CERTIFICATION_REQUEST_SELECTED_TYPE,
  SET_CERTIFICATION_REQUEST_SELECTED_OWNER, SET_MY_ASSET_LOCATION_SEARCH_TEXT,
  DOWNLOADING_CERTIFICATION_REQUEST_APPROVALS, APPROVING_CERTIFICATION_REQUEST,
  REJECTING_CERTIFICATION_REQUEST, SET_HAS_CERTIFICATION_REQUEST_APPROVAL_PERMISSION,
  CHECKING_CERTIFICATION_REQUEST_APPROVAL_PERMISSION,
} from '../action';
import { INITIAL_ORDER_BY_CERTIFICATION_REQUESTS } from '../../constant';

const initialState = {
  addingEditing: false,
  approving: false,
  checkingApprovalPermission: false,
  downloadingDeleting: false,
  downloading: false,
  downloadingApprovals: false,
  downloadingMyAssetLocations: false,
  dialogVisibility: false,
  hasApprovalPermission: false,
  rejecting: false,
  activeTab: '',
  dialogMode: '',
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_CERTIFICATION_REQUESTS,
  selectedCategory: null,
  selectedType: null,
  selectedOwner: null,
  myAssetLocationSearchBarText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_CERTIFICATION_REQUESTS:
      return { ...state, downloading: action.status };
    case DOWNLOADING_DELETING_CERTIFICATION_REQUEST:
      return { ...state, downloadingDeleting: action.status };
    case ADDING_EDITING_CERTIFICATION_REQUEST:
      return { ...state, addingEditing: action.status };
    case SET_CERTIFICATION_REQUEST_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_CERTIFICATION_REQUEST_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_CERTIFICATION_REQUEST_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_CERTIFICATION_REQUEST_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_CERTIFICATION_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_CERTIFICATION_REQUEST_DIALOG_VISIBILITY:
      return { ...state, dialogVisibility: action.status, dialogMode: action.mode };
    case SET_CERTIFICATION_REQUEST_ACTIVE_TAB:
      return { ...state, activeTab: action.text };
    case DOWNLOADING_MY_ASSET_LOCATIONS:
      return { ...state, downloadingMyAssetLocations: action.status };
    case SET_CERTIFICATION_REQUEST_SELECTED_CATEGORY:
      return { ...state, selectedCategory: action.option };
    case SET_CERTIFICATION_REQUEST_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    case SET_CERTIFICATION_REQUEST_SELECTED_OWNER:
      return { ...state, selectedOwner: action.option };
    case SET_MY_ASSET_LOCATION_SEARCH_TEXT:
      return { ...state, myAssetLocationSearchBarText: action.text };
    case DOWNLOADING_CERTIFICATION_REQUEST_APPROVALS:
      return { ...state, downloadingApprovals: action.status };
    case APPROVING_CERTIFICATION_REQUEST:
      return { ...state, approving: action.status };
    case REJECTING_CERTIFICATION_REQUEST:
      return { ...state, rejecting: action.status };
    case SET_HAS_CERTIFICATION_REQUEST_APPROVAL_PERMISSION:
      return { ...state, hasApprovalPermission: action.status };
    case CHECKING_CERTIFICATION_REQUEST_APPROVAL_PERMISSION:
      return { ...state, checkingApprovalPermission: action.status };
    default: return state;
  }
};
