import { reset } from 'redux-form';
import {
  addingEditingCertificationRequest, clearCertificationRequests, setAlertErrorMessage,
  setCertificationRequestAdvancedFilterDialogSelectedFilterString,
  setCertificationRequestSearchText, setCertificationRequestSelectedCategory,
  setCertificationRequestSelectedOrderBy, setCertificationRequestSelectedOwner,
  setCertificationRequestSelectedPageSize, setCertificationRequestSelectedType,
  setFunctionalPageMode,
} from '../simple-action';
import { addCertificationRequest } from '../../../helper';
import {
  INITIAL_ORDER_BY_CERTIFICATION_REQUESTS, PAGE_MODE_TABLE, RXFORM_CERTIFICATION_REQUEST,
} from '../../../constant';
import downloadCertificationRequestsAsync from './downloadCertificationRequestsAsync';

export default (selectedAssets) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingCertificationRequest(true));

    const { token } = getState().authentication;
    const { selectedCategory, selectedType, selectedOwner } = getState().uiCertificationRequest;

    const assetIds = selectedAssets.map((x) => x.id);

    await addCertificationRequest(selectedOwner, selectedCategory, selectedType, assetIds, token);

    dispatch(reset(RXFORM_CERTIFICATION_REQUEST));
    dispatch(setCertificationRequestSelectedCategory(''));
    dispatch(setCertificationRequestSelectedOwner(''));
    dispatch(setCertificationRequestSelectedType(''));
    dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    dispatch(clearCertificationRequests());
    dispatch(setCertificationRequestAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setCertificationRequestSearchText(''));
    dispatch(setCertificationRequestSelectedPageSize(20));
    dispatch(setCertificationRequestSelectedOrderBy(INITIAL_ORDER_BY_CERTIFICATION_REQUESTS));
    dispatch(downloadCertificationRequestsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingEditingCertificationRequest(false));
  }
};
