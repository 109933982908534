import { connect } from 'react-redux';
import { change } from 'redux-form';
import PDFList from './pdf-list.presentation';
import {
  resetColorPicker,
  generateDocumentTemplatePreviewAsync, setAnnotationDesignJsonVisibility,
  setAlertErrorMessage, setCreateAnnotationDialogPdfType,
  setCreateAnnotationDialogVisibility,
} from '../../redux/action';
import {
  DIALOG_TYPE_ADD, PDF_TYPE_IMAGE, PDF_TYPE_QR_CODE, PDF_TYPE_TEXT,
  RXFIELD_ANNOTATION_DESIGNER_ROTATION, RXFORM_ANNOTATION_DESIGNER,
} from '../../constant';
import { clearAnnotationDesigns } from '../../redux/action/simple-action';

const mapStateToProps = (state) => ({
  selectedImage: state.uiAnnotationDesigner.selectedImage,
  templatePdf: state.uiUploadPdf.templatePdf,
  disableClear: state.annotationDesigners.data.annotations.length === 0,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateNewText: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_TEXT));
    dispatch(resetColorPicker());
    dispatch(change(RXFORM_ANNOTATION_DESIGNER, RXFIELD_ANNOTATION_DESIGNER_ROTATION, '0'));
  },
  onCreateNewQrCode: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_QR_CODE));
    dispatch(resetColorPicker());
  },
  onCreateNewImage: (title) => {
    dispatch(setCreateAnnotationDialogVisibility(true, DIALOG_TYPE_ADD, title));
    dispatch(setCreateAnnotationDialogPdfType(PDF_TYPE_IMAGE));
    dispatch(resetColorPicker());
  },
  onShowJSONPressed: () => {
    dispatch(setAnnotationDesignJsonVisibility(true));
  },
  onPreviewClicked: () => {
    dispatch(generateDocumentTemplatePreviewAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onClearClicked: () => {
    dispatch(clearAnnotationDesigns());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PDFList);
