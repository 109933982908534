import {
  addCertificationRequest, approvingCertificationRequest, clearCertificationRequestApprovals,
  setAlertErrorMessage,
} from '../simple-action';
import { approveRejectCertificationRequest, downloadCertificationRequest } from '../../../helper';
import { CERT_STATUS_APPROVED } from '../../../constant';
import downloadCertificationRequestApprovalsAsync from './downloadCertificationRequestApprovalsAsync';
import checkHasCertificationRequetApprovalPermissionAsync from './checkHasCertificationRequetApprovalPermissionAsync';

export default (notes) => async (dispatch, getState) => {
  try {
    dispatch(approvingCertificationRequest(true));

    const { token } = getState().authentication;
    const { data } = getState().certificationRequests;
    const { tappedId } = getState().uiCertificationRequest;
    const found = data[tappedId];

    await approveRejectCertificationRequest(found.approvalId, CERT_STATUS_APPROVED, notes, token);
    const result = await downloadCertificationRequest(tappedId, token);

    dispatch(addCertificationRequest(result));
    dispatch(clearCertificationRequestApprovals());
    dispatch(downloadCertificationRequestApprovalsAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
    dispatch(checkHasCertificationRequetApprovalPermissionAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(approvingCertificationRequest(false));
  }
};
