import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { PAGE_MODE_TABLE } from '../../../constant';
import { changeAssetReminderFlagAsync, downloadAssetAsync, setAlertErrorMessage } from '../../../redux/action';
import { transformInitialValues } from '../../../helper';
import SettingTab from './setting-tab.presentation';

const getInitialValues = (state) => {
  const {
    assets, uiFunctionalPage, uiAsset,
  } = state;
  const { data } = assets;
  const { downloadingDeleting, tappedId } = uiAsset;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? data[tappedId] : {};
  const result = !isEmpty(found) ? transformInitialValues(found) : { hasReminder: false };
  return result;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  downloading: state.uiAsset.downloadingDeleting,
  changing: state.uiAsset.changingAssetReminderFlag,
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(downloadAssetAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onSubmitPressed: (reminderFlag) => {
    dispatch(changeAssetReminderFlagAsync(reminderFlag))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingTab);
