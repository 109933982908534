import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_DROPDOWN,
  PAGE_MODE_VIEW, PICKER_MODE_TIME,
  RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_SHIFT_BRANCH,
  RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
  RXFIELD_WORKING_SHIFT_END_AFTER_MIN,
  RXFIELD_WORKING_SHIFT_END_BEFORE_MIN,
  RXFIELD_WORKING_SHIFT_END_WORKING_HOUR,
  RXFIELD_WORKING_SHIFT_NAME,
  RXFIELD_WORKING_SHIFT_START_AFTER_MIN,
  RXFIELD_WORKING_SHIFT_START_BEFORE_MIN,
  RXFIELD_WORKING_SHIFT_START_WORKING_HOUR,
  RXFIELD_WORKING_SHIFT_CREATED_DATE,
  RXFORM_WORKING_SHIFT,
  RXSTATE_WORKING_SHIFTS,
  RXSTATE_WORKING_SHIFT_PAGE,
  status,
  FILTER_TYPE_DATE_RANGE,
  STATUS_ENABLED,
  STATUS_DISABLED,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormOutlinedTextField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateWorkingShift } from '../../validation';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    marginBottom: 20,
  },
  heading: {
    margin: 0,
  },
}));

const renderDialogContent = (
  defaultBranchValue, branches, addingEditing, loadingBranch,
  onAutoClockOutChanged, onChangeBranchText,
  onBranchOptionSelected, onEarliestClockInChanged, onEndWorkingHourChanged,
  onStartWorkingHourChanged, pageMode,
) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.workingShiftPage.placeholderWorkingShiftName}
            label={LocalizedString.workingShiftPage.placeholderWorkingShiftName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_START_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingShiftPage.placeholderStartWorkingHour}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onStartWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
        <Grid item container>
          <Grid item sm md>
            <Field
              name={RXFIELD_WORKING_SHIFT_START_BEFORE_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.workingShiftPage.placeholderStartBeforeMin}
              label={LocalizedString.workingShiftPage.placeholderStartBeforeMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_WORKING_SHIFT_START_AFTER_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.workingShiftPage.placeholderStartAfterMin}
              label={LocalizedString.workingShiftPage.placeholderStartAfterMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingShiftPage.placeholderEarliestClockIn}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onEarliestClockInChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_BRANCH}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.workingShiftPage.placeholderBranchName}
            label={LocalizedString.workingShiftPage.placeholderBranchName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={branches}
            value={defaultBranchValue}
            loading={loadingBranch}
            onChangeText={onChangeBranchText}
            onOptionSelected={onBranchOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_END_WORKING_HOUR}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingShiftPage.placeholderEndWorkingHour}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onEndWorkingHourChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
        <Grid item container>
          <Grid item sm md>
            <Field
              name={RXFIELD_WORKING_SHIFT_END_BEFORE_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.workingShiftPage.placeholderEndBeforeMin}
              label={LocalizedString.workingShiftPage.placeholderEndBeforeMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_WORKING_SHIFT_END_AFTER_MIN}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.workingShiftPage.placeholderEndAfterMin}
              label={LocalizedString.workingShiftPage.placeholderEndAfterMin}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WORKING_SHIFT_AUTO_CLOCK_OUT_AFTER_MINUTES}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.workingShiftPage.placeholderAutoClockOut}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            onChangeDate={onAutoClockOutChanged}
            pickerMode={PICKER_MODE_TIME}
            disableFuture
            required
          />
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const WorkingShiftPage = ({
  initialValues, branches, downloading, loadingBranch,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onAutoClockOutChanged, onCancelPressed, onChangeBranchText,
  onChangePage, onChangePageSize, onBranchOptionSelected,
  onCreatePressed, onDownloadPressed,
  onEarliestClockInChanged, onEditPressed, onEndWorkingHourChanged,
  onRefresh, onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onStartWorkingHourChanged, onSubmitPressed, onViewPressed,
  workingShifts, pageMode, addingEditing,
  currentWorkShiftStatus, enablingDisabling,
  onContextMenuPressed, onConfirmContextMenuPressed, isResyncAllowed, resyncing,
  onDuplicatePressed, isDuplicateAllowed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_WORKING_SHIFTS}
      uiPage={RXSTATE_WORKING_SHIFT_PAGE}
      tableColumns={[
        {
          title: LocalizedString.workingShiftPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.workingShiftPage.labelName, field: 'name', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelStartWorkingHour, field: 'startWorkingHour', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelEndWorkingHour, field: 'endWorkingHour', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelBranchName, field: 'branch.name', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
        { title: LocalizedString.workingShiftPage.labelStatus, field: 'status', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.workingShiftPage.labelCreatedDate,
          field: RXFIELD_WORKING_SHIFT_CREATED_DATE,
          type: FILTER_TYPE_DATE_RANGE,
        },
        {
          title: LocalizedString.workingShiftPage.labelBranch,
          field: 'branch',
          type: FILTER_TYPE_DROPDOWN,
          data: branches,
          loading: loadingBranch,
          onChangeFilterText: onChangeBranchText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.workingShiftPage.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmContextMenuPressed}
      onCreatePressed={onCreatePressed}
      onDownloadPressed={onDownloadPressed}
      onEditPressed={(id) => onEditPressed(id, workingShifts)}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={(id) => onViewPressed(id, workingShifts)}
      createNewButtonCaption={LocalizedString.workingShiftPage.buttonCaptionCreateNewWorkingShift}
      editButtonCaption={LocalizedString.workingShiftPage.buttonCaptionEditWorkingShift}
      title={LocalizedString.workingShiftPage.title}
      addDialogHeaderContainerStyle={classes.dialogHeader}
      createPermissionName="MDATA_CREATE_WORKINGSHIFT"
      editPermissionName="MDATA_EDIT_WORKINGSHIFT"
      savePermissionName="MDATA_GET_WORKINGSHIFT"
      useFullWidth
      disableDelete
      moreMenus={[
        {
          caption: LocalizedString.common.buttonCaptionEnable,
          disabled: currentWorkShiftStatus === STATUS_ENABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            LocalizedString.common.msgEnableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionDisable,
          disabled: currentWorkShiftStatus === STATUS_DISABLED || enablingDisabling,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            LocalizedString.common.msgDisableConfirmation),
        },
        {
          caption: LocalizedString.common.buttonCaptionDuplicate,
          disabled: !isDuplicateAllowed,
          onPress: (tappedId) => { onEditPressed(tappedId); onDuplicatePressed(); },
        },
        {
          caption: LocalizedString.common.buttonCaptionResync,
          disabled: resyncing || !isResyncAllowed,
          onPress: (tappedId) => onContextMenuPressed(tappedId,
            LocalizedString.common.msgResyncConfirmation),
        },
      ]}
    >
      {renderDialogContent(
        initialValues.branch.label, branches, addingEditing, loadingBranch,
        onAutoClockOutChanged, onChangeBranchText,
        onBranchOptionSelected, onEarliestClockInChanged, onEndWorkingHourChanged,
        onStartWorkingHourChanged, pageMode,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_WORKING_SHIFT,
  validate: rxformValidateWorkingShift,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WorkingShiftPage);

WorkingShiftPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingBranch: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resyncing: PropTypes.bool.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onAutoClockOutChanged: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeBranchText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onBranchOptionSelected: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onDuplicatePressed: PropTypes.func.isRequired,
  onEarliestClockInChanged: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEndWorkingHourChanged: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  isResyncAllowed: PropTypes.bool.isRequired,
  isDuplicateAllowed: PropTypes.bool.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onStartWorkingHourChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  workingShifts: PropTypes.objectOf(PropTypes.object).isRequired,
  pageMode: PropTypes.string.isRequired,
  currentWorkShiftStatus: PropTypes.string.isRequired,
};

WorkingShiftPage.defaultProps = {
};
