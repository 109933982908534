import {
  loggingInWithGoogle, addToken, addCurrentUser, setActiveSideMenuItem, setAlertConfirmationMessage,
  setAlertErrorMessage, setAlertInfoMessage, setAlertInputMessage, setAlertWarningMessage,
  setDrawerVisibility, setBackToUrl,
} from '../simple-action';
import { loginWithGoogle } from '../../../helper';
import LocalizedString from '../../../localization';
import { MENUID_DASHBOARD, ROUTE_NAME_DASHBOARD } from '../../../constant';

export default (authCode, navigateTo, redirectUrl, backTo) => async (dispatch) => {
  try {
    dispatch(loggingInWithGoogle(true));
    const auth = await loginWithGoogle(authCode);
    const { token, user } = auth;

    if (!user.permissions.WEB_ADMIN_LOGIN) {
      throw new Error(LocalizedString.loginScreen.errMsgNoPermissionToLogin);
    }

    dispatch(addToken(token));
    dispatch(addCurrentUser(user));

    dispatch(setActiveSideMenuItem(MENUID_DASHBOARD));
    dispatch(setDrawerVisibility(true));

    if (redirectUrl) {
      dispatch(setBackToUrl(backTo));
      navigateTo(redirectUrl);
    } else {
      navigateTo(ROUTE_NAME_DASHBOARD);
    }
  } finally {
    dispatch(setAlertErrorMessage(''));
    dispatch(setAlertInfoMessage(''));
    dispatch(setAlertWarningMessage(''));
    dispatch(setAlertConfirmationMessage(''));
    dispatch(setAlertInputMessage('', ''));
    dispatch(loggingInWithGoogle(false));
  }
};
