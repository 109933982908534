import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_FONT = 'DOWNLOADING_FONT';
export const downloadingFont = makeActionCreator(DOWNLOADING_FONT, 'status');

export const SET_FONT = 'SET_FONT';
export const setFont = makeActionCreator(SET_FONT, 'data');

export const CLEAR_FONT = 'CLEAR_FONT';
export const clearFont = makeActionCreator(CLEAR_FONT);

export const SET_SELECTED_FONT = 'SET_SELECTED_FONT';
export const setSelectedFont = makeActionCreator(SET_SELECTED_FONT, 'font');

export const DOWNLOADING_ANNOTATION_DESIGNS = 'DOWNLOADING_ANNOTATION_DESIGNS';
export const downloadingAnnotationDesigns = makeActionCreator(DOWNLOADING_ANNOTATION_DESIGNS, 'status');

export const SET_ANNOTATION_DESIGNS = 'SET_ANNOTATION_DESIGNS';
export const setAnnotationDesigns = makeActionCreator(SET_ANNOTATION_DESIGNS, 'data');

export const ADD_ANNOTATION_DESIGN = 'ADD_ANNOTATION_DESIGN';
export const addAnnotationDesign = makeActionCreator(ADD_ANNOTATION_DESIGN, 'data');

export const ADD_ANNOTATION = 'ADD_ANNOTATION';
export const addAnnotation = makeActionCreator(ADD_ANNOTATION, 'data');

export const EDIT_ANNOTATION_DESIGN = 'EDIT_ANNOTATION_DESIGN';
export const editAnnotationDesign = makeActionCreator(EDIT_ANNOTATION_DESIGN, 'data');

export const CLEAR_ANNOTATION_DESIGNS = 'CLEAR_ANNOTATION_DESIGNS';
export const clearAnnotationDesigns = makeActionCreator(CLEAR_ANNOTATION_DESIGNS);

export const ADDING_EDITING_ANNOTATION_DESIGN = 'ADDING_EDITING_ANNOTATION_DESIGN';
export const addingEditingAnnotationDesign = makeActionCreator(ADDING_EDITING_ANNOTATION_DESIGN, 'status');

export const DELETING_ANNOTATION_DESIGN = 'DELETING_ANNOTATION_DESIGN';
export const deletingAnnotationDesign = makeActionCreator(DELETING_ANNOTATION_DESIGN, 'status');


export const SET_ANNOTATION_DESIGN_SELECTED_PAGE_SIZE = 'SET_ANNOTATION_DESIGN_SELECTED_PAGE_SIZE';
export const setAnnotationDesignSelectedPageSize = makeActionCreator(SET_ANNOTATION_DESIGN_SELECTED_PAGE_SIZE, 'size');

export const SET_ANNOTATION_DESIGN_SELECTED_ORDER_BY = 'SET_ANNOTATION_DESIGN_SELECTED_ORDER_BY';
export const setAnnotationDesignSelectedOrderBy = makeActionCreator(SET_ANNOTATION_DESIGN_SELECTED_ORDER_BY, 'order');

export const SET_ANNOTATION_DESIGN_TAPPED_ID = 'SET_ANNOTATION_DESIGN_TAPPED_ID';
export const setAnnotationDesignTappedId = makeActionCreator(SET_ANNOTATION_DESIGN_TAPPED_ID, 'id');

export const SET_ANNOTATION_DESIGN_JSON_VISIBILITY = 'SET_ANNOTATION_DESIGN_JSON_VISIBILITY';
export const setAnnotationDesignJsonVisibility = makeActionCreator(SET_ANNOTATION_DESIGN_JSON_VISIBILITY, 'status');

export const SET_CREATE_ANNOTATION_DIALOG_VISIBILITY = 'SET_CREATE_ANNOTATION_DIALOG_VISIBILITY';
export const setCreateAnnotationDialogVisibility = makeActionCreator(SET_CREATE_ANNOTATION_DIALOG_VISIBILITY, 'status', 'dialogType', 'title');

export const SET_CREATE_ANNOTATION_DIALOG_PDF_TYPE = 'SET_CREATE_ANNOTATION_DIALOG_PDF_TYPE';
export const setCreateAnnotationDialogPdfType = makeActionCreator(SET_CREATE_ANNOTATION_DIALOG_PDF_TYPE, 'pdfType');

export const SET_UPLOADING_PDF_VISIBILITY = 'SET_UPLOADING_PDF_VISIBILITY';
export const setUploadingPDFVisibility = makeActionCreator(SET_UPLOADING_PDF_VISIBILITY, 'status');

export const UPLOADING_PDF_TEMPLATE = 'UPLOADING_PDF_TEMPLATE';
export const uploadingPDFTemplate = makeActionCreator(UPLOADING_PDF_TEMPLATE, 'status');

export const SET_PDF_TEMPLATE = 'SET_PDF_TEMPLATE';
export const setPDFTemplate = makeActionCreator(SET_PDF_TEMPLATE, 'data');

export const SET_TEMPLATE_TOTAL_PAGES = 'SET_TEMPLATE_TOTAL_PAGES';
export const setTemplateTotalPages = makeActionCreator(SET_TEMPLATE_TOTAL_PAGES, 'totalPages');

export const SET_ANNOTATION_DESIGN_SELECTED_IMAGE = 'SET_ANNOTATION_DESIGN_SELECTED_IMAGE';
export const setAnnotationDesignSelectedImage = makeActionCreator(SET_ANNOTATION_DESIGN_SELECTED_IMAGE, 'url', 'fileName');

export const SUBMITTING_ANNOTATION = 'SUBMITTING_ANNOTATION';
export const submittingAnnotation = makeActionCreator(SUBMITTING_ANNOTATION, 'status');

export const SET_PDF_URL = 'SET_PDF_URL';
export const setPDFUrl = makeActionCreator(SET_PDF_URL, 'url');

export const SET_PDF_DATA = 'SET_PDF_DATA';
export const setPDFData = makeActionCreator(SET_PDF_DATA, 'data');

export const SET_PDF_FILE_NAME = 'SET_PDF_FILE_NAME';
export const setPDFFileName = makeActionCreator(SET_PDF_FILE_NAME, 'data');

export const PREVIEWING_PDF_TEMPLATE = 'PREVIEWING_PDF_TEMPLATE';
export const previewingPDFTemplate = makeActionCreator(PREVIEWING_PDF_TEMPLATE, 'status');
