import {
  clearAssetChildren, deletingAssetChild, setAlertErrorMessage,
  setAssetChildAdvancedFilterDialogSelectedFilterString, setAssetChildSearchText,
  setAssetChildSelectedOrderBy, setAssetChildSelectedPageSize,
} from '../simple-action';
import { INITIAL_ORDER_BY_ASSET_CHILDREN } from '../../../constant';
import { deleteAssetChild } from '../../../helper';
import downloadAssetChildrenAsync from './downloadAssetChildrenAsync';

export default (assetChildId) => async (dispatch, getState) => {
  try {
    dispatch(deletingAssetChild(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiAsset;

    await deleteAssetChild(assetChildId, token);

    dispatch(clearAssetChildren());
    dispatch(setAssetChildAdvancedFilterDialogSelectedFilterString(`assetParent.id=${tappedId}`));
    dispatch(setAssetChildSearchText(''));
    dispatch(setAssetChildSelectedPageSize(20));
    dispatch(setAssetChildSelectedOrderBy(INITIAL_ORDER_BY_ASSET_CHILDREN));
    dispatch(downloadAssetChildrenAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(deletingAssetChild(false));
  }
};
