/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, CircularProgress, FormControl, FormHelperText, InputLabel, makeStyles, NoSsr,
  Typography,
} from '@material-ui/core';
import { CheckBox, CheckBoxOutlineBlank, Close } from '@material-ui/icons/';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import styled from 'styled-components';
import {
  COLOR_BODY_TEXT, COLOR_DANGEROUS, COLOR_ICON, COLOR_POLYGON, COLOR_PRIMARY, COLOR_SECONDARY,
} from '../constant';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  form: {
    margin: '12px 8px 4px',
    '& label.MuiInputLabel-root': {
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      width: '70%',
    },
    '& label.MuiInputLabel-shrink': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& label.Mui-focused': {
      color: COLOR_BODY_TEXT,
      fontSize: 14,
      overflow: 'visible',
      textOverflow: 'clip',
      whiteSpace: 'nowrap',
      width: '100%',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLOR_SECONDARY,
      },
    },
    width: '100%',
    minWidth: 250,
  },
  labelContainer: {
    marginBottom: 10,
  },
  label: {
    flexDirection: 'row',
    display: 'flex',
    color: COLOR_ICON,
    fontWeight: 400,
    marginLeft: -12,
  },
  requiredSymbol: {
    color: COLOR_POLYGON,
    fontSize: 14,
    fontWeight: 400,
    marginTop: -2,
    marginLeft: 2,
  },
  loadingIcon: {
    margin: '0px 2px',
    color: COLOR_PRIMARY,
  },
  helperText: {
    fontWeight: 'bold',
    color: COLOR_DANGEROUS,
  },
}));

const InputWrapper = styled('div')`
  width: 100%;
  min-width: 250px;
  border: 1px solid #D7E2F9;
  background-color: #fff;
  border-radius: 6px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #D7E2F9;
  }

  &.focused {
    border-color: #d7e2f9;
    box-shadow: 0 0 0 0.2px #d7e2f9;
  }

  & input {
    font-size: 12px;
    height: 16px;
    box-sizing: border-box;
    padding: 4px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <Close onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 14px;
  margin: 2px;
  line-height: 12px;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #d7e2f9;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

const Listbox = styled('ul')`
  width: 100%;
  min-width: 250px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #E7F6FF;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #D7E2F9;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

const AutocompleteCheckboxField = ({
  options,
  disabled, error, hidden, loading, required,
  onChangeText, onOptionSelected,
  helperText, label,
  value: defaultValue,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const isRequired = required ? '*' : '';

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'customized-hook',
    disableCloseOnSelect: true,
    multiple: true,
    value: defaultValue || [],
    inputValue,
    disabled,
    loading,
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    getOptionLabel: (option) => (typeof option === 'object' ? option.label : (option || '')),
    getOptionSelected: (option, selectedValue) => {
      if (typeof option === 'object') {
        return option.value === selectedValue.value;
      }
      return option === selectedValue || '';
    },
    onChange: (event, option) => onOptionSelected(option),
    onInputChange: (event, text, reason) => {
      if (reason === 'input') {
        setInputValue(text);
        onChangeText(text);
      }
    },
    options,
    renderOption: (option, { selected }) => (
      <>
        <Checkbox
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {typeof option === 'object' ? option.label : (option || '')}
      </>
    ),
    ...props,
  });

  useEffect(() => {
    if (!value.length) {
      setInputValue('');
    }
  }, [value.length]);

  if (!hidden) {
    return (
      <div className={classes.container}>
        <FormControl className={classes.form} variant="outlined" error={error}>
          <div className={classes.labelContainer}>
            <InputLabel shrink htmlFor="bootstrap-input" className={classes.label}>
              {label}
              <Typography className={classes.requiredSymbol}>
                {isRequired}
              </Typography>
            </InputLabel>
          </div>

          <NoSsr>
            <div>
              <div {...getRootProps()}>
                <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
                  {value.map((option, index) => (
                    <Tag label={option.label} {...getTagProps({ index })} />
                  ))}

                  <input {...getInputProps()} />

                  {loading ? <CircularProgress className={classes.loadingIcon} size={12} /> : null}
                </InputWrapper>
              </div>
              {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                  {groupedOptions.map((option, index) => (
                    <li {...getOptionProps({ option, index })}>
                      <span>{option.label}</span>
                    </li>
                  ))}
                </Listbox>
              ) : null}
            </div>
          </NoSsr>

          <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </FormControl>
      </div>
    );
  }
  return null;
};

export default AutocompleteCheckboxField;

AutocompleteCheckboxField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  onChangeText: PropTypes.func,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
};

AutocompleteCheckboxField.defaultProps = {
  disabled: false,
  error: false,
  hidden: false,
  loading: false,
  required: false,
  onChangeText: () => {},
  onOptionSelected: () => {},
  helperText: '',
  label: '',
  value: [],
};
