import { downloadAttendanceSummaryTotalUsers } from '../../../helper';
import { downloadingAttendanceSummaryTotalUsers, setAttendanceSummaryTotalUsers } from '../../../module/attendance/redux/action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceSummaryTotalUsers(true));

    const { authentication } = getState();
    const { token } = authentication;

    const result = await downloadAttendanceSummaryTotalUsers(token);

    dispatch(setAttendanceSummaryTotalUsers(result));
  } finally {
    dispatch(downloadingAttendanceSummaryTotalUsers(false));
  }
};
