/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { EditableTableField } from '../../../component';
import { REVERSED_ISO_DATE_FORMAT, ROUTE_NAME_ASSET_DETAIL } from '../../../constant';
import LocalizedString from '../../../localization';
import { toMoment } from '../../../helper';

const pdfIcon = require('../../../../../asset/pdf-icon.png');

const CertificatesTab = ({
  assetCertificates, downloading,
  onAppear, onChangePage, onChangePageSize, onPdfPressed, onSortPressed,
  currentPage, totalCount, selectedPageSize, orderBy,
}) => {
  const isDetailRoute = useRouteMatch(ROUTE_NAME_ASSET_DETAIL);
  const detailIdParam = isDetailRoute?.params?.id;

  useEffect(() => onAppear(detailIdParam), [onAppear, detailIdParam]);

  return (
    <EditableTableField
      data={assetCertificates}
      loading={downloading}
      tableColumns={[
        {
          title: LocalizedString.assetPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        {
          title: LocalizedString.assetPage.labelDate,
          field: 'issuedDate',
          sorting: !downloading,
          render: ({ issuedDate }) => (issuedDate
            ? toMoment(issuedDate).format(REVERSED_ISO_DATE_FORMAT) : null),
        },
        { title: LocalizedString.assetPage.labelResultType, field: 'resultType', sorting: !downloading },
        {
          title: LocalizedString.assetPage.labelFile,
          field: 'file',
          sorting: !downloading,
          render: ({ asset: { assetNumber }, file, issuedDate }) => (file ? (
            <IconButton
              aria-label="download file"
              onClick={() => onPdfPressed(assetNumber, file, issuedDate
                ? toMoment(issuedDate).format(REVERSED_ISO_DATE_FORMAT) : null)}
            >
              <img alt="cert-file" src={pdfIcon} width={20} height={20} />
            </IconButton>
          ) : null),
        },
        {
          title: LocalizedString.assetPage.labelExpired,
          field: 'expiredDate',
          sorting: !downloading,
          render: ({ expiredDate }) => (expiredDate
            ? toMoment(expiredDate).format(REVERSED_ISO_DATE_FORMAT) : null),
        },
        { title: LocalizedString.assetPage.labelRemark, field: 'remark', sorting: !downloading },
      ]}
      disabled
      disableToolbar
      paging
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onSortPressed={onSortPressed}
      currentPage={currentPage}
      totalCount={totalCount}
      selectedPageSize={selectedPageSize}
      orderBy={orderBy}
    />
  );
};

export default CertificatesTab;

CertificatesTab.propTypes = {
  assetCertificates: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onPdfPressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  selectedPageSize: PropTypes.number.isRequired,
  orderBy: PropTypes.string.isRequired,
};
